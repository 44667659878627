import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RoleService } from 'src/app/@core/services/role.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-rs-assign-report',
  templateUrl: './rs-assign-report.component.html',
  styleUrls: ['./rs-assign-report.component.scss']
})

export class RsAssignReportComponent implements OnInit {

 companyDD :any =  [{
    id: 0,
    company_name: ''}];
  buDD = [{
    id: 0,
    name: ''}];
  DepDD = [{
    id: 0,
    name: ''}];
  DesignationDD = [{
    id: 0,
    name: ''}];
  BranchDD = [{
    id: 0,
    name: ''}];
  cityDD = [{
    id: 0,
    name: ''}];
  gradeDD = [{
    id: 0,
    name: ''}];
  employeeDropdown:any  = [];
  submitted             = false;
  disabled              = false;
  saveClicked           = false;
  alertToggle           = false;
  priorityFlag          = true;

 @Output() submitmethod = new EventEmitter();

  constructor(
    public fb:FormBuilder,
    private roleService:RoleService,
    private reportService:ReportsService,
    public messageService : MessageService,
    public appservice:AppService
    ) { }

  ngOnInit(): void {
    // this.employeeChange();
  }

  ngAfterViewInit(){
    this.makeCheckRadioButton();
    this.getCompanyList();
    this.getBUList();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getGradeList();
    this.getEmployeeList();
  }

  @Input()formGp : FormGroup = this.fb.group({
    company         : [''],
    businessunit    : [''],
    department      : [''],
    designation     : [''],
    grade           : [''],
    branch          : [''],
    employee        : ['']
  });

  /************ GET COMPANY LIST FUNCTION *****************/
  getCompanyList(){
    this.roleService.getCompanyList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.companyDD = res.body;
            this.selectAllForDropdownItems(this.companyDD);
      }
    });
  }

  // New design updation start
  makeCheckRadioButton(){
   if(this.formGp.value.employee.length == 0){
    this.priorityFlag  =  true;
   }else{
    this.priorityFlag  =  false;
   }
  }

  priorityFunction(e:any){
    if(e.target.id == 'assign_ou'){
      e.target.checked   = true;
      this.priorityFlag  =  true;
      this.setDropDownValue();
    }else{
      e.target.checked   = true;
      this.priorityFlag  =  false;
      this.setDropDownValue();
    }
  }

  setDropDownValue(){
    this.formGp.patchValue({
      company         : [],
      businessunit    : [],
      department      : [],
      designation     : [],
      branch          : [],
      grade           : [],
      employee        : []
    });
  }
  // New design updation end

  /************ GET BU LIST FUNCTION *****************/
  getBUListFunction(){
    this.formGp.get('businessunit')?.reset([]);
    this.buDD   = [];
    this.getBUList();
  }

  getBUList(){
    if(this.formGp.value.company != undefined && this.formGp.value.company != null && this.formGp.value.company != ''){
      this.buDD   = [];
      let comp    = this.formGp.value.company;
      this.reportService.getBuList(comp).subscribe((res: any) => {
        this.buDD = res;
        this.selectAllForDropdownItems(this.buDD);
      });
    }
  }

  /************ GET DEPT LIST FUNCTION *****************/
  getDepList(){
    this.roleService.getDepartmentList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.DepDD = res.body;
           this.selectAllForDropdownItems(this.DepDD);
      }
    });
  }

  /************ GET DESIGNATION LIST FUNCTION *****************/
  getDesignationList(){
    this.roleService.getDesignationList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.DesignationDD = res.body;
          this.selectAllForDropdownItems(this.DesignationDD);
      }
    });
  }

  /************ GET BRANCH LIST FUNCTION *****************/
  getBranchList(){
    this.roleService.getBranchList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.BranchDD = res.body;
          this.selectAllForDropdownItems(this.BranchDD);
      }
    });
  }

  /************ GET GRADE LIST FUNCTION *****************/
  getGradeList(){
    this.roleService.getGradeList().subscribe((res: any) => {
      if(res.status == 200 && res.body.length > 0) {
          this.gradeDD = res.body;
          this.selectAllForDropdownItems(this.gradeDD);
      }
    });
  }

  /************ GET EMPLOYEE LIST FUNCTION *****************/
  getEmployeeList(){
    this.reportService.getempList(true)
    .subscribe((res: any) => {
        if (res.length > 0) {
          for(let i=0;i<res.length;i++){
            res[i]['name'] = res[i]['first_name'];
            if(res[i]['first_name'] != null)
              res[i]['name']  = res[i]['first_name']+" ";
            if(res[i]['middle_name'] != null)
              res[i]['name'] += res[i]['middle_name']+" ";
            if(res[i]['last_name'] != null)
              res[i]['name'] += res[i]['last_name'];
            res[i]['name']   +=" ("+res[i]['employee_code']+")";

          }
          this.employeeDropdown = res;
          let len = this.employeeDropdown.length;
          for (let i = 0; i < len; i++) {
          this.employeeDropdown[i]['fullname']  = "";
          if(this.employeeDropdown[i]['first_name'] != null)
           this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name']+" ";
          if(this.employeeDropdown[i]['middle_name'] != null)
           this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name']+" ";
          if(this.employeeDropdown[i]['last_name'] != null)
           this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
          }
          this.selectAllForDropdownItems(this.employeeDropdown);
        }
    })
  }

  
  get f() {return  this.formGp.controls; }

  /************ CLEAR DROP DOWN VALUE FUNCTION *****************/
  clearcommonForm(item:any,index:number){
    if(item == "company"){
      let daata = this.formGp.value.company;
      daata.splice(index, 1);
      this.formGp.patchValue({
        company: daata,
        businessunit: []
      });
      // this.formGp.get('businessunit')?.reset('');
      // this.getBUList();
      this.getBUListFunction();
    }
    else if(item == "businessunit"){
      let daata = this.formGp.value.businessunit;
      daata.splice(index, 1);
      this.formGp.patchValue({
        businessunit: daata
      });
    }else if(item == "department"){
      let daata = this.formGp.value.department;
      daata.splice(index, 1);
      this.formGp.patchValue({
        department: daata
      });
    }
    else if(item == "designation"){
      let daata = this.formGp.value.designation;
      daata.splice(index, 1);
      this.formGp.patchValue({
        designation: daata
      });
    }else if(item == "grade"){
      let daata = this.formGp.value.grade;
      daata.splice(index, 1);
      this.formGp.patchValue({
        grade: daata
      });
    }else if(item == "employee"){
      let daata = this.formGp.value.employee;
      // console.log("data", daata);
      daata.splice(index, 1);
      // console.log("datasaaa", daata);
      this.formGp.patchValue({
        employee: daata
      });
      // if(this.formGp.value.employee.length == 0 && (this.formGp.value.company?.length != 0 || this.formGp.value.businessunit?.length != 0 || this.formGp.value.department?.length != 0 || this.formGp.value.designation?.length != 0 || this.formGp.value.grade?.length != 0 || this.formGp.value.branch?.length != 0)){
      //   // this.priorityFlag = true;
      // }else{
      //   // this.priorityFlag = false;
      // }
      // this.employeeChange();
    } else if(item == "branch"){
      let daata = this.formGp.value.branch;
      daata.splice(index, 1);
      this.formGp.patchValue({
        branch: daata
      });
    }
  }

  /********* FORM VALIDATION  ****************/
  checkcommonFormValid(){

    if(this.formGp.value.company?.length != 0 || this.formGp.value.businessunit?.length != 0 || this.formGp.value.department?.length != 0 || this.formGp.value.designation?.length != 0 || this.formGp.value.grade?.length != 0 || this.formGp.value.branch?.length != 0 || this.formGp.value.employee?.length != 0){
      return false;
    } else{
      return true;
    }

  }

  /********* SUBMIT ****************/
  validateRights() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.saveClicked = true;
    this.submitmethod.emit();
  }

  /********* DROP DOWN SELECT ALL FUNCTION ****************/
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  /********* PRIORITY CHECKING FUNCTION ****************/
  // employeeChange(){
  //   this.alertToggle = false;
  //   if(this.formGp.value.employee.length != 0  ){
  //     this.formGp.get('company')?.setValue('');
  //     this.formGp.get('businessunit')?.setValue('');
  //     this.formGp.get('department')?.setValue('');
  //     this.formGp.get('designation')?.setValue('');
  //     this.formGp.get('grade')?.setValue('');
  //     this.formGp.get('branch')?.setValue('');

  //     this.formGp.get('company')?.disable();
  //     this.formGp.get('businessunit')?.disable();
  //     this.formGp.get('department')?.disable();
  //     this.formGp.get('designation')?.disable();
  //     this.formGp.get('grade')?.disable();
  //     this.formGp.get('branch')?.disable();
  //   }else{
  //     this.formGp.get('company')?.enable();
  //     this.formGp.get('businessunit')?.enable();
  //     this.formGp.get('department')?.enable();
  //     this.formGp.get('designation')?.enable();
  //     this.formGp.get('grade')?.enable();
  //     this.formGp.get('branch')?.enable();
  //   }
  // }

  employeeClick(){
    if(this.formGp.value.employee.length == 0 && (this.formGp.value.company?.length != 0 || this.formGp.value.businessunit?.length != 0 || this.formGp.value.department?.length != 0 || this.formGp.value.designation?.length != 0 || this.formGp.value.grade?.length != 0 || this.formGp.value.branch?.length != 0)){
      this.alertToggle = true;
    }

  }

  // infoOk(){
  //   this.priorityFlag = false;
  //   this.employeeChange();
  // }
}
