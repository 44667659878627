import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-final-settlement-resignation-details',
  templateUrl: './final-settlement-resignation-details.component.html',
  styleUrls: ['./final-settlement-resignation-details.component.scss']
})
export class FinalSettlementResignationDetailsComponent implements OnInit {

  @Output() submitmethod       = new EventEmitter();
  @Output() isSalaryProsessed  = new EventEmitter();
  @Output() resignationEmitter = new EventEmitter();

  empId:any               = "";
  loader                  = false;
  saveBtnClicked          = false

  @Input()resignationData:any ;
  @Input()salary_hold:any    ;
  @Input()resigloader:any;
  @Input()allowSave :any;
  @Input() errorMsg:any;
  @Input() payrollStatus:any;
  @Output() warningMsg = new EventEmitter();

  // for month and year dropdown
  yearList : any      = [];
  listOfMonth         = [ {id:1,value:"January"},
                          {id:2,value:"February"},
                          {id:3,value:"March"},
                          {id:4,value:"April"},
                          {id:5,value:"May"},
                          {id:6,value:"June"},
                          {id:7,value:"July"},
                          {id:8,value:"August"},
                          {id:9,value:"September"},
                          {id:10,value:"October"},
                          {id:11,value:"November"},
                          {id:12,value:"December"},
                        ];
  currrentMonth       = moment(new Date()).format('MMMM')
  currentYear         = new Date().getFullYear();

  leavingDate : any
  leavingDateArr : any
  settlementValid : any

  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    public fb: FormBuilder,
    public datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.loader = true;
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['id'])){
        this.empId = parseInt(params['id']);

      }
    });
    this.yearListFunction()

}

  @Input() formGp: FormGroup = this.fb.group({
    pay_month  : ['',Validators.required],
    pay_year   : ['',Validators.required],
  })

  get f() { return this.formGp.controls; }

  resignationDetails(){

    let month = (this.formGp.get('pay_month')?.value == null || this.formGp.get('pay_month')?.value == '') ? '' : this.formGp.get('pay_month')?.value;

    let year = (this.formGp.get('pay_year')?.value == null || this.formGp.get('pay_year')?.value == '') ? null : this.formGp.get('pay_year')?.value;

    this.finalSettlementService.getResignationDetails(this.empId, 'resignation_details',month,year).subscribe((res: any) => {

      this.resignationData = res?.data;

      if(res?.data?.settlement_month == null || res?.data?.settlement_month == '' || res?.data?.settlement_year == null || res?.data?.settlement_year == ''){
        this.formGp.get('pay_month')?.setValue(this.currrentMonth);
        this.formGp.get('pay_year')?.setValue(this.currentYear);
      }else{
        this.formGp.get('pay_month')?.setValue(res?.data?.settlement_month);
        this.formGp.get('pay_year')?.setValue(res?.data?.settlement_year);
      }

      if(this.resignationData?.salary_hold?.length > 0){
        for(let i=0; i<this.resignationData?.salary_hold?.length; i++){
          this.salary_hold.push(this.resignationData?.salary_hold[i]?.['pay_month']+' '+ this.resignationData?.salary_hold[i]?.['pay_year'])
        }
      }

      if(this.resignationData?.salary_structure_exist){
        if(this.resignationData?.relieving_month_payroll_details?.error == 'Salary is not processed for the leaving month, kindly process the salary before processing the Final settlement.'){
          this.errorMsg = "Salary is not processed for the leaving month, kindly process the salary before processing the Final settlement.";
          this.allowSave = false;
          this.isSalaryProsessed.emit(this.allowSave);
        }
        else if(this.resignationData?.relieving_month_payroll_details?.error == 'Salary is already Processed for the selected month.'){
          this.errorMsg = "Selected month salary is already processed.";
          this.allowSave = false;
          this.isSalaryProsessed.emit(this.allowSave);
        }
        else if(this.resignationData?.relieving_month_payroll_details?.error == 'Attendance is already processed for the selected month through payroll.'){
          this.errorMsg = "Selected month's attendance is processed in run payroll. Please process the salary from run payroll or unprocess the attendance from the run payroll  to  continue the settlement.";
          this.allowSave = false;
          this.isSalaryProsessed.emit(this.allowSave);
        }
        else if(this.resignationData?.error != null && this.resignationData?.error != ''){
          this.errorMsg = this.resignationData?.error;
          this.allowSave = false;
          this.isSalaryProsessed.emit(this.allowSave);
        }else{
          this.errorMsg  = '';
          this.allowSave = true;
          this.isSalaryProsessed.emit(this.allowSave);

          if(this.formGp.valid && this.allowSave){
            this.submitmethod.emit({'pay_month':this.formGp?.value?.pay_month, 'pay_year':this.formGp?.value?.pay_year});
          }
        }
      }else{
        this.errorMsg = "Employee's salary structure is not yet assigned.";
        this.allowSave = false;
        this.isSalaryProsessed.emit(this.allowSave);
      }

      this.loader = false;
    });
  }

  submitResignationDetails(){
    this.saveBtnClicked = true;
    this.resignationDetails();
  }

  // Year dropdown
  yearListFunction(){
    this.yearList=[];
     var i:number;
    for(i = this.currentYear;i<this.currentYear+2;i++){
      this.yearList.push(String(i))
    }
    this.yearList.push(String(this.currentYear-1))

  }

  // change function for month and year dropdown
  changeAttendanceMonth(key: any, field: any) {

    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.warningMsg.emit();
      this.formGp.get('pay_month')?.setValue(this.resignationData?.settlement_month);
      this.formGp.get('pay_year')?.setValue(this.resignationData?.settlement_year);
    }else{

    this.leavingDate     = this.resignationData?.leaving_date
    this.leavingDateArr  = this.leavingDate.split('-')
    var date             = new Date(Date.parse(key +" 1, "+this.currentYear+""))
    this.settlementValid = String(date.getMonth() + 1).padStart(2, '0');

    if(field == 'month')
      this.formGp.get('pay_month')?.setValue(key)
    else
      this.formGp.get('pay_year')?.setValue(key);

    const leavingYearMonth = new Date(new Date(this.resignationData?.leaving_date).getFullYear(), new Date(this.resignationData?.leaving_date).getMonth());
    const settleYearMonth = new Date(this.formGp.get('pay_year')?.value, this.appService.getMonthNumberFromName(this.formGp.get('pay_month')?.value));

    if(this.settlementValid < this.leavingDateArr[1]){
        this.formGp?.get('pay_month')?.setErrors({
          'monthValidOne' : true
        })

        this.isSalaryProsessed.emit(false);
        this.errorMsg = 'Settlement month should be the last working month or a future month.';

      }else if(this.resignationData?.relieving_month_payroll_details?.status == 'Unprocessed' && (this.datePipe.transform(leavingYearMonth, 'yyyy-MM') != this.datePipe.transform(settleYearMonth, 'yyyy-MM'))){
        this.isSalaryProsessed.emit(false);
        this.errorMsg = "Salary unprocessed for the leaving month. Kindly set the leaving month as the settlement month.";
      }
      // else if(this.settlementValid == this.leavingDateArr[1] && this.resignationData?.relieving_month_payroll_details?.status != 'Unprocessed'){
      //   this.formGp?.get('pay_month')?.setErrors({
      //     'monthValidTwo' : true
      //   })

      //   this.isSalaryProsessed.emit(false);
      //   this.errorMsg = 'Selected month salary is already processed.';

      // }
      else{
        this.resignationEmitter.emit();
      }
    // }
    // else{
    //   this.formGp.get('pay_year')?.setValue(key);
    //   this.resignationEmitter.emit();
    // }
  }

  }


}
