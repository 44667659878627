import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { PayComponentService } from 'src/app/@core/services/pay-component.service';
import { MessageService } from 'src/app/message.global';
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-pc-define',
  templateUrl: './pc-define.component.html',
  styleUrls: ['./pc-define.component.scss']
})
export class PcDefineComponent implements OnInit {

  isDefaultSetings              = false;
  submitted : boolean           = false;
  checkBoxError                 = false;
  @Input()disabled              = false;
  checkarray:any                = [];
  @Input()componentType:any     = [];
  @Input()disabledComponent:any = [];
  @Output() submitmethod        = new EventEmitter();
  @Output() defaultData         = new EventEmitter();
  @Output() perquisiteSec       = new EventEmitter();
  @Output() paytypeChange       = new EventEmitter();
  @Output() prorateRestrictions = new EventEmitter();
  @Input()nameError:any;
  @Input()componentError:any;
  @Input() editflag:any
  @Input() defaultCompName:any;
  @Input() leaveTypes:any;
  @Input()formGp : FormGroup    = this.fb.group({
                                    name                      : ['',Validators.required],
                                    pay_type                  : [''],
                                    set_component_ctc         : [''],
                                    set_component_gross       : [''],
                                    pay_component_salary      : [''],
                                    set_reimbursable          : [''],
                                    set_fbp                   : [''],
                                    restric_fbp_amount        : [''],
                                    nature_payment            : [''],
                                    prorate_per_day           : [''],
                                    arrear_applicable         : [''],
                                    process_join_month        : [''],
                                    process_settlement_month  : [''],
                                    component_type            : [,Validators.required]
                                  });


  validated : boolean = false
  payDefineUnit : any =[]
  value:any
  private subjectKeyUp = new Subject<any>();
  id:any

  constructor(private fb:FormBuilder,public messageService : MessageService,private componentService:PayComponentService,
    public existDataService :  ExistDatasService, public route : ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService();
    })

    this.id = this.route.snapshot.params['id']
  }

  changeFunction(name:string){

  }

  checkFBP(event:any){
    if(!(event.target.checked)){
      this.formGp.get('restric_fbp_amount')?.setValue(false);
    }

  }


  validateDefineComponent() {
    if (this.formGp.invalid) {
      return;
    }

    this.submitmethod.emit();
  }

  get f() { return this.formGp.controls; }

  makeDefaultSettings(){
    this.defaultData.emit();

  }
  changeSection(){
    this.perquisiteSec.emit();
   
  }
  handleChange(status:any){

    this.paytypeChange.emit();
  }
  prorateRestrict(){
    this.prorateRestrictions.emit();
  }

  // Simplified function to use for validation
  existService(){
    if(!this.editflag){
      this.value ={
        "page": "paycomponent",
        "field":"name",
        "value": this.f.name.value
      }
    }else{
       this.value ={
        "id":this.id,
        "page":"paycomponent",
        "field":"name",
        "value": this.f.name.value
      }
    }

    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.payDefineUnit = res.body

      if(this.payDefineUnit.data ==='invalid'){
        this.f.name.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of keyup(name)

  keyUp(){

    this.subjectKeyUp.next(this.f.name.value)

  }
}
