<form class="" [formGroup]="formGp" autocomplete="off">
    <div class="container-fluid p-32 form-container">
        <div class="row ">
            <p class="text-uppercase fw-500">SCHEDULER DETAILS </p>
        </div>
        <div class="row row-16">
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Scheduler Name</label>
                <input type="text" class="form-control " placeholder="Enter Scheduler name" formControlName="scheduler_name" [ngClass]="{ 'is-invalid': (submitted || f.scheduler_name.dirty || f.scheduler_name.touched) && f.scheduler_name.errors || f.scheduler_name.errors?.exist}" (keyup)="keyUp(f.scheduler_name.value)">
                <div *ngIf="(submitted || f.scheduler_name.dirty || f.scheduler_name.touched) && f.scheduler_name.errors || f.scheduler_name.errors?.exist" class="invalid-feedback">
                  <div *ngIf="f.scheduler_name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="f.scheduler_name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                  <div *ngIf="!(f.scheduler_name.errors?.pattern) && f.scheduler_name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                  <div *ngIf="f.scheduler_name.hasError('exist')">{{this.messageService.validationDisplay(f.scheduler_name.value)}}</div>
                </div>
            </div>
            <div class=" col-12 form-row">
              <label for="" class="form-label required">Reports</label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Reports')}} " formControlName="report" [items]="reportsDD" [multiple]="true" groupBy="selectedAllGroup"
              [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"  class="form-ngselect multiselect" [ngClass]="{ 'is-invalid': (submitted || f.report.touched) && f.report.errors}">
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index">
                    <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                    {{ item.name }}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="formGp.value.report.length ">
                        <span class="ng-value-label">{{formGp.value.report.length }} Report Selected</span>
                    </div>
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index">
                    {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}
                </ng-template>
            </ng-select>
            <div *ngIf="(submitted || f.report.touched) && f.report.errors" class="invalid-feedback">
              <div *ngIf="f.report.errors.required">Please select atleast one report.</div>
            </div>
            <ul class="selected-values mt-16" *ngIf="formGp.value.report.length != 0">
                <li class="ng-value" *ngFor="let item of formGp.value.report,let ki =index">
                    <ng-container *ngFor="let insideitem of reportsDD, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                            {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>

                    </ng-container>
                    <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('report',ki)" aria-hidden="true">×</span>
                </li>
            </ul>
            </div>
            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Schedule </label>
                <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.schedule.touched) &&  f.schedule.errors}" [placeholder]="reportScheduleDropdownPlaceHolder" id="schedule" [submitted]="submitted"
                [isrequired]="isrequired" [selectedItem]="f.schedule.value" formControlName="schedule" [type]="report_schedule" (ngModelChange)="scheduleChangeFunction(formGp.value.schedule)"></app-master-select>
                <div *ngIf="(submitted || f.schedule.touched) && f.schedule.errors" class="invalid-feedback">
                    <div *ngIf="f.schedule.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>

            <ng-container *ngIf="formGp.value.schedule == 'Weekly'">
              <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Schedule Day </label>
                <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.report_scheduler_day.touched) &&  f.report_scheduler_day.errors}" [placeholder]="scheduleDayPH" id="report_scheduler_day" [submitted]="submitted"
                [isrequired]="isrequired" [selectedItem]="f.report_scheduler_day.value" formControlName="report_scheduler_day" [type]="weekdays"></app-master-select>
                <div *ngIf="(submitted || f.report_scheduler_day.touched) && f.report_scheduler_day.errors" class="invalid-feedback">
                    <div *ngIf="f.report_scheduler_day.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="formGp.value.schedule == 'Monthly' || formGp.value.schedule == 'Yearly'">
              <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Schedule Date</label>
                <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.report_scheduler_date.touched) &&  f.report_scheduler_date.errors}" [placeholder]="scheduleDatePH" id="report_scheduler_date" [submitted]="submitted"
                [isrequired]="isrequired" [selectedItem]="f.report_scheduler_date.value" formControlName="report_scheduler_date" [type]="schedule_dates" [range]="28" [lastDay]="true"></app-master-select>
                <div *ngIf="(submitted || f.report_scheduler_date.touched) && f.report_scheduler_date.errors" class="invalid-feedback">
                    <div *ngIf="f.report_scheduler_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="formGp.value.schedule == 'Yearly'">
              <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Schedule Month</label>
                <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.report_scheduler_month.touched) &&  f.report_scheduler_month.errors}" [placeholder]="scheduleMonthPH" id="report_scheduler_month" [submitted]="submitted"
                [isrequired]="isrequired" [selectedItem]="f.report_scheduler_month.value" formControlName="report_scheduler_month" [type]="listOfMonth"></app-master-select>
                <div *ngIf="(submitted || f.report_scheduler_month.touched) && f.report_scheduler_month.errors" class="invalid-feedback">
                    <div *ngIf="f.report_scheduler_month.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
              </div>
            </ng-container>

            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Report Scheduler time </label>

                <igx-time-picker formControlName="report_scheduler_time" class="form-control " placeholder="{{this.appservice.gettimeformat()}}"  displayFormat="{{this.appservice.gettimeformat()}}" [ngClass]="{ 'is-invalid': (submitted || f.report_scheduler_time.touched) &&  f.report_scheduler_time.errors}" class="form-control form-timepicker " (click)="picker2.open()" #picker2></igx-time-picker>
                <div *ngIf="(submitted || f.report_scheduler_time.dirty || f.report_scheduler_time.touched) && f.report_scheduler_time.errors" class="invalid-feedback">
                  <div *ngIf="f.report_scheduler_time.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>
            <div class="col-lg-6 form-row">
                <label for="" class="form-label required">Schedule Start Date </label>
                <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (submitted || f.schedule_start_date.touched) && (f.schedule_start_date.errors) }">
                  <input (click)="datePicker1.open()"  class="form-control"  placeholder="{{this.messageService.selectplaceholddisplay('Date')}} " [min]="fromMinDate"
                  matInput formControlName="schedule_start_date" [max]="fromMaxDate"
                  [matDatepicker]="datePicker1"  [ngClass]="{ 'is-invalid': (submitted || f.schedule_start_date.touched) && (f.schedule_start_date.errors) }">
                  <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                  <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
                </div>
                <div *ngIf="(submitted || f.schedule_start_date.touched) && f.schedule_start_date.errors" class="invalid-feedback">
                  <div *ngIf="f.schedule_start_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
            </div>
            <div class=" col-lg-6 form-row">
                <label for="" class="form-label required">Schedule End Date </label>
                <div class="input-group form-icon icon-right"  [ngClass]="{ 'is-invalid': (submitted || f.schedule_end_date.touched) && (f.schedule_end_date.errors || formGp.errors!=null)}">
                  <input (click)="datePicker2.open()"  class="form-control"  placeholder="{{this.messageService.selectplaceholddisplay('Date')}} " [min]="toMinDate"
                  matInput formControlName="schedule_end_date" [max]="toMaxDate"
                  [matDatepicker]="datePicker2" [ngClass]="{ 'is-invalid': (submitted || f.schedule_end_date.touched) && (f.schedule_end_date.errors || formGp.errors!=null) }">
                  <i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                  <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
                </div>
                <div *ngIf="(submitted || f.schedule_end_date.touched) && (f.schedule_end_date.errors || formGp.errors!=null)" class="invalid-feedback">
                  <div *ngIf="f.schedule_end_date.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="formGp.errors!=null">{{this.messageService.greaterThanMessage('Schedule end date', 'Schedule start date')}}</div>
                </div>
            </div>
            <div class=" col-12 form-row">
                <label for="" class="form-label required">Description </label>
                <textarea type="text" class="form-control " rows="4" placeholder="{{this.messageService.descriptiontext('desc')}}" formControlName="description" [ngClass]="{ 'is-invalid': (submitted || f.description.dirty || f.description.touched) && f.description.errors }"></textarea>
                <div *ngIf="(submitted || f.description.dirty || f.description.touched) && f.description.errors" class="invalid-feedback">
                  <div *ngIf="f.description.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                  
                  <div *ngIf="!(f.description.errors?.pattern) && f.description.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
                </div>
            </div>
        </div>
    </div>
<!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
    <footer class="submit-footer">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="!formGp.valid" (click)="validateSchedule()">NEXT</button>
    </footer>
</form>
