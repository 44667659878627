<form action="" class=""  [formGroup]="formGp" autocomplete="off">
  <div class="container-fluid p-32 form-container" >
      <div class="row ">
        <p class="text-uppercase fw-500 ">Define pay component</p>

      </div>
      <div class="row mb-24">
        <div class="col-lg-6 form-row">
            <label for="" class="form-label required">Component name  </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter the pay component name that included in your salary structure eg. Basic, HRA, PF,ESI, Bonus, incentives etc." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <input type="text" class="form-control" autocomplete="off" name="name" id="name" placeholder="{{this.messageService.placeholderdisp('component name')}}" formControlName="name" [ngClass]="{ 'is-invalid': (submitted || f.name.touched || f.name.dirty || nameError==1) &&  (f.name.errors || nameError==1) || f.name.errors?.exist}" (keyup)="keyUp()">
            <div *ngIf="(submitted || f.name.touched  || f.name.dirty || nameError==1) &&  (f.name.errors || nameError==1) || f.name.errors?.exist" class="invalid-feedback">
              <div *ngIf="(submitted || f.name.touched  || f.name.dirty) &&  f.name.errors?.required">{{this.messageService.validationDisplay('required')}}              </div>
              <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
              <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
              </div>
              <div *ngIf="nameError==1 && !(f.name.errors)">{{this.messageService.validationDisplay('component name')}}

              </div>
              <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
            </div>
        </div>
      </div>
      <div class="row mb-24">
        <div class="col-lg-6 form-row" [ngClass]="{ 'is-invalid': (submitted || f.component_type.touched) &&  f.component_type.errors}">
          <label for="" class="form-label required">Component type </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Few commonly used paycomponent types are pre-defined, select the appropriate component type from the drop-down. If you want to add a special pay component for eg. Festival bonus, Referral bonus etc, select the component type as user-defined." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('component type')}}" formControlName="component_type" [ngClass]="{ 'is-invalid': (submitted || f.component_type.touched) &&  f.component_type.errors}" (change)="makeDefaultSettings()">
            <ng-option *ngFor="let comp_type of componentType" [value]="comp_type.id">{{comp_type.name}}</ng-option>

          </ng-select>
          <div *ngIf="(submitted || f.component_type.touched) &&  f.component_type.errors" class="invalid-feedback">
            <div *ngIf="f.component_type.errors.required">{{this.messageService.validationDisplay('required')}}</div>

          </div>
        </div>
      </div>

      <div class="row  mb-24" *ngIf="defaultCompName=='Perquisite'">
        <div class="col-lg-6 form-row">
          <label for="perquisite_type" class="form-label required">Perquisite type</label>
          <app-master-select [ngClass]="{ 'is-invalid': (submitted || f.perquisite_type.touched) && f.perquisite_type.errors }" [placeholder]="this.messageService.selectplaceholddisplay('perquisite types')" id="perquisite_type" [submitted]="submitted" [isdisabled]="false" [selectedItem]="f.perquisite_type.value" formControlName="perquisite_type" [type]="'perquisite_type'" [isrequired]="true" (ngModelChange)="changeSection()"></app-master-select>
          <div *ngIf="(submitted || f.perquisite_type.touched) &&  f.perquisite_type.errors" class="invalid-feedback">
            <div *ngIf="f.perquisite_type.errors.required">{{this.messageService.validationDisplay('required')}}</div>

          </div>
        </div>
      </div>
      <div class="row  mb-24" *ngIf="defaultCompName=='Leave Encashment'">
        <div class="col-lg-6 form-row">
          <label for="encashment_leave_type" class="form-label required">Leave type</label>

          <ng-select [items]="leaveTypes"
           bindLabel="leave_name"
           autofocus
           bindValue="id"
           [ngClass]="{ 'is-invalid': (submitted || f.encashment_leave_type.touched) && f.encashment_leave_type.errors }" [placeholder]="this.messageService.selectplaceholddisplay('Leave type')"
           formControlName="encashment_leave_type" class="form-ngselect" ></ng-select>

          <div *ngIf="(submitted || f.encashment_leave_type.touched) &&  f.encashment_leave_type.errors" class="invalid-feedback">
            <div *ngIf="f.encashment_leave_type.errors.required">{{this.messageService.validationDisplay('required')}}</div>

          </div>
        </div>
      </div>
      <div class="row mb-24 row-8">
        <div class="col-12 form-row">
          <label for="" class="form-label required">Pay type </label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Addition/Deduction specifies whether the component is to be added to the employee’s salary or deducted from it. For example, HRA is added to the salary while income tax is deducted from it." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        </div>
        <div class="col-lg-6 form-row">
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="Addition">
                Addition
              </label>
              <input class="form-check-input" name="pay_type" type="radio" id="Addition" formControlName="pay_type" [value]="true" (change)="handleChange(true)">

            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="Deduction">
                Deduction
              </label>
              <input class="form-check-input" name="pay_type" type="radio" id="Deduction"  formControlName="pay_type" [value]="false" (change)="handleChange(false)">

            </div>
        </div>
      </div>

      <div class="row row-12 mb-24">
        <div class="col-12 form-row">
          <label for="" class="form-label">Set whichever is possible</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="check the appropriate options that is applicable for the paycomponent type." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        </div>
        <div class="col-12 form-row">


          <div class=" form-check">
            <input type="checkbox" class="form-check-input" name="" id="part-ctc" formControlName="set_component_ctc">
            <label for="part-ctc" class="form-check-label ">Set this component as part of CTC</label>
          </div>
        </div>
        <div class="col-12 form-row">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" name="" id="part-gross" formControlName="set_component_gross">
            <label for="part-gross" class="form-check-label ">Set this component as part of gross</label>
          </div>
        </div>
        <div class="col-12 form-row is-invalid">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" name="" id="part-salary" formControlName="pay_component_salary">
            <label for="part-salary" class="form-check-label ">Want to pay this component in salary</label>
          </div>
        </div>
        <div class="col-12 form-row is-invalid">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" name="" id="part-reimbursable" formControlName="set_reimbursable">
            <label for="part-reimbursable" class="form-check-label ">Set this component as reimbursable</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Check this option If you want to add a reimbursement component for your employees. To add a reimbursement component, the component type should be selected as reimbursable component. If you want to make the reimbursement as part of FBP then check the below option Set this component as flexi benefit plan (FBP)." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          </div>
        </div>
        <div class="col-12 form-row is-invalid">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" name="" id="part-fbp" formControlName="set_fbp" (change)="checkFBP($event)">
            <label for="part-fbp" class="form-check-label ">Set this component as flexi benefit plan (FBP)</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Check this option If you want to enable Flexible Benefit Plan  for your organization eg. Fuel bills, conveyance, telephone bills etc." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          </div>
        </div>
        <div class="col-12 form-row mt-24" *ngIf="f.set_fbp.value">
          <label for="" class="form-label">Flexi benefit plan (FBP)</label>
        </div>
        <div class="col-12 form-row is-invalid mt-0" *ngIf="f.set_fbp.value">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" name="" id="part-fbp-amount" formControlName="restric_fbp_amount">
            <label for="part-fbp-amount" class="form-check-label ">Restrict employee from overriding the FBP amount</label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover='Employees are restricted from modifying (increase or decrease) the amount specified on that component if the option "Restrict employee from overriding the FBP amount" is selected. Either they can include or exclude the component with the amount specified by the HR.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
          </div>
        </div>
        <div *ngIf="checkBoxError" class="invalid-feedback">
          <div>At least one possible option be selected.</div>
        </div>
      </div>
      <div class="row  mb-24  ">
        <div class="col-12 form-row">
          <label for="" class="form-label required">Nature of payment</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Select the nature of payment as fixed or variable based on the pay component type selected.
            •Fixed pay is predefined; it is a fixed amount of salary that the employee will receive every month.
            •Variable pay is provided as benefits such as bonuses, incentives, commissions, and so on. It is paid quarterly or yearly. This varies from organization to organization." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        </div>
        <div class="col-lg-6 form-row">

            <div class="form-check form-check-inline">
              <input class="form-check-input" name="nature_payment" type="radio"  id="Fixed" formControlName="nature_payment" [value]="true" (change)="prorateRestrict()">
              <label class="form-check-label" for="Fixed">
                Fixed
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" name="nature_payment" type="radio" id="Variable" formControlName="nature_payment" [value]="false" (change)="prorateRestrict()">
              <label class="form-check-label" for="Variable">
                Variable
              </label>
            </div>
        </div>
      </div>
      <div class="row  mb-24 ">
        <div class="col-12 form-row">
          <label for="" class="form-label required">Prorate as per paid days</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If you prorate the salary based on paid days, the employee will only be paid for the days worked." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        </div>
        <div class="col-lg-6 form-row">

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="prorate_per_day" id="flexRadioDefault1" formControlName="prorate_per_day" [value]="true" >
              <label class="form-check-label" for="flexRadioDefault1">
                Yes
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="prorate_per_day" id="flexRadioDefault2" checked formControlName="prorate_per_day" [value]="false">
              <label class="form-check-label" for="flexRadioDefault2">
                No
              </label>
            </div>
        </div>
      </div>
      <div class="row  mb-24 ">
        <div class="col-12 form-row">
          <label for="" class="form-label required">Arrear applicable</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="If yes, component will be considered when calculating LOP reversal and Salary revision arrears." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        </div>
        <div class="col-lg-6 form-row">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="arrear_applicable" id="flexRadioDefault1" formControlName="arrear_applicable" [value]="true" >
              <label class="form-check-label" for="flexRadioDefault1">
                Yes
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="arrear_applicable" id="flexRadioDefault2" checked formControlName="arrear_applicable" [value]="false" >
              <label class="form-check-label" for="flexRadioDefault2">
                No
              </label>
            </div>
        </div>
      </div>
      <div class="row  mb-24 ">
        <div class="col-12 form-row">
          <label for="" class="form-label required">Do you want to process in joining month</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover='Select "YES” if you want to process the pay component in the joining month payroll.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        </div>
        <div class="col-lg-6 form-row">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="process_join_month" id="flexRadioDefault1" formControlName="process_join_month" [value]="true" >
              <label class="form-check-label" for="flexRadioDefault1">
                Yes
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="process_join_month" id="flexRadioDefault2" checked formControlName="process_join_month" [value]="false" >
              <label class="form-check-label" for="flexRadioDefault2">
                No
              </label>
            </div>
        </div>
      </div>
      <div class="row  mb-24 ">
        <div class="col-12 form-row">
          <label for="" class="form-label required">Do you want to process in settlement month</label>
          <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover='Select "YES” if you want to process the pay component in the settlement month payroll.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
        </div>
        <div class="col-lg-6 form-row">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="process_settlement_month" id="flexRadioDefault1" formControlName="process_settlement_month" [value]="true">
              <label class="form-check-label" for="flexRadioDefault1">
                Yes
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="process_settlement_month" id="flexRadioDefault2" checked formControlName="process_settlement_month" [value]="false">
              <label class="form-check-label" for="flexRadioDefault2">
                No
              </label>
            </div>
        </div>
      </div>
  </div>
  <footer class="submit-footer">
    <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="(!formGp.valid || componentError)" (click)="validateDefineComponent()">NEXT</button>
    <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto text-uppercase"  (click)="validateDefineComponent()">NEXT</button>
  </footer>
 </form>

