import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { SalaryStructureService } from 'src/app/@core/services/salary-structure';
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AppService } from 'src/app/app.global';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-my-salary-structure',
  templateUrl: './my-salary-structure.component.html',
  styleUrls: ['./my-salary-structure.component.scss']
})
export class MySalaryStructureComponent implements OnInit {
    empData:any                   =[];
    salaryData:any                   =[];
    employeeId                    = 0;
    calculationData:any;
    gross_benefits:any;
    other_benefits:any;
    contributions:any;
    recurring_deductions:any;
    salaryRevisionHistory       = false;
    salarystructdata:any=[];
    loader                = false;
    from                  = "profile"
    noData : boolean = false

  constructor(private SSS:SalaryStructureService,
    public fb:FormBuilder,
    private authService:AuthService,
    public appservice:AppService,
    private route : ActivatedRoute,
    private router : Router
    ) { }

  ngOnInit(): void {
   this.employeeId        = this.authService.isEmployeeId;
   this.getSinglesalary();

  this.route.queryParamMap.subscribe((params)=>{
    if(params.get('home')){
    this.salaryRevisionHistory = true
    }
  })

  //  Remove query params
  this.router.navigate([], {
    queryParams: {
      'home': null,
    },
    queryParamsHandling: 'merge'
  })
  }
  getSinglesalary(){
    this.loader = true;

    this.SSS.getempSalarydata(this.employeeId).subscribe((res:any)=>{
      this.salaryData = res.body;
      this.empData = res.body.employee_data
      this.noData = Object.keys(this.salaryData)?.length == 0
      this.salarystructdata = this.salaryData.salary_structure_summary;
      Object.entries(res.body).forEach(([key, value]) => {
      if(key=='assign_salary'){
            this.calculationData = value;
          if(this.calculationData.length>0){

            var gross =  this.calculationData.filter(function(gross_sal:any) {

              return gross_sal.component_type == "gross_benefits" && gross_sal?.is_display==true;
            });
            var other =  this.calculationData.filter(function(other_sal:any) {

              return other_sal.component_type == "other_benefits" && other_sal?.is_display==true;
            });
            var contibution =  this.calculationData.filter(function(contribution_sal:any) {

              return contribution_sal.component_type == "contributions" && contribution_sal?.is_display==true;
            });
            var recurring =  this.calculationData.filter(function(recurring_sal:any) {

              return recurring_sal.component_type == "recurring_deductions" && recurring_sal?.is_display==true;
            });

            this.gross_benefits       = gross;
            this.other_benefits       = other;
            this.contributions        = contibution;
            this.recurring_deductions = recurring;

          }

        }

      })
      this.loader = false;
  },(error:any)=>{
    this.loader = false;
  });
  }

  downLoad(url:any){
    if(url != null){
      this.appservice.downloadFile(url,'Payslip')
    }
  }

}
