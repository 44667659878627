<div *ngIf="employeeDetails" class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">Revise Salary</h5>
    <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
  <ng-container *ngIf="!loader">
    <aside class=" config-sidebar ">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="step" [class]="{'success':(structureForm.valid)}">
            <div class="count">1</div>
            <div class="content">
              <span>Step 1</span>
              <h6>Define Salary Details</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-sr-define-salary-details [error]="error" [payGroupDD]="payGroupDD" [formGp]="structureForm"
              [employeeDetails]="employeeDetails" (submitmethod)="submitMethod(2)" (defualtData)="defualtData($event)"
              [defaultMonthData]="defaultMonthData"></app-sr-define-salary-details>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="step">
            <div class="count">2</div>
            <div class="content">
              <span>Step 2</span>
              <h6>Pay components</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-sr-pay-component [componentLoader]="componentLoader" [employeeData]="employeeDetails"
              [formGp]="structureForm" [payGroupDD]="payGroupDD" [detailsForm]="detailsForm"
              [calculationData]="calculationData" [gross_benefits]="gross_benefits" [other_benefits]="other_benefits"
              [contributions]="contributions" [recurring_deductions]="recurring_deductions"
              (submitmethod)="submitForm()" [stepperData]="stepperData" [salaryForm]="salaryForm" [empId]="empId"
              (recalculateComponent)="recalculateComponent($event)"></app-sr-pay-component>
          </ng-template>
        </li>
      </ul>
    </aside>
    <main class=" config-container">
      <div [ngbNavOutlet]="nav"></div>
      <!-- <pre>Form Values: {{salaryForm.value | json}}</pre> -->
    </main>
  </ng-container>
</div>
