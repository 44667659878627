import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if(!window.location.origin.includes("skizzlehrdev")){

        if(!window.location.origin .includes("localhost")){
              request = request.clone({
                  setHeaders: { 'X-DTS-SCHEMA': window.location.origin }
              });
          }
        else{
          request = request.clone({
              setHeaders: { 'X-DTS-SCHEMA': 'http://remya.dev-skizzlehr.in' }
          });
        }
      }

      return next.handle(request);
    }
}
