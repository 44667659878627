import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-final-settlement-gratuity',
  templateUrl: './final-settlement-gratuity.component.html',
  styleUrls: ['./final-settlement-gratuity.component.scss']
})

export class FinalSettlementGratuityComponent implements OnInit {
  @Input() empId: any;
  @Input()gratuityData:any;
  @Input() payrollStatus: any;

  @Output() submitmethod = new EventEmitter();
  @Output() warningMsg = new EventEmitter();
  @Output() getGratuityDetails = new EventEmitter();

  @Input()loader :any;
  submitted = false;
  @Input() resignationDetails: any;
  paymentName = "GRATUITY";
  @Input()errCompmsg : any;
  @Input()errleaveCompmsg :any;
  @Input()bothCompMsg :any;
  @Input()attendance_lop:any;


  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route: ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    public fb: FormBuilder, private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  @Input() formGp: FormGroup = this.fb.group({
    payable_amount       : [''],
    leave_payable_amount :['']

  });

  get f() { return this.formGp.controls; }

  submitGratuity() {
    this.submitmethod.emit('');
  }

  gratuityAmountSave(){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.warningMsg.emit();
    } else{
    if (this.formGp.invalid) {
      return;
    }

     let except_array = [];
     let min_exception;
     except_array     = this.gratuityData?.gratuity_excemption_array?.slice();

     except_array?.push(Number(this.formGp.controls.payable_amount?.value));
     min_exception = except_array?.reduce((a:any, b:any)=>Math.min(a, b));

    this.finalSettlementService.finalSettlementSave({ "employee": this.empId,"step_name": 'gratuity',"gratuity_amount": this.gratuityData?.gratuity_amount, "payable_amount": Number(this.formGp.controls.payable_amount?.value),'exception_amount':min_exception,'payable_exception_amount':min_exception
  }).subscribe((res: any) => {

    if(res?.status == 1){
      this.notificationService.handleSuccessNotification("Created successfully.","Success");
      this.getGratuityDetails.emit();
    }

  },(error)=>{

  });
 }
  }

  leaveAmountSave(){
    if (this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish') {
      this.warningMsg.emit();
    } else{
    var data = [];

    let except_array = [];
    let min_exception;
    except_array     = this.gratuityData?.excemption_array?.slice();

    except_array?.push(Number(this.formGp.controls.leave_payable_amount?.value));
    min_exception = except_array?.reduce((a:any, b:any)=>Math.min(a, b));

    data.push({
      'paycomponent'             : this.gratuityData?.leave_encashment_id,
      'amount'                   : this.gratuityData?.encashed_amount,
      'no_of_days'               : 0,
      'payable_amount'           : Number(this.formGp.controls.leave_payable_amount?.value),
      'exception_amount'         : min_exception,
      'payable_exception_amount' : min_exception,
      'comments'                 : ''
    })
      this.finalSettlementService.finalSettlementSave({"step_name": 'other_payments', "component_type": "leave_encashment", "employee": this.empId,"other_components":data
    }).subscribe((res: any) => {

      if(res?.status == 1){
        this.notificationService.handleSuccessNotification("Created successfully.","Success");
        this.getGratuityDetails.emit();
      }
    },(error)=>{

    });
   }
  }

}
