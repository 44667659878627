import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login.component';
import { NewpasswordComponent } from './newpassword/newpassword.component';
import { OneTimePasswordComponent } from './one-time-password/one-time-password.component';
import { OTPComponent } from './otp/otp.component';
import { UnsupportedResolutionComponent } from './unsupported-resolution/unsupported-resolution.component';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { showHeader: false, showSidebar: false } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { showHeader: false, showSidebar: false } },
  { path: 'otp', component: OTPComponent, data: { showHeader: false, showSidebar: false } },
  { path: 'newpassword', component: NewpasswordComponent, data: { showHeader: false, showSidebar: false } },
  { path: 'one-time-password', component: OneTimePasswordComponent, data: { showHeader: false, showSidebar: false } },
  { path: 'session-timeout', component: SessionTimeoutComponent, data: { showHeader: false, showSidebar: false } },
  { path: 'mobile-view', component: UnsupportedResolutionComponent, data: { showHeader: false, showSidebar: false } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
