import { Component, OnInit,Input,Output,EventEmitter, ChangeDetectorRef} from '@angular/core';
import { ProfileDocumentService } from 'src/app/@core/services/profile-document.service';
import {saveAs} from 'file-saver';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  loader                      = false;
  docSaveLoader               = false;
  addNewDocPanel              = false;
  modalToggle                 = false;
  inputId:string              = "empDoc";
  currentDocName              = "";
  currentDocId                = 0;
  currntUrl                   = "";
  currentExtension            = "";
  allowedExtension            = "";
  permissions:any             = [];
  uploadedDoc:any             = [];
  showInfo                    = false;
  infoMsg:any;
  @Output() editDocument      = new EventEmitter;
  fileName:any;
  @Input() docData:any;
  @Input() id:any;
  @Input() Savecontent:any;
  @Input() permittedDoc:any;
  @Input() emptyDoc:any;
  @Input() loginAs:any;
  @Input() createdBy:any;
  @Input() is_employeeRequest:any;
  // @Output() loadData     = new EventEmitter();
  @Input() routingUrl:any;
  @Input() from:any;
  @Input() fieldPermission:any;
  @Input() addEditPermission:any;
  errormsg:any="";

  constructor(private profileDocService:ProfileDocumentService,public appservice:AppService,public messageService : MessageService,private changeDetectorRef: ChangeDetectorRef,private profileService:SnapshotService) {
    this.profileDocService.profile_doc_data.subscribe(result =>{

      this.permittedDoc = [];
      this.emptyDoc     = [];
      let res           = result.event

      this.docData      = res?.length>0?res[0]:res;
      for(let j=0;j<res.length;j++){
        if(res[j].document_file!==null && res[j].document_file!==''){
          this.permittedDoc.push(res[j]);
        }
        else{
          this.emptyDoc.push(res[j])
        }
      }

      this.changeDetectorRef.detectChanges();
    })
   }

  ngOnInit(): void {
    this.getPermission();
    this.getDocumentData()
    this.profileDocService.employeeDocSocket();
  }
  getPermission(){
    this.permissions = this.appservice.getPermissions(this.routingUrl);
     if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }

  }

  addNewDoc(isPermission:boolean,currentDoc:any){
    this.addNewDocPanel = isPermission;
    this.currentDocName = currentDoc;
  }

  // downloadDoc(filename:any,docType:any){
  //   this.currentExtension = filename.split('.').pop();
  //   let FileSaver = require('file-saver');
  //   FileSaver.saveAs(filename,docType+'.'+this.currentExtension);
  // }
  viewDocument(filename:any,docType:any){

    this.modalToggle      = true;
    this.fileName         = filename;
    this.currentDocName   = docType;
    this.currentExtension = this.fileName?.split('.').pop();

  }
  closeDoc(id:number){
    this.Savecontent[id] = {'url':'','loader':false,'pending':false,'valid':true};

  }

  getURL(event:any){
    this.currntUrl                      = event;
    this.Savecontent[this.currentDocId] = {'url':event,'loader':false,'pending':true,'valid':true};

  }
  getloader(event:any){

    this.docSaveLoader = event;
    this.Savecontent[this.currentDocId] = {'url':'','loader':this.docSaveLoader,'pending':true,'valid':true};

  }
  saveDoc(){
  this.uploadedDoc  = [];
  for(let i=0;i<this.docData?.length;i++){
    var dict:any = {};
    if(this.Savecontent[this.docData[i]['id']]['url']!=''){

      dict['document_file']      = this.Savecontent[this.docData[i]['id']]['url'];
      dict['id']                 = this.docData[i]['id']
      this.uploadedDoc.push(dict);

    }
    this.Savecontent[this.docData[i]['id']] = {'url':'','loader':false,'pending':false,'valid':true};

    if(i==this.docData?.length-1)
      this.submitDocument(this.uploadedDoc)
  }



  }
  submitDocument(val:any){

    if(this.loginAs=='Employee'){
      for(let i=0;i<val.length;i++){
        val[i].old_object = val[i].id // on object create new key name. Assign old value
        delete val[i].id;
      }

      let tempData = {
        "temp_data": "document",
        "data"     : val,
      }

      this.profileService.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          this.getDocumentData();
          this.changeDetectorRef.detectChanges();
      })
    } else{
      this.profileDocService.createDocument({"document":val}).subscribe((res:any)=>{
        this.getDocumentData();
        this.changeDetectorRef.detectChanges();
      })
    }


  }
  validCount(){
    this.uploadedDoc  = [];
    for(let i=0;i<this.docData?.length;i++){
      var dict:any = {};
      if(this.Savecontent[this.docData[i]['id']]['url']!=='' && this.Savecontent[this.docData[i]['id']]['url']!==null){

        dict['document_file']      = this.Savecontent[this.docData[i]['id']]['url'];
        dict['id']                 = this.docData[i]['id']
        this.uploadedDoc.push(dict);

      }

    }
    return this.uploadedDoc?.length;
  }
  getfileformat(event:any){
    if(event==false)
    this.errormsg = "Kindly upload files in pdf/jpeg/png/jpg format."
    this.Savecontent[this.currentDocId] = {'url':'','loader':false,'pending':false,'valid':event};

  }
  getvalidsize(event:any){
    this.errormsg = event;
    this.Savecontent[this.currentDocId] = {'url':'','loader':false,'pending':false,'valid':false};
  }


  currentId(id:number,status:any){
    if(this.is_employeeRequest?.is_document_requested || status==='Pending'){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);
    }else{
      this.showInfo     = false;
      this.currentDocId = id;
    }

  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  getFieldPermission(fieldName:any){
    if((this.from=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }

  getDocumentData(){
    this.loader=true
    this.profileDocService.getAllDocumentData(this.id,this.from).subscribe((doc:any)=>{
      this.Savecontent  = [];
      this.permittedDoc = [];
      this.emptyDoc     = [];
      this.docData      = doc;
      this.loader = false;
      var loadComplete = false;
      for(let i=0;i<doc?.length;i++){
        this.Savecontent[this.docData[i].id] = {'url':'','loader':false,'pending':false,'valid':true};
        if((this.docData[i].document_type?.view==true || this.loginAs=="HR")  && (this.docData[i].document_file!=null || JSON.stringify(this.docData[i].doc_request_detail) !== '{}')){
          this.permittedDoc.push(doc[i]);
          if(doc[i].doc_request_detail && doc[i].doc_request_detail.approver?.length==0 )
            loadComplete = true;

        }
        else if((this.docData[i].document_type?.edit==true || this.loginAs=="HR") && (this.docData[i].document_file==null && JSON.stringify(this.docData[i].doc_request_detail) === '{}'))
          this.emptyDoc.push(doc[i]);
      }
      if(loadComplete==true){
        setTimeout(() => {
          this.getDocumentData();
        }, 3000);
      }
      this.changeDetectorRef.detectChanges();
    })


  }
}
