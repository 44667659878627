<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{reportName}}</h5>
    <button class="btn-reset text-light-600" routerLink="/payroll-report"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
    <form class="">
      <div class="container-fluid p-32 form-container">
        <div class="row mb-24">
          <div class="col-md-4 col-lg-6">
            <h3 class="mb-0">{{reportName}}</h3>
          </div>
          <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
              <!-- <ng-container *ngIf="reportName != 'LWF (Labour Welfare Fund)'"> -->

              <!-- Add columns start -->
              <button class="btn-square btn btn-secondary" ngbPopover="Add column"
                popoverClass="popover-default popover-info" container="body" triggers="hover"
                (click)="addColumnFilter = true">
                <i class="icon-plus fs-14"></i>
              </button>
              <!-- Add columns end -->

              <button class="btn-square btn btn-secondary" (click)="viewDetail = true">
                <i class="icon-filter-left fs-13"></i>
              </button>
              <!-- <button class="btn flex-shrink-0 fs-14 fw-600 text-uppercase btn-outline-primary"
                                    (click)="scheduleReportFunction()">Schedule
                                    Report</button> -->
              <button class="btn flex-shrink-0 fs-10 px-4 fw-700 text-uppercase btn-primary" [disabled]="loader"
                (click)="export()">DOWNLOAD
                REPORT</button>
              <!-- </ng-container> -->
            </div>
          </div>
        </div>
        <app-table-loader *ngIf="loader"></app-table-loader>
        <div class="col-12" *ngIf="appliedFilter != 0 && !loader">
          <ul class="selected-values  ">
            <ng-container *ngFor="let filter of appliedFilter | keyvalue ">
              <li class="ng-value" *ngIf="filter.value != '' && filter.value != null && filter.value != 'null'">
                <ng-container
                  *ngIf="filter.key == 'salary_month' || filter.key == 'date_of_joining' || filter.key == 'requested_on' || filter.key == 'date_of_leaving' || filter.key == 'effective_month' || filter.key == 'payout_month' || filter.key == 'settlement_date' || filter.key == 'ot_payment_month';else all_data">
                  <ng-container *ngIf="filter.value != 'this_month' && filter.value != 'last_month' ;else all_data1">
                    <span class="ng-value-label ">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{filter.value}}</span>
                  </ng-container>
                  <ng-template #all_data1>
                    <span class="ng-value-label ">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{removeUnderscoreAddUpperCase(filter.value)}}</span>
                  </ng-template>
                </ng-container>
                <ng-template #all_data>
                  <ng-container
                    *ngIf="filter.key === 'pay_group' || filter.key === 'branch' || filter.key === 'bu' || filter.key === 'city' || filter.key === 'company' || filter.key === 'country' || filter.key === 'department' || filter.key === 'designation' || filter.key === 'grade' || filter.key === 'employee_list' || filter.key === 'state' || filter.key === 'emp_status' || filter.key === 'emp_type'|| filter.key === 'employment_status' || filter.key === 'employment_type' || filter.key == 'settlement_done_by' || filter.key == 'reimbursement_component' || filter.key == 'variable_component'; else singleData">
                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key === 'company' ? tagMultiDataFunction (appliedFilter.company):''">

                      Company :
                      <img class="img-fluid rounded-circle sq-24 mx-1 " src="{{tagMultiData[0].logo}}">
                      {{tagMultiData[0].company_name}}

                      <ng-container *ngIf="tagMultiCnt != 0"> ... <span
                          class="flex-center radius-24 bg-dark-400 px-2">{{tagMultiCnt}}</span>
                      </ng-container>
                    </span>
                    <!-- Employee -->
                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key === 'employee_list' ? tagMultiDataFunction (appliedFilter.employee_list):''">

                      Employees :
                      <img class="img-fluid rounded-circle sq-24 mx-1 "
                        *ngIf="tagMultiData[0].profile_image !==null && tagMultiData[0].profile_image !== ''; else firstName"
                        src="{{tagMultiData[0].profile_image}}">
                      <ng-template #firstName>
                        <span
                          class="avatar-sm sq-24 mx-1 rounded-circle {{tagMultiData[0]?.color_code}}">{{this.appservice.getFirstChar(tagMultiData[0].first_name+"
                          "+(tagMultiData[0].middle_name!=null?tagMultiData[0].middle_name+" ":" "
                          )+tagMultiData[0].last_name,1)}}
                        </span>
                      </ng-template>
                      {{ tagMultiData[0].first_name+" "+(tagMultiData[0].middle_name!=null?tagMultiData[0].middle_name+"
                      ":" "
                      )+tagMultiData[0].last_name+ " (" +
                      tagMultiData[0].employee_code + ")" }}
                      <ng-container *ngIf="tagMultiCnt != 0"> ... <span class="flex-center radius-24 bg-dark-400 px-2"
                          [ngbPopover]="popContentMultilpes" popoverClass="user-list multi-user"
                          (click)="onPopoverClick($event)" triggers="click" container="body">{{tagMultiCnt}}</span>
                      </ng-container>
                    </span>
                    <ng-template #popContentMultilpes>
                      <ul>
                        <ng-container *ngFor="let re of  appliedFilter?.employee_list, let i = index">
                          <ng-container *ngIf="i > 0">
                            <li *ngIf="re?.profile_image !='' && re?.profile_image !=null">
                              <img class="user-avatar" src="{{re?.profile_image}}">
                              <div class="user-details">
                                <span class="user-label">{{re?.first_name+'
                                  '+(re?.middle_name!=null?re?.middle_name:'')+'
                                  '+re?.last_name}}</span>
                                <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                              </div>
                            </li>
                            <li *ngIf="(re?.profile_image =='' || re?.profile_image ==null )">
                              <span
                                class="user-avatar {{re?.color_code}}">{{this.appservice.getFirstChar(re?.first_name+'
                                '+re?.last_name,2)}}</span>
                              <div class="user-details">
                                <span class="user-label">{{re?.first_name+'
                                  '+(re?.middle_name!=null?re?.middle_name:'')+'
                                  '+re?.last_name}}</span>
                                <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                              </div>
                            </li>
                          </ng-container>
                        </ng-container>
                      </ul>
                    </ng-template>
                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key === 'settlement_done_by' ? tagMultiDataFunction (appliedFilter.settlement_done_by):''">

                      Settlement Done By :
                      <img class="img-fluid rounded-circle sq-24 mx-1 "
                        *ngIf="tagMultiData[0].profile_image !==null && tagMultiData[0].profile_image !== ''; else firstName"
                        src="{{tagMultiData[0].profile_image}}">
                      <ng-template #firstName>
                        <span
                          class="avatar-sm sq-24 mx-1 rounded-circle {{tagMultiData[0]?.color_code}}">{{this.appservice.getFirstChar(tagMultiData[0].first_name+"
                          "+(tagMultiData[0].middle_name!=null?tagMultiData[0].middle_name+" ":" "
                          )+tagMultiData[0].last_name,1)}}
                        </span>
                      </ng-template>
                      {{ tagMultiData[0].first_name+" "+(tagMultiData[0].middle_name!=null?tagMultiData[0].middle_name+"
                      ":" "
                      )+tagMultiData[0].last_name+ " (" +
                      tagMultiData[0].employee_code + ")" }}
                      <ng-container *ngIf="tagMultiCnt != 0"> ... <span class="flex-center radius-24 bg-dark-400 px-2"
                          [ngbPopover]="popContentMultilpe" popoverClass="user-list multi-user"
                          (click)="onPopoverClick($event)" triggers="click" container="body">{{tagMultiCnt}}</span>
                      </ng-container>
                    </span>
                    <ng-template #popContentMultilpe>
                      <ul>
                        <ng-container *ngFor="let re of  appliedFilter?.settlement_done_by, let i = index">
                          <ng-container *ngIf="i > 0">
                            <li *ngIf="re?.profile_image !='' && re?.profile_image !=null">
                              <img class="user-avatar" src="{{re?.profile_image}}">
                              <div class="user-details">
                                <span class="user-label">{{re?.first_name+'
                                  '+(re?.middle_name!=null?re?.middle_name:'')+'
                                  '+re?.last_name}}</span>
                                <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                              </div>
                            </li>
                            <li *ngIf="(re?.profile_image =='' || re?.profile_image ==null )">
                              <span
                                class="user-avatar {{re?.color_code}}">{{this.appservice.getFirstChar(re?.first_name+'
                                '+re?.last_name,2)}}</span>
                              <div class="user-details">
                                <span class="user-label">{{re?.first_name+'
                                  '+(re?.middle_name!=null?re?.middle_name:'')+'
                                  '+re?.last_name}}</span>
                                <span class="user-code">EMP CODE : {{re?.employee_code}}</span>
                              </div>
                            </li>
                          </ng-container>
                        </ng-container>
                      </ul>
                    </ng-template>
                    <!-- Company Logo Integration Start -->
                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key != 'company' && filter.key != 'employee_list' && filter.key != 'settlement_done_by'">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{tagMultiDataFunction(filter.value)}}
                      <ng-container *ngIf="tagMultiCnt != 0"> ... <span
                          class="flex-center radius-24 bg-dark-400 px-2">{{tagMultiCnt}}</span>
                      </ng-container>
                    </span>
                    <!-- Company Logo Integration End -->
                  </ng-container>
                  <ng-template #singleData>
                    <span class=""> {{removeUnderscoreAddUpperCase(filter.key == 'finantial_year' ? 'financial_year' : filter.key)}} :
                      {{removeUnderscoreAddUpperCase(filter.value =='previous_year' ? 'previous' : filter.value == 'current_year' ? 'current' :filter.value)}}</span>
                  </ng-template>
                </ng-template>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName== 'Monthly TDS Deduction Report' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appMonthly.applyTagClose();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'ESI (Employee State Insurance)' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appEsi.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'PT (Professional Tax)' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appPt.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'PF (Provident Fund)' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appPf.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0" *ngIf="reportName == 'CTC As Per Salary Structure' && filter.key != 'ctc_breakup' && filter.key != 'pay_group'
                                    " (click)="applyTagClose(reportName,filter.key)"
                  (click)="appCtc.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Salary Register' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appsalaryRegister.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Pay Salary (Bank Transfer) Report' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appReleaseSalary.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'YTD Salary Details' && filter.key != 'financial_year'"
                  (click)="applyTagClose(reportName,filter.key)"
                  (click)="appYtdSalaryDetails.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Investment Request' && filter.key != 'financial_year' && filter.key != 'investment_type'"
                  (click)="applyTagClose(reportName,filter.key)"
                  (click)="appInvestmentRequest.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0" *ngIf="reportName == 'CTC Revision'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appCTCRevision.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0" *ngIf="reportName == 'Salary On Hold' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appSalaryOnHold.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'LWF (Labour Welfare Fund)' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appLwf.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Employee Statutory Report'" (click)="applyTagClose(reportName,filter.key)"
                  (click)="appEmployee_statutory.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Reimbursement Request' && filter.key != 'financial_year' && filter.key != 'requested_on'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appReimburse.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Final Settlement' && filter.key != 'settlement_date'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appFinalSettlement.applyTagClose1();">×</span>
                  <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Investment Not Submitted' && filter.key != 'financial_year' && filter.key != 'investment_type'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="investmentNotSubmitted.applyTagClose1();">×</span>
                  <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Variable Paid' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appVarPaid.applyTagClose1();">×</span>
                  <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Overtime Payment' && filter.key != 'ot_payment_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appOverPy.applyTagClose1();">×</span>
                  <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'Gratuity Paid' && filter.key != 'finantial_year'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appGratitude.applyTagClose1();">×</span>
                  <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'NPS Employer Contribution' && filter.key != 'salary_month'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appNPS.applyTagClose1();">×</span>
                  <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName == 'TDS Computation' && filter.key != 'finantial_year'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appTDSComp.applyTagClose1();">×</span>
              </li>
            </ng-container>
          </ul>
        </div>
        <div *ngIf="nodata && !loader ;else all_data" class="row row-16">
          <div class="col-12">
            <div class="card card-c2 overflow-hidden">
              <!-- <span class="fw-500 fs-14">There are no data currently</span> -->
              <div class="table-responsive " style="max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));
              ">
                <table
                  class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header td-width-15ch td-white-space"
                  mat-table [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal"
                  (cdkDropListDropped)="drop($event)">
                  <ng-container *ngFor="let prop of displayedColumns">
                    <ng-container [matColumnDef]="prop">
                      <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header
                        ngbPopover="Click and drag  the header’s  to change the column position"
                        popoverClass="popover-default popover-info" container="body" triggers="hover">
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        {{prop}}
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{element[prop]}}
                      </td>
                    </ng-container>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                </table>
                <div class="text-center card card-c2 flex-center p-16 bg-dark-500 m-12">No data found</div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #all_data>
          <div class="row row-16" *ngIf="!loader">
            <div class="col-12 " *ngIf="alertShow">
              <div role="alert" class="alert alert-info   mb-0 pe-32 show">
                <p class="mb-0 fs-14 hstack ">Click and drag the header’s to change the column position
                </p>
                <div (click)="alertShow = false" class="btn-close fs-9 pointer"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 overflow-hidden">
                <!-- <div ngbDropdown #myDrop="ngbDropdown" class="d-inline-block add-column"
                                    container="body" placement="bottom-right">
                                    <button class="btn-outline-primary btn sq-32 p-0 radius-4" id="dropdownBasic1"
                                        ngbDropdownToggle>
                                        <i class="icon-plus fs-14"></i>
                                    </button>
                                    <div ngbDropdownMenu class=" fs-14" aria-labelledby="dropdownBasic1">
                                        <form class="" [formGroup]="testform">
                                            <div class="container-fluid p-16" style="max-width: 22.1875rem;">
                                                <div class="row row-16 table-filter mb-16">
                                                    <div class="col-12"
                                                        *ngFor="let prop of columnOptions,let i = index ">
                                                        <label for="check{{i}}" class="form-check p-0 d-flex ">
                                                            <input type="checkbox" name="check" id="check{{i}}"
                                                                class="form-check-input ms-0" [value]="prop"
                                                                formControlName="checkbox"
                                                                (click)="headerFilterAdd($event)">
                                                            <label for="check{{i}}"
                                                                class="form-check-label fs-16">{{prop}}</label>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row row-16">
                                                    <div class="col-6">
                                                        <button type="submit"
                                                            class="btn w-100 btn-outline-primary text-uppercase btn-sm"
                                                            (click)="resetColumnFilterFunction();myDrop.close();">
                                                            RESET COLUMNS</button>
                                                    </div>
                                                    <div class="col-6">
                                                        <button type="submit"
                                                            class="btn w-100 btn-primary text-uppercase btn-sm"
                                                            (click)="headerFilterApply();myDrop.close();">
                                                            Apply columns</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> -->
                <!-- <div class="table-navigation">
                                    <button (click)="scrollLeft($event)" class="btn-left"><i
                                            class="icon-arrow-left"></i></button>
                                    <button (click)="scrollRight($event)" class="btn-right"><i
                                            class="icon-arrow-left"></i></button>
                                </div> -->
                <div class="table-responsive " style="  max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));
                ">
                  <table
                    class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header  td-width-15ch td-white-space"
                    mat-table [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="drop($event)">
                    <ng-container *ngFor="let prop of displayedColumns">
                      <ng-container [matColumnDef]="prop">
                        <th mat-header-cell cdkDrag *matHeaderCellDef (click)="onSort(prop)" class="th-sort">
                          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                          {{prop}}
                          <i class="fs-14 ms-8 icon-info-circle" *ngIf="hint_dict[prop]"
                            ngbPopover="{{hint_dict[prop]}}" popoverClass="popover-default popover-info"
                            container="body" triggers="hover"></i>
                          <i class="fs-12 ms-8 icon-th-sort" [ngClass]="{
                                  'th-sort-ascend': sortProperty === prop && direction == 'asc',
                                  'th-sort-descend': sortProperty === prop && direction == 'desc'
                              }"></i>
                        </th>

                        <td mat-cell *matCellDef="let element" title="{{element[prop]}}">
                          {{element[prop]}}
                        </td>
                      </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="lastPage > 20 && lastPage > dataSource?.filteredData?.length">
              <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="lastPage"
                [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="limit"
                (pageChange)="pageChanged($event)"></ngb-pagination>
            </div>
          </div>
        </ng-template>
      </div>
    </form>
  </main>
</div>
<!-- Investment Request filter start -->
<ng-container *ngIf="reportName == 'Investment Request'">

  <app-investment-request #appInvestmentRequest [ouFilterForm]="OuFilterForm" [ordering]="ordering"
    [investmentForm]="investmentForm" [pageNumber]="pageNumber" [lastPage]="lastPage"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    [callMonthlyTDS]="callMonthlyTDS" (callMonthlyReport)="callReport($event)" [salFilter]="salFilter"
    [viewDetail]="viewDetail" [DesignationDD]="DesignationDD" [companyDD]="companyDD" [buDD]="buDD"
    [checkListElements]="checkListElements" [disabled]="disabled" [DepDD]="DepDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
    [submitted]="submitted" [Years]="Years"></app-investment-request>

</ng-container>
<!-- Investment Request filter end -->
<!-- Salary Register filter start -->
<ng-container *ngIf="reportName == 'Salary Register'">

  <app-salary-register #appsalaryRegister [ouFilterForm]="OuFilterForm" [ordering]="ordering"
    [salaryRegisterForm]="salaryRegisterForm" (resetFilter)="resetFilterFn($event)" [pageNumber]="pageNumber"
    [lastPage]="lastPage" [resDataFilter]="resDataFilter" [closeControl]="closeControl" [tempArr]="tempArr"
    (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    [callMonthlyTDS]="callMonthlyTDS" (callMonthlyReport)="callReport($event)" [salFilter]="salFilter"
    [viewDetail]="viewDetail" [companyDD]="companyDD" [buDD]="buDD" [checkListElements]="checkListElements"
    [disabled]="disabled" [DepDD]="DepDD" [BranchDD]="BranchDD" [gradeDD]="gradeDD" [empstatusDD]="empstatusDD"
    [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [DesignationDD]="DesignationDD"
    [payGroupDD]="payGroupDD" [submitted]="submitted" [Years]="Years"></app-salary-register>

</ng-container>
<!-- Salary Register filter end -->
<!-- PT (Professional Tax) filter start -->
<ng-container *ngIf="reportName == 'PT (Professional Tax)'">
  <app-pt #appPt [ptForm]="ptForm" [Years]="Years" [ordering]="ordering" [ouFilterForm]="OuFilterForm"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [pageNumber]="pageNumber"
    [lastPage]="lastPage" [closeControl]="closeControl" [tempArr]="tempArr" (dataFilters)="dataFilters($event)"
    (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    [callMonthlyTDS]="callMonthlyTDS" (callMonthlyReport)="callReport($event)" [salFilter]="salFilter"
    [viewDetail]="viewDetail" [companyDD]="companyDD" [buDD]="buDD" [disabled]="disabled" [DepDD]="DepDD"
    [DesignationDD]="DesignationDD" [BranchDD]="BranchDD" [gradeDD]="gradeDD" [empstatusDD]="empstatusDD"
    [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [submitted]="submitted"></app-pt>
</ng-container>
<!-- PT (Professional Tax) filter end -->

<!-- LWF (Labour Welfare Fund) filter start -->
<ng-container *ngIf="reportName == 'LWF (Labour Welfare Fund)'">
  <app-lwf #appLwf [lwfForm]="lwfForm" [ordering]="ordering" [ouFilterForm]="OuFilterForm"
    (resetFilter)="resetFilterFn($event)" [pageNumber]="pageNumber" [lastPage]="lastPage"
    [resDataFilter]="resDataFilter" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)" [callMonthlyTDS]="callMonthlyTDS"
    (callMonthlyReport)="callReport($event)" [salFilter]="salFilter" [viewDetail]="viewDetail" [companyDD]="companyDD"
    [buDD]="buDD" [disabled]="disabled" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
    [submitted]="submitted" [Years]="Years"></app-lwf>
</ng-container>
<!-- LWF (Labour Welfare Fund) filter end -->


<!-- PF (Provident Fund) filter start -->
<ng-container *ngIf="reportName == 'PF (Provident Fund)'">

  <app-pf #appPf [employeeForm]="employeeForm" [Years]="Years" [ordering]="ordering" [ouFilterForm]="OuFilterForm"
    (resetFilter)="resetFilterFn($event)" [pageNumber]="pageNumber" [lastPage]="lastPage"
    [resDataFilter]="resDataFilter" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)" [callMonthlyTDS]="callMonthlyTDS"
    (callMonthlyReport)="callReport($event)" [salFilter]="salFilter" [viewDetail]="viewDetail" [companyDD]="companyDD"
    [buDD]="buDD" [disabled]="disabled" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
    [submitted]="submitted"></app-pf>
</ng-container>
<!-- PF (Provident Fund) filter end -->
<!-- ESI (Employee State Insurance) filter start -->
<ng-container *ngIf="reportName == 'ESI (Employee State Insurance)'">
  <app-esi #appEsi [esiForm]="esiForm" [ordering]="ordering" [ouFilterForm]="OuFilterForm" [Years]="Years"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [pageNumber]="pageNumber"
    [lastPage]="lastPage" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)" [callMonthlyTDS]="callMonthlyTDS"
    (callMonthlyReport)="callReport($event)" [salFilter]="salFilter" [viewDetail]="viewDetail" [companyDD]="companyDD"
    [buDD]="buDD" [disabled]="disabled" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
    [submitted]="submitted"></app-esi>
</ng-container>
<!-- ESI (Employee State Insurance) filter end -->
<!-- YTD Salary Details filter start -->
<ng-container *ngIf="reportName == 'YTD Salary Details'">

  <app-ytd-salary-details #appYtdSalaryDetails [ordering]="ordering" [ouFilterForm]="OuFilterForm"
    [ytdSalaryForm]="ytdSalaryForm" [pageNumber]="pageNumber" [lastPage]="lastPage"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    [callMonthlyTDS]="callMonthlyTDS" (callMonthlyReport)="callReport($event)" [viewDetail]="viewDetail"
    [companyDD]="companyDD" [buDD]="buDD" [disabled]="disabled" [DepDD]="DepDD" [payGroupDD]="payGroupDD"
    [BranchDD]="BranchDD" [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD"
    [employeeDropdown]="employeeDropdown" [DesignationDD]="DesignationDD"
    [submitted]="submitted"></app-ytd-salary-details>
</ng-container>
<!-- YTD Salary Details filter end -->
<!-- Pay Salary (Bank Transfer) Report filter start -->
<ng-container *ngIf="reportName == 'Pay Salary (Bank Transfer) Report'">

  <app-pay-salary #appReleaseSalary [ordering]="ordering" [ouFilterForm]="OuFilterForm" [Years]="Years"
    [releseSalaryForm]="releseSalaryForm" [pageNumber]="pageNumber" [lastPage]="lastPage"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    [callMonthlyTDS]="callMonthlyTDS" (callMonthlyReport)="callReport($event)" [salFilter]="salFilter"
    [viewDetail]="viewDetail" [companyDD]="companyDD" [buDD]="buDD" (filterSubmit)="filterSubmits($event)"
    [disabled]="disabled" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD" [gradeDD]="gradeDD"
    [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [submitted]="submitted"
    [DesignationDD]="DesignationDD"></app-pay-salary>
</ng-container>
<!-- Pay Salary (Bank Transfer) Report filter end -->
<!-- Salary On Hold filter start -->
<ng-container *ngIf="reportName == 'Salary On Hold'">

  <app-salary-on-hold #appSalaryOnHold [ordering]="ordering" [ouFilterForm]="OuFilterForm"
    [salaryOnoldForm]="salaryOnoldForm" (radioBtnChangeFunction)="radioBtnChangeFunctions($event)"
    [pageNumber]="pageNumber" [lastPage]="lastPage" (resetFilter)="resetFilterFn($event)"
    [resDataFilter]="resDataFilter" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="empstatArr" (filterPanel)="panel($event)" [callMonthlyTDS]="callMonthlyTDS"
    (callMonthlyReport)="callReport($event)" [salFilter]="salFilter" [viewDetail]="viewDetail" [companyDD]="companyDD"
    [buDD]="buDD" [disabled]="disabled" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
    [submitted]="submitted" [Years]="Years"></app-salary-on-hold>
</ng-container>
<!-- Salary On Hold filter end -->
<!-- CTC As Per Salary Structure filter start -->
<ng-container *ngIf="reportName == 'CTC As Per Salary Structure'">
  <app-ctc-per-salary #appCtc [ordering]="ordering" [ouFilterForm]="OuFilterForm" [Years]="Years"
    [ctcSalaryStructureForm]="ctcSalaryStructureForm" [pageNumber]="pageNumber" [lastPage]="lastPage"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    [callMonthlyTDS]="callMonthlyTDS" (callMonthlyReport)="callReport($event)" [salFilter]="salFilter"
    [viewDetail]="viewDetail" [companyDD]="companyDD" [buDD]="buDD" [checkListElements]="checkListElements"
    [disabled]="disabled" [DepDD]="DepDD" [BranchDD]="BranchDD" [gradeDD]="gradeDD" [payGroupDD]="payGroupDD"
    [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [submitted]="submitted"
    [DesignationDD]="DesignationDD"></app-ctc-per-salary>
</ng-container>
<!-- CTC As Per Salary Structure filter end -->

<!-- Monthly TDS Deduction Report -->
<ng-container *ngIf="reportName == 'Monthly TDS Deduction Report'">
  <app-monthly-tds #appMonthly [ordering]="ordering" [Years]="Years" [ouFilterForm]="OuFilterForm"
    (radioBtnChangeFunction)="radioBtnChangeFunctions($event)" (resetFilter)="resetFilterFn($event)"
    [pageNumber]="pageNumber" [lastPage]="lastPage" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
    [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
    [callMonthlyTDS]="callMonthlyTDS" [monthlyTDSForm]="monthlyTDSForm" (callMonthlyReport)="callReport($event)"
    [salFilter]="salFilter" [viewDetail]="viewDetail" [companyDD]="companyDD" [buDD]="buDD"
    (filterSubmit)="filterSubmits($event)" [disabled]="disabled" [DepDD]="DepDD" [DesignationDD]="DesignationDD"
    [countryDD]="countryDD" [stateDD]="stateDD" [BranchDD]="BranchDD" [cityDD]="cityDD" [gradeDD]="gradeDD"
    [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
    [submitted]="submitted"></app-monthly-tds>
</ng-container>
<!-- CTC Revision -->
<ng-container *ngIf="reportName == 'CTC Revision'">
  <app-ctc-revision #appCTCRevision [ordering]="ordering" [Years]="Years" [ouFilterForm]="OuFilterForm"
    [ctcRevisionForm]="ctcRevisionForm" [salFilter]="salFilter" [pageNumber]="pageNumber" [lastPage]="lastPage"
    [viewDetail]="viewDetail" (filterPanel)="panel($event)" [companyDD]="companyDD" [buDD]="buDD" [disabled]="disabled"
    [BranchDD]="BranchDD" [DesignationDD]="DesignationDD" [DepDD]="DepDD" [gradeDD]="gradeDD"
    [employeeDropdown]="employeeDropdown" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD"
    [resDataFilter]="resDataFilter" (resetFilter)="resetFilterFn($event)" (callMonthlyReport)="callReport($event)"
    [submitted]="submitted" (submitDone)="submitDone($event)" [payGroupDD]="payGroupDD" [tempArr]="tempArr"
    [empstatArr]="empstatArr" [closeControl]="closeControl"></app-ctc-revision>
</ng-container>

<!-- Employee Statutory Report -->
<ng-container *ngIf="reportName == 'Employee Statutory Report'">
  <app-employee-statutory #appEmployee_statutory [ordering]="ordering" [Years]="Years" [ouFilterForm]="OuFilterForm"
    [employeeStatutoryForm]="employeeStatutoryForm" (submitDone)="submitDone($event)" [tempArr]="tempArr"
    [empstatArr]="empstatArr" [closeControl]="closeControl" (callMonthlyReport)="callReport($event)"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [viewDetail]="viewDetail"
    [submitted]="submitted" [salFilter]="salFilter" [companyDD]="companyDD" [buDD]="buDD" [disabled]="disabled"
    [BranchDD]="BranchDD" [DesignationDD]="DesignationDD" [DepDD]="DepDD" [gradeDD]="gradeDD"
    [employeeDropdown]="employeeDropdown" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD"
    (filterPanel)="panel($event)" [callMonthlyTDS]="callMonthlyTDS"></app-employee-statutory>
</ng-container>

<!-- Reimbursement Request -->
<ng-container *ngIf="reportName == 'Reimbursement Request'">
  <app-reimbursement-request #appReimburse [Years]="Years" [ordering]="ordering" [ouFilterForm]="OuFilterForm"
    [reimbursement_dropdown]="reimbursement_dropdown" [pageNumber]="pageNumber" [lastPage]="lastPage"
    [employeeDropdown]="employeeDropdown" (loader)="loaderFn($event)" [empstatusDD]="empstatusDD"
    [closeControl]="closeControl" (submitDone)="submitDone($event)" [emptypeDD]="emptypeDD"
    [reimbursementRequestForm]="reimbursementRequestForm" [viewDetail]="viewDetail" (filterPanel)="panel($event)"
    [submitted]="submitted" [salFilter]="salFilter" [companyDD]="companyDD" [buDD]="buDD" [BranchDD]="BranchDD"
    [DesignationDD]="DesignationDD" [DepDD]="DepDD" [gradeDD]="gradeDD" [resDataFilter]="resDataFilter"
    (resetFilter)="resetFilterFn($event)" (callMonthlyReport)="callReport($event)" [disabled]="disabled"
    [tempArr]="tempArr" [empstatArr]="empstatArr" [callMonthlyTDS]="callMonthlyTDS"></app-reimbursement-request>
</ng-container>

<!-- Final settlement  -->
<ng-container *ngIf="reportName == 'Final Settlement'">
  <app-final-settlement-report #appFinalSettlement [ordering]="ordering" [ouFilterForm]="OuFilterForm" [Years]="Years"
    [finalSettlementForm]="finalSettlementForm" [pageNumber]="pageNumber" [lastPage]="lastPage"
    [employeeDropdown]="employeeDropdown" (loader)="loaderFn($event)" [empstatusDD]="empstatusDD"
    [closeControl]="closeControl" (submitDone)="submitDone($event)" [emptypeDD]="emptypeDD" [viewDetail]="viewDetail"
    (filterPanel)="panel($event)" [submitted]="submitted" [salFilter]="salFilter" [companyDD]="companyDD" [buDD]="buDD"
    [BranchDD]="BranchDD" [DepDD]="DepDD" [gradeDD]="gradeDD" [resDataFilter]="resDataFilter"
    (resetFilter)="resetFilterFn($event)" (callMonthlyReport)="callReport($event)" [disabled]="disabled"
    [tempArr]="tempArr" [empstatArr]="empstatArr" [DesignationDD]="DesignationDD"></app-final-settlement-report>
</ng-container>

<!-- Investment Not Submitted  -->
<ng-container *ngIf="reportName == 'Investment Not Submitted'">
  <app-investment-not-submitted #investmentNotSubmitted [ouFilterForm]="OuFilterForm" [ordering]="ordering"
  [investmentForm]="investmentnsForm" [pageNumber]="pageNumber" [lastPage]="lastPage"
  (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
  [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
  [callMonthlyTDS]="callMonthlyTDS" (callMonthlyReport)="callReport($event)" [salFilter]="salFilter"
  [viewDetail]="viewDetail" [DesignationDD]="DesignationDD" [companyDD]="companyDD" [buDD]="buDD"
  [checkListElements]="checkListElements" [disabled]="disabled" [DepDD]="DepDD" [BranchDD]="BranchDD"
  [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
  [submitted]="submitted" [Years]="Years">
  </app-investment-not-submitted>
</ng-container>

<!-- Variable Paid -->
<ng-container *ngIf="reportName == 'Variable Paid'">
  <app-variable-paid #appVarPaid [variablePaidForm]="variablePaidForm" [ordering]="ordering" [ouFilterForm]="OuFilterForm" [Years]="Years"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [pageNumber]="pageNumber"
    [lastPage]="lastPage" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)" [callMonthlyTDS]="callMonthlyTDS"
    (callMonthlyReport)="callReport($event)" [salFilter]="salFilter" [viewDetail]="viewDetail" [companyDD]="companyDD"
    [buDD]="buDD" [disabled]="disabled" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [variableDropdown]="variableDropdown"
    [submitted]="submitted"></app-variable-paid>
</ng-container>

<!-- Overtime Payment -->
<ng-container *ngIf="reportName == 'Overtime Payment'">
  <app-overtime-payment #appOverPy [overtimePaymentForm]="overtimePaymentForm" [ordering]="ordering" [ouFilterForm]="OuFilterForm" [Years]="Years"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [pageNumber]="pageNumber"
    [lastPage]="lastPage" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)" [callMonthlyTDS]="callMonthlyTDS"
    (callMonthlyReport)="callReport($event)" [salFilter]="salFilter" [viewDetail]="viewDetail" [companyDD]="companyDD"
    [buDD]="buDD" [disabled]="disabled" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
    [submitted]="submitted"></app-overtime-payment>
</ng-container>

<!-- Gratuity Paid  -->
<ng-container *ngIf="reportName == 'Gratuity Paid'">
  <app-gratitude-paid #appGratitude [ouFilterForm]="OuFilterForm" [ordering]="ordering"
  [gratitudePaidForm]="gratitudePaidForm" [pageNumber]="pageNumber" [lastPage]="lastPage"
  (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
  [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
  [callMonthlyTDS]="callMonthlyTDS" (callMonthlyReport)="callReport($event)" [salFilter]="salFilter"
  [viewDetail]="viewDetail" [DesignationDD]="DesignationDD" [companyDD]="companyDD" [buDD]="buDD"
  [checkListElements]="checkListElements" [disabled]="disabled" [DepDD]="DepDD" [BranchDD]="BranchDD"
  [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
  [submitted]="submitted" [Years]="Years">
  </app-gratitude-paid>
</ng-container>

<!-- NPS Employer Contribution -->
<ng-container *ngIf="reportName == 'NPS Employer Contribution'">
  <app-nps-employer #appNPS [npsForm]="npsForm" [ordering]="ordering" [ouFilterForm]="OuFilterForm" [Years]="Years"
    (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [pageNumber]="pageNumber"
    [lastPage]="lastPage" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)" [callMonthlyTDS]="callMonthlyTDS"
    (callMonthlyReport)="callReport($event)" [salFilter]="salFilter" [viewDetail]="viewDetail" [companyDD]="companyDD"
    [buDD]="buDD" [disabled]="disabled" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [variableDropdown]="variableDropdown"
    [submitted]="submitted"></app-nps-employer>
</ng-container>

<!-- TDS Computation  -->
<ng-container *ngIf="reportName == 'TDS Computation'">
  <app-tds-computation #appTDSComp [ouFilterForm]="OuFilterForm" [ordering]="ordering"
  [tdsComputationForm]="tdsComputationForm" [pageNumber]="pageNumber" [lastPage]="lastPage"
  (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter" [closeControl]="closeControl"
  [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="" empstatArr (filterPanel)="panel($event)"
  [callMonthlyTDS]="callMonthlyTDS" (callMonthlyReport)="callReport($event)" [salFilter]="salFilter"
  [viewDetail]="viewDetail" [DesignationDD]="DesignationDD" [companyDD]="companyDD" [buDD]="buDD"
  [checkListElements]="checkListElements" [disabled]="disabled" [DepDD]="DepDD" [BranchDD]="BranchDD"
  [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
  [submitted]="submitted" [Years]="Years">
  </app-tds-computation>
</ng-container>


<div class="modal  modal-alert {{resetFilter == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Reset Report Filter?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">This reset report back to default filter. You'll have to change to get it back in the
          list.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="resetFilter = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="restFilterSave"
          (click)="resetFilterApply()">Reset</button>
      </div>
    </div>
  </div>
</div>
<div class="modal  modal-alert {{applyFilter == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <form class="" [formGroup]="filterSaveForm" autocomplete="off">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <h4 class="modal-title">Custom Filters Applied</h4>
          <button class="btn-reset  ms-auto" (click)="applyFilter = false"> <i class="icon-close-lg fs-16"></i></button>
        </div>
        <div class="modal-body">
          <div class="row row-16">
            <div class="col-12 form-row">
              <div class="form-check">
                <input name="filter" formControlName="filter" type="radio" id="filter1" class="form-check-input "
                  [value]="true" checked>
                <label for="filter1" class="form-check-label">Set the filters as default for the
                  report</label>
              </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check ">
                <input name="filter" formControlName="filter" type="radio" id="filter2" class="form-check-input "
                  [value]="false">
                <label for="filter2" class="form-check-label">Don't set the filter as default</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary col-8 mx-auto btn-sm text-uppercase"
            [disabled]="saveFilterClicked" (click)="saveFilter()">Apply</button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal  modal-alert {{scheduleReport == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <form class="" [formGroup]="scheduleReportFilterMemorizeForm" autocomplete="off">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <h4 class="modal-title">Custom Filters Applied</h4>
          <button class="btn-reset  ms-auto" (click)="scheduleReport = false"> <i
              class="icon-close-lg fs-16"></i></button>
        </div>
        <div class="modal-body">
          <div class="row row-16">
            <div class="col-12 form-row">
              <div class="form-check">
                <input name="filter" formControlName="filter" type="radio" id="filter1" class="form-check-input "
                  [value]="true" checked>
                <label for="filter1" class="form-check-label">Schedule the default report without
                  filters</label>
              </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check ">
                <input name="filter" formControlName="filter" type="radio" id="filter2" class="form-check-input "
                  [value]="false">
                <label for="filter2" class="form-check-label">{{scheduleText}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary col-8 mx-auto btn-sm text-uppercase"
            (click)="reportMemorizeFunction()">Schedule Report</button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Add columns start -->
<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='addColumnFilter === true'>
  <form class="side-panel-container" autocomplete="off" [formGroup]="testform">
    <header class="side-panel-head">
      <h5>Add columns</h5>
      <a class="toggle-panel" (click)="addColumnFilter = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 mb-16">
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-8">Select Columns
            </p>
          </div>
          <div class="col-12 form-row" *ngFor="let prop of columnOptions,let i = index ">
            <label for="check{{i}}" class="form-check p-0 d-flex ">
              <input type="checkbox" name="check" id="check{{i}}" class="form-check-input ms-0" [value]="prop"
                formControlName="checkbox" (change)="headerFilterAdd($event,i)">
              <label for="check{{i}}" class="form-check-label fs-16">{{prop}}</label>
            </label>

          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class=" btn btn-outline-primary text-uppercase btn-sm"
        (click)="resetColumnFilterFunction(); addColumnFilter = false">
        RESET COLUMNS
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        (click)="headerFilterApply(); addColumnFilter = false">
        SAVE COLUMNS
      </button>
    </footer>
  </form>
</div>
<!-- Add columns end -->
