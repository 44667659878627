import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, NgZone  } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { right } from '@popperjs/core';
import { PageAccessService } from 'src/app/@core/services/page-access.service';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-pa-assign-rights',
  templateUrl: './pa-assign-rights.component.html',
  styleUrls: ['./pa-assign-rights.component.scss']
})
export class PaAssignRightsComponent implements OnInit {

  @Input() formGp : any
  @Input() subMenus :any
  @Input() orderFormArray:any;
  @Input() disabled=false;
  @Input() formGpSecond : any
  @Input() dataItem : any

  @Output() submitmethod = new EventEmitter();

  menu :any             = [];
  sectionFormGroup !: FormGroup
  sectionArr: any = []
  sectionHaveChildLength :any
  selectedmenu:number   = 0;
  mainId = 0
  selectname:any         = 'Home'
  subMenu:any           = [];
  // dataItem:any          = [];
  firstlevelmenu:number = 0;
  secondlevelmenu:any= [];
  thirdlevelmenu:any = []
  num:number            = 0;
  selectsection:any     = [];
  ngrandchildren:any    = 0;
  grandchildren:any     = 0;
  submenuchildren:any   = 0;
  selectedSubSec:any        = [];
  selectedSubSecError : any = []
  subemenusectionerrorarray:any   = [];
  selectedidsectionarray:Number[] = [];
  selectedsectionarray:any        = [];
  subMenuSectionArray : any = []
  levelOneForm :  any = []
  selectError:any     = false;
  loader:any                 = false;
  id:any                          = 0;
  saveClicked = false
  currentSelectedIndex: number = 0;

  constructor( private pageAccessService:PageAccessService,
    public fb:FormBuilder,
    public router: Router,
    public messageService : MessageService,
    public route:ActivatedRoute,
    private cd : ChangeDetectorRef,
    private ngZone: NgZone) { }

  ngOnInit(): void {
    this.saveClicked = false
    this.sectionFormGroup = this.fb.group({
      newSection : this.fb.array([])
    })
    this.route.params.subscribe((params: Params) => {
      if(isNaN(params['id'])){
        this.id=params['id'];
      }
    })
    // Initial API
    this.callInitialListAPI()
  }

  // Initial API
  callInitialListAPI(){
    this.controls().getRawValue().forEach((item,ind)=>{
      this.menu.push(item[0])
    })
  }

  newSection() : FormArray {
    return this.sectionFormGroup.get("newSection") as FormArray
  }

  addtoSec : boolean = false
  setIndex(index:any,menuId:any){
    this.newSection().clear()
    this.sectionArr = []
    this.selectedSubSec = []
    this.selectedSubSecError = []
    this.secondlevelmenu = []
    this.loader = true;
    this.mainId = index
    this.selectedmenu = index
    this.selectname = this.menu[index]['name']

    if(this.selectname == 'Profile' || this.selectname == 'Request'){
      this.sectionHaveChildLength = this.selectname == 'Profile' ? 0 : -1
      this.oneLevelChild(index).disable()
      if(this.oneLevelChild(index)?.length){
        for(let i=0;i< this.oneLevelChild(index)?.length ;i++){
          this.oneLevelChild(index)?.at(i).patchValue({
            view : true,
            add : true,
            edit : true,
            delete : this.oneLevelChild(index)?.at(i).get('delete')?.value == null ? null : true
          })
          if(this.secondLvlChild(i)?.length !=0){
            this.secondLvlChild(i)?.disable()
            for(let j=0;j< this.secondLvlChild(i)?.length;j++){
              this.secondLvlChild(i).at(j).patchValue({
                view : true,
                add : true,
                edit : true,
                delete : this.secondLvlChild(i)?.at(j).get('delete')?.value == null ? null : true
              })
            }
          }
        }
      }
    }
    for(let j=0;j< this.oneLevelChild(this.mainId)?.length;j++){
      if(this.nFormGp(j).get('lvlTwoChildren')?.value.length != 0){
        if(this.nFormGp(j).get('add')?.value == true|| this.nFormGp(j).get('edit')?.value == true || this.nFormGp(j).get('delete')?.value == true){
          this.addtoSec = false
          for(let k=0;k<this.secondLvlChild(j)?.length;k++){
            if(this.nSecFormGp(j,k).get('add')?.value || this.nSecFormGp(j,k).get('edit')?.value || this.nSecFormGp(j,k).get('delete')?.value || this.nSecFormGp(j,k).get('view')?.value){
               this.addtoSec = true
              setTimeout(()=>{
                this.setCheckOrNotValidation(this.secondLvlChild(j))
              },1501)
            }
          }
          if(this.addtoSec){
            this.selectedSubSec.push(this.nFormGp(j)?.get('menu')?.value)
            this.secondlevelmenu.push(this.nFormGp(j)?.get('menu')?.value)
            this.selectedSubSecError.push(false)
            this.newSection().push(
              this.fb.group({
                'menu' : this.nFormGp(j)?.get('menu')?.value,
                'name' : this.nFormGp(j)?.get('name')?.value
              })
            )
          }
        }
        this.sectionArr.push({
          'menu' : this.nFormGp(j)?.get('menu')?.value,
          'name' : this.nFormGp(j)?.get('name')?.value
        })
      }
      if(this.newSection().length < 1 && this.selectname != 'Request' && this.oneLevelChild(this.mainId)?.length-1 == j){
        this.newSection().push(
          this.fb.group({
            'menu' : this.nFormGp(j)?.get('menu')?.value,
            'name' : this.nFormGp(j)?.get('name')?.value
          })
        )
      }
      if(this.secondLvlChild(j)?.length !=0){
        for(let k=0;k<this.secondLvlChild(j)?.length;k++){
          if(this.thirdLvlChild(j,k)?.length !=0){
            for(let l=0;l< this.thirdLvlChild(j,k)?.length;l++){
              if(this.nThirdFormGp(j,k,l).get('add')?.value == true || this.nThirdFormGp(j,k,l).get('edit')?.value == true || this.nThirdFormGp(j,k,l).get('delete')?.value == true){
                this.thirdlevelmenu[k] = this.nSecFormGp(j,k).get('menu')?.value
              }
             }
          }
        }
      }
    }
    this.sectionHaveChildLength = (this.sectionArr?.length - 1)
    this.firstlevelmenu = menuId
    setTimeout(()=>{
      this.loader = false
      this.cd.detectChanges()
    },1500)
  }

  // Initial FormArray of Main FormGroup
  controls() : FormArray {
    return this.formGp?.get('assignrights') as FormArray
  }

  // FormArray for HOME,PROFILE......
  details(index:any){
    return this.controls()?.controls[index] as FormArray
  }

  // FormArray of Children from Details()
  oneLevelChild(index:any) : FormArray {
    return this.details(index).controls[0]?.get('lvlOneChildren') as FormArray
  }

  nFormGp(index:any){
    return this.oneLevelChild(this.mainId)?.controls[index] as FormGroup
  }

  // 2nd Level Child FormArray
  secondLvlChild(index:any) : FormArray{
    return this.nFormGp(index)?.get('lvlTwoChildren') as FormArray
  }

  // 2nd Level Child FormGroup
  nSecFormGp(index:any,contIndex:any){
    return this.secondLvlChild(index)?.controls[contIndex] as FormGroup
  }

  // 3rd Level Child FormArray
  thirdLvlChild(index:any,contIndex:any) : FormArray{
    return this.nSecFormGp(index,contIndex)?.get('lvlThreeChildren') as FormArray
  }

  getFormArrayLength(index:any,contIndex:any): number {
    const formArray =  this.secondLvlChild(index)?.controls[contIndex]?.get('lvlThreeChildren')?.value
    return formArray ? formArray.length : 0;
  }

  // 3rd Level Child FormGroup
  nThirdFormGp(index:any,contIndex:any,contChildIndex:any){
    return this.thirdLvlChild(index,contIndex)?.controls[contChildIndex] as FormGroup
  }

  // Second Lvl Menu Selection
  setCheckChildrens(menuId:any,e:any,j:any,k:any){
    let checked = e?.target?.checked
    if(this.nSecFormGp(j,k).get('menu')?.value == menuId ){
        if(checked){
          if (!this.thirdlevelmenu.includes(menuId)) {
            this.thirdlevelmenu.push(menuId);
        }
        }else{
          this.thirdlevelmenu = this.thirdlevelmenu.filter((item:any, index:any) => item !== menuId);

        }
      this.nSecFormGp(j,k).patchValue({
        view : checked,
        add: checked,
        edit: checked,
        delete: this.nSecFormGp(j,k)?.get('delete')?.value == null ? null : checked
      })
      this.setCheckOrNotValidation(this.secondLvlChild(j))
    }
  }

  // Main Menu Selection
  setCheckChildren(menuId:any,e:any,index:any){
    let checked = e?.target?.checked
    if(this.nFormGp(index).get('menu')?.value == menuId){
      this.nFormGp(index).patchValue({
        view : checked,
        add: checked,
        edit: checked,
        delete: this.nFormGp(index)?.get('delete')?.value == null ? null : checked
      })
    }
  }

  // New Section
  subMenuSection(){
    if((this.newSection().length-1) < this.sectionHaveChildLength){
      this.newSection().push(
        this.fb.group({
          'menu' : [],
          'name' : []
        })
      )
    }
  }

  // Dropdown selection
  sectionSelect(e:any,index:any){
    if(this.selectedSubSec?.indexOf(e.menu) == -1){
      this.selectedSubSecError[index] = e.menu
    }
    let selectedError = false
    this.selectedSubSecError[index] = selectedError
    let findDuplicates = (arr:any) => arr.filter((item:any, index:any) => arr.indexOf(item) !== index)
    findDuplicates(this.selectedSubSec).forEach((item:any)=>{
      if(item == e.menu){
        this.selectedSubSecError[index] = true
        this.currentSelectedIndex = index
      }
    })
    this.oneLevelChild(this.mainId).controls.forEach((control: AbstractControl, pos: number) => {
      this.clearCustomError(pos);
    });

    this.oneLevelChild(this.mainId).controls.forEach((item:any, pos:any)=> {
      if(item.controls.menu.value == e.menu){
        this.secondlevelmenu[index] = e.menu
        this.oneLevelChild(this.mainId).at(pos)?.patchValue({
          view : true,
          add : true,
          edit : true,
          delete : this.oneLevelChild(this.mainId).at(pos).get('delete')?.value == null ? null : true
        })
        this.setCheckOrNotValidation(this.secondLvlChild(pos))
      }
      if(this.selectname == 'Profile'){
        this.secondLvlChild(1).at(pos)?.patchValue({
          view : true,
          add : true,
          edit : true,
          delete : this.secondLvlChild(1)?.at(pos).get('delete')?.value == null ? null : true
        })
      }
      this.cd.detectChanges();
    })
  }

  // Take ngselected formarray & pass to the fn, loop through its control
  // 1. Initially check if it's add,edit,delete(NULL || FALSE),view is false for all controls
  // 2. Any of them is not satisfied set Errors or set as NULL
  // 3. If no errors then take it's 'lvlThreeChildren' control and check if it's parent's add,edit,delete(NULL || TRUE),view are true
  // 4. If yes from 3,check current formarray of parent is having length > 0
  // 5. if yes then repeat 1 & 2
  // 6. One more to check if parentHasCustomer is false and already the childHasCustomError is having ERROR's automcatically those should be set to NULL
  setCheckOrNotValidation(formArray: FormArray): void {
    let parentHasCustomError = formArray.controls.every((control: AbstractControl) => {
      const formGroupControl = control as FormGroup;
      return formGroupControl.get('add')?.value === false && formGroupControl.get('edit')?.value === false && (formGroupControl.get('delete')?.value === false || formGroupControl.get('delete')?.value === null) && formGroupControl.get('view')?.value === false;
    });
    formArray.controls.forEach((control: AbstractControl) => {
      const formGroupControl = control as FormGroup;
      formGroupControl.setErrors(parentHasCustomError ? { 'customError': true } : null);
        const checkChildren = formGroupControl.get('add')?.value === true && formGroupControl.get('edit')?.value === true && (formGroupControl.get('delete')?.value === true || formGroupControl.get('delete')?.value === null) && formGroupControl.get('view')?.value === true;
        const lvlThreeChildren = formGroupControl.get('lvlThreeChildren') as FormArray;
        if (lvlThreeChildren && lvlThreeChildren.length > 0) {
          let childHasCustomError = lvlThreeChildren.controls.every((childControl: AbstractControl) => {
            const childFormGroup = childControl as FormGroup;
            return childFormGroup.get('add')?.value === false && childFormGroup.get('edit')?.value === false && (childFormGroup.get('delete')?.value === false || childFormGroup.get('delete')?.value === null) && childFormGroup.get('view')?.value === false;
          });
          lvlThreeChildren.controls.forEach((childControl : AbstractControl)=>{
            const childFormGroup = childControl as FormGroup;
            if (checkChildren) {
              childFormGroup.setErrors(childHasCustomError ? { 'customError': true } : null);
              this.cd.detectChanges()
            } else {
              childFormGroup.setErrors(null);
            }
          })
        }
    });
  }

  // Dropdown Deletion
  deleteSection(index:any){
    this.oneLevelChild(this.mainId).controls.forEach((item:any, pos:any)=> {
      if(item.controls.menu.value == this.selectedSubSec[index]){
        this.oneLevelChild(this.mainId).at(pos)?.patchValue({
          view : false,
          add : false,
          edit : false,
          delete : this.oneLevelChild(this.mainId)?.at(pos).get('delete')?.value == null ? null : false
        })
        this.clearCustomError(pos);
      }
    })
    this.newSection().removeAt(index)
    this.selectedSubSec.splice(index,1)
    this.selectedSubSecError.splice(index,1)
    this.secondlevelmenu.splice(index,1)
    this.currentSelectedIndex = this.currentSelectedIndex-1
  }

  // Clear errors on delete & select change for 2nd & 3rd level
  clearCustomError(pos:any){
    const secondLevelControl = this.secondLvlChild(pos) as FormArray;
    if (secondLevelControl) {
      this.clearErrorsRecursively(secondLevelControl);
    }
    this.cd.detectChanges();
  }

  // Fn to recursively call whole children of 2nd & 3rd level from ClearCustomError
  clearErrorsRecursively(control: AbstractControl): void {
    control.setErrors(null);
    control.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    if (control instanceof FormGroup || control instanceof FormArray) {
      Object.values(control.controls).forEach((childControl: AbstractControl) => {
        this.clearErrorsRecursively(childControl);
      });
    }
  }

  setCheckChildrenss(menuId:any,e:any,l:any,k:any,m:any){
    let checked = e?.target?.checked
    if(this.nThirdFormGp(l,k,m)?.get('menu')?.value == menuId){
      this.nThirdFormGp(l,k,m).patchValue({
        view : checked,
        add: checked,
        edit: checked,
        delete: this.nThirdFormGp(l,k,m)?.get('delete')?.value == null ? null : checked
      })
      this.setCheckOrNotValidation(this.thirdLvlChild(l,k))
    }
  }

  validateAssignRightFlow(){
    this.saveClicked = true
    this.submitmethod.emit();
  }
}
