<div class=" config-layout">
  <header class=" config-header">
    <h5 class="m-0">{{reportName}}</h5>
    <button class="btn-reset text-light-600" routerLink="/employee-report"><i class="icon-close-lg fs-16"></i></button>
  </header>
  <main class=" config-container">
    <form class="">
      <div class="container-fluid p-32 form-container">
        <div class="row mb-16">
          <div class="col-md-4 col-lg-6">
            <h3>{{reportName}}</h3>
          </div>
          <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">

              <!-- Add columns start -->
              <button class="btn-square btn btn-secondary" (click)="addColumnFilter = true">
                <i class="icon-plus fs-14" ngbPopover="Add column" popoverClass="popover-default popover-info"
                  container="body" triggers="hover"></i>
              </button>
              <!-- Add columns end -->

              <button class="btn-square btn btn-secondary" (click)="viewDetail = true">
                <i class="icon-filter-left fs-13"></i>
              </button>
              <!-- <button class="btn flex-shrink-0 fs-14 fw-600 text-uppercase btn-outline-primary"
                                (click)="scheduleReportFunction()">Schedule
                                Report</button> -->
              <button class="btn flex-shrink-0 fs-10 px-4 fw-700 text-uppercase btn-primary" (click)="export()">DOWNLOAD
                REPORT</button>

            </div>
          </div>
        </div>
        <app-table-loader *ngIf="loader"></app-table-loader>
        <div class="col-12" *ngIf="appliedFilter != 0 && !loader">
          <ul class="selected-values  ">
            <ng-container *ngFor="let filter of appliedFilter | keyvalue ">
              <li class="ng-value" *ngIf="filter.value != '' && filter.value != null && filter.value != 'null'">
                <ng-container
                  *ngIf="filter.key == 'birthday' || filter.key == 'date_of__joining'  || filter.key == 'date_of_login'  || filter.key == 'created_date' || filter.key == 'date_of_leaving'|| filter.key == 'date_of__confirmation' || filter.key == 'resignation_date' || filter.key == 'date_of_birth' || filter.key == 'date_of_confirmation' || filter.key == 'date_of_joining' || filter.key == 'disabled_at';else all_data">
                  <ng-container
                    *ngIf="filter.value != 'this_month' && filter.value != 'this_week' && filter.value != 'today' && filter.value != 'tomorrow' && filter.value != 'yesterday' && filter.value != 'last_month'&& filter.value != 'next_month' && filter.value != 'current_month' && filter.value != 'last_7_days' && filter.value != 'last_15_days' && filter.value != 'last_week';else all_data1">
                    <ng-container
                      *ngIf="filter.key == 'date_of_login' || filter.key == 'resignation_date' || filter.key == 'date_of_joining'">
                      {{removeUnderscoreAddUpperCase(filter.key)}} : {{this.appservice.dateFormatDisplay(dateSplit[0])+'
                      - '+this.appservice.dateFormatDisplay(dateSplit[1])}}
                    </ng-container>
                    <ng-container *ngIf="filter.key == 'date_of_birth'">
                      {{removeUnderscoreAddUpperCase('birthday')}} :
                      {{this.appservice.dateFormatDisplay(dateSplitBirth[0])+' -
                      '+this.appservice.dateFormatDisplay(dateSplitBirth[1])}}
                    </ng-container>
                    <ng-container *ngIf="filter.key == 'date_of_confirmation'">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{this.appservice.dateFormatDisplay(dateSplitConfirmation[0])+' -
                      '+this.appservice.dateFormatDisplay(dateSplitConfirmation[1])}}
                    </ng-container>
                    <ng-container *ngIf="filter.key == 'date_of_leaving'">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{dateSplitLeave}}
                    </ng-container>
                    <ng-container *ngIf="filter.key == 'created_date'">
                      <span class="ng-value-label ">
                        {{removeUnderscoreAddUpperCase(filter.key)}} :
                        {{this.appservice.dateFormatDisplay(filter.value)}}</span>
                    </ng-container>
                  </ng-container>
                  <ng-template #all_data1>
                    <span class="ng-value-label ">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{removeUnderscoreAddUpperCase(filter.value)}}</span>
                  </ng-template>
                </ng-container>

                <ng-template #all_data>
                  <ng-container
                    *ngIf="filter.key === 'disabled_by' || filter.key === 'reporting_manager' || filter.key === 'functional_manager' || filter.key === 'branch' || filter.key === 'bu'  || filter.key === 'company'  || filter.key === 'department' || filter.key === 'designation' || filter.key === 'grade' || filter.key === 'employee_list'  || filter.key === 'emp_status' || filter.key === 'emp_type'; else singleData">
                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key === 'company' ? tagMultiDataFunction (appliedFilter.company):''">
                      Company : <img class="img-fluid rounded-circle sq-24 mx-1" src="{{tagMultiData[0].logo}}">
                      {{tagMultiData[0].company_name}}
                      <ng-container *ngIf="tagMultiCnt != 0"> ... <span
                          class="flex-center radius-24 bg-dark-400 px-2">{{tagMultiCnt}}</span>
                      </ng-container>
                    </span>

                    <app-employee-tags *ngIf="filter.key === 'employee_list' " [key]="'employee_list'"
                      [list]="appliedFilter.employee_list" [title]="'Employee list'"></app-employee-tags>
                    <app-employee-tags *ngIf="filter.key === 'reporting_manager' " [key]="'reporting_manager'"
                      [list]="appliedFilter.reporting_manager" [title]="'Reporting manager'"></app-employee-tags>
                    <app-employee-tags *ngIf="filter.key === 'functional_manager' " [key]="'functional_manager'"
                      [list]="appliedFilter.functional_manager" [title]="'Functional manager'"></app-employee-tags>
                    <app-employee-tags *ngIf="filter.key === 'disabled_by' " [key]="'disabled_by'"
                      [list]="appliedFilter.disabled_by" [title]="'Disabled By'"></app-employee-tags>






                    <span class="d-flex align-items-center p-0 "
                      *ngIf="filter.key != 'company' && filter.key != 'disabled_by' && filter.key != 'employee_list' && filter.key != 'reporting_manager' && filter.key != 'functional_manager' ">
                      {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{tagMultiDataFunction(filter.value)}}
                      <ng-container *ngIf="tagMultiCnt != 0"> ... <span
                          class="flex-center radius-24 bg-dark-400 px-2">{{tagMultiCnt}}</span>
                      </ng-container>
                    </span>
                  </ng-container>
                  <ng-template #singleData>
                    <span class=""> {{removeUnderscoreAddUpperCase(filter.key)}} :
                      {{filter?.value == 'Incompleted' ? 'Not Completed' :
                      removeUnderscoreAddUpperCase(filter.value)}}</span>
                  </ng-template>
                </ng-template>
                <span aria-hidden="true" class="ng-value-icon right m-0" *ngIf="reportName== 'Snapshot'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appSnapshot.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0" *ngIf="reportName== 'Bank Details'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appbank.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0" *ngIf="reportName== 'Employee Details'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appempdetails.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0" *ngIf="reportName== 'Personal'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="apppersonaldetails.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0"
                  *ngIf="reportName== 'Employee Login Report' && filter.key != 'date_of_login'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="applogin.applyTagClose1();">×</span>
                <span aria-hidden="true" class="ng-value-icon right m-0" *ngIf="reportName== 'Resignation'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="appResignation.applyTagClose1();">×</span>
                  <span aria-hidden="true" class="ng-value-icon right m-0" *ngIf="reportName== 'Disable Portal Acccess' && filter.key != 'disabled_on'"
                  (click)="applyTagClose(reportName,filter.key)" (click)="disableAccess.applyTagClose1();">×</span>
              </li>
            </ng-container>
          </ul>
        </div>
        <div *ngIf="nodata && !loader ;else all_data" class="row row-16">
          <div class="col-12">
            <div class="card card-c2 overflow-hidden">
              <!-- <span class="fw-500 fs-14">There are no data currently</span> -->
              <div class="table-responsive " style="max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));
                ">
                <table
                  class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header  td-width-15ch td-white-space"
                  mat-table [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal"
                  (cdkDropListDropped)="drop($event)">
                  <ng-container *ngFor="let prop of displayedColumns">
                    <ng-container [matColumnDef]="prop">
                      <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

                        {{prop}}<i class="fs-14 ms-8 icon-info-circle" *ngIf="hint_dict[prop]"  ngbPopover="{{hint_dict[prop]}}"
                          popoverClass="popover-default popover-info" container="body" triggers="hover"></i>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{element[prop]}}
                      </td>
                    </ng-container>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                </table>
                <div class="text-center card card-c2 flex-center p-16 bg-dark-500 m-12">No data found</div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #all_data>
          <div class="row row-16" *ngIf="!loader">
            <div class="col-12 " *ngIf=" alertShow">
              <div role="alert" class="alert alert-info   mb-0 pe-32 show">
                <p class="mb-0 fs-14 hstack ">Click and drag the header’s to change the column position
                </p>
                <div (click)="alertShow = false" class="btn-close fs-9 pointer"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 overflow-hidden">
                <!-- <div ngbDropdown #myDrop="ngbDropdown" class="d-inline-block add-column"
                                    container="body" placement="bottom-right">
                                    <button class="btn-outline-primary btn sq-32 p-0 radius-4" id="dropdownBasic1"
                                        ngbDropdownToggle>
                                        <i class="icon-plus fs-14"></i>
                                    </button>
                                    <div ngbDropdownMenu class=" fs-14" aria-labelledby="dropdownBasic1">
                                        <form class="" [formGroup]="testform">
                                            <div class="container-fluid p-16" style="max-width: 22.1875rem;">
                                                <div class="row row-16 table-filter mb-16">
                                                    <div class="col-12"
                                                        *ngFor="let prop of columnOptions,let i = index ">
                                                        <label for="check{{i}}" class="form-check p-0 d-flex ">
                                                            <input type="checkbox" name="check" id="check{{i}}"
                                                                class="form-check-input ms-0" [value]="prop"
                                                                formControlName="checkbox"
                                                                (click)="headerFilterAdd($event)">
                                                            <label for="check{{i}}"
                                                                class="form-check-label fs-16">{{prop}}</label>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row row-16">
                                                    <div class="col-6">
                                                        <button type="submit"
                                                            class="btn w-100 btn-outline-primary text-uppercase btn-sm"
                                                            (click)="resetColumnFilterFunction();myDrop.close();">
                                                            RESET COLUMNS</button>
                                                    </div>
                                                    <div class="col-6">
                                                        <button type="submit"
                                                            class="btn w-100 btn-primary text-uppercase btn-sm"
                                                            (click)="headerFilterApply();myDrop.close();">
                                                            Apply columns</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> -->
                <!-- <div class="table-navigation">
                                    <button (click)="scrollLeft($event)" class="btn-left"><i
                                            class="icon-arrow-left"></i></button>
                                    <button (click)="scrollRight($event)" class="btn-right"><i
                                            class="icon-arrow-left"></i></button>
                                </div> -->
                <div class="table-responsive "
                  style="  max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));">
                  <table
                    class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header td-width-15ch td-white-space"
                    mat-table [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="drop($event)">
                    <ng-container *ngFor="let prop of displayedColumns">
                      <ng-container [matColumnDef]="prop">
                        <th mat-header-cell cdkDrag *matHeaderCellDef (click)="onSort(prop)" class="th-sort">
                          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                          {{prop}}
                          <i class="fs-14 ms-8 icon-info-circle" *ngIf="hint_dict[prop]"
                            ngbPopover="{{hint_dict[prop]}}" popoverClass="popover-default popover-info"
                            container="body" triggers="hover"></i>
                          <i class="fs-12 ms-8 icon-th-sort" [ngClass]="{
                                  'th-sort-ascend': sortProperty === prop && direction == 'asc',
                                  'th-sort-descend': sortProperty === prop && direction == 'desc'
                              }"></i>
                        </th>
                        <td mat-cell *matCellDef="let element" title="{{element[prop]}}">
                          {{element[prop]}}
                        </td>
                      </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="lastPage > 20 && lastPage > dataSource?.filteredData?.length">
              <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="lastPage"
                [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="limit"
                (pageChange)="pageChanged($event)"></ngb-pagination>
            </div>
          </div>
        </ng-template>
      </div>
    </form>
  </main>
</div>
<!-- Snapshot filter start -->
<ng-container *ngIf="reportName == 'Snapshot'">
  <app-snapshot-report #appSnapshot [Snapshot]="Snapshot" [ouFilterForm]="OuFilterForm" [pageNumber]="pageNumber"
    [ordering]="ordering" [dateSetup]="dateSetup" (resetFilter)="resetFilterFn($event)" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" [tempArr]="tempArr" (dataFilters)="dataFilters($event)"
    (submitDone)="submitDone($event)" [empstatArr]="empstatArr" (filterPanel)="panel($event)" [callemp]="callemp"
    (callMonthlyReport)="callReport($event)" [viewDetail]="viewDetail" [companyDD]="companyDD" [buDD]="buDD"
    [checkListElements]="checkListElements" [disabled]="disabled" [salFilter]="salFilter" [DepDD]="DepDD"
    [DesignationDD]="DesignationDD" [BranchDD]="BranchDD" [gradeDD]="gradeDD" [empstatusDD]="empstatusDD"
    [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [submitted]="submitted" [lastPage]="lastPage"
    [Years]="Years"></app-snapshot-report>
</ng-container>

<!-- Snapshot Report filter end -->

<!-- Bank Report Filter starts -->

<ng-container *ngIf="reportName == 'Bank Details'">

  <app-bank-details-report #appbank [bankdetails]="bankdetails" [ouFilterForm]="OuFilterForm"
    (resetFilter)="resetFilterFn($event)" [ordering]="ordering" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="empstatArr"
    (filterPanel)="panel($event)" [callemp]="callemp" (callMonthlyReport)="callReport($event)" [viewDetail]="viewDetail"
    [companyDD]="companyDD" [buDD]="buDD" [disabled]="disabled" [salFilter]="salFilter" [DepDD]="DepDD"
    [DesignationDD]="DesignationDD" [BranchDD]="BranchDD" [gradeDD]="gradeDD" [empstatusDD]="empstatusDD"
    [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [submitted]="submitted" [pageNumber]="pageNumber"
    [lastPage]="lastPage"></app-bank-details-report>

</ng-container>

<!-- bank details filter end -->
<!-- Employee details Report Filter starts -->

<ng-container *ngIf="reportName == 'Employee Details'">
  <app-employee-details #appempdetails [Employeedetails]="Employeedetails" [ouFilterForm]="OuFilterForm"
    (resetFilter)="resetFilterFn($event)" [ordering]="ordering" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="empstatArr"
    (filterPanel)="panel($event)" [callemp]="callemp" (callMonthlyReport)="callReport($event)" [viewDetail]="viewDetail"
    [companyDD]="companyDD" [buDD]="buDD" [checkListElements]="checkListElements" [disabled]="disabled"
    [salFilter]="salFilter" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD" [gradeDD]="gradeDD"
    [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [submitted]="submitted"
    [pageNumber]="pageNumber" [lastPage]="lastPage"></app-employee-details>
</ng-container>


<!-- Employee details filter end -->
<!-- Personal details Report Filter starts -->

<ng-container *ngIf="reportName == 'Personal'">

  <app-personal-details #apppersonaldetails [ouFilterForm]="OuFilterForm" [personaldetails]="personaldetails"
    (resetFilter)="resetFilterFn($event)" [ordering]="ordering" [resDataFilter]="resDataFilter"
    [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)" [empstatArr]="" empstatArr
    (filterPanel)="panel($event)" [callemp]="callemp" (callMonthlyReport)="callReport($event)" [viewDetail]="viewDetail"
    [companyDD]="companyDD" [buDD]="buDD" [checkListElements]="checkListElements" [disabled]="disabled"
    [salFilter]="salFilter" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD" [gradeDD]="gradeDD"
    [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [submitted]="submitted"
    [pageNumber]="pageNumber" [lastPage]="lastPage"></app-personal-details>

</ng-container>

<!-- Personal details report filter ends -->

<!-- Login Report Filter starts -->

<ng-container *ngIf="reportName == 'Employee Login Report'">
  <app-login-report #applogin [ouFilterForm]="OuFilterForm" [loginForm]="loginForm"
    (resetFilter)="resetFilterFn($event)" [dateSetup]="appliedFilter" [ordering]="ordering"
    [resDataFilter]="resDataFilter" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)" [callemp]="callemp"
    (callMonthlyReport)="callReport($event)" [viewDetail]="viewDetail" [companyDD]="companyDD" [buDD]="buDD"
    [checkListElements]="checkListElements" [disabled]="disabled" [salFilter]="salFilter" [DepDD]="DepDD"
    [DesignationDD]="DesignationDD" [BranchDD]="BranchDD" [gradeDD]="gradeDD" [empstatusDD]="empstatusDD"
    [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown" [submitted]="submitted" [pageNumber]="pageNumber"
    [lastPage]="lastPage"></app-login-report>
</ng-container>

<!-- login filter end -->

<!-- Resignation Report Filter starts -->

<ng-container *ngIf="reportName == 'Resignation'">
  <app-resignation-report #appResignation [ouFilterForm]="OuFilterForm" [resignationForm]="resignationForm"
    (resetFilter)="resetFilterFn($event)" [ordering]="ordering" [dateSetup]="appliedFilter"
    [resDataFilter]="resDataFilter" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)" [callemp]="callemp"
    (callMonthlyReport)="callReport($event)" [viewDetail]="viewDetail" [companyDD]="companyDD" [buDD]="buDD"
    [disabled]="disabled" [salFilter]="salFilter" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
    [submitted]="submitted" [pageNumber]="pageNumber" [lastPage]="lastPage"></app-resignation-report>
</ng-container>

<!-- Resignation filter end -->

<!-- Disable Portal Access Report Filter starts -->

<ng-container *ngIf="reportName == 'Disable Portal Acccess'">
  <app-disable-portal-access #disableAccess [ouFilterForm]="OuFilterForm" [disabledForm]="disabledForm"
    (resetFilter)="resetFilterFn($event)" [ordering]="ordering" [dateSetup]="appliedFilter"
    [resDataFilter]="resDataFilter" [closeControl]="closeControl" [tempArr]="tempArr" (submitDone)="submitDone($event)"
    [empstatArr]="" empstatArr (filterPanel)="panel($event)" [callemp]="callemp"
    (callMonthlyReport)="callReport($event)" [viewDetail]="viewDetail" [companyDD]="companyDD" [buDD]="buDD"
    [disabled]="disabled" [salFilter]="salFilter" [DepDD]="DepDD" [DesignationDD]="DesignationDD" [BranchDD]="BranchDD"
    [gradeDD]="gradeDD" [empstatusDD]="empstatusDD" [emptypeDD]="emptypeDD" [employeeDropdown]="employeeDropdown"
    [submitted]="submitted" [pageNumber]="pageNumber" [lastPage]="lastPage"></app-disable-portal-access>
</ng-container>

<!-- Disable Portal Access filter end -->

<div class="modal  modal-alert {{resetFilter == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Reset Report Filter?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">This reset report back to default filter. You'll have to change to get it back in the
          list.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="resetFilter = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="restFilterSave"
          (click)="resetFilterApply()">Reset</button>
      </div>
    </div>
  </div>
</div>
<div class="modal  modal-alert {{applyFilter == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <form class="" [formGroup]="filterSaveForm" autocomplete="off">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <h4 class="modal-title">Custom Filters Applied</h4>
          <button class="btn-reset  ms-auto" (click)="applyFilter = false"> <i class="icon-close-lg fs-16"></i></button>
        </div>
        <div class="modal-body">
          <div class="row row-16">
            <div class="col-12 form-row">
              <div class="form-check">
                <input name="filter" formControlName="filter" type="radio" id="filter1" class="form-check-input "
                  [value]="true" checked>
                <label for="filter1" class="form-check-label">Set the filters as default for the
                  report</label>
              </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check ">
                <input name="filter" formControlName="filter" type="radio" id="filter2" class="form-check-input "
                  [value]="false">
                <label for="filter2" class="form-check-label">Don't set the filter as default</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary col-8 mx-auto btn-sm text-uppercase"
            [disabled]="saveFilterClicked" (click)="saveFilter()">Apply</button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal  modal-alert {{scheduleReport == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <form class="" [formGroup]="scheduleReportFilterMemorizeForm" autocomplete="off">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <h4 class="modal-title">Custom Filters Applied</h4>
          <button class="btn-reset  ms-auto" (click)="scheduleReport = false"> <i
              class="icon-close-lg fs-16"></i></button>
        </div>
        <div class="modal-body">
          <div class="row row-16">
            <div class="col-12 form-row">
              <div class="form-check">
                <input name="filter" formControlName="filter" type="radio" id="filter1" class="form-check-input "
                  [value]="true" checked>
                <label for="filter1" class="form-check-label">Schedule the default report without
                  filters</label>
              </div>
            </div>
            <div class="col-12 form-row">
              <div class="form-check ">
                <input name="filter" formControlName="filter" type="radio" id="filter2" class="form-check-input "
                  [value]="false">
                <label for="filter2" class="form-check-label">{{scheduleText}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary col-8 mx-auto btn-sm text-uppercase"
            (click)="reportMemorizeFunction()">Schedule Report</button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Add columns start -->
<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='addColumnFilter === true'>
  <form class="side-panel-container" autocomplete="off" [formGroup]="testform">
    <header class="side-panel-head">
      <h5>Add columns</h5>
      <a class="toggle-panel" (click)="addColumnFilter = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 mb-16">
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-8">Select Columns
            </p>
          </div>
          <div class="col-12 form-row" *ngFor="let prop of columnOptions,let i = index ">
            <label for="check{{i}}" class="form-check p-0 d-flex ">
              <input type="checkbox" name="check" id="check{{i}}" class="form-check-input ms-0" [value]="prop"
                formControlName="checkbox" (click)="headerFilterAdd($event,i)">
              <label for="check{{i}}" class="form-check-label fs-16">{{prop}}</label>
            </label>

          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class=" btn btn-outline-primary text-uppercase btn-sm"
        (click)="columnApplied=false;resetColumnFilterFunction(); addColumnFilter = false;">
        RESET COLUMNS
      </button>
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm"
        (click)="columnApplied=true;headerFilterApply(); addColumnFilter = false;">
        SAVE COLUMNS
      </button>
    </footer>
  </form>
</div>
<!-- Add columns end -->
