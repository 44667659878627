import { Component, OnInit } from '@angular/core';
import { MyItDeclarationService } from 'src/app/@core/services/my-it-declaration.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';


@Component({
  selector: 'app-my-confirm-declaration',
  templateUrl: './my-confirm-declaration.component.html',
  styleUrls: ['./my-confirm-declaration.component.scss']
})

export class MyConfirmDeclarationComponent implements OnInit {

  constructor(
    private myItDeclaration:MyItDeclarationService,
    private authService:AuthService,
    public messageService : MessageService,
    public appService:AppService
  ) { }

  activeTab1              = 1;
  detailedTaxCalculation  = false;
  empId                   = 0;
  currentYear             = new Date().getFullYear();
  yearList : any          = [];
  proposedData:any        = [];
  taxRegimeData:any       = [];
  loader                  = false;
  alertShow               = 'show';
  netIncome:any           = 0;
  modalToggle             = false;
  currentDocName          = "";
  currentExtension        = "";
  allowedExtension        = "";
  fileName:any;
  alertToggle             = false;
  investalertMsg:any      = '';
  selectedYear :any;
  hra80ggData:any;
  currency:any;
  loader1 : boolean = false
  alertShowflatRate = 'show';
  flatRate = false
  ngOnInit(): void {
    this.empId        = this.authService.isEmployeeId;
    this.selectedYear = this.appService.getFinancialYear();
    this.getMyItDeclarationData();
    // this.yearListCreate();
    this.getEmployeeCurrency();
  }

  /********* GET DATA FUNCTION ****************/
  getMyItDeclarationData(){
      this.flatRate = false
      this.loader = true;
    this.myItDeclaration.getMyItDeclarations(this.empId,this.selectedYear,'True').subscribe((res: any) => {
      this.flatRate = res?.is_employee_under_flat_rate
      this.proposedData = res?.data;
      if(res?.data?.length > 0)
        this.taxRegimeData  = JSON.parse(this.proposedData[0]?.declared_datail);
      if(this.taxRegimeData?.excemption_declaration?.hasOwnProperty('hra'))
        this.hra80ggData='hra';
      if(this.taxRegimeData?.excemption_declaration?.hasOwnProperty('80gg'))
        this.hra80ggData='80gg';
      this.totalLetOutAmt(this.proposedData[0]?.incomeloss_property?.loss_lender);
      this.loader = false;
    });
  }

  /********* YEAR CHANGE FUNCTION ****************/
  yearChange(val:any){
    this.selectedYear = Number(val);
    this.getMyItDeclarationData();
  }

  /********* EMPLOYEE CURRENCY FUNCTION ****************/
  getEmployeeCurrency(){
    let userData     = this.appService.getEmpData();
    this.currency    = userData.currency;
  }


  totalLetOutAmt(data:any){
    this.netIncome  = 0;
    if(data?.length > 0){
      for(let i = 0; i<data?.length;i++){
        this.netIncome += data[i].net_income_houseproperty;
      }
    }
    this.netIncome = parseFloat(this.netIncome).toFixed(2);
    return Number(this.netIncome);
  }



  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appService.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.investalertMsg       = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  // Loader
  showGif(event: {loaded: number, total: number}) {
    this.loader1 = event.loaded < event.total;
  }

  // Modal Close
  close(bool:any){
    this.modalToggle = bool;
  }

  // Modal close for Invalid Extension
  closed(bool:any){
    this.alertToggle = bool
  }

}
