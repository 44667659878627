import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NotificationService } from 'src/app/@core/services/notification.service';


@Component({
  selector: 'app-final-settlement-process-f-and-f',
  templateUrl: './final-settlement-process-f-and-f.component.html',
  styleUrls: ['./final-settlement-process-f-and-f.component.scss']
})
export class FinalSettlementProcessFAndFComponent implements OnInit {

  @Input() empId:any;
  @Input() processDetails:any;
  @Input() inboxId:any;
  @Input() payrollStatus:any;
  @Input() company_id:any;
  @Input() workDaysSaveData:any;
  @Input() relievingStat:any;

  @Output() payRollStatusEmitter     = new EventEmitter();

  modalToggle             = false;
  currentDocName          = 'Payslip';
  currentExtension        = 'pdf';
  pdfUrl:any              = '';
  saveClicked             = false;
  alertMsg: any;

  dateRangeData: any      = [];
  loader                  = false;

  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    public fb:FormBuilder,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {

    this.getDateRangeSliderFunction();

  }

  @Input()formGp : FormGroup = this.fb.group({
    comments     : ['']
  });
  @Input() resignationDetails: any;

  @Input() lopOrAttendance:any;
  @Input() workDaysPayableForm:any;


  get f() {return  this.formGp.controls; }


  getDateRangeSliderFunction(){
    this.loader = true;
    this.finalSettlementService.getDateRangeSlider(this.company_id,this.resignationDetails?.value?.pay_month,this.resignationDetails?.value?.pay_year).subscribe((res: any) => {
      this.dateRangeData = res?.data[0];
      this.loader        = false;

    })
  }

  processFandF(type:any){

    this.saveClicked = true;
    this.loader      = true;

    let start_date = this.dateRangeData?.start_date;
    let end_date   = this.dateRangeData?.end_date;

    const month    = new Date(this.resignationDetails?.value?.pay_month+' 1, 2022').getMonth();
    let payMonth   = Number(month)+1;
    let processData:any;

    let relieve_month_processed = (this.relievingStat == null || this.relievingStat == '' || this.relievingStat == 'Unprocessed') ? false : true;

    if(type == 'process'){

      if(this.lopOrAttendance){
        let WrokDayData = {
          "step_name"        : "workdays_payable",
          "employee"         : this.empId,
          "pay_year"         : this.resignationDetails?.value?.pay_year,
          "pay_month"        : this.resignationDetails?.value?.pay_month,
          "present_days"     : this.workDaysPayableForm.get('present_days')?.value,
          "weekoff_days"     : this.workDaysPayableForm.get('weekoff_days')?.value,
          "lop_days"         : this.workDaysPayableForm.get('lop_days')?.value,
          "fnf_reversal_lop" : this.workDaysPayableForm.get('fnf_reversal_lop')?.value

        }
        this.workDaysSaveData = WrokDayData;
      }

      this.finalSettlementService.finalSettlementSave(this.workDaysSaveData).subscribe((res:any)=>{

      })

       processData = {
        "start_date"  : moment(start_date).format('YYYY-MM-DD'),
        "end_date"    : moment(end_date).format('YYYY-MM-DD'),
        "month"       : payMonth,
        "year"        : this.resignationDetails?.value?.pay_year,
        "company"     : this.company_id,
        "relieve_month_processed" : relieve_month_processed,
        "employee_list"   :[
          {   "id"        : this.empId,
              "status"    :"Processed",
              "comments"  : this.formGp.get('comments')?.value
          }
        ]
      }


      this.finalSettlementService.processSave(processData).subscribe((res:any)=>{
        if(res?.error?.length == 0){
        this.getProcessDetails();
        }else{
          this.alertMsg    = res?.error[0]?.error_list[0]?.description;
          this.saveClicked = false;
          this.loader      = false;
          this.notificationService.handleErrorNotification(this.alertMsg,"Error");

        }
      },(error:any) => {
        this.saveClicked = false;
        this.loader      = false;
      });
    }else{
         processData = {
          "start_date"  : moment(start_date).format('YYYY-MM-DD'),
          "end_date"    : moment(end_date).format('YYYY-MM-DD'),
          "month"       : payMonth,
          "year"        : this.resignationDetails?.value?.pay_year,
          "company"     : this.company_id,
          "relieve_month_processed" : relieve_month_processed,
          "employee_list"   :[
            {   "id"        : this.empId,
                "status"    :"Unprocessed",
            }
          ]
      }


    this.finalSettlementService.processSave(processData).subscribe((res:any)=>{
      if(res?.error?.length == 0){
      this.getProcessDetails();
      }else{
        this.alertMsg    = res?.error[0]?.error_list[0]?.description;
        this.saveClicked = false;
        this.loader      = false;
        this.notificationService.handleErrorNotification(this.alertMsg,"Error");

      }
    },(error:any) => {
      this.saveClicked = false;
    });
  }
  }

  close(bool:any){
    this.modalToggle = bool;
  }

  viewSlip(type:any){
    this.modalToggle = true;
    if(type == 'pay'){
      this.currentDocName = this.appService.getPayTaxName(this.processDetails?.payslip,'Payslip');
      this.pdfUrl         = this.processDetails?.payslip+"?"+Date.now();
    }else{
      this.currentDocName = this.appService.getPayTaxName(this.processDetails?.taxslip,'Taxslip');
      this.pdfUrl         = this.processDetails?.taxslip+"?"+Date.now();
    }

  }

  getProcessDetails(){
    this.finalSettlementService.getProcessDetails(this.empId).subscribe((res: any) => {
      this.processDetails = res?.data;
      this.payrollStatus  = this.processDetails?.status;
      this.saveClicked    = false;
      this.loader         = false;
      this.payRollStatusEmitter.emit(this.processDetails?.status);
    },(error:any) => {
      this.saveClicked = false;
      this.loader      = false;
    });
  }

  finishProcess(){
    let data =  {"inbox_id": this.inboxId};
    this.finalSettlementService.finishProcess(data,this.empId).subscribe((res:any)=>{
      this.router.navigate(['final-settlement']);
    })
  }
}
