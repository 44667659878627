import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewpasswordComponent } from './newpassword/newpassword.component';
import { ConfirmEqualValidatorDirective } from 'src/app/newpassword/confirm-equal-validator.directive';
import { OTPComponent } from './otp/otp.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OneTimePasswordComponent } from './one-time-password/one-time-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MasterSelectModule } from 'src/app/@shared/master-select.module';
import { UnsupportedResolutionComponent } from './unsupported-resolution/unsupported-resolution.component';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';


@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    OTPComponent,
    NewpasswordComponent,
    ConfirmEqualValidatorDirective,
    OneTimePasswordComponent,
    UnsupportedResolutionComponent,
    SessionTimeoutComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MasterSelectModule
  ]
})
export class AuthModule {}
