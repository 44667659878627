import { Component, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import { PayComponentService } from 'src/app/@core/services/pay-component.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';


@Component({
  selector: 'app-pay-component-setup',
  templateUrl: './pay-component-setup.component.html',
  styleUrls: ['./pay-component-setup.component.scss']
})
export class PayComponentSetupComponent implements OnInit {
  activeTab                   = 1;
  disabled                    = false;
  defaultComponent            = [];
  disabledComponent           = [];
  leaveTypes = []
  id: number                  = 0;
  headerText                  = 'Add';
  isMapItem:any               = false;
  taxLimit:any                = false;
  loader                      = false;
  isuserDefined               = false;
  sortError                   = 0;
  nameError                   = 0;
  // component_value:any         = null;
  tax_config_disable          = false;
  sectionIncomeDisable        = false;
  componentError              = false;
  isEdit                      = false;
  isNPS                       = false;
  userDefinedAdd              = false;
  isNotice                    = false;
  defaultCompName:any;
  payComponentForm: FormGroup = this.formBuilder.group({
                                      id        : 0,
                                      define    : this.formBuilder.group({
                                                  name                      : ['',[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
                                                  pay_type                  : ['',Validators.required],
                                                  perquisite_type           : [null],
                                                  encashment_leave_type           : [null],
                                                  set_component_ctc         : [''],
                                                  set_component_gross       : [''],
                                                  pay_component_salary      : [''],
                                                  set_reimbursable          : [''],
                                                  set_fbp                   : [''],
                                                  restric_fbp_amount        : [''],
                                                  nature_payment            : ['',Validators.required],
                                                  prorate_per_day           : ['',Validators.required],
                                                  arrear_applicable         : ['',Validators.required],
                                                  process_join_month        : ['',Validators.required],
                                                  process_settlement_month  : ['',Validators.required],
                                                  component_type            : [,Validators.required],



                                      }),
                                      statuatory : this.formBuilder.group({

                                                    show_salary_slip        : ['',Validators.required],
                                                    show_salary_register    : ['',Validators.required],
                                                    salary_register_order   : ['',[NumberpatternValidator(),Validators.required,Validators.maxLength(4)]],
                                                    roundoff                : [,Validators.required],
                                                    pf_contribution         : [false],
                                                    pt_contribution         : [false],
                                                    esi_contribution        : [false],
                                                    esi_limit               : [false],
                                                    pf_always               : [true],


                                      }),
                                      taxDetails : this.formBuilder.group({
                                                    tax_configuration       : [,[Validators.required]],
                                                    non_taxable_limit       : [null,[NumberpatternValidator(),Validators.maxLength(10)]],
                                                    tax_computation         : ['',Validators.required],
                                                    under_section_income    : []
                                      })
                                });
  userDefinedData = [{
              'set_component_ctc'       : false,
              'set_component_gross'     : false,
              'pay_component_salary'    : true,
              'set_reimbursable'        : false,
              'set_fbp'                 : false,
              'restric_fbp_amount'      : false,
              'nature_payment'          : true,
              'prorate_per_day'         : true,
              'arrear_applicable'       : true,
              'process_join_month'      : true,
              'process_settlement_month': true,
              'show_salary_slip'        : true,
              'show_salary_register'    : true,
              'salary_register_order'   : '',
              'tax_configuration'       : 'Non-Taxable',
              'roundoff'                : 'Nearest',
              'pf_contribution'         : false,
              'pt_contribution'         : false,
              'esi_contribution'        : false,
              'esi_limit'               : false,
              'pf_always'               : false,


  }]
  previousResetData =[{
              'nature_payment'            : false,
              'prorate_per_day'           : false,
              'arrear_applicable'         : false,
              'process_join_month'        : false,
              'process_settlement_month'  : false,
              'pay_type'                  : false,
              'set_component_ctc'         : false,
              'set_component_gross'       : false,
              'pay_component_salary'      : false,
              'set_reimbursable'          : false,
              'set_fbp'                   : false,
              'restric_fbp_amount'        : false,
              'show_salary_slip'          : false,
              'show_salary_register'      : false,
              'tax_computation'           : false
  }]
  disableEnableData =[{
              'set_component_ctc'     : true,
              'set_component_gross'   : false,
              'pay_component_salary'  : false,
              'set_reimbursable'      : false,
              'set_fbp'               : false,
              'restric_fbp_amount'    : false,
              'tax_configuration'     : false,
              'pf_contribution'       : false,
              'pt_contribution'       : false,
              'esi_contribution'      : false,
              'esi_limit'             : false,
              'pf_always'             : false,



  }]
  NPSData = [{
    'set_component_ctc'       : false,
    'set_component_gross'     : false,
    'pay_component_salary'    : true,
    'set_reimbursable'        : false,
    'set_fbp'                 : false,
    'restric_fbp_amount'      : false,
    'nature_payment'          : false,
    'prorate_per_day'         : true,
    'arrear_applicable'       : false,
    'process_join_month'      : true,
    'process_settlement_month': true,
    'show_salary_slip'        : true,
    'show_salary_register'    : true,
    'salary_register_order'   : '',
    'tax_configuration'       : 'Non-Taxable',
    'roundoff'                : 'Nearest',
    'under_section_income'    : null,

  }]
  disableNPSData =[{
    'set_component_ctc'     : false,
    'set_component_gross'   : false,
    'pay_component_salary'  : false,
    'set_reimbursable'      : false,
    'set_fbp'               : false,
    'restric_fbp_amount'    : false,
    'arrear_applicable'     : false,
    'pf_contribution'       : false,
    'pt_contribution'       : false,
    'esi_contribution'      : false,
    'esi_limit'             : false,
    'pf_always'             : false,

  }]
  NoticeAmountData =[{

  'pay_component_salary'  : false,
  'tax_configuration'     : 'Non-Taxable',
  'under_section_income'  : null,
  'show_salary_slip'      : true,
  'show_salary_register'  : true,

  }]
  disableNoticeAmount =[{

    'pf_contribution'       : false,
    'pt_contribution'       : false,
    'esi_contribution'      : false,
    'esi_limit'             : false,
    'pf_always'             : false,
    'tax_configuration'     : false
  }]
  taxrestricted         = false;
  taxRestrictedData     = ['GST','Salary Recovery','Other Allowance','80D: Medical Insurance Premium','Clearance Recovery'];
  prorateRestrictData   = ['Reimbursement','LTA','Perquisite'];

  editflag : boolean = false

  tab1touched : boolean = false
  tab2touched : boolean = false

  constructor(private formBuilder:FormBuilder,private componentService:PayComponentService,public router: Router,
    public route:ActivatedRoute,public existDataService :  ExistDatasService) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {

      if( !isNaN(params['id'])){
        this.id         = +params['id'];
        this.headerText = "Edit";
        this.editflag = true
        this.editForm();
      }
      else{
        this.resetDefaultValue(this.previousResetData[0]);
      }
    })
    this.componentService.getDropdownComponent(false).subscribe((res:any)=>{

      this.defaultComponent = res;

    })

  }

  get defineForm() {
    return this.payComponentForm.get('define') as FormGroup;
  }
  get statuatoryForm() {
    return this.payComponentForm.get('statuatory') as FormGroup;
  }
  get taxDetailsForm() {
    return this.payComponentForm.get('taxDetails') as FormGroup;
  }


  editForm(){
    this.loader         = true;
    this.isEdit         = true;
    this.taxrestricted  = false;
    this.userDefinedAdd = true;
    this.componentService.getSinglePayComponent(this.id).subscribe((res:any)=>{
      this.loader       = false;
      let pay_type    = res.pay_type;
      let currentYear = new Date().getFullYear();

      if(res.is_active == false){
        this.payComponentForm.disable();
        this.disabled   = true;
        this.headerText = "View";
      }

      if(res.component_type==null)
      {
        this.disabledComponent=res.component_type;
      }
      Object.entries(res).forEach(([key, value]) => {
         this.resetComponentForm(key,value);
      })
      if(res.is_active == true){
        this.componentService.getSinglePayComponent(res.component_type).subscribe((res:any)=>{
          this.defaultCompName = res.name;

          if(this.taxRestrictedData.includes(res.name))
            this.taxrestricted = true;
          // if(res.name=='Other Allowance' ||res.name=='Gratuity'){

          //   this.disableOtherAllowanceControl();
          // }
          // else
            this.disableFormControl(res.predefined_component);

          if(res.name=='User Defined'){
            this.isuserDefined = true;
            if(pay_type==false){

              this.disableUserDefined(this.disableEnableData[0]);
              this.userDefinedAdd = false;

            }
            else
              this.userDefinedAdd = true;
            if(this.payComponentForm.getRawValue().define.nature_payment==false){

              this.payComponentForm['controls'].define.get('arrear_applicable')?.setValue(false);
              this.payComponentForm['controls'].define.get('arrear_applicable')?.disable();
            }
          }
          else if(res.name=='NPS-Salary'){
            this.isNPS = true;
            if(pay_type==false){

              this.disableUserDefined(this.disableNPSData[0]);

            }
            else
            this.isuserDefined = true;

          }
          else if(res.name=='Notice Amount'){
            this.isNotice = true;
            if(pay_type==false){
              this.disableUserDefined(this.disableNoticeAmount[0]);
            }
          }

          if(res.name=='Notice Amount' || res.name=='User Defined'){
            this.isMapItem = true;

          }

          if(res.name=='NPS-Salary' || res.name=='Gratuity'){

            this.taxLimit = true;

          }


          if(this.isMapItem==true){
            this.taxDataSettings();

          }
          if(this.taxLimit==true){
              this.payComponentForm['controls'].taxDetails.get('non_taxable_limit')?.setValue(null);

          }
          if(this.defaultCompName=='Perquisite'){
            this.payComponentForm['controls'].define.get('perquisite_type')?.setValidators([Validators.required]);
          }  else{
            this.payComponentForm['controls'].define.get('perquisite_type')?.clearValidators();
            this.payComponentForm['controls'].define.get('perquisite_type')?.setValue(null);
          }

          if(this.defaultCompName=='Leave Encashment'){
            this.getLeaveDropDownList()
            this.payComponentForm['controls'].define.get('encashment_leave_type')?.setValidators([Validators.required]);
          }
          else{
            this.payComponentForm['controls'].define.get('encashment_leave_type')?.clearValidators();
            this.payComponentForm['controls'].define.get('encashment_leave_type')?.setValue(null);
          }
          this.payComponentForm['controls'].define.get('encashment_leave_type')?.updateValueAndValidity();

          this.payComponentForm['controls'].define.get('perquisite_type')?.updateValueAndValidity();

        })
      }


    },(error)=>{
      this.router.navigate(['pay-component']);
    });

  }
  getLeaveDropDownList(){
    this.componentService.getLeaveDropDownList().subscribe((res:any)=>{
      this.leaveTypes = res
    })

  }
  onNavChange(changeEvent: NgbNavChangeEvent){

    if(this.isMapItem==true){
      this.taxDataSettings();

    }
    if(this.taxLimit==true){
        this.payComponentForm['controls'].taxDetails.get('non_taxable_limit')?.setValue(null);

    }

    if (changeEvent.nextId === 2) {
      if (this.payComponentForm['controls'].define.invalid || this.componentError==true) {
        changeEvent.preventDefault();
      }else
        this.tab1touched = true
    } else  if (changeEvent.nextId === 3) {
      if (this.payComponentForm['controls'].statuatory.invalid || this.payComponentForm['controls'].define.invalid || this.componentError==true) {
        changeEvent.preventDefault();
      }else
        this.tab2touched = true
    }
  }

  submitPayDefine(){
    this.activeTab = 2;
    this.tab1touched = true
    if(this.isMapItem==true){
      this.taxDataSettings();

    }
    if(this.taxLimit==true){
        this.payComponentForm['controls'].taxDetails.get('non_taxable_limit')?.setValue(null);

    }
  }
  taxDataSettings(){
    if(this.payComponentForm['controls'].define.value.pay_type==true){
      // this.payComponentForm['controls'].taxDetails.get('under_section_income')?.enable();
      if(this.payComponentForm['controls'].taxDetails.get('under_section_income')?.value=='')
      this.payComponentForm['controls'].taxDetails.get('under_section_income')?.setValue('Section 17(1)');
    }
    else{
      this.payComponentForm['controls'].taxDetails.get('under_section_income')?.setValue('');

    }

  }

  submitStatuatory(){
    this.activeTab = 3;
    this.tab2touched=true
  }
  submitForm(){
    if(this.payComponentForm['controls'].define.value.component_type==0)
      this.payComponentForm['controls'].define.get('component_type')?.setValue(null);
    if(this.payComponentForm.getRawValue().statuatory.salary_register_order==0 && this.payComponentForm.getRawValue().statuatory.show_salary_register==false)
      this.payComponentForm['controls'].statuatory.get('salary_register_order')?.setValue(null);

    // this.payComponentForm['controls'].statuatory.get('roundoff')?.setValue(this.payComponentForm['controls'].statuatory.value.roundoff=='Ceil'?true:false);

    let mergedData = { ...this.payComponentForm.getRawValue().define, ...this.payComponentForm.getRawValue().statuatory ,...this.payComponentForm.getRawValue().taxDetails};

    if(this.id==0){
      this.componentService.createPaycomponent(mergedData).subscribe((res:any)=>{

          this.router.navigate(['pay-component']);

      },
      (error)=>{
        this.sortError = error.error.sort_order_error;
        this.nameError = error.error.name_error;
        this.resetValueForError();
      });

    }
    else{
      this.componentService.updatePaycomponent(this.id,mergedData).subscribe((res:any)=>{

          this.router.navigate(['pay-component']);

        },
        (error)=>{

          this.sortError = error.error.sort_order_error;
          this.nameError = error.error.name_error;
          this.resetValueForError();
        });
    }


  }
  makeDefaultSettings(){
    let currentYear     = new Date().getFullYear();
    this.userDefinedAdd = true;
    this.taxrestricted  = false;
    this.tab1touched = false
    this.tab2touched = false

    let conponent_id = this.payComponentForm['controls'].define.value.component_type;

    if(conponent_id!=0 && conponent_id!=undefined && conponent_id!=null && conponent_id!=''){
      this.loader = true;
      this.componentService.getComponentDataById(conponent_id).subscribe((res:any)=>{
        this.isMapItem      = false;
        this.componentError = false;

        Object.entries(res).forEach(([key, value]) => {

          if(value == null && key!='component_type') {
              this.resetComponentForm(key,value);

          }
          else if(key!='name' && key!='component_type'){
            this.resetComponentForm(key,value);
          }


          if(key=='name' && (value=='Notice Amount' || value=='User Defined')){
            this.isMapItem = true;

          }
          if(key=='name' && value=='User Defined'){
            this.isuserDefined  = true;
            this.userDefinedAdd = true;
          }
          else if(key=='name' && value=='NPS-Salary'){
            this.isNPS          = true;
            this.userDefinedAdd = true;

          }
          else if(key=='name' && (value=='Notice Amount'))
              this.isNotice = true;
          if(key=='name' && (value=='NPS-Salary' || value=='Gratuity')){

            this.taxLimit = true;

          }
          if(key=='name'){

            this.defaultCompName = value;
            if(this.taxRestrictedData.includes(this.defaultCompName))
              this.taxrestricted = true;

          }

          if(this.isMapItem==true){
            this.taxDataSettings();

          }
          if(this.taxLimit==true){
              this.payComponentForm['controls'].taxDetails.get('non_taxable_limit')?.setValue(null);

          }
        })
        if(this.defaultCompName=='Perquisite'){
          this.payComponentForm['controls'].define.get('perquisite_type')?.setValidators([Validators.required]);
        }
        else{
          this.payComponentForm['controls'].define.get('perquisite_type')?.clearValidators();
        }
        this.payComponentForm['controls'].define.get('perquisite_type')?.updateValueAndValidity();
        this.payComponentForm['controls'].define.get('perquisite_type')?.setValue(null);

        if(this.defaultCompName=='Leave Encashment'){
          this.getLeaveDropDownList()
          this.payComponentForm['controls'].define.get('encashment_leave_type')?.setValidators([Validators.required]);
        }
        else{
          this.payComponentForm['controls'].define.get('encashment_leave_type')?.clearValidators();
          this.payComponentForm['controls'].define.get('encashment_leave_type')?.setValue(null);
        }
        this.payComponentForm['controls'].define.get('encashment_leave_type')?.updateValueAndValidity();
        this.payComponentForm['controls'].define.get('encashment_leave_type')?.setValue(null);

        this.disableFormControl(res.predefined_component);
        this.loader = false;


      },
      (err: any) => {
        this.componentError = true;
        this.loader         = false;

      }
      );

    }
    else{

      this.payComponentForm['controls'].statuatory.reset();
      this.payComponentForm['controls'].taxDetails.reset();
      this.payComponentForm['controls'].statuatory.enable();
      this.payComponentForm['controls'].taxDetails.enable();
      this.payComponentForm['controls'].define.enable();
      this.payComponentForm['controls'].define.get('component_type')?.setValue(this.payComponentForm['controls'].define.value.component_type);

      this.resetDefaultValue(this.previousResetData[0]);
    }

  }

  resetValueForError(){
    if(this.payComponentForm['controls'].define.value.component_type==null)
      this.payComponentForm['controls'].define.get('component_type')?.setValue(0);
    if(this.payComponentForm['controls'].statuatory.value.salary_register_order==null && this.payComponentForm.getRawValue().statuatory.show_salary_register==false)
      this.payComponentForm['controls'].statuatory.get('salary_register_order')?.setValue(0);

    // this.payComponentForm['controls'].statuatory.get('roundoff')?.setValue(this.payComponentForm['controls'].statuatory.value.roundoff==true?'Ceil':'None');

  }
  // reset form control value
  resetComponentForm(key:any,value:any){

    if(value== null && (key=='component_type')){
      this.payComponentForm['controls'].define.get(key)?.setValue(0);
    }

    else if(value== null && (key=='salary_register_order' && this.payComponentForm.getRawValue().statuatory.show_salary_register==false)){
      this.payComponentForm['controls'].statuatory.get(key)?.setValue(0);
    }

    // else if(key=='roundoff'){
    //   this.payComponentForm['controls'].statuatory.get(key)?.setValue(value==true?'Ceil':'None');

    // }
    else if(value == null) {

      this.payComponentForm['controls'].define.get(key)?.setValue('');
      this.payComponentForm['controls'].statuatory.get(key)?.setValue('');
      this.payComponentForm['controls'].taxDetails.get(key)?.setValue('');
    } else {

      this.payComponentForm['controls'].define.get(key)?.setValue(value);
      this.payComponentForm['controls'].statuatory.get(key)?.setValue(value);
      this.payComponentForm['controls'].taxDetails.get(key)?.setValue(value);
    }

  }
  disableOtherAllowanceControl(){
    this.tax_config_disable   = true;
    this.sectionIncomeDisable = true;
    this.payComponentForm['controls'].statuatory.disable();
    this.payComponentForm['controls'].taxDetails.disable();
    this.payComponentForm['controls'].define.disable();
    this.payComponentForm['controls'].define.get('name')?.enable();
    // this.payComponentForm['controls'].taxDetails.get('under_section_income')?.enable();

  }
  disableFormControl(data:any){

    this.payComponentForm['controls'].statuatory.enable();
    this.payComponentForm['controls'].taxDetails.enable();
    this.payComponentForm['controls'].define.enable();
    this.tax_config_disable = false;
    Object.entries(data).forEach(([key, value]) => {
      let final_key = key.split("_status");

      if(value==false){
        if(final_key[0]=='tax_configuration')
        this.tax_config_disable = true;

        this.payComponentForm['controls'].define.get(final_key[0])?.disable();
        this.payComponentForm['controls'].statuatory.get(final_key[0])?.disable();
        this.payComponentForm['controls'].taxDetails.get(final_key[0])?.disable();
      }
    })
  }
  // Restriction when change pay type
  paytypeChange(){
    if(this.payComponentForm.getRawValue().define.pay_type==false && this.isuserDefined==true){
      this.resetDefaultValue(this.userDefinedData[0]);
      this.disableUserDefined(this.disableEnableData[0])
      this.userDefinedAdd = false;
      this.taxrestricted  = false;
    }
    else if(this.payComponentForm.getRawValue().define.pay_type==true && this.isuserDefined==true){

      this.userDefinedAdd = true;
      this. makeDefaultSettings();
      this.taxrestricted  = true;

    }
    if(this.payComponentForm.getRawValue().define.pay_type==false && this.isNPS==true){

      this.resetDefaultValue(this.NPSData[0]);
      this.disableUserDefined(this.disableNPSData[0]);
      this.taxrestricted  = true;

    }
    else if(this.payComponentForm.getRawValue().define.pay_type==true && this.isNPS==true){

      this. makeDefaultSettings();
      this.taxrestricted  = false;
      this.userDefinedAdd = false;

    }
    if(this.payComponentForm.getRawValue().define.pay_type==false && this.isNotice==true){

      this.resetDefaultValue(this.NoticeAmountData[0]);
      this.disableUserDefined(this.disableNoticeAmount[0]);
      this.taxrestricted  = true;

    }
    else if(this.payComponentForm.getRawValue().define.pay_type==true && this.isNotice==true){

      this. makeDefaultSettings();
      this.taxrestricted  = false;

    }
    else if(this.defaultCompName=='Notice Amount'){

      this.payComponentForm['controls'].taxDetails.get('tax_configuration')?.setValue('Non-Taxable');

    }
  }
  /*For reimbursement when nature of payment is variable prorate should be no and disabled */
  prorateRestrictions(){

    if(this.payComponentForm.getRawValue().define.nature_payment==true && this.prorateRestrictData.includes(this.defaultCompName)){

      this.payComponentForm['controls'].define.get('prorate_per_day')?.setValue(false);
      this.payComponentForm['controls'].define.get('arrear_applicable')?.setValue(false);
      this.payComponentForm['controls'].define.get('prorate_per_day')?.enable();
      this.payComponentForm['controls'].define.get('arrear_applicable')?.enable();

    }
    else if(this.payComponentForm.getRawValue().define.nature_payment==false && this.prorateRestrictData.includes(this.defaultCompName)){
      this.makeDefaultSettings();
    }
    if(this.defaultCompName=='User Defined' && this.payComponentForm.getRawValue().define.nature_payment==false){

      this.payComponentForm['controls'].define.get('arrear_applicable')?.setValue(false);
      this.payComponentForm['controls'].define.get('arrear_applicable')?.disable();
    }
    else if(this.defaultCompName=='User Defined' && this.payComponentForm.getRawValue().define.nature_payment==true){

      this.payComponentForm['controls'].define.get('arrear_applicable')?.setValue(true);
      this.payComponentForm['controls'].define.get('arrear_applicable')?.enable();
    }
  }
  resetDefaultValue(data:any){

    Object.entries(data).forEach(([key, value]) => {
      this.payComponentForm['controls'].define.get(key)?.setValue(value);
      this.payComponentForm['controls'].statuatory.get(key)?.setValue(value);
      this.payComponentForm['controls'].taxDetails.get(key)?.setValue(value);
    })
    if(this.payComponentForm.getRawValue().statuatory.show_salary_register==false)
    this.payComponentForm['controls'].statuatory.get('salary_register_order')?.setValue(0);
    else
    this.payComponentForm['controls'].statuatory.get('salary_register_order')?.setValue('');

  }
  /*when user defined component pay type is deduction*/
  disableUserDefined(data:any){

    Object.entries(data).forEach(([key, value]) => {
        if(value==false){
          this.tax_config_disable = true;
          this.payComponentForm['controls'].define.get(key)?.disable();
          this.payComponentForm['controls'].statuatory.get(key)?.disable();
          this.payComponentForm['controls'].taxDetails.get(key)?.disable();
        }
        else{
          this.payComponentForm['controls'].define.get(key)?.enable();
          this.payComponentForm['controls'].statuatory.get(key)?.enable();
          this.payComponentForm['controls'].taxDetails.get(key)?.enable();
        }

    })

  }
  sortFlagSet(data:any){
    this.sortError = data;
  }
  // section based on perquisite type
  changeSection(){

    this.payComponentForm['controls'].taxDetails.get('tax_configuration')?.setValue('Taxable');
    let perqType          = this.payComponentForm['controls'].define.get('perquisite_type')?.value;
    this.taxrestricted    = false;

    if(perqType!='Stock options (non- qualified options)' && perqType!='Tax paid by employer')
    {
      this.payComponentForm['controls'].taxDetails.get('under_section_income')?.setValue('Section 17(2)');

    }
    else if(perqType=='Stock options (non- qualified options)')
    {
      this.payComponentForm['controls'].taxDetails.get('under_section_income')?.setValue('Section 17(3)');

    }
    else{
      this.payComponentForm['controls'].taxDetails.get('tax_configuration')?.setValue('Non-Taxable');
      this.payComponentForm['controls'].taxDetails.get('under_section_income')?.setValue(null);
      this.taxrestricted    = true;


    }


  }


}
