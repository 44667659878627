import { Component, OnInit,ViewChild} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'src/app/message.global';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { AppService } from 'src/app/app.global';
import * as XLSX from 'xlsx';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SalaryDashboardFilterComponent } from 'src/app/@shared/components/salary-dashboard-filter/salary-dashboard-filter.component';
import { CompanyService } from 'src/app/@core/services/company.service';
import * as moment from 'moment';

@Component({
  selector: 'app-variable-payments-and-deductions',
  templateUrl: './variable-payments-and-deductions.component.html',
  styleUrls: ['./variable-payments-and-deductions.component.scss']
})
export class VariablePaymentsAndDeductionsComponent implements OnInit {

  filter              = false;
  activeTab           = 1;
  status              = 'Unprocessed';
  showSidePanel       = false;
  fileName: string    = '';
  fileSize: string    = '';
  searchString:any    = "";
  invalidFormat       = false;
  submiited           = false;
  empDD :any[]        = [];
  variable :any[]     = [];
  page                = 1;
  pageSize            = 20;
  loader              = false;
  ws:any              = null;
  excelData:any       = [];
  deleteToggle        = false;
  confirmMsg          = false;
  deleteMsg           = "";
  keyArray:any        = [];
  importData:any      = [];
  monthlyData:any     = [];
  Isalert             = true;
  source              = 'monthly';
  saveData:any        = [];
  permissions:any     = [];
  failedData:any      = [];
  successDataCount    = 0;
  ind:any;
  downloadMsg         = false;
  buList:any          = [];
  DepList:any         = [];
  DesignationList:any = [];
  BranchList:any      = [];
  cityList:any        = [];
  gradeList:any       = [];
  pageRef:any         = "Monthly Variable";
  saveComplete        = false;
  monthlyHeader       = ['employee','pay_component','employee_code','pay_amount','amount_recovered'];
  alertToggle         = false;
  empalertMsg         = "";
  statusFlag          = false;
  errorMsg = ''
  successMsg = ''
  exportData:any      = [];
  body = '';
  year:any;
  month:any;
  company:any;
  inputdata :any;
  excelMonth : any;
  msgText:any
  buttonText:any;
  confirmMsgText:any;
  currentIndex:any;
  currency:any;
  isProgressStart = false
  chunkLen = 10
  percentage:any = 0;
  iscompleted = false;
  isDataUpload = 0;
  previousMonth:any
  // overtime
  overTimeData:any     = [];
  modelHead:any;
  downloadFile:any;
  months:any = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  }

  popupHead = ''
  @ViewChild(SalaryDashboardFilterComponent) filtercomp!:SalaryDashboardFilterComponent;

  constructor(
    public route:ActivatedRoute,
    public messageService : MessageService,
    public sds:SalaryDashboardService,
    public appService: AppService,
    private notificationService: NotificationService,
    private cs:CompanyService


  ) { }

  ngOnInit(): void {
    this.getPermission();
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year =  params['year'];
      }
      if( isNaN(params['month'])){
        this.month          = params['month'];
        let currentYear     = new Date().getFullYear();

       let currentDate = new Date(currentYear+'-'+this.months[this.month]+"-"+'01');
       let today = new Date(currentDate);
      today.setMonth(today.getMonth() - 1);

      this.previousMonth = today.toLocaleString("en-US", {
        month: "long",
      });

      }
      if( !isNaN(params['company'])){
        this.company = parseInt(params['company']);
      }
    })
    this.getMonthlyData(this.status,'monthly');
    this.getEmployeeList();
    this.cs.companyNewGet(this.company).subscribe((res:any)=>{
      this.currency = res.body.currency;
    })
  }
  closeFilter(){
    this.filter  = false
  }
  resetFilter(){
    this.buList           = []
    this.DepList          = []
    this.DesignationList  = []
    this.gradeList        = []
    this.BranchList        = []
    if(this.activeTab!=2){
    this.getMonthlyData(this.status,this.source);}
    else{
    this.getOvertimeData();}
    this.filter           = false
  }
  submitForm(data:any){
    this.filter  = false
    this.page   = 1;
    this.filtercomp.getBUList(this.company);
    this.buList           = data.buList;
    this.DepList          = data.DepList;
    this.DesignationList  = data.DesignationList;
    this.gradeList        = data.gradeList;
    this.BranchList       = data.BranchList;
    if(this.activeTab!=2)
    this.getMonthlyData(this.status,this.source);
    else
    this.getOvertimeData();

  }
  getPermission(){
    this.permissions = this.appService.getPermissions('/run-payroll');

    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  clearData(){
    this.fileName       = '';
    this.fileSize       = '';
    this.invalidFormat  = false
    this.submiited      = false;
    this.excelData      = [];

  }
  completeProcess(){
    this.fileName      = '';
    this.fileSize      = '';
    this.importData    = [];
    this.monthlyData   = [];
    this.excelData     = [];
    this.importData    = [];

  }
  clearImportData(){

    this.fileName      = '';
    this.fileSize      = '';
    this.excelData     = [];
    this.importData    = [];
    this.submiited     = false;
    let j: number = 0;
    while (j<this.monthlyData.length) {
      if(this.monthlyData[j]['source'].data=='excel')
        this.monthlyData.splice(j, 1);
      else
        j++

    }
    this.showSidePanel = false;


  }
  /*import strat*/
  onFileChange(evt: any) {
    this.excelData  = [];

    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: false,cellDates:true,cellNF:true });
        const wsname: string = wb.SheetNames[0];
        this.ws   = wb.Sheets[wsname];
        var ws    = wb.Sheets[wsname];

        this.fileName = target.files[0].name;

        this.fileSize = this.appService.formatSizeUnits(target.files[0].size)
        this.excelData = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: true,rawNumbers: false,blankrows:false }));
        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }
  }
  processData(){

    if(this.excelData.length <=1){
      this.deleteToggle = true;
      this.deleteMsg    = "No data found in the uploaded file.";
      this.clearData()
    } else {
      var headerRow = this.excelData[0];
      let Heading:any ;
      let headCount:any = true;
      if(this.activeTab==1)
      Heading = ['Employee code','Employee name','Salary month','Salary Year', 'Variable component','Payable amount'];
      else if(this.activeTab==2)
      Heading = ['Employee Code','Employee Name','Attendance Month','Attendance Year','Working Day OT Hours','Weekly Off OT Hours','Holiday OT Hours','National Holiday OT Hours','Half Working Day OT Hours'];
      else
      Heading = ['Employee code','Employee name','Salary month','Salary Year','Perquisite component','Payable amount','Amount recovered from employee'];

      let success = Heading.every(function(val:any) {

        return headerRow.indexOf(val) !== -1;
      });

      if(headerRow.length!=Heading.length)
      headCount = false
      if(success == false || headCount==false){
        this.deleteToggle = true;
        this.deleteMsg    = this.messageService.invalidExcelFormat();
        this.clearData();
      }
      else
      this.prepareUploadData();
    }
  }
  prepareUploadData(){
    this.importData = [];
    // console.log("this.excelData",this.excelData)
    this.excelData.forEach((item:any, key:any) => {

      if(key != 0){

        let k = item[0];
        this.keyArray.push({k : key-1,v:k});
        if(isNaN(item[2])){
          this.excelMonth = item[2]
        }else{
          this.excelMonth = this.toMonthName(item[2])
        }

        let convertedMonth      = this.month.replace(/\s/g, '').toUpperCase();
        this.excelMonth         = this.excelMonth!=undefined && this.excelMonth!='' && this.excelMonth!=null?this.excelMonth.replace(/\s/g, '').toUpperCase():'';
        let previousConvert     = this.previousMonth.replace(/\s/g, '').toUpperCase();

        if(this.activeTab!=2){


          if(convertedMonth === this.excelMonth && this.year == item[3]){


            if(this.activeTab==1){

              this.importData.push({

                "employee_code"       : item[0]?item[0]:"",
                "employee_name"       : item[1]?item[1]:"",
                "pay_component_name"  : item[4]?item[4]:"",
                "pay_amount"          : item[5]?item[5]:0,

              })
            }
            else{
              this.importData.push({

                "employee_code"       : item[0]?item[0]:"",
                "employee_name"       : item[1]?item[1]:"",
                "pay_component_name"  : item[4]?item[4]:"",
                "pay_amount"          : item[5]?item[5]:"",
                "amount_recovered"    : item[6]?item[6]:"",

              })
            }
          }

        }

        else
        {
          let previousYear = previousConvert=='DECEMBER'?Number(this.year)-1:this.year

          if((convertedMonth === this.excelMonth && this.year == item[3] ) || (previousConvert==this.excelMonth && previousYear == item[3])){
            this.importData.push({

              "employee"                  : item[0]?item[0]:"",
              "emp_name"                  : item[1]?item[1]:"",
              "overtime_month"            : item[2]?item[2]:"",
              "overtime_year"             : item[3]?item[3]:"",
              "working_day_ot_hours"      : item[4] && moment(item[4], 'HH:mm', true).isValid()?moment.duration(moment(item[4]).format("HH:mm")).asHours():item[4],
              "week_off_ot_hours"         : item[5] && moment(item[5], 'HH:mm', true).isValid()?moment.duration(moment(item[5]).format("HH:mm")).asHours():item[5],
              "holiday_ot_hours"          : item[6] && moment(item[6], 'HH:mm', true).isValid()?moment.duration(moment(item[6]).format("HH:mm")).asHours():item[6],
              "national_holiday_ot_hours" : item[7] && moment(item[7], 'HH:mm', true).isValid()?moment.duration(moment(item[7]).format("HH:mm")).asHours():item[7],
              "half_working_day_ot_hours" : item[8] && moment(item[8], 'HH:mm', true).isValid()?moment.duration(moment(item[8]).format("HH:mm")).asHours():item[8],

            })

          }

        }
      }
    });
    if(this.importData.length <=0){
      this.deleteToggle = true;
      if(this.activeTab!=2)
      this.deleteMsg = "The uploaded file does not contain any data for "+this.month+" "+this.year;
      else{
        let previousYear:any
        previousYear = this.previousMonth=='December'?this.year-1:this.year
        this.deleteMsg = "The uploaded file does not contain any data for "+this.month+" "+this.year+" or "+this.previousMonth+" "+previousYear;
      }

      this.clearData();
    }

  }
  toMonthName(monthNumber:any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }

  uploadData(source:any){
    this.submiited     = true;
    if(this.activeTab!=2){
      let uploadData:any = []

      this.importData.forEach((item:any, key:any) => {

          uploadData.push({
            "employee_code"       : item.employee_code?item.employee_code:"",
            "employee_name"       : item.employee_name?item.employee_name:"",
            "pay_amount"          : item.pay_amount?item.pay_amount:0,
            "pay_component_name"  : item.pay_component_name?item.pay_component_name:"",
            "pay_month"           : this.month,
            "pay_year"            : this.year,
            "pay_type"            : this.activeTab==1?'MONTHLY':this.activeTab==3?'PERQUISITE':''
          });
          if(this.activeTab==3){

            uploadData[uploadData.length-1]['amount_recovered'] = item.amount_recovered?Number(item.amount_recovered):0;
          }
      });

      this.sds.verifyMonthlyData({ "company_id" : this.company,"data":uploadData}).subscribe((res:any)=>{
        if(source=='excel'){

          let last_length = this.monthlyData.length;
          for(let i=0;i<res.body.error_info.length;i++){
            this.monthlyData.push(res.body.error_info[i]);
            if(this.status=='Unprocessed'){

              for(let i=last_length;i<this.monthlyData.length;i++){
                if(this.monthlyData[i]['employee'].data!=null && this.monthlyData[i]['employee'].data!='' && this.monthlyData[i]['pay_component'].is_valid==true)
                  this.getVariablecomponent(this.monthlyData[i]['employee'].data,i,'default');

              }
            }

          }
          this.showSidePanel = false;
        }
        else{
          this.rowLoader[source] = false
          let data = res.body.error_info[0]
          this.monthlyData[source]['employee_code'].is_valid                = data.employee_code.is_valid;
          this.monthlyData[source]['employee_code'].error                   = data.employee_code.error;
          this.monthlyData[source]['pay_component_yearly_amount'].data      = data.pay_component_yearly_amount.data;
          this.monthlyData[source]['salary_status'].data                    = data.salary_status.data;
          // Assigned below code for monthly & payable amount to be same
          this.monthlyData[source]['pay_component_amount'].data             = data.pay_component_amount.data;
          this.monthlyData[source]['pay_amount'].data                       = data.pay_component_amount.data;
          this.monthlyData[source]['var_hint']                              = data.employee_code.pay_month?.length == 0 ? '' : `The variable component amount is configured for the months ${data.employee_code.pay_month?.join(', ')}. You can override the amount if needed.`
        }

      })
    }
    else
    this.uploadOvertimedata();
  }
  /*End- imort*/
  onNavChange(changeEvent: NgbNavChangeEvent){
    this.page =1;
    this.status              = 'Unprocessed';
    this.clearData();
    this.searchString = ''
    if(changeEvent.nextId==1){

      this.getMonthlyData(this.status,'monthly');
      this.pageRef       = "Monthly Variable";

    }
    else if(changeEvent.nextId==2){
      this.status = 'Pending';
      this.getOvertimeData();

    }
    else{
      this.source       = 'perquisite';
      this.pageRef       = "Perquisite";
      this.activeTab     = 3;
      this.resetFilter();
    }

  }
  getMonthlyData(status:any,type:any){
    this.loader       = true;
    this.status       = status;
    this.source       = type;
    this.searchString = "";
    this.monthlyData=[];
    this.sds.getVariablepayment(this.year,this.month,status,this.company,type,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{

      this.monthlyData = res.body.error_info;

      if(status=='Unprocessed' && this.monthlyData.length>0){

        for(let i=0;i<this.monthlyData.length;i++){
         this.getVariablecomponent(this.monthlyData[i]['employee'].data,i,'default');
         if(i==this.monthlyData.length-1){
          this.loader = false;

         }
        }

      }
      else
      this.loader = false;
    })
  }
  getPerquisite(){

  }
  getEmployeeList(){
    this.sds.getempList(this.company ).subscribe((res: any) => {
      this.empDD = res.body;
      let len = this.empDD.length
      for (let i = 0; i < len; i++) {
        this.empDD[i]['fullname']  = "";
        if(this.empDD[i]['first_name'] != null)
          this.empDD[i]['fullname'] += this.empDD[i]['first_name']+" ";
        if(this.empDD[i]['middle_name'] != null)
          this.empDD[i]['fullname'] += this.empDD[i]['middle_name']+" ";
        if(this.empDD[i]['last_name'] != null)
          this.empDD[i]['fullname'] += this.empDD[i]['last_name'];

        }
    });
  }
  setEmpCode(data:any,i:any){

    let index_withPage  =  i;
    let employee        = this.empDD.find(item => item.id === data);
    this.monthlyData[index_withPage]['employee_code'].data                = employee?.employee_code;
    this.monthlyData[index_withPage]['employee_code'].is_valid            = true;
    this.monthlyData[index_withPage]['employee'].is_valid                 = true;
    this.monthlyData[index_withPage]['employee'].data                     = data;
    this.monthlyData[index_withPage]['pay_component'].data                = null;
    this.monthlyData[index_withPage]['pay_component_amount'].data         = 0;
    this.monthlyData[index_withPage]['pay_component_yearly_amount'].data  = 0;
    this.monthlyData[index_withPage]['salary_status'].data                = ''
    this.monthlyData[index_withPage]['employee_name'].data                = ''
    this.monthlyData[index_withPage]['var_hint']                          = ''

    if(this.activeTab==3)
    this.monthlyData[index_withPage]['amount_recovered'].data  = 0;

    this.getVariablecomponent(data,index_withPage,'onchange');
    this.sds.getPayrollststus(data,'',this.month,this.year).subscribe((res:any)=>{
      this.monthlyData[index_withPage]['salary_status'].data  = res.body[0]?.status;

    })
  }
  getVariablecomponent(employee:any,index:any,action:any){

    this.sds.getVariableData(employee,this.source).subscribe((res:any)=>{
      this.monthlyData[index]['variable_com'] = res.body;
    },
    (error:any)=>{
      this.monthlyData[index]['employee_code'].error      = error.error.error;
      this.monthlyData[index]['employee_code'].is_valid   = false;
    }
    )

    if(action=='onchange'){

      this.monthlyData[index]['pay_component'].data     = null;
      this.monthlyData[index]['pay_component'].is_valid = true;
    }

  }
  deleteSingleRow(i:any){

    let index         = i;
    this.monthlyData.splice(index, 1);
    let count = 0;
    for(let i=0;i<this.monthlyData.length;i++){
      if(this.monthlyData[i]['source'].data=='excel')
      {
        count++;
      }
    }
    if(count==0)
      this.clearData();
  }
  rowLoader:any = []
  setAmount(data:any,i:any){
    this.rowLoader[i] = true
    let index           = i;
    let component       = this.monthlyData[index]['variable_com'].find((item:any) => item.component_id === data);
    this.importData     = [];

    this.monthlyData[index]['pay_amount'].data            = component?.amount;
    // this.monthlyData[index]['pay_component_amount'].data  = component?.amount;
    // Assign pay_component_amount with same value otherwise yearly value will be shown in monthly column
    this.monthlyData[index]['pay_component_amount'].data  = component?.pay_component_amount;
    this.monthlyData[index]['pay_component_name'].data    = component?.component_name;

    this.monthlyData[index]['pay_component'].is_valid     = true;
    this.monthlyData[index]['pay_component'].error        = '';
    this.monthlyData[index]['var_hint']                   = '';

    this.importData.push({

      "employee_code"       : this.monthlyData[index]['employee_code'].data,
      "employee_name"       : this.monthlyData[index]['employee_name'].data,
      "pay_component_name"  : component.component_name,
      "pay_amount"          : this.monthlyData[index]['pay_amount'].data

    })

    this.uploadData(index);

  }
  checkall(event:any){
    var newList   = this.filterIt();
    var begin     = (this.page-1) * this.pageSize ;
    var end       = this.page* this.pageSize;
    var subArray  = newList.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      item.checked = event.target.checked
    })
  }

  checkallSelected(){
    var newList   = this.filterIt();
    var begin     = (this.page-1) * this.pageSize ;
    var end       = this.page* this.pageSize;
    var subArray  = newList.slice(begin,end);
    if(subArray.length){
      return subArray.every((item: { checked: any; }) => {
        return item.checked;
      });
    } else {
      return false;
    }
  }
  filterIt() {
    if(this.activeTab!=2){

      return this.monthlyData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key].toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    }
    else{
      return this.overTimeData.filter((obj: any) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key].toString().toLowerCase();
            const tempSearch = this.searchString.toLowerCase();
            return tempKey.includes(tempSearch);
          }
        });
      });
    }
  }
  filteredcount(){
    return this.filterIt().length;
  }
  countSelected(){

    var delArray:any = [];
    if(this.activeTab!=2){

      this.monthlyData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(value)
      });
    }
    else{
      this.overTimeData.forEach((item:any, value:any) => {
        if(item?.checked == true)
          delArray.push(value)
      });
    }

    return delArray.length
  }
  invalidData(){
    var invalidArray:any = [];
    let counter          = 0;

    this.monthlyData.forEach((item:any, value:any) => {
    var temp:any = [];
    if(item?.checked == true){
      for(let i=0;i<this.monthlyHeader.length;i++){
        let header = this.monthlyHeader[i];

        if(header!='pay_amount' && header!='amount_recovered'){
          if(this.monthlyData[counter][header].data=='' || this.monthlyData[counter][header].data==null || this.monthlyData[counter][header]?.is_valid==false){
            temp.push(this.monthlyHeader[i])

          }
        }
        else{

          if((header=='amount_recovered' && this.activeTab!=1) && this.monthlyData[counter][header]?.is_valid==false){
              temp.push(this.monthlyHeader[i])

          }
          else if(header=='pay_amount' && this.monthlyData[counter][header]?.is_valid==false){
              temp.push(this.monthlyHeader[i])

          }
        }

      }
    }
    if(temp.length>0)
      invalidArray.push(temp);
    counter++;

    });
    return invalidArray.length;

  }
  bulkDelete(){

    this.loader       = true
    var delArray:any  = [];
    this.searchString = "";

    this.monthlyData.forEach((item:any, value:any) => {
      if(item?.checked == true)
        delArray.push(value)
    });
    var delArrayRev = delArray.reverse()
      delArrayRev.forEach((item:any, value:any) =>{
        this.deleteSingleRow(item);
      })
    this.loader = false;

  }
  constructData(status:any){
    this.saveData   = [];
    this.statusFlag = false;
    this.chunkLen   = 10;
    this.percentage = 0;
    for(let i=0;i<this.monthlyData.length;i++) {

      if(this.monthlyData[i]?.checked == true && this.monthlyData[i]?.salary_status.data=='Unprocessed'){
        this.saveData.push({
          "pay_type"              : this.activeTab==1?'MONTHLY':this.activeTab==3?'PERQUISITE':'',
          "pay_month"             : this.month,
          "pay_year"              : Number(this.year),
          "status"                : status,
          "employee"              : this.monthlyData[i]['employee'].data,
          "employee_code"         : this.monthlyData[i]['employee_code'].data,
          "pay_component"         : this.monthlyData[i]['pay_component'].data,
          "pay_component_name"    : this.monthlyData[i]['pay_component_name'].data,
          "pay_amount"            : this.monthlyData[i]['pay_amount'].is_valid?Number(this.monthlyData[i]['pay_amount'].data):this.monthlyData[i]['pay_amount'].data,
          "id"                    : this.monthlyData[i].hasOwnProperty("id")?this.monthlyData[i]['id'].data:null,
          "pay_component_amount"  : this.monthlyData[i]['pay_component_amount'].data
          // "amount_recovered"    : (this.monthlyData[i].hasOwnProperty("amount_recovered"))?this.monthlyData[i]['amount_recovered'].is_valid?Number(this.monthlyData[i]['amount_recovered'].data):this.monthlyData[i]['amount_recovered'].data:'',
        })

        if(this.activeTab==3){
         this.saveData[this.saveData.length-1]['amount_recovered'] = this.monthlyData[i]['amount_recovered'].is_valid?Number(this.monthlyData[i]['amount_recovered'].data):this.monthlyData[i]['amount_recovered'].data
        }

      }
      else if(this.monthlyData[i]?.checked == true && (this.monthlyData[i]?.salary_status.data!='Unprocessed' && this.monthlyData[i]?.salary_status.data!='' && this.monthlyData[i]?.salary_status.data!=null)){
        this.statusFlag = true;
      }
    }
    if(this.statusFlag==true)
    {
        this.empalertMsg = "The selected employee(s) payroll has already been Processed/Freezed/Held/Published. Kindly undo the respective action to map/unmap "+this.pageRef.toLowerCase();
        this.alertToggle = true;
    }
    if(this.saveData.length>0){

      this.buttonText     = status=='Processed'?'Save':'Remove';
      this.confirmMsgText = "Click the "+this.buttonText+ " button, If you want to "+(status=='Processed'?'map':'unmap')+ " or modify the "+this.pageRef.toLowerCase()+" component for the selected employee(s).";
      this.confirmMsg     = true;
      this.saveComplete   = false;
      this.msgText        = status=='Processed'?'added':'removed';
      this.popupHead      = status == 'Processed' ? 'Adding' : 'Removing'
    }

  }


  saveMonthlyData(purpose:any){
    this.isDataUpload = 1;
    this.iscompleted  = false
    this.loader       = true
    this.saveComplete = true;
    this.percentage   = 0;
    this.chunkLen     = 10;


    if(this.saveData.length>0){
        this.isProgressStart = true
        this.failedData = []
        var l = this.saveData.length;
        var cLoop = Math.ceil(l/this.chunkLen)
        var p = 100/cLoop
        this.successDataCount = 0
        if(this.activeTab!=2)
        this.processUpload(0,p);
        else
        this.processOvertime(0,p,purpose)
    } else
    this.loader       = false
    this.alertToggle  = false;
}

processUpload(k:any,p:any){
    var l = this.saveData.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    this.body = x+' out of '+l+" data "+(this.popupHead == 'Adding' ? 'added' : 'removed')+" successfully"
    console.log('1')
    var saveDataArray:any = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.saveData[i])
    }

    this.sds.saveMonthlyData(saveDataArray).subscribe((res: any) => {
        this.confirmMsg = false;
        if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
            res.body.failed_data.forEach((i:any, k:any) => {
                this.failedData.push(i)
            });
        }
        this.percentage +=((p/4)*3);
        if(x<l)
            this.processUpload(k+1,p);
        else{
            this.iscompleted = true
            this.isProgressStart = false
            this.successDataCount = this.saveData.length- this.failedData.length;
            if(this.failedData.length == 0){
                this.notificationService.handleSuccessNotification("Selected employee's "+this.pageRef?.toLowerCase()+" "+this.msgText?.toLowerCase() +" successfully.",this.pageRef+" "+this.msgText);
            } else {
              this.exportData = []
              for (let i = 0; i < this.failedData.length; i++) {
                this.exportData.push({'Employee code':this.failedData[i]['data']['employee_code'],'Employee name':this.failedData[i]['data']['employee_name'],'Salary month':this.month,'Salary Year':this.year, 'Variable component':this.failedData[i]['data']['name'],'Payable amount':this.failedData[i]['data']['pay_amount'],'Error description':this.failedData[i]['error'][0]});

              }
              this.successMsg   = this.successDataCount+" out of "+l+" data "+this.popupHead?.toLowerCase()+" successfully "
              this.errorMsg     = this.failedData.length+' employee(s) have error data'
              this.downloadMsg  = true;
              this.modelHead    = 'Import Variable payments & deductions';
              this.downloadFile = 'Import Variable payments & deductions Error'
            }
            if(this.status=='Unprocessed')
                this.bulkDelete();
            else
                this.getMonthlyData(this.status,this.source);
            this.clearData();
        }

      },
      (error:any)=>{
        this.isProgressStart = false
        this.notificationService.handleErrorNotification('Something went wrong','Error')
      });
}
  selectAll(){
    let selectData  = this.activeTab!=2?this.monthlyData:this.overTimeData
    this.inputdata = this.appService.selectAll(selectData)

  }
  unselectAll(){
    let selectData  = this.activeTab!=2?this.monthlyData:this.overTimeData
    this.inputdata = this.appService.unselectAll(selectData)

  }
  addMonthlyData(){

    let initialData:any  =
    {
      "employee_code": {
        "data"    : "",
        "is_valid": true,
        "error"   : null
      },
      "employee_name": {
        "data"    : "",
        "is_valid": true,
        "error"   : null
      },
      "pay_component": {
        "data"    : null,
        "is_valid": true,
        "error"   : null
      },
      "pay_amount": {
        "data"    : 0,
        "is_valid": true,
        "error"   : null
      },
      "pay_component_amount": {
        "data"    : 0,
        "is_valid": true,
        "error"   : null
      },
      "pay_component_yearly_amount": {
        "data"    : 0,
        "is_valid": true,
        "error"   : null
      },

      "employee": {
        "data"    : null,
        "is_valid": true,
        "error"   : null
      },
      "source": {
        "data"    : 'direct',
        "is_valid": true,
        "error"   : null
      },
      "pay_component_name": {
        "data"    : '',
        "is_valid": true,
        "error"   : null
      },
      "salary_status":{
        "data"    : '',

      },
      "amount_recovered": {
        "data"    : 0,
        "is_valid": true,
        "error"   : null
      }


    }
    this.monthlyData.push(initialData);
    this.page = Math.ceil(this.monthlyData.length / this.pageSize);
    this.invalidData();

  }

  validateField(i:any,field:any){
    let index                   = i;
    let currentValue            = this.monthlyData[index][field].data
    const digitdecimalpattern   = /^[0-9]\d*(\.\d{1,2})?$/.test(currentValue);
    this.monthlyData[index][field].is_valid  = true;
    this.monthlyData[index][field].error     = null;
    if(currentValue=='' || currentValue==null){
      this.monthlyData[index][field].is_valid  = false;
      this.monthlyData[index][field].error     = this.messageService.validationDisplay('required');
    }
    else{

      if(digitdecimalpattern){
        if(currentValue.length>10){

          this.monthlyData[index][field].is_valid  = false;
          this.monthlyData[index][field].error     = this.messageService.fieldlengthvalidation('value','10');
        }
        if(field=='amount_recovered'){
          if(Number(this.monthlyData[index].amount_recovered.data)>Number(this.monthlyData[index].pay_amount.data)){
            this.monthlyData[index][field].is_valid  = false;
            this.monthlyData[index][field].error     = this.messageService.lessThanMessage('Payable amount','Amount recovered');
          }
        }else if(((Number(this.monthlyData[index].amount_recovered.data)<Number(this.monthlyData[index].pay_amount.data)) || (Number(this.monthlyData[index].amount_recovered.data)==Number(this.monthlyData[index].pay_amount.data))) && (Number(this.monthlyData[index].pay_amount.data)<Number(this.monthlyData[index].pay_component_yearly_amount.data) && this.activeTab==3)){
          this.monthlyData[index]['amount_recovered'].is_valid  = true;
          this.monthlyData[index]['amount_recovered'].error     = null;
        } else if(Number(this.monthlyData[index].amount_recovered.data)>Number(this.monthlyData[index].pay_amount.data)  && this.activeTab==3){
          this.monthlyData[index]['amount_recovered'].is_valid  = false;
          this.monthlyData[index]['amount_recovered'].error     = this.messageService.lessThanMessage('Payable amount','Amount recovered');
        }
        else{
          if(Number(this.monthlyData[index].pay_amount.data)>Number(this.monthlyData[index].pay_component_yearly_amount.data) && this.activeTab==3){
            this.monthlyData[index][field].is_valid  = false;
            this.monthlyData[index][field].error     = this.messageService.lessThanMessage('Default value','Payable amount');
          }
      }
      }
      else{

        this.monthlyData[index][field].is_valid  = false;
        this.monthlyData[index][field].error     = this.messageService.validationDisplay('pattern');

      }
    }

  }
  downloadError(){
    let exportData:any = []
    for (let i = 0; i < this.failedData.length; i++) {
      exportData.push({'Employee code':this.failedData[i]['data']['employee_code'],'Employee name':this.failedData[i]['data']['employee_name'],'Salary month':this.month,'Salary Year':this.year, 'Variable component':this.failedData[i]['data']['name'],'Payable amount':this.failedData[i]['data']['pay_amount'],'Error description':this.failedData[i]['error'][0]});

    }
    let file_name = this.activeTab==1?'Monthly Error':this.activeTab==3?'Perquisite Error':'';
    this.appService.exportExcel(exportData,file_name);
    this.downloadMsg = false;
  }
  closeInfo(bool:any){
    this.alertToggle = bool;

  }

  // overtime integration

  getOvertimeData(){
    this.loader = true;
    this.searchString = "";
    this.sds.getOvertimeData(this.year,this.month,this.status,this.company,this.buList,this.DepList,this.DesignationList,this.BranchList,this.gradeList).subscribe((res:any)=>{

      this.overTimeData = res.body?.data;
      this.loader       = false

    })
  }
  toggleOverTime(status:any){
    this.status = status;
    this.getOvertimeData();
  }
  uploadOvertimedata(){
    this.saveData = this.importData;
    this.saveMonthlyData('upload');
    // this.sds.uploadOvertime({ "company_id" : this.company,"data":this.importData}).subscribe((res:any)=>{
    //   console.log("upload result",res);
    //   this.showSidePanel = false;
    //   this.clearData();
    //   this.getOvertimeData();

    // })
  }
  getcurrentPage(){
    let currentPage;
    if(this.activeTab==1)
    currentPage = 'Variable Payment';
    else if(this.activeTab==2)
    currentPage = 'Overtime'
    else
    currentPage = 'Perquisite'
    return currentPage
  }
  processOvertime(k:any,p:any,purpose:any){
    var l = this.saveData.length;
    var x = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.percentage +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.saveData[i])
    }
    if(purpose=='upload'){

      this.sds.uploadOvertime({ "company_id" : this.company,"month": this.month,
      "year": this.year,"data":saveDataArray}).subscribe((res:any)=>{
        this.body = x+' out of '+l+" data uploaded successfully"
        this.popupHead = 'Uploading'
          this.confirmMsg = false;
          if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
              res.body.failed_data.forEach((i:any, k:any) => {
                  this.failedData.push(i)
              });
          }
          this.percentage +=((p/4)*3);
          if(x<l)
              this.processOvertime(k+1,p,'upload');
          else{
              this.iscompleted      = true
              this.isProgressStart  = false
              this.successDataCount = this.saveData.length- this.failedData.length;
              if(this.failedData.length == 0){
                  this.notificationService.handleSuccessNotification("Selected employee's overtime uploaded successfully.","Success");
              } else {
                this.exportData = []
                for (let i = 0; i < this.failedData.length; i++) {
                  this.exportData.push({'Employee Code':this.failedData[i]['data']['employee'],'Employee Name':this.failedData[i]['data']['emp_name'],'Attendance Month':this.failedData[i]['data']['overtime_month'],'Attendance Year':this.failedData[i]['data']['overtime_year'], 'Working Day OT Hours':this.failedData[i]['data']['working_day_ot_hours'],'Weekly Off OT Hours':this.failedData[i]['data']['week_off_ot_hours'],'Holiday OT Hours':this.failedData[i]['data']['holiday_ot_hours'],'National Holiday OT Hours':this.failedData[i]['data']['national_holiday_ot_hours'],'Half Working Day OT Hours':this.failedData[i]['data']['half_working_day_ot_hours'],'Error description':this.failedData[i]['error']});

                }
                this.successMsg   = this.successDataCount+" out of "+l+" data uploaded successfully "
                this.errorMsg     = this.failedData.length+' employee(s) have error data'
                this.modelHead    = 'Import Overtime';
                this.downloadFile = 'Overtime Error';

                this.downloadMsg = true;
              }
              this.showSidePanel = false;
              this.getOvertimeData();
              this.clearData();
          }



      })
    }
    else{
      this.sds.processUnprocessOvertime({  "month": this.month,"year": this.year,"status": this.msgText,"data":saveDataArray}).subscribe((res:any)=>{
        this.popupHead = this.msgText == 'Completed' ? 'Removing' : 'Adding'
        this.body = x+' out of '+l+" data "+(this.msgText=='Completed'?'removed':'added')+" successfully"
          this.confirmMsg = false;
          if(res.body.error_list != undefined && res.body.error_list.length != 0){
              res.body.error_list.forEach((i:any, k:any) => {
                  this.failedData.push(i)
              });
          }
          this.percentage +=((p/4)*3);
          if(x<l)
              this.processOvertime(k+1,p,'save');
          else{
              this.iscompleted      = true
              this.isProgressStart  = false
              this.successDataCount = this.saveData.length- this.failedData.length;
              let msg               = this.msgText=='Completed'?'Added':'Removed'
              let headMsg           = this.msgText=='Completed'?'Adding':'Removing'
              if(this.failedData.length == 0){
                  this.notificationService.handleSuccessNotification("Selected employee's overtime "+msg?.toLowerCase()+" successfully.","Success");
              } else {
                this.exportData = []
                for (let i = 0; i < this.failedData.length; i++) {
                  this.exportData.push({'Employee Code':this.failedData[i]['employee_code'],'Employee Name':this.failedData[i]['name'],'Company':this.failedData[i]['company'],'Department':this.failedData[i]['department'],'Error description':this.failedData[i]['error']});

                }
                this.successMsg   = this.successDataCount+" out of "+l+" data "+msg?.toLowerCase()+"  successfully "
                this.errorMsg     = this.failedData.length+' employee(s) have error data'
                this.downloadMsg  = true;
                this.modelHead    = headMsg+' Overtime';
                this.downloadFile = "Overtime Error";

              }
              this.getOvertimeData();
              this.clearData();
          }



      })
    }
  }

  constructOvertime(status:any){
    this.saveData   = [];
    this.statusFlag = false;
    this.chunkLen   = 10;
    this.percentage = 0;
    for(let i=0;i<this.overTimeData.length;i++) {

      if(this.overTimeData[i]?.checked == true){
        this.saveData.push({
          "id"                        : this.overTimeData[i]['employee_id'],
          "overtime_month"            : this.overTimeData[i]['process_month'],
          "overtime_year"             : this.overTimeData[i]['process_year'],
          "working_day_ot_hours"      : this.overTimeData[i]['working_day_ot_hours'],
          "week_off_ot_hours"         : this.overTimeData[i]['week_off_ot_hours'],
          "holiday_ot_hours"          : this.overTimeData[i]['holiday_ot_hours'],
          "national_holiday_ot_hours" : this.overTimeData[i]['national_holiday_ot_hours'],
          "half_working_day_ot_hours" : this.overTimeData[i]['half_working_day_ot_hours'],
          "total_ot_hours"            : this.overTimeData[i]['total_ot_hours'],
        })
      }
    }
    if(this.saveData.length>0){

      this.buttonText     = status=='Completed'?'Process':'Unprocess';
      this.confirmMsgText = "Click the "+this.buttonText+ " button, If you want to "+this.buttonText+ " the overtime for the selected employee(s).";
      this.confirmMsg     = true;
      this.saveComplete   = false;
      this.msgText        = status;
    }
  }
  validateData(i:any,field:any){
    let index                   = i;
    let currentValue            = this.overTimeData[index][field]
    const digitdecimalpattern   = /^[0-9]\d*(\.\d{1,2})?$/.test(currentValue);
    if(currentValue=='' || currentValue==null){

      this.overTimeData[index][field+'_error']   = true;
      this.overTimeData[index][field+'_msg']      = this.messageService.validationDisplay('required');
    }
    else if(!digitdecimalpattern){
      this.overTimeData[index][field+'_error']   = true;
      this.overTimeData[index][field+'_msg']      = this.messageService.validationDisplay('pattern');
    }
    else if(currentValue.length>10){
      this.overTimeData[index][field+'_error']   = true;
      this.overTimeData[index][field+'_msg']     = this.messageService.fieldlengthvalidation('num','10');
    }
    else{
      this.overTimeData[index][field+'_error']    = false;
      this.overTimeData[index][field+'_msg']      = '';
      this.overTimeData[index]['total_ot_hours']  = Number(this.overTimeData[index]['working_day_ot_hours'])+Number(this.overTimeData[index]['week_off_ot_hours'])+Number(this.overTimeData[index]['holiday_ot_hours'])+Number(this.overTimeData[index]['national_holiday_ot_hours'])+Number(this.overTimeData[index]['half_working_day_ot_hours'])
    }
  }
  invalidOvertimeData(){
    let count= 0;
    let index =0;
    this.overTimeData.forEach((item:any, value:any) => {

      var temp:any = [];
      if(item?.checked==true){
        if(item?.working_day_ot_hours_error == true || item?.week_off_ot_hours_error==true || item?.holiday_ot_hours_error==true ||item?.national_holiday_ot_hours_error==true || item?.half_working_day_ot_hours_error==true){

          count ++;
          this.overTimeData[index]['valid'] = false
        }
        else
        this.overTimeData[index]['valid']   = true;

      }
      index++;


    })

    return count;
  }
}
