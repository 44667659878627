<app-profile-right-loader *ngIf="loader"></app-profile-right-loader>
<div class="row row-16" *ngIf="!loader && permissions">
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <h5 class="mb-0">Geo Locations</h5>
    </div>
  </div>
  <ng-container *ngIf="empLocationData?.location_data?.length!=0">
    <div class="col-12" *ngFor="let  items of empLocationData.location_data">
      <div class="card details-card shadow-0 px-24 py-16">
        <div class="row row-24 ">
          <div class="col-12 d-flex">
            <p class="title  mb-0">{{items?.location_name}}</p>
            <a class="link-danger fs-14 ms-auto" *ngIf="this.loginAs=='HR'  && addEditPermission"
              (click)="deleteEmpLocation(items?.assigned_id)">
              <i class="icon-trash"></i></a>
          </div>
          <div class="col-lg-3  col-sm-6 col-12" *ngIf="getFieldPermission('coordinates')">
            <div class="detail-title">Coordinates</div>
            <div class="detail-desc">{{items?.latitude}}, - {{items?.longitude}}</div>
          </div>
          <div class="col-lg-3  col-sm-6 col-12" *ngIf="getFieldPermission('last_check_in')">
            <div class="detail-title">Last Check In</div>
            <div class="detail-desc" *ngIf="items?.last_punch_date!=null && items?.last_punch_date!=''">
              {{this.appService.dateFormatDisplay(items?.last_punch_date)}}
              {{this.appService.timeFormatDisplay(items?.last_punch_time)}}</div>
            <div class="detail-desc" *ngIf="items?.last_punch_date==null || items?.last_punch_date==''">-</div>
          </div>
          <div class="col-lg-3  col-sm-6 col-12" *ngIf="getFieldPermission('added_by')">
            <div class="detail-title">Added By</div>
            <div class="detail-desc">{{items?.added_by}}</div>
          </div>
          <div class="col-lg-3  col-sm-6 col-12" *ngIf="getFieldPermission('added_on')">
            <div class="detail-title">Added On</div>
            <div class="detail-desc">{{this.appService.dateFormatDisplay(items?.created_at)}}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="col-12 " *ngIf="this.loginAs=='HR' && permissions?.a && addEditPermission">
    <div class="card card-c2 px-24 py-16">
      <div class="row row-24">
        <div class="col-12"><span class="title">New Location</span></div>
        <div class="col-12 d-flex">
          <a class="btn btn-outline-primary px-64" (click)="addlocation();showSidePanel=true">Add location</a>

        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="this.loginAs!=='HR' && empLocationData?.location_data?.length==0" >
    <div class="flex-center flex-column gap-8" style="min-height: calc(100vh - 17rem);">
        <img src="/assets/images/helper/helper3.png" alt="" srcset="">
        <p class="mb-0 fs-12">
          No location added
        </p>
    </div>
  </div>
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" [formGroup]="locationForm" autocomplete="off">
    <header class="side-panel-head">
      <h5>Add location</h5>
      <a class="toggle-panel" (click)="showSidePanel = false;this.location().clear();"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 ">
          <div class="col-12">
            <p class="text-uppercase fw-500 mb-0 fs-16">Location</p>
          </div>
          <ng-container formArrayName="location" *ngFor="let insur of location().controls; let i = index">
            <ng-container [formGroupName]="i">
              <div class="col-12">
                <div class="card card-c2">
                  <div class="card-body  p-24">
                    <div class="row row-16">
                      <div class="col-12 d-flex">
                        <p class="mb-0 title">LOCATION {{i+1}}</p>
                        <a class="link-danger fs-14 ms-auto" *ngIf="i!=0" (click)="deleteLocationControl(i)">
                          <i class="icon-trash"></i></a>
                      </div>
                      <div class="col-12 form-row">
                        <label for="" class="form-label required">Location</label>
                        <ng-select
                          [ngClass]="{ 'is-invalid': ( location().controls[i].get('geofencing_location_id')?.dirty || location().controls[i].get('geofencing_location_id')?.touched) && location().controls[i].get('geofencing_location_id')?.errors }"
                          class="form-ngselect" formControlName="geofencing_location_id"
                          placeholder="{{this.messageService.selectplaceholddisplay('Location')}}">
                          <ng-option *ngFor="let item of locationItem" [value]="item.id">{{ item.locationData
                            }}</ng-option>
                        </ng-select>
                        <div
                          *ngIf="( location().controls[i].get('geofencing_location_id')?.touched ) && location().controls[i].get('geofencing_location_id')?.errors"
                          class="invalid-feedback">
                          <div *ngIf="location().controls[i].get('geofencing_location_id')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                        </div>
                      </div>
                      <div class="col-12 ">
                        <div class="form-row">
                          <label for=" " class="form-label required ">Is this location for specific period? </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" formControlName="for_specific_days_only"
                            [value]=true (ngModelChange)="addValidator(i)">
                          <label class="form-check-label">
                            Yes
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" formControlName="for_specific_days_only"
                            [value]=false (ngModelChange)="addValidator(i)">
                          <label class="form-check-label">
                            No
                          </label>
                        </div>
                      </div>
                      <div class="col-12 form-row "
                        *ngIf="location().controls[i].get('for_specific_days_only')?.value==true">
                        <input type="text" class="form-control" name="duration_of_days" id="duration_of_days"
                          placeholder="Mention days" formControlName="duration_of_days"
                          [ngClass]="{ 'is-invalid': (location().controls[i].get('duration_of_days')?.touched || location().controls[i].get('duration_of_days')?.dirty) && location().controls[i].get('duration_of_days')?.errors}">
                        <div
                          *ngIf="(location().controls[i].get('duration_of_days')?.touched || location().controls[i].get('duration_of_days')?.dirty) && location().controls[i].get('duration_of_days')?.errors"
                          class="invalid-feedback">
                          <div *ngIf="location().controls[i].get('duration_of_days')?.errors?.required">
                            {{this.messageService.validationDisplay('required')}}</div>
                          <div *ngIf="location().controls[i].get('duration_of_days')?.errors?.max">
                            {{this.messageService.fieldlengthvalidation('days','365')}}</div>
                            <div *ngIf="location().controls[i].get('duration_of_days')?.errors?.pattern">
                              {{this.messageService.validationDisplay('pattern')}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="col-12 ">
            <div class="link-primary1  ">
              <i class="icon-plus-square-fill me-8" (click)="addlocation()"> Add more</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer gap-32">
      <button class="ms-auto btn btn-primary text-uppercase btn-sm" type="submit" [disabled]="!locationForm.valid"
        (click)="submitEmployeeLocation()">Save</button>
    </footer>
  </form>
</div>
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Location?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('location')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase"
          (click)="deleteEmpLocationConfirm()">Delete</button>
      </div>
    </div>
  </div>
</div>

