import { Component, OnInit, Inject, ViewChild,ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {  AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { RoleService } from 'src/app/@core/services/role.service';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';
import { PunchTimeComponent } from './punch-time/punch-time.component';
import { AttendanceRegularizationRequestComponent } from './attendance-regularization-request/attendance-regularization-request.component'
import { ArrearDaysComponent } from './arrear-days/arrear-days.component';
import { AttendanceRegisterComponent } from './attendance-register/attendance-register.component';
import { CompoffRequestComponent } from './compoff-request/compoff-request.component';
import { MonthlyOvertimeDetailComponent } from './monthly-overtime-detail/monthly-overtime-detail.component';
import { MonthlyPaidDayComponent } from './monthly-paid-day/monthly-paid-day.component';
import { MonthlyShiftRoasterComponent } from './monthly-shift-roaster/monthly-shift-roaster.component';
import { MonthlyWorkingHourComponent } from './monthly-working-hour/monthly-working-hour.component';
import { OndutyRequestComponent } from './onduty-request/onduty-request.component';
import { OvertimeRequestComponent } from './overtime-request/overtime-request.component';
import { FloatingHolidayRequestComponent } from './floating-holiday-request/floating-holiday-request.component';
import { MusterRollForm25Component } from './muster-roll-form25/muster-roll-form25.component';
import { AbsentDetailsComponent } from './absent-details/absent-details.component';
import { DailyAttendanceComponent } from './daily-attendance/daily-attendance.component';
import { LateEarlyComponent } from './late-early/late-early.component';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { WorkFromHomeReportComponent } from './work-from-home-report/work-from-home-report.component';
import { CheckInRequestReportComponent } from './check-in-request-report/check-in-request-report.component';
import { LocationLiveComponent } from './location-live/location-live.component';
import { LocationRemoteComponent } from './location-remote/location-remote.component';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-attendance-report-configure',
  templateUrl: './attendance-report-configure.component.html',
  styleUrls: ['./attendance-report-configure.component.scss']
})
export class AttendanceReportConfigureComponent implements OnInit {

  constructor(
    public attendanceReportService:AttendanceReportsService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,
    public roleService:RoleService,
    public messageService : MessageService,public router: Router,
    public route:ActivatedRoute,   public cd : ChangeDetectorRef,
    private notify : NotificationService) { }

  scrollLeft(e:any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft -= 150;
  }
  scrollRight(e:any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft += 150;
   }
   columnChangedArr : any = []
   // Calling the close fn of tag in parent from child
 @ViewChild(PunchTimeComponent, { static: false }) appPunchtime!: PunchTimeComponent;
 @ViewChild(AttendanceRegularizationRequestComponent, { static: false }) appAttendanceRegularize!: AttendanceRegularizationRequestComponent;
 @ViewChild(ArrearDaysComponent, { static: false }) appArreardays!: ArrearDaysComponent;
 @ViewChild(AttendanceRegisterComponent, { static: false }) appAttendanceRegister!: AttendanceRegisterComponent;
 @ViewChild(CompoffRequestComponent, { static: false }) appCompoff!: CompoffRequestComponent;
 @ViewChild(MonthlyOvertimeDetailComponent, { static: false }) appMonthlyOvertime!: MonthlyOvertimeDetailComponent;
 @ViewChild(MonthlyPaidDayComponent, { static: false }) appMonthlyPaidDay!: MonthlyPaidDayComponent;
 @ViewChild(MonthlyShiftRoasterComponent, { static: false }) appMonthlyShiftroaster!: MonthlyShiftRoasterComponent;
 @ViewChild(MonthlyWorkingHourComponent, { static: false }) appMonthlyWorkingHour!: MonthlyWorkingHourComponent;
 @ViewChild(OndutyRequestComponent, { static: false }) appOndutyRequest!: OndutyRequestComponent;
 @ViewChild(OvertimeRequestComponent, { static: false }) appOvertimeRequest!: OvertimeRequestComponent;
 @ViewChild(FloatingHolidayRequestComponent, { static: false }) appFloatingHoliday!: FloatingHolidayRequestComponent;
 @ViewChild(MusterRollForm25Component, { static: false }) appMusterRollForm!: MusterRollForm25Component;
 @ViewChild(AbsentDetailsComponent, { static: false }) appAbsentDetails!: AbsentDetailsComponent;
 @ViewChild(DailyAttendanceComponent, { static: false }) appDailyAttendance!: DailyAttendanceComponent;
 @ViewChild(LateEarlyComponent, { static: false }) appLateEarly!: LateEarlyComponent;
 @ViewChild(WorkFromHomeReportComponent, { static: false }) appWorkFromHome!: WorkFromHomeReportComponent;
 @ViewChild(CheckInRequestReportComponent, { static: false }) appCheckin!: CheckInRequestReportComponent;
 @ViewChild(LocationLiveComponent, { static: false }) appLive !: LocationLiveComponent;
 @ViewChild(LocationRemoteComponent, { static: false }) appRemote !: LocationRemoteComponent;
  alertShow = true;
 table: any;
 scheduleReport      = false;
 applyFilter         = false;
 resetFilter         = false;
 viewDetail          = false;
 addColumnFilter     = false;
 dataSource:any      = [];
 columnNames:any     = [];
 page                = 1;
 pageSize            = 20;
 showTotalPages      = 3;
 fileType            = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
 fileExtension       = '.xlsx';
 fileName:any;
 loader              = true;
 nodata              = false;
 reportName:any      = '';
 paginateData: any[] = [];
 startDate             : any
 endDate               : any
 displayedColumns:any  = [];
 columnOptions:any     = [];

 columnOptions1:any    = [];

 datasControl          = new FormControl();
 columnsControl        = new FormControl();

 checkColumn:any       = [];
 tempArr: any          = [] ;
 empstatArr : any      = [];
 fullData:any          = [];
 callReports : any;
 callemp :boolean = false
 checkListValue:any
 checkList :any

 closeControl :any;
 responseLength :any;
 headerCheck :any;


 //FILTER MEMORIZE VARIABLES
 dataFilter: any;
 header_filter: any    = [];
 resDataFilter:any;
 resHeader_filter:any  = [];
 filterDisable         = false;
 resetReportName:any;
 is_colfilter          = false;

 //SCHEDULE REPORT MEMORIZE VARIABLES
 isFilter              = false;
 scheduleText:any      = '';

 //TAG FILTER VARIABLES
 appliedFilter:any     = [];
 tagMultiData:any      = [];
 tagMultiCnt:any       = 0;
//  columnsArr : any      = []
 restFilterSave : boolean = false

 filterFormData      : any     = ''
 childFilterSubmit   : boolean = false

 disabled=false;
 companyDD :any =  [{
    id            : 0,
    company_name  : ''
  }];
  buDD = [{
    id          : 0,
    name        : ''
  }];
  DepDD = [{
    id          : 0,
    name        : ''
  }];
  DesignationDD = [{
    id          : 0,
    name        : ''
  }];
  countryDD = [{
    id          : 0,
    name        : ''
  }];
  stateDD = [{
    id          : 0,
    name        : ''
  }];
  BranchDD = [{
    id          : 0,
    name        : ''
  }];
  cityDD = [{
    id          : 0,
    name        : ''
  }];
  gradeDD = [{
    id           : 0,
    name         : ''
  }];
  employeeDropdown:any  = [];
  filterSaveForm = this.formBuilder.group({
    filter: true,
  });
  scheduleReportFilterMemorizeForm = this.formBuilder.group({
    filter    : true,
  });
  testform = this.formBuilder.group({
    checkbox  : [''],
  })
  submitted         = false;
  filterSubmit      = false;
  checkFilterSubmit = false;
  dateSetup          : any
  toppings = new FormControl();
  checkboxData:any = [
    { id: '1', value: 'One' },
    { id: '2', value: 'Two' },
  ]
  columns1: string[] = [];
  columns2: string[] = [];
  employeetype: any[] = [
    {name: 'Permanent'},
    {name: 'Consultant'},
    {name: 'Wages'},
    {name: 'Trainee'},
    {name: 'Contractual'},
    {name: 'Apprentice'},
  ];
  employeestatus: any[] = [
    {name: 'Probation'},
    {name: 'Confirmed'},
    {name: 'Suspended'},
    {name: 'Absconding'},
    {name: 'Resigned'},
    {name: 'Terminated'},
    {name: 'Relieved'},
    {name: 'Retired'},
  ];
  emptypeDD = [
    {id:"Contractual",    name:"Contractual"},
    {id:"Consultant",    name:"Consultant"},
    {id:"Permanent",    name:"Permanent"},
    {id:"Trainee",    name:"Trainee"},
    {id:"Wages",    name:"Wages"},
  ];
  empstatusDD = [
    {id:0,    name:"Probation"},
    {id:1,    name:"Confirmed"},
    {id:2,    name:"Relieved"},
    {id:3,    name:"Resigned"},
    {id:4,    name:"Settled"},
    {id:5,    name:"Terminated"},
    {id:6,    name:"Retired"},
    {id:7,    name:"NA"},
  ];
  requestStatus = [{name:'Pending',value:'Pending'},{name:'Approved',value:'Accepted'},{name:'Rejected',value:'Rejected'},{name:'Cancelled',value:'Cancelled'}]
    listOfMonth= [{id:1,name:"January"},{id:2,name:"February"},{id:3,name:"March"},{id:4,name:"April"},{id:5,name:"May"},{id:6,name:"June"},{id:7,name:"July"},
  {id:8,name:"August"},{id:9,name:"September"},{id:10,name:"October"},{id:11,name:"November"},{id:12,name:"December"}];
    Years : any = []
  attendanceStatus = [{name:'Leave'},{name:'Present'},{name:'Absent'},{name:'Weekoff'}]
  lateEarlyArr =[{name:'Late'},{name:'Early'}]
  checkInarr =[{name:'Web'},{name:'Mobile'}]
  reportId = 0;
  element: any;
  selectedYear : any
  OuFilterForm = this.formBuilder.group({
    company               : [''],
    bu                    : [''],
    branch                : [''],
    designation           : [''],
    department            : [''],
    employee_list         : [''],
    grade                 : [''],
    employee_type         : [''],
    employment_status     : [''],
    reporting_manager     : null
  })
  punchTime = this.formBuilder.group({
    from_date :['',Validators.required],
    to_date :['',Validators.required],
    ouFilterForm          : this.OuFilterForm
  })
  attendanceRegularize = this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    request_status : ['',Validators.required],
    ouFilterForm          : this.OuFilterForm
  })
  arrearDays = this.formBuilder.group({
    arrear_month :['this_month',Validators.required],
    arrear_specific_month : null,
    arrear_specific_year : null,
    attendance_month :['this_month'],
    attend_specific_month : null,
    attend_specific_year : null,
    ouFilterForm          : this.OuFilterForm
  })
  attendanceRegister  = this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    ouFilterForm          : this.OuFilterForm
  })
  compOffRequest = this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    request_status : ['',Validators.required],
    ouFilterForm          : this.OuFilterForm
  })
  dailyAttendance = this.formBuilder.group({
    attendance_date :[null,Validators.required],
    specific_month : null,
    attendance_status : '',
    ouFilterForm          : this.OuFilterForm,
    late_or_early  : ''
  })
  lateEarly = this.formBuilder.group({
    attendance_date :['this_month',Validators.required],
    from_date : null,
    to_date : null,
    ouFilterForm  : this.OuFilterForm
  })
  monthlyOvertime = this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    ouFilterForm          : this.OuFilterForm
  })
  monthlyPaid = this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    ouFilterForm          : this.OuFilterForm
  })
  monthlyShiftRoaster = this.formBuilder.group({
    calendar_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    ouFilterForm          : this.OuFilterForm
  })
  monthlyWorkingHour = this.formBuilder.group({
    calendar_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    ouFilterForm          : this.OuFilterForm
  })
  onDutyRequest= this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    request_status : ['',Validators.required],
    ouFilterForm          : this.OuFilterForm
  })
  overtimeRequest = this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    request_status : ['',Validators.required],
    ouFilterForm          : this.OuFilterForm
  })
  floatingHoliday= this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    request_status : ['',Validators.required],
    ouFilterForm          : this.OuFilterForm
  })
  musterRollForm = this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    ouFilterForm          : this.OuFilterForm
  })
  absentDetails= this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    ouFilterForm          : this.OuFilterForm
  })
  workFromHomeForm= this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    request_status : ['',Validators.required],
    ouFilterForm : this.OuFilterForm
  })
  checkinForm = this.formBuilder.group({
    attendance_month :['this_month',Validators.required],
    specific_month : null,
    specific_year : null,
    request_status : ['',Validators.required],
    ouFilterForm : this.OuFilterForm,
    check_in_type         : ''
  })
  remoteLocationForm = this.formBuilder.group({
    attendance_date : ['today',[Validators.required]],
    sm_specific_month : null,
    ouFilterForm : this.OuFilterForm
  })
  liveLocationForm = this.formBuilder.group({
    attendance_date : ['today',[Validators.required]],
    sm_specific_month : null,
    long_halt : null,
    stop_time : null,
    ouFilterForm : this.OuFilterForm
  })
  hasChange: boolean = false;
  hasChangeCount:any = 0;

  monthColumnList :any
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    this.columnChangedArr = this.displayedColumns
    this.filterSubmit = true
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  defaultHeaderList:any=[];
  hint_dict:any;
  ngOnInit(): void {
    this.childFilterSubmit = true
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
    this.initialLoaded = true
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
          this.reportId = params['id'];
      }
      if(params['name'] == 'ClockingTimeReport'){
        this.reportName = 'Clocking Time Report'
        this.punchTimeReport();
      }
      else if(params['name'] == 'AttendanceRegularizationRequest'){
        this.reportName = 'Attendance Regularization Request'
        this.attendanceRegularizationReport();
      }
      else if(params['name'] == 'ArrearDays'){
        this.reportName = 'Arrear Days'
        this.arrearDaysReport();
      }
      else if(params['name'] == 'MonthlyAttendanceRegister'){
        this.reportName = 'Monthly Attendance Register'
        this.attendanceRegisterReport();
      }
      else if(params['name'] == 'CompensatoryOffRequest'){
        this.reportName = 'Compensatory Off Request'
        this.compOffReport();
      }
      else if(params['name'] == 'MonthlyOvertimeDetail'){
        this.reportName = 'Monthly Overtime Detail'
        this.monthlyOvertimeReport();
      }
      else if(params['name'] == 'MonthlyPaidDay'){
        this.reportName = 'Monthly Paid Day'
        this.monthlyPaidReport();
      }
      else if(params['name'] == 'MonthlyShiftRoaster'){
        this.reportName = 'Monthly Shift Roaster'
        this.monthlyShiftRoasterReport();
      }
      else if(params['name'] == 'MonthlyWorkingHour'){
        this.reportName = 'Monthly Working Hour'
        this.monthlyWorkingHourReport();
      }
      else if(params['name'] == 'OnDutyRequest'){
        this.reportName = 'On Duty Request'
        this.onDutyReport();
      }
      else if(params['name'] == 'OvertimeRequest'){
        this.reportName = 'Overtime Request'
        this.overtimeReport();
      }
      else if(params['name'] == 'FloatingHolidayRequestReport'){
        this.reportName = 'Floating Holiday Request'
        this.restrictedHolidayReport();
      }
      else if(params['name'] == 'MusterRollForm25'){
        this.reportName = 'Muster Roll Form 25'
        this.musterRollFormReport();
      }
      else if(params['name'] == 'AbsentDetails'){
        this.reportName = 'Absent Details'
        this.absentDetailsReport();
      }
      else if(params['name'] == 'DailyAttendance'){
        this.reportName = 'Daily Attendance'
        this.dailyAttendanceReport();
      }
      else if(params['name'] == 'LateAndEarlyGo'){
        this.reportName = 'Late And Early Go'
        this.lateEarlyReport();
      }
      else if(params['name'] == 'WorkFromHomeRequest'){
        this.reportName = 'Work From Home Request'
        this.workFromHomeReport();
      }
      else if(params['name'] == 'CheckInRequest'){
        this.reportName = 'Check In Request'
        this.checkInRequestReport();
      }
      else if(params['name'] == 'RemotePunchLocations'){
        this.reportName = 'Remote Punch Locations'
        this.locationRemoteReport();
      }
      else if(params['name'] == 'LiveLocation'){
        this.reportName = 'Live Location'
        this.locationLiveReport();
      }
  })
  this.selectAllForDropdownItems(this.empstatusDD);
  this.selectAllForDropdownItems(this.emptypeDD);
  this.selectAllForDropdownItems(this.requestStatus);
  this.selectAllForDropdownItems(this.attendanceStatus);
  this.selectAllForDropdownItems(this.lateEarlyArr);
  this.selectAllForDropdownItems(this.checkInarr);
  this.yearListFunction()
  }
  ordering : any = 'Employee Code'
  pageChangeDetect : boolean = false
  timeSetup : any
  locationRemoteReport(){
    this.loader             = true;
    this.reportName         = "Remote Punch Locations";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.attendanceReportService.remoteLocation(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res?.data?.length != 0){
        this.dataSource = res?.data
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
            this.dataSource[i]['Check in time'] = `${this.appservice.dateFormatDisplay(this.dataSource[i]['Check in time'])},${moment(this.dataSource[i]['Check in time']).format(this.appservice.gettimeformat())}`;
            this.dataSource[i]['Check out time'] = this.dataSource[i]['Check out time'] == null ? '' : `${this.appservice.dateFormatDisplay(this.dataSource[i]['Check out time'])},${moment(this.dataSource[i]['Check out time']).format(this.appservice.gettimeformat())}`;
            this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date']);
            this.dataSource[i]['Check in Location'] = this.dataSource[i]['Check in location']
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
      if(res?.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        if(this.appliedFilter.attendance_date != 'yesterday' && this.appliedFilter.attendance_date != 'today' && this.appliedFilter.attendance_date != ''){
          this.remoteLocationForm.get('attendance_date')?.setValue(true);
          this.remoteLocationForm.get('sm_specific_month')?.setValue(new Date(this.appliedFilter.attendance_date));
        }
        else{
          this.remoteLocationForm.get('attendance_date')?.reset(this.appliedFilter.attendance_date);
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata           = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()
      // /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res?.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res?.data?.length,res['header_list'],res['check_list']);
      }
       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.remoteLocationForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.reporting_manager == ""){
            this.OuFilterForm.get('reporting_manager')?.setValue(null)
          }
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter.attendance_date != 'yesterday' && this.resDataFilter.attendance_date != 'today' && this.resDataFilter.attendance_date != ''){
            this.remoteLocationForm.get('attendance_date')?.setValue(true);
            this.remoteLocationForm.get('sm_specific_month')?.setValue(new Date(this.resDataFilter.attendance_date));
          }
          else{
            this.remoteLocationForm.get('attendance_date')?.reset(this.resDataFilter.attendance_date);
          }
        }
       }
      /**Header filter data**/
     if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
       this.headerFilterImplementation(res?.header_list)
     }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader           = false;
      this.pageChangeDetect = false
    })
  }
   // Live Report
   locationLiveReport(){
    this.loader             = true;
    this.reportName         = "Live Location";
    this.appliedFilter      = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page
    this.attendanceReportService.liveLocation(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
      if(res?.data?.length != 0){
        this.dataSource = res?.data
        this.lastPage = res?.count
        if(this.dataSource?.length > 0){
          for(let i=0; i<this.dataSource?.length;i++){
            this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
            this.dataSource[i]['Time'] = `${this.appservice.dateFormatDisplay(this.dataSource[i]['Time'])},${moment(this.dataSource[i]['Time']).format(this.appservice.gettimeformat())}`;
            this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date']);
            this.dataSource[i]['Employee Code'] = this.dataSource[i]['Employee code']
            this.dataSource[i]['Long halt'] = this.dataSource[i]['Long hault']
            this.dataSource[i]['Location name'] = this.dataSource[i]['Location']
          }
        }
        this.dataSource       = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }
      this.resDataFilter        = res?.data_filter;
      this.resHeader_filter     = res?.header_filter;
      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
      if(res?.hasOwnProperty('applied_filter'))
        this.appliedFilter =  res.applied_filter;
        this.timeSetup = res?.applied_filter?.stop_time
        if(this.appliedFilter.attendance_date != 'yesterday' && this.appliedFilter.attendance_date != 'today' && this.appliedFilter.attendance_date != ''){
          this.liveLocationForm.get('attendance_date')?.setValue(true);
          this.liveLocationForm.get('sm_specific_month')?.setValue(new Date(this.appliedFilter.attendance_date));
        }
        else{
          this.liveLocationForm.get('attendance_date')?.reset(this.appliedFilter.attendance_date);
        }
      this.columns2         = res['check_list'];
      this.columnOptions    = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata           = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()
      // /**dynamic data table**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
          this.dynamicHeader(res?.data?.length,this.resHeader_filter,res['check_list']);
        else
          this.dynamicHeader(res?.data?.length,res['header_list'],res['check_list']);
      }
       /**Filter data**/
       if(this.resDataFilter != null && this.resDataFilter != undefined){
        if(!!Object.keys(this.resDataFilter)?.length == true){
          this.liveLocationForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if(this.resDataFilter?.reporting_manager == ""){
            this.OuFilterForm.get('reporting_manager')?.setValue(null)
          }
          if(this.resDataFilter?.company?.length > 0){
            this.getBUList();
            if(this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter.attendance_date != 'yesterday' && this.resDataFilter.attendance_date != 'today' && this.resDataFilter.attendance_date != ''){
            this.liveLocationForm.get('attendance_date')?.setValue(true);
            this.liveLocationForm.get('sm_specific_month')?.setValue(new Date(this.resDataFilter.attendance_date));
          }
          else{
            this.liveLocationForm.get('attendance_date')?.reset(this.resDataFilter.attendance_date);
          }
        }
       }
      /**Header filter data**/
      if(!this.resetOffset && !this.pageChangeDetect){
        if(this.resHeader_filter?.length != 0)
         this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }
      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader           = false;
      this.pageChangeDetect = false
    })
  }
punchTimeReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "Clocking Time Report";
  this.appliedFilter      = [];
  this.attendanceReportService.punchTimeReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.punchTime.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        this.punchTime.get('from_date')?.setValue(new Date(this.resDataFilter.from_date));
        this.punchTime.get('to_date')?.setValue(new Date(this.resDataFilter.to_date));
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
       this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
attendanceRegularizationReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "Attendance Regularization Request";
  this.appliedFilter      = [];
  this.attendanceReportService.attendanceRegularizeReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
         this.dataSource[i]['Request on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Request on']);
         this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on']);
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      if(res?.applied_filter?.request_status){
        let data = res?.applied_filter?.request_status
        const approvedIndex = data.indexOf('Accepted');
        if (approvedIndex !== -1) {
          data[approvedIndex] = 'Approved';
        }
        res.applied_filter.request_status = data
      }
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
        this.attendanceRegularize?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.attendance_month
        const split_year_month = attendance_year_month?.split('-')
        const atten_month = moment(split_year_month[1], 'M')?.format('MMMM');
        this.attendanceRegularize?.get('specific_year')?.setValue(split_year_month[0]);
        this.attendanceRegularize?.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.attendanceRegularize?.get('attendance_month')?.setValue(this.appliedFilter?.attendance_month);
      }
      if(this.appliedFilter?.attendance_month == undefined){
        this.attendanceRegularize?.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.attendanceRegularize?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.attendanceRegularize?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month?.split('-')
          const atten_month = moment(split_year_month[1], 'M')?.format('MMMM');
          this.attendanceRegularize?.get('specific_year')?.setValue(split_year_month[0]);
          this.attendanceRegularize?.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.attendanceRegularize?.get('attendance_month')?.setValue(this.resDataFilter?.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm?.get('bu')?.setValue(this.resDataFilter?.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
       this.headerFilterImplementation(this.resHeader_filter)
      else
       this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
arrearDaysReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "Arrear Days";
  this.appliedFilter      = [];
  this.attendanceReportService.arrearDaysReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.arrear_month != 'this_month' && this.appliedFilter?.arrear_month != 'last_month'  && this.appliedFilter?.arrear_month != '' && this.appliedFilter?.arrear_month != null){
        this.arrearDays?.get('arrear_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.arrear_month
        const split_year_month = attendance_year_month?.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.arrearDays.get('arrear_specific_year')?.setValue(split_year_month[0]);
        this.arrearDays.get('arrear_specific_month')?.setValue(atten_month);
      }
      else{
        this.arrearDays.get('arrear_month')?.setValue(this.appliedFilter.arrear_month);
      }
      if(this.appliedFilter?.arrear_month == undefined){
        this.arrearDays.get('arrear_month')?.setValue('this_month');
      }
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
        this.arrearDays?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.arrearDays.get('attend_specific_year')?.setValue(split_year_month[0]);
        this.arrearDays.get('attend_specific_month')?.setValue(atten_month);
      }
      else{
        this.arrearDays.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
      }
      if(this.appliedFilter?.attendance_month== undefined ){
        this.arrearDays.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
       this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
       this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.arrearDays?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.arrear_month != 'this_month' && this.resDataFilter?.arrear_month != 'last_month'  && this.resDataFilter?.arrear_month != '' && this.resDataFilter?.arrear_month != null){
          this.arrearDays?.get('arrear_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.arrear_month
          const split_year_month = attendance_year_month?.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.arrearDays.get('arrear_specific_year')?.setValue(split_year_month[0]);
          this.arrearDays.get('arrear_specific_month')?.setValue(atten_month);
        }
        else{
          this.arrearDays.get('arrear_month')?.setValue(this.resDataFilter.arrear_month);
        }
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.arrearDays?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.arrearDays.get('attend_specific_year')?.setValue(split_year_month[0]);
          this.arrearDays.get('attend_specific_month')?.setValue(atten_month);
        }
        else{
          this.arrearDays.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
       this.headerFilterImplementation(this.resHeader_filter)
      else
       this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
lateEarlyReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "Late And Early Go";
  this.appliedFilter      = [];
  this.attendanceReportService.lateEarlyReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
      }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_date == 'this_month' || this.appliedFilter?.attendance_date == 'last_month' || this.appliedFilter?.attendance_date == 'yesterday' ){
        this.lateEarly.get('attendance_date')?.setValue(this.appliedFilter.attendance_date);
      }
      if(this.appliedFilter?.from_date != '' && this.appliedFilter?.from_date != null && this.appliedFilter?.to_date != '' && this.appliedFilter?.to_date != null  ){
        this.lateEarly.get('attendance_date')?.setValue(true);
        this.lateEarly.get('from_date')?.setValue(new Date(this.appliedFilter.from_date));
        this.lateEarly.get('to_date')?.setValue(new Date(this.appliedFilter.to_date));
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.lateEarly?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_date == 'this_month' || this.resDataFilter?.attendance_date == 'last_month' || this.resDataFilter?.attendance_date == 'yesterday' ){
          this.lateEarly.get('attendance_date')?.setValue(this.resDataFilter.attendance_date);
        }
        if(this.resDataFilter?.from_date != '' && this.resDataFilter?.from_date != null && this.resDataFilter?.to_date != '' && this.resDataFilter?.to_date != null  ){
          this.lateEarly.get('attendance_date')?.setValue(true);
          this.lateEarly.get('from_date')?.setValue(new Date(this.resDataFilter.from_date));
          this.lateEarly.get('to_date')?.setValue(new Date(this.resDataFilter.to_date));
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
dailyAttendanceReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "Daily Attendance";
  this.appliedFilter      = [];
  this.attendanceReportService.dailyAttendanceReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
      }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_date != 'today' && this.appliedFilter?.attendance_date != 'yesterday'  && this.appliedFilter?.attendance_date != '' && this.appliedFilter?.attendance_date != null){
        this.dailyAttendance?.get('attendance_date')?.setValue(true);
        this.dailyAttendance.get('specific_month')?.setValue(new Date(this.appliedFilter.attendance_date));
      }
      else{
        this.dailyAttendance.get('attendance_date')?.setValue(this.appliedFilter.attendance_date);
      }
      if(this.appliedFilter?.attendance_date ==  undefined){
        this.dailyAttendance.get('attendance_date')?.setValue('today');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.dailyAttendance?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_date != 'today' && this.resDataFilter?.attendance_date != 'yesterday'  && this.resDataFilter?.attendance_date != '' && this.resDataFilter?.attendance_date != null){
          this.dailyAttendance?.get('attendance_date')?.setValue(true);
          this.dailyAttendance.get('specific_month')?.setValue(new Date(this.resDataFilter.attendance_date));
        }
        else{
          this.dailyAttendance.get('attendance_date')?.setValue(this.resDataFilter.attendance_date);
        }
        if(this.resDataFilter?.attendance_date ==  undefined){
          this.dailyAttendance.get('attendance_date')?.setValue('today');
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
absentDetailsReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "Absent Details";
  this.appliedFilter      = [];
  this.attendanceReportService.absentDetailsReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Date Attendance Missing'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date Attendance Missing'])
         this.dataSource[i]['Regularized']= this.dataSource[i]['Regularized'] == 'False' ? 'No' :'Yes'
         this.dataSource[i]['Reporting Manager'] = this.dataSource[i]['Reporting manager']
      }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
        this.absentDetails?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.absentDetails.get('specific_year')?.setValue(split_year_month[0]);
        this.absentDetails.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.absentDetails.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.absentDetails?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.absentDetails?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.absentDetails.get('specific_year')?.setValue(split_year_month[0]);
          this.absentDetails.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.absentDetails.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
         this.headerFilterImplementation(this.resHeader_filter)
      else
         this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.loader  = false;
    this.pageChangeDetect = false
  });
}
monthlyWorkingHourReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "Monthly Working Hour";
  this.appliedFilter      = [];
  this.attendanceReportService.monthlyWorkingHourReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count;
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.calendar_month != 'this_month' && this.appliedFilter?.calendar_month != 'last_month'  && this.appliedFilter?.calendar_month != '' && this.appliedFilter?.calendar_month != null){
        this.monthlyWorkingHour?.get('calendar_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.calendar_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.monthlyWorkingHour.get('specific_year')?.setValue(split_year_month[0]);
        this.monthlyWorkingHour.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.monthlyWorkingHour.get('calendar_month')?.setValue(this.appliedFilter.calendar_month);
      }
      if(this.appliedFilter?.calendar_month == undefined){
        this.monthlyWorkingHour.get('calendar_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.monthlyWorkingHour?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.calendar_month != 'this_month' && this.resDataFilter?.calendar_month != 'last_month'  && this.resDataFilter?.calendar_month != '' && this.resDataFilter?.calendar_month != null){
          this.monthlyWorkingHour?.get('calendar_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.calendar_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.monthlyWorkingHour.get('specific_year')?.setValue(split_year_month[0]);
          this.monthlyWorkingHour.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.monthlyWorkingHour.get('calendar_month')?.setValue(this.resDataFilter.calendar_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.loader  = false;
    this.pageChangeDetect = false
  });
}
overtimeReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "Overtime Request";
  this.appliedFilter      = [];
  this.attendanceReportService.overTimerequestReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
         this.dataSource[i]['Request on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Request on']);
         this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on']);
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter      = res?.data_filter;
    this.resHeader_filter   = res?.header_filter;
    // }
    this.defaultHeaderList  = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
            this.overtimeRequest?.get('attendance_month')?.setValue(true);
            const attendance_year_month = this.appliedFilter?.attendance_month
            const split_year_month = attendance_year_month.split('-')
            const atten_month = moment(split_year_month[1], 'M').format('MMMM');
            this.overtimeRequest.get('specific_year')?.setValue(split_year_month[0]);
            this.overtimeRequest.get('specific_month')?.setValue(atten_month);
        }
      else{
        this.overtimeRequest.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
      }
      if(this.appliedFilter?.attendance_month == undefined){
        this.overtimeRequest.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
     else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.overtimeRequest?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.overtimeRequest?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.overtimeRequest.get('specific_year')?.setValue(split_year_month[0]);
          this.overtimeRequest.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.overtimeRequest.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
restrictedHolidayReport(){
  this.loader             = true;
  this.reportName         = "Floating Holiday Request";
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.appliedFilter      = [];
  this.attendanceReportService.restrictedHolidayRequestReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
         this.dataSource[i]['Request on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Request on']);
         this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on']);
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter      = res?.data_filter;
    this.resHeader_filter   = res?.header_filter;
    // }
    this.defaultHeaderList  = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
        this.floatingHoliday?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.floatingHoliday.get('specific_year')?.setValue(split_year_month[0]);
        this.floatingHoliday.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.floatingHoliday.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
      }
      if(this.appliedFilter?.attendance_month == undefined){
        this.floatingHoliday.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.floatingHoliday?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.floatingHoliday?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.floatingHoliday.get('specific_year')?.setValue(split_year_month[0]);
          this.floatingHoliday.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.floatingHoliday.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
onDutyReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "On Duty Request";
  this.appliedFilter      = [];
  this.attendanceReportService.onDutyRequestReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
         this.dataSource[i]['Request on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Request on']);
         this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on']);
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
          this.onDutyRequest?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.appliedFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.onDutyRequest.get('specific_year')?.setValue(split_year_month[0]);
          this.onDutyRequest.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.onDutyRequest.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
        }
        if(this.appliedFilter?.attendance_month == undefined){
          this.onDutyRequest.get('attendance_month')?.setValue('this_month');
        }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.onDutyRequest?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.onDutyRequest?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.onDutyRequest.get('specific_year')?.setValue(split_year_month[0]);
          this.onDutyRequest.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.onDutyRequest.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
monthlyOvertimeReport(){
  this.loader             = true;
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.reportName         = "Monthly Overtime Detail";
  this.appliedFilter      = [];
  this.attendanceReportService.monthlyOvertimeReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter       = res?.data_filter;
    this.resHeader_filter    = res?.header_filter;
    // }
    this.defaultHeaderList   = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
        this.monthlyOvertime?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.monthlyOvertime.get('specific_year')?.setValue(split_year_month[0]);
        this.monthlyOvertime.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.monthlyOvertime.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
      }
      if(this.appliedFilter?.attendance_month == undefined){
        this.monthlyOvertime.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.monthlyOvertime?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.monthlyOvertime?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.monthlyOvertime.get('specific_year')?.setValue(split_year_month[0]);
          this.monthlyOvertime.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.monthlyOvertime.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.loader  = false;
    this.pageChangeDetect = false
  });
}
compOffReport(){
  this.loader             = true;
  this.reportName         = "Compensatory Off Request";
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.appliedFilter      = [];
  this.attendanceReportService.compOffReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
         this.dataSource[i]['Request on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Request on']);
         this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on']);
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter      = res?.data_filter;
    this.resHeader_filter   = res?.header_filter;
    // }
    this.defaultHeaderList  = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
        this.compOffRequest?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.compOffRequest.get('specific_year')?.setValue(split_year_month[0]);
        this.compOffRequest.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.compOffRequest.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
      }
      if(this.appliedFilter?.attendance_month ==  undefined){
        this.compOffRequest.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.compOffRequest?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.compOffRequest?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.compOffRequest.get('specific_year')?.setValue(split_year_month[0]);
          this.compOffRequest.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.compOffRequest.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.compOffRequest.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.loader  = false;
    this.pageChangeDetect = false
  });
}
headerList:any
monthlyShiftRoasterReport(){
  this.loader             = true;
  this.reportName         = "Monthly Shift Roaster";
  this.appliedFilter      = [];
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.attendanceReportService.monthlyShiftRoasterReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    this.monthColumnList = res?.month_date_column_list
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter')){
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.calendar_month != 'this_month' && this.appliedFilter?.calendar_month != 'last_month'  && this.appliedFilter?.calendar_month != '' && this.appliedFilter?.calendar_month != null){
        this.monthlyShiftRoaster?.get('calendar_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.calendar_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.monthlyShiftRoaster.get('specific_year')?.setValue(split_year_month[0]);
        this.monthlyShiftRoaster.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.monthlyShiftRoaster.get('calendar_month')?.setValue(this.appliedFilter.calendar_month);
      }
      if(this.appliedFilter?.calendar_month == undefined){
        this.monthlyShiftRoaster.get('calendar_month')?.setValue('this_month');
      }
    }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.monthlyShiftRoaster?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.calendar_month != 'this_month' && this.resDataFilter?.calendar_month != 'last_month'  && this.resDataFilter?.calendar_month != '' && this.resDataFilter?.calendar_month != null){
          this.monthlyShiftRoaster?.get('calendar_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.calendar_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.monthlyShiftRoaster.get('specific_year')?.setValue(split_year_month[0]);
          this.monthlyShiftRoaster.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.monthlyShiftRoaster.get('calendar_month')?.setValue(this.resDataFilter.calendar_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerList = res['header_list']
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
attendanceRegisterReport(){
  this.loader             = true;
  this.reportName         = "Monthly Attendance Register";
  this.appliedFilter      = [];
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.attendanceReportService.attendanceRegisterReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
          this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    this.monthColumnList = res?.month_date_column_list
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
        this.attendanceRegister?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.attendanceRegister.get('specific_year')?.setValue(split_year_month[0]);
        this.attendanceRegister.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.attendanceRegister.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
      }
      if(this.appliedFilter?.attendance_month == undefined){
        this.attendanceRegister.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.attendanceRegister?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.attendanceRegister?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.attendanceRegister.get('specific_year')?.setValue(split_year_month[0]);
          this.attendanceRegister.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.attendanceRegister.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.headerList = res['header_list']
    this.checkChange = true
    this.headerFilterApply()
    this.loader  = false;
    this.pageChangeDetect = false
  });
}
musterRollFormReport(){
  this.loader             = true;
  this.reportName         = "Muster Roll Form 25";
  this.appliedFilter      = [];
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.attendanceReportService.musterRollFormReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
         this.dataSource[i]['Absent'] = this.dataSource[i]['Absent days']
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    this.monthColumnList = res.month_date_column_list
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
        this.musterRollForm?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.musterRollForm.get('specific_year')?.setValue(split_year_month[0]);
        this.musterRollForm.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.musterRollForm.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
      }
      if(this.appliedFilter?.attendance_month == undefined){
        this.musterRollForm.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.musterRollForm?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.musterRollForm?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.musterRollForm.get('specific_year')?.setValue(split_year_month[0]);
          this.musterRollForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.musterRollForm.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.headerList = res['header_list']
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
  monthlyPaidReport(){
  this.loader             = true;
  this.reportName         = "Monthly Paid Day";
  this.appliedFilter      = [];
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.attendanceReportService.monthlyPaidReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
        }
      }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    this.monthColumnList = res?.month_date_column_list
    // if(this.childFilterSubmit){
    this.resDataFilter     = res?.data_filter;
    this.resHeader_filter  = res?.header_filter;
    // }
    this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.attendance_month != 'this_month' && this.appliedFilter?.attendance_month != 'last_month'  && this.appliedFilter?.attendance_month != '' && this.appliedFilter?.attendance_month != null){
        this.monthlyPaid?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.monthlyPaid.get('specific_year')?.setValue(split_year_month[0]);
        this.monthlyPaid.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.monthlyPaid.get('attendance_month')?.setValue(this.appliedFilter.attendance_month);
      }
      if(this.appliedFilter?.attendance_month== undefined){
        this.monthlyPaid.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.monthlyPaid?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.monthlyPaid?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.monthlyPaid.get('specific_year')?.setValue(split_year_month[0]);
          this.monthlyPaid.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.monthlyPaid.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.headerList = res['header_list']
    this.checkChange = true
    this.headerFilterApply()
    this.loader  = false;
    this.pageChangeDetect = false
  });
}
workFromHomeReport(){
  this.loader             = true;
  this.reportName         = "Work From Home Request";
  this.appliedFilter      = [];
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.attendanceReportService.workFromHomeReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
         this.dataSource[i]['Request on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Request on']);
         this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on']);
         this.dataSource[i]['Actual-Check-In'] = this.dataSource[i]['Actual Check-in'];
         this.dataSource[i]['Actual-Check-Out'] = this.dataSource[i]['Actual Check-out'];
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.Attendance_month != 'this_month' && this.appliedFilter?.Attendance_month != 'last_month'  && this.appliedFilter?.Attendance_month != '' && this.appliedFilter?.Attendance_month != null){
        this.workFromHomeForm?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.Attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.workFromHomeForm.get('specific_year')?.setValue(split_year_month[0]);
        this.workFromHomeForm.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.workFromHomeForm.get('attendance_month')?.setValue(this.appliedFilter.Attendance_month);
      }
      if(this.appliedFilter?.Attendance_month == undefined){
        this.workFromHomeForm.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.workFromHomeForm?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.attendance_month != 'this_month' && this.resDataFilter?.attendance_month != 'last_month'  && this.resDataFilter?.attendance_month != '' && this.resDataFilter?.attendance_month != null){
          this.workFromHomeForm?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.workFromHomeForm.get('specific_year')?.setValue(split_year_month[0]);
          this.workFromHomeForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.workFromHomeForm.get('attendance_month')?.setValue(this.resDataFilter.attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.headerFilterImplementation(this.resHeader_filter)
      else
        this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
checkInRequestReport(){
  this.loader             = true;
  this.reportName         = "Check In Request";
  this.appliedFilter      = [];
  this.initialLoaded      = true
  this.offset = this.resetOffset ? 0 : this.offset
  this.page = this.resetOffset ? 1 : this.page
  this.attendanceReportService.checkInrequestReport(this.limit,this.offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
    if(res.data?.length != 0){
      this.dataSource       = res?.data;
      this.lastPage = res?.count
      if(this.dataSource?.length > 0){
        for(let i=0; i<this.dataSource?.length;i++){
         this.dataSource[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Attendance date'])
         this.dataSource[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of joining']);
         this.dataSource[i]['Request on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Request on']);
         this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on']);
         this.dataSource[i]['Punch (date)'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Punch (date)']);
        }
     }
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.fullData   = this.dataSource?.filteredData;
    }
    // if(this.childFilterSubmit){
    this.resDataFilter        = res?.data_filter;
    this.resHeader_filter     = res?.header_filter;
    // }
    this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}
    if(res.hasOwnProperty('applied_filter'))
      this.appliedFilter =  res.applied_filter;
      if(this.appliedFilter?.Attendance_month != 'this_month' && this.appliedFilter?.Attendance_month != 'last_month'  && this.appliedFilter?.Attendance_month != '' && this.appliedFilter?.Attendance_month != null){
        this.checkinForm?.get('attendance_month')?.setValue(true);
        const attendance_year_month = this.appliedFilter?.Attendance_month
        const split_year_month = attendance_year_month.split('-')
        const atten_month = moment(split_year_month[1], 'M').format('MMMM');
        this.checkinForm.get('specific_year')?.setValue(split_year_month[0]);
        this.checkinForm.get('specific_month')?.setValue(atten_month);
      }
      else{
        this.checkinForm.get('attendance_month')?.setValue(this.appliedFilter.Attendance_month);
      }
      if(this.appliedFilter?.Attendance_month == undefined){
        this.checkinForm.get('attendance_month')?.setValue('this_month');
      }
    this.setDataSourceAttributes();
    this.columns2       = res['check_list'];
    this.columnOptions  = this.columns2;
    this.columnsControl.setValue(this.columnOptions1);
    this.nodata = res.data?.length==0?true:false;
    /**dynamic data table**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
        this.dynamicHeader(res.data?.length,this.resHeader_filter,res['check_list']);
      else
        this.dynamicHeader(res.data?.length,res['header_list'],res['check_list']);
    }
    if(this.resDataFilter != null && this.resDataFilter != undefined){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.checkinForm?.reset(this.resDataFilter);
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.Attendance_month != 'this_month' && this.resDataFilter?.Attendance_month != 'last_month'  && this.resDataFilter?.Attendance_month != '' && this.resDataFilter?.Attendance_month != null){
          this.checkinForm?.get('attendance_month')?.setValue(true);
          const attendance_year_month = this.resDataFilter?.Attendance_month
          const split_year_month = attendance_year_month.split('-')
          const atten_month = moment(split_year_month[1], 'M').format('MMMM');
          this.checkinForm.get('specific_year')?.setValue(split_year_month[0]);
          this.checkinForm.get('specific_month')?.setValue(atten_month);
        }
        else{
          this.checkinForm.get('attendance_month')?.setValue(this.resDataFilter.Attendance_month);
        }
        if(this.resDataFilter?.company?.length > 0){
          this.getBUList();
          if(this.resDataFilter?.bu?.length > 0)
            this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
        }
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
      }
    }
    /**Header filter data**/
    if(!this.resetOffset && !this.pageChangeDetect){
      if(this.resHeader_filter?.length != 0)
      this.headerFilterImplementation(this.resHeader_filter)
      else
      this.headerFilterImplementation(res?.header_list)
    }
    this.resetOffset = false
    this.checkListElements();
    this.checkChange = true
    this.headerFilterApply()
    this.pageChangeDetect = false
    this.loader  = false;
  });
}
@ViewChild(MatSort) set matSort(ms: MatSort) {
  this.sort = ms;
  this.setDataSourceAttributes();
}
@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  this.paginator = mp;
  this.setDataSourceAttributes();
}
setDataSourceAttributes() {
  if(this.dataSource != undefined && this.dataSource != null){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort      = this.sort;
  }
}
headerFilterImplementation(headerFilter:any){
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i1=0;i1<elements?.length;i1++){
      var elementss = <HTMLInputElement>elements[i1];
      elementss.checked = false;
    }
  }, 100);
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      for(let j=0;j<headerFilter?.length;j++){
        if(element.defaultValue == headerFilter[j]){
          element.checked   = true;
          element.disabled  = false;
          this.checkColumn.push(headerFilter[j]);
          this.header_filter.push(headerFilter[j]);
        }
      }
      if(element.defaultValue == "Attendance Status" && this.resHeader_filter?.length == 0){
        if(this.monthColumnList.every((r: any) => headerFilter.includes(r))){
          element.checked   = true;
          element.disabled  = false;
          this.checkColumn.push('Attendance Status');
          this.header_filter.push('Attendance Status');
      }
      }
    }
  }, 1000);
  for (let i = 0; i < headerFilter?.length; i++) {
    const element = headerFilter[i];
    if (!this.displayedColumns.includes(element)) {
      this.displayedColumns.push(element);
    }
  }
}

checkListElement(value:any){
  this.checkListValue = value
  if(this.checkListValue){
    this.checkListElements();
  }
}
checkListElements(){
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      for(let j=0;j<this.columns1?.length;j++){
        if(element.defaultValue == this.columns1[j]){
          // element.checked   = true;
          element.disabled  = false;
        }
      }
    }
  }, 1000);
}
scheduleReportFunction(){
  this.isFilter       = true;
  this.scheduleText = '';
  if(this.resDataFilter != undefined && this.resDataFilter != null  && this.resHeader_filter != undefined  && this.resHeader_filter != null){
    if((!!Object.keys(this.resDataFilter)?.length == true || this.resHeader_filter?.length) && (this.submitted == false)){
      this.scheduleReport = true;
      this.scheduleText = 'Schedule the report with filters';
    }else if(this.submitted == true){
      this.scheduleReport = true;
      this.scheduleText = 'Save filters and schedule the report'
    }
    else{
      this.applyFilter    = false;
      this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/false']);
    }
  }
}
dynamicHeader(data_length:any,header_list:any,check_list:any){
  // this.nodata           = data_length==0?true:false;
  this.columns1         = header_list.slice();
  this.displayedColumns = this.dupliCateRemove(this.columns1)
  this.columnOptions1   = this.columns1;
}
dupliCateRemove(data:any){
  const uniqueColumnsSet = new Set();
  data.filter((column:any) => {
    if (!uniqueColumnsSet.has(column)) {
      uniqueColumnsSet.add(column);
      return true;
    }
    return false;
  });
  return Array.from(uniqueColumnsSet)
}

// Reorder Arr
reorderArray(arr1:any, arr2:any) {
  const set1 = new Set(arr1);
  const matchingItems = arr2.filter((item:any) => set1.has(item));
  const extraItems = arr2.filter((item:any) => !set1.has(item));
  matchingItems.sort((a:any, b:any) => arr1.indexOf(a) - arr1.indexOf(b));
  return matchingItems.concat(extraItems);
}
  /********* FILE EXPORT FUNCTION ****************/
  extremeLimit : boolean = false
  export() {
   let limit = this.lastPage
   let offset = 0
   this.fullData = []
   this.loader = true
     if(this.reportName == 'Daily Attendance'){
       this.attendanceReportService.dailyAttendanceReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
         if(res?.data?.length != 0){
           this.fullData  = res?.data;
           if(this.fullData?.length > 0){
             for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
          }
         }
         }
         this.commonLogic(this.fullData)
       })
     }
     else if(this.reportName == 'Monthly Attendance Register'){
      this.attendanceReportService.attendanceRegisterReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Muster Roll Form 25'){
      this.attendanceReportService.musterRollFormReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
               this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
               this.fullData[i]['Absent'] = this.fullData[i]['Absent days']
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Clocking Time Report'){
      this.attendanceReportService.punchTimeReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Attendance Regularization Request'){
      this.attendanceReportService.attendanceRegularizeReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              this.fullData[i]['Request on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Request on']);
              this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Arrear Days'){
      this.attendanceReportService.arrearDaysReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Compensatory Off Request'){
      this.attendanceReportService.compOffReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              this.fullData[i]['Request on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Request on']);
              this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Monthly Overtime Detail'){
      this.attendanceReportService.monthlyOvertimeReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Monthly Paid Day'){
      this.attendanceReportService.monthlyPaidReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Monthly Shift Roaster'){
      this.attendanceReportService.monthlyShiftRoasterReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Monthly Working Hour'){
      this.attendanceReportService.monthlyWorkingHourReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'On Duty Request'){
      this.attendanceReportService.onDutyRequestReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              this.fullData[i]['Request on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Request on']);
              this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on']);
              this.fullData[i]['Actual Check-in'] = this.fullData[i]['Actual Check-in'][0]
              this.fullData[i]['Actual Check-out'] = this.fullData[i]['Actual Check-out'][0]
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Overtime Request'){
      this.attendanceReportService.overTimerequestReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              this.fullData[i]['Request on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Request on']);
              this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Floating Holiday Request'){
      this.attendanceReportService.restrictedHolidayRequestReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              this.fullData[i]['Request on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Request on']);
              this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Absent Details'){
      this.attendanceReportService.absentDetailsReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Date Attendance Missing'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date Attendance Missing'])
              this.fullData[i]['Regularized']= this.fullData[i]['Regularized'] == 'False' ? 'No' :'Yes'
              this.fullData[i]['Reporting Manager'] = this.fullData[i]['Reporting manager']
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Late And Early Go'){
      this.attendanceReportService.lateEarlyReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Work From Home Request'){
      this.attendanceReportService.workFromHomeReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              this.fullData[i]['Request on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Request on']);
              this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on']);
              this.fullData[i]['Actual-Check-In'] = this.fullData[i]['Actual Check-in'];
              this.fullData[i]['Actual-Check-Out'] = this.fullData[i]['Actual Check-out'];
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }
    else if(this.reportName == 'Check In Request'){
      this.attendanceReportService.checkInrequestReport(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.data?.length != 0){
          this.fullData  = res?.data;
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date'])
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              this.fullData[i]['Request on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Request on']);
              this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on']);
              this.fullData[i]['Punch (date)'] = this.appservice.dateFormatDisplay(this.fullData[i]['Punch (date)']);
            }
        }
        }
        this.commonLogic(this.fullData)
      })
    }else if(this.reportName == 'Remote Punch Locations'){
      this.attendanceReportService.remoteLocation(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.body?.data?.length != 0){
          this.fullData = res?.data
          this.lastPage = res?.count
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              this.fullData[i]['Check in time'] = `${this.appservice.dateFormatDisplay(this.fullData[i]['Check in time'])},${moment(this.fullData[i]['Check in time']).format(this.appservice.gettimeformat())}`;
              this.fullData[i]['Check out time'] = this.fullData[i]['Check out time'] == null ? '' : `${this.appservice.dateFormatDisplay(this.fullData[i]['Check out time'])},${moment(this.fullData[i]['Check out time']).format(this.appservice.gettimeformat())}`;
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date']);
              this.fullData[i]['Check in Location'] = this.fullData[i]['Check in location']
            }
          }
          this.commonLogic(this.fullData)
        }
      })
    }else if(this.reportName == 'Live Location'){
      this.attendanceReportService.liveLocation(limit,offset,this.ordering,this.filterFormData,this.childFilterSubmit).subscribe((res: any) => {
        if(res?.body?.data?.length != 0){
          this.fullData = res?.data
          this.lastPage = res?.count
          if(this.fullData?.length > 0){
            for(let i=0; i<this.fullData?.length;i++){
              this.fullData[i]['Date of joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of joining']);
              this.fullData[i]['Time'] = `${this.appservice.dateFormatDisplay(this.fullData[i]['Time'])},${moment(this.fullData[i]['Time']).format(this.appservice.gettimeformat())}`;
              this.fullData[i]['Attendance date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Attendance date']);
              this.fullData[i]['Employee Code'] = this.fullData[i]['Employee code']
              this.fullData[i]['Long halt'] = this.fullData[i]['Long hault']
              this.fullData[i]['Location name'] = this.fullData[i]['Location']
            }
          }
          this.commonLogic(this.fullData)
        }
      })
    }
  }
 commonLogic(data:any){
   let column = this.displayedColumns;
   const newArray = [];
   for (const obj of data) {
     const newObject : any = {};
     for (const key of column) {
         if (obj.hasOwnProperty(key)) {
             newObject[key] = obj[key];
         }
     }
     newArray.push(newObject);
   }
  //  this.nodata = newArray?.length == 0 ? true : false
   if(data != undefined )
    this.exportExcel(newArray, this.reportName);
    this.loader = false
    this.extremeLimit = false
    this.cd.detectChanges()
 }
  excelHeaders: string[] = [];
  templateToExcel:any[] =[]
  public exportExcel(jsonData: any[], fileName: string): void {
    if(!this.nodata){
      const ws: XLSX.WorkSheet  = XLSX.utils.json_to_sheet(jsonData);
      const wb: XLSX.WorkBook   = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer: any    = XLSX.write(wb,{ bookType: 'xlsx', type: 'array'});
      this.saveExcelFile(excelBuffer, fileName);
    }
    else{
      for(let i=0;i<this.displayedColumns.length;i++){
        this.excelHeaders.push(this.displayedColumns[i])
        this.templateToExcel = this.excelHeaders
      }
      const header = this.templateToExcel
      const data : any = []
      const ws : XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header,...data]);
      const wb : XLSX.WorkBook = {Sheets : {'data':ws}, SheetNames:['data']};
      const excelBuffer : any = XLSX.write(wb, {bookType : 'xlsx', type:'array'})
      this.saveExcelFile(excelBuffer,fileName)
    }
  }
 private saveExcelFile(buffer: any, fileName: string): void {
  const data: Blob = new Blob([buffer], {type: this.fileType});
  FileSaver.saveAs(data, fileName + this.fileExtension);
}
  removeUnderscoreAddUpperCase(str:any) {
    var i, frags = str?.split('_');
    for (i=0; i<frags?.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
  }
  tagMultiDataFunction(data:any){
    this.tagMultiData = [];
    this.tagMultiCnt = 0;
    this.tagMultiData.push(data[0]);
    if(data?.length == 1){
      return this.tagMultiData;
    }else{
      this.tagMultiCnt = '+'+JSON.stringify(data?.length-1);
      return this.tagMultiData;
    }
  }
  applyTagClose(rptName:any,controlName:any){
    controlName = controlName == 'long_hault' ? 'long_halt'  : controlName
    controlName = controlName == 'employment_type' ? 'employee_type' : controlName
    this.filterSubmit = true;
    this.page = 1;
    this.pageNumber = 0
    this.cd.detectChanges();
    if(rptName == 'Clocking Time Report'){
    this.callemp= true
    this.closeControl = controlName
    // Sending the above data to child
    }
    if(rptName == 'Attendance Regularization Request'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Arrear Days'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Late And Early Go'){
        this.callemp= true
        this.closeControl = controlName
    }
    if(rptName == 'Daily Attendance'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Absent Details'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Monthly Working Hour'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Monthly Attendance Register'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Compensatory Off Request'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Overtime Request'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Floating Holiday Request'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'On Duty Request'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Monthly Overtime Detail'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Monthly Paid Day'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Monthly Shift Roaster'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Muster Roll Form 25'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Work From Home Request'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Check In Request'){
      this.callemp= true
      this.closeControl = controlName
    }
    if(rptName == 'Remote Punch Locations'){
      controlName = controlName == 'emp_type' ? 'employee_type'  : controlName
      controlName = controlName == 'emp_status' ?  'employment_status' : controlName
      this.callemp = true;
      this.closeControl   = controlName;
    }
    if(rptName == 'Live Location'){
      controlName = controlName == 'emp_status' ?  'employment_status' : controlName
      controlName = controlName == 'emp_type' ? 'employee_type'  : controlName
      this.callemp = true;
      this.closeControl   = controlName;
    }
  }
  checkChange : boolean  = false
headerFilterAdd(e:any,index:any){
  this.checkChange = true
  this.columnApplied = true
  this.header_filter=this.dupliCateRemove(this.header_filter)
  if(e.target.checked){
    this.submitted = true;
    this.checkColumn.push(e.target.value);
    this.header_filter.push(e.target.value);
  }else{
    if(this.header_filter.length==1){
      var elements = document.getElementsByName("check");
      setTimeout(() => {
          var element = <HTMLInputElement>elements[index];
            element.checked = true;
      })
    }
    else{
      this.checkColumn = this.dupliCateRemove(this.checkColumn)
      const index: number        = this.checkColumn.indexOf(e.target.value);
      const header_index: number = this.header_filter.indexOf(e.target.value);
      const column_1: number     = this.columns1.indexOf(e.target.value);
      this.checkColumn.splice(index, 1);
      this.header_filter.splice(header_index,1);
      this.columns1.splice(column_1,1);
    }
  }
}
  resetColumnFilterFunction(){
    if(this.resHeader_filter?.length != 0){
      this.resetFilter = true
     this.is_colfilter = true;
    }else{
      this.resetAllUnDefaultColumns();
    }
    this.columnChangedArr = []
  }
  arrayAreSame(res1:any,res2:any){
    if(res1.length !== res2.length){
      return false
    }
    for(let i=0;i<res1.length;i++){
      if(res1[i] !== res2[i]){
        return false
      }
    }
    return true
  }
 // test start
 resetAllUnDefaultColumns(){
  this.checkFilterSubmit  = false;
  this.checkColumn = []
  var elements = document.getElementsByName("check");
  setTimeout(() => {
    for(let i=0;i<elements?.length;i++){
      var element = <HTMLInputElement>elements[i];
      if(element.disabled == false)
        element.checked = false;
    }
    for(let j=0;j<this.header_filter?.length;j++){
      const index: number        = this.displayedColumns.indexOf(this.header_filter[j]);
      if(index != -1){
      this.displayedColumns.splice(index, 1);
      }
    }
    if(this.resHeader_filter?.length==0){
      this.dynamicHeader(this.defaultHeaderList?.length,this.defaultHeaderList,this.columns2);
      this.headerFilterImplementation(this.defaultHeaderList)
    }
    this.header_filter = []
    // this.columnsArr =[]
  }, 1000);
  if(this.resHeader_filter?.length != 0){
    this.callAllReportsFunctions()
  }
  }
  columnApplied : boolean = false
// test end
  headerFilterApply(){
    if(!this.checkChange){
      return
    }
    let array3 = this.columns1?.concat(this.checkColumn);
    array3     = array3?.filter((item:any,index:any)=>{
        return (array3.indexOf(item) == index)
    })
    if(this.columnChangedArr?.length !=0){
      array3 = this.reorderArray(this.columnChangedArr,array3)
    }
    this.displayedColumns   = array3;
    this.displayedColumns = this.displayedColumns.filter((id:any) => {
      return this.columnOptions.indexOf(id) > -1;
    });


  if(this.checkColumn?.length >0){
    if(this.monthColumnList?.every((r: any) => this.headerList.includes(r)) && this.checkColumn.indexOf('Attendance Status') != -1){
      this.displayedColumns = this.displayedColumns.concat(this.monthColumnList)
    }
  }else{
    let headerList =this.resHeader_filter?.length != 0 ? this.resHeader_filter : this.headerList
    if(this.monthColumnList?.every((r: any) => headerList.includes(r)) && this.columns2.indexOf('Attendance Status') != -1){
      this.displayedColumns = this.displayedColumns.concat(this.monthColumnList)
    }
  }

    const index: number        = this.displayedColumns.indexOf('Attendance Status');
    if(index != -1){
      this.displayedColumns.splice(index, 1);

    }

    if(this.checkColumn?.length > 0 && this.columnApplied)
      this.checkFilterSubmit  = true;
    else
      this.checkFilterSubmit  = false;
    // this.checkFilterSubmit  = true;
    this.checkChange = false
  }
 ngAfterViewInit() {
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort      = this.sort;
  this.getCompanyList();
  this.getBUList();
  this.getDepList();
  this.getDesignationList();
  this.getGradeList();
  this.getBranchList();
  this.getEmployeeList();
  this.selectAllForDropdownItems(this.empstatusDD);
  this.selectAllForDropdownItems(this.emptypeDD);
  this.selectAllForDropdownItems(this.requestStatus);
  this.selectAllForDropdownItems(this.attendanceStatus);
  this.selectAllForDropdownItems(this.lateEarlyArr);
  this.selectAllForDropdownItems(this.checkInarr);
  }
getCompanyList(){
  this.roleService.getCompanyList().subscribe((res: any) => {
    if(res.status == 200 && res.body?.length > 0) {
        this.companyDD = res.body;
      this.selectAllForDropdownItems(this.companyDD);
    }
  });
}
getBUListFunction(){
  if(this.reportName == 'Clocking Time Report'){
    this.punchTime.get('bu')?.setValue('');
  }
  if(this.reportName == 'Attendance Regularization Request'){
    this.attendanceRegularize.get('bu')?.setValue('');
  }
  if(this.reportName == 'Arrear Days'){
    this.arrearDays.get('bu')?.setValue('');
  }
  if(this.reportName == 'Late And Early Go'){
    this.lateEarly.get('bu')?.setValue('');
  }
  if(this.reportName == 'Daily Attendance'){
    this.dailyAttendance.get('bu')?.setValue('');
  }
  if(this.reportName == 'Absent Details'){
    this.absentDetails.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Working Hour'){
    this.monthlyWorkingHour.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Attendance Register'){
    this.attendanceRegister.get('bu')?.setValue('');
  }
  if(this.reportName == 'Compensatory Off Request'){
    this.compOffRequest.get('bu')?.setValue('');
  }
  if(this.reportName == 'Overtime Request'){
    this.overtimeRequest.get('bu')?.setValue('');
  }
  if(this.reportName == 'Floating Holiday Request'){
    this.floatingHoliday.get('bu')?.setValue('');
  }
  if(this.reportName == 'On Duty Request'){
    this.onDutyRequest.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Overtime Detail'){
    this.monthlyOvertime.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Paid Day'){
    this.monthlyPaid.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Shift Roaster'){
    this.monthlyShiftRoaster.get('bu')?.setValue('');
  }
  if(this.reportName == 'Muster Roll Form 25'){
    this.musterRollForm.get('bu')?.setValue('');
  }
  if(this.reportName == 'Work From Home Request'){
    this.workFromHomeForm.get('bu')?.setValue('');
  }
  if(this.reportName == 'Check In Request'){
    this.checkinForm.get('bu')?.setValue('');
  }
  this.buDD = [];
  this.getBUList();
}
getBUList(){
  let comp;
  if(this.reportName == 'Clocking Time Report'){
    comp = this.punchTime.value.company;
    this.punchTime.get('bu')?.setValue('');
  }
  if(this.reportName == 'Attendance Regularization Request'){
    comp = this.attendanceRegularize.value.company;
    this.attendanceRegularize.get('bu')?.setValue('');
  }
  if(this.reportName == 'Arrear Days'){
    comp = this.arrearDays.value.company;
    this.arrearDays.get('bu')?.setValue('');
  }
  if(this.reportName == 'Late And Early Go'){
    comp = this.lateEarly.value.company;
    this.lateEarly.get('bu')?.setValue('');
  }
  if(this.reportName == 'Daily Attendance'){
    comp = this.dailyAttendance.value.company;
    this.dailyAttendance.get('bu')?.setValue('');
  }
  if(this.reportName == 'Absent Details'){
    comp = this.absentDetails.value.company;
    this.absentDetails.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Working Hour'){
    comp = this.monthlyWorkingHour.value.company;
    this.monthlyWorkingHour.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Attendance Register'){
    comp = this.attendanceRegister.value.company;
    this.attendanceRegister.get('bu')?.setValue('');
  }
  if(this.reportName == 'Compensatory Off Request'){
    comp = this.compOffRequest.value.company;
    this.compOffRequest.get('bu')?.setValue('');
  }
  if(this.reportName == 'Overtime Request'){
    comp = this.overtimeRequest.value.company;
    this.overtimeRequest.get('bu')?.setValue('');
  }
  if(this.reportName == 'Floating Holiday Request'){
    comp = this.floatingHoliday.value.company;
    this.floatingHoliday.get('bu')?.setValue('');
  }
  if(this.reportName == 'On Duty Request'){
    comp = this.onDutyRequest.value.company;
    this.onDutyRequest.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Overtime Detail'){
    comp = this.monthlyOvertime.value.company;
    this.monthlyOvertime.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Paid Day'){
    comp = this.monthlyPaid.value.company;
    this.monthlyPaid.get('bu')?.setValue('');
  }
  if(this.reportName == 'Monthly Shift Roaster'){
    comp = this.monthlyShiftRoaster.value.company;
    this.monthlyShiftRoaster.get('bu')?.setValue('');
  }
  if(this.reportName == 'Muster Roll Form 25'){
    comp = this.musterRollForm.value.company;
    this.musterRollForm.get('bu')?.setValue('');
  }
  if(this.reportName == 'Work From Home Request'){
    comp = this.workFromHomeForm.value.company;
    this.workFromHomeForm.get('bu')?.setValue('');
  }
  if(this.reportName == 'Check In Request'){
    comp = this.checkinForm.value.company;
    this.checkinForm.get('bu')?.setValue('');
  }
  if(this.reportName == 'Remote Punch Locations'){
    comp = this.OuFilterForm.value.company;
    this.OuFilterForm.get('bu')?.setValue('');
  }
  if(this.reportName == 'Live Location'){
    comp = this.OuFilterForm.value.company;
    this.OuFilterForm.get('bu')?.setValue('');
  }
  if(comp != undefined && comp != null && comp != ''){
    this.attendanceReportService.getBuList(comp).subscribe((res: any) => {
      this.buDD = res;
      this.selectAllForDropdownItems(this.buDD);
  });
  }
}
getDepList(){
  this.roleService.getDepartmentList().subscribe((res: any) => {
    if(res.status == 200 && res.body?.length > 0) {
        this.DepDD = res.body;
        this.selectAllForDropdownItems(this.DepDD);
    }
  });
}
getDesignationList(){
  this.roleService.getDesignationList().subscribe((res: any) => {
    if(res.status == 200 && res.body?.length > 0) {
        this.DesignationDD = res.body;
        this.selectAllForDropdownItems(this.DesignationDD);
    }
  });
}
getGradeList(){
  this.roleService.getGradeList().subscribe((res: any) => {
    if(res.status == 200 && res.body?.length > 0) {
        this.gradeDD = res.body;
        this.selectAllForDropdownItems(this.gradeDD);
    }
  });
}
getBranchList(){
  this.attendanceReportService.getBranchList().subscribe((res: any) => {
    this.BranchDD = res;
    this.selectAllForDropdownItems(this.BranchDD);
  });
}
reportingDD : any = []
getEmployeeList(){
  this.attendanceReportService.getempList(true)
  .subscribe((res: any) => {
    // if(res.status == 200) {
      if (res?.length > 0) {
        for(let i=0;i<res?.length;i++){
          res[i]['name'] = res[i]['first_name'];
          if(res[i]['first_name'] != null)
          res[i]['name'] = res[i]['first_name']+" ";
          if(res[i]['middle_name'] != null)
          res[i]['name'] += res[i]['middle_name']+" ";
          if(res[i]['last_name'] != null)
          res[i]['name']   += res[i]['last_name'];
          res[i]['name']   +=" ("+res[i]['employee_code']+")";
        }
        this.employeeDropdown = res;
        let len = this.employeeDropdown.length;
        for (let i = 0; i < len; i++) {
        this.employeeDropdown[i]['fullname']  = "";
        if(this.employeeDropdown[i]['first_name'] != null)
         this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name']+" ";
        if(this.employeeDropdown[i]['middle_name'] != null)
         this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name']+" ";
        if(this.employeeDropdown[i]['last_name'] != null)
         this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
        }
        this.reportingDD = this.employeeDropdown
        // this.selectAllForDropdownItems(this.employeeDropdown);
      }
    // }
  })
}
selectAllForDropdownItems(items: any[]) {
  let allSelect = (items: any[]) => {
    items.forEach(element => {
      element['selectedAllGroup'] = 'selectedAllGroup';
    });
  };
  allSelect(items);
}
onChangEmpStatus(event:any, data: any){ // Use appropriate model type instead of any
  const index: number = this.empstatArr.indexOf(event.target.value);
  if(event.target.checked){
    this.empstatArr.push(data);
  }else{
    this.empstatArr.splice(index, 1);
  }
}
confirm():boolean {
  // return false;
  if(this.isFilter){
    return true;
  }
  else{
  if(this.filterSubmit == true || this.checkFilterSubmit == true) {
    this.applyFilter  = true;
    this.filterSubmit = false;
    this.checkFilterSubmit = false;
    return false;
  }else{
    this.applyFilter = false;
    return true;
  }
}
}
 // Submit after filter applied, getting value from child
 submitDone(value:any){
    if(this.callemp && Object.keys(this.resDataFilter)?.length > 0){
      this.filterMemorizeFunction('saveFilter','')
    }else{
      this.callemp = false
    }
  this.limit = value.limit
  this.offset = value.offset
  this.filterFormData = value.filterForm
    this.filterSubmit = !this.filterFormData?.split('&').every((data:any)=>{
      const [key, value] = data?.split('=')
      if(key === 'ordering'){
        return true;
      }
      return (value === '[]' || value === '')
    })
  this.childFilterSubmit = false
  this.page = 1
  this.pageNumber = 0
  this.resetOffset = true
  this.callAllReportsFunctions()
  this.pageChangeDetect = false
  this.viewDetail = false
}
// After reseting the form from child, main report fn is called
callReport(value:any){
  this.filterSubmit = false
  this.childFilterSubmit = true
  this.page = 1
  this.pageNumber = 0
  this.resetOffset = true
  this.callAllReportsFunctions()
}

dojFilter(d:any){
  let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date()]
  return (d >= dateRange[0] && d<= dateRange[1])
}
salFilter(d:any){
  let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
  return (d >= dateRange[0] && d<= dateRange[1])
}
filterSubmits(value:any){
  this.filterSubmit = value
}
// Loader
loaderFn(value:any){
  this.loader = value
}
// Reset filter btn
resetFilterFn(value:any){
  this.resetFilter = value
  this.columnApplied=false
}
// FIlter Panel ON and OFF
panel(value:any){
  this.viewDetail = value
}
 // Datafilters for filter save
 dataFilters(value:any){
  this.dataFilter = value.data
}
reportMemorizeFunction(){
  if(this.reportId != undefined && this.reportId != null){
    if(this.scheduleReportFilterMemorizeForm.value.filter == false){
      if(this.scheduleText == 'Save filters and schedule the report'){
        this.filterMemorizeFunction('saveFilter','saveAndSchedule');
      }else{
        this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/true']);
      }
    }else{
      this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/false']);
    }
  }
}
public isChangesSaved() {
  if(this.submitted == true) {
   return confirm("Do you want to discard changes?")
  }
  return true;
}
saveFilterClicked = false
saveFilter(){
  // this.columnsArr = [];
  if(this.filterSaveForm.value.filter == true){
    this.saveFilterClicked = true
    this.filterMemorizeFunction('saveFilter','');
  }else{
    this.applyFilter  = false;
    this.submitted    = false;
    this.filterSubmit = false;
    this.checkFilterSubmit = false;
  }
  this.router.navigate(['/attendance-report'])
}
isValueEmpty(value:any) {
  return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
}

// Arrange data based on 1st & remove if not in 2nd
rearragneAndRemove(arr1:any,arr2:any){
  let result = arr1.filter((item:any) => arr2.includes(item))
  let additionalItems = arr2.filter((item:any) => !arr1.includes(item));
  result.push(...additionalItems);
  return result
}

filterMemorizeFunction(data:any,data1:any){
  if(this.header_filter?.length > 0){
    var mySet = new Set(this.header_filter);
    this.header_filter = [...mySet];
  }
  let headerNeeded = !this.arrayAreSame(this.displayedColumns, this.defaultHeaderList)
  if(this.columnChangedArr?.length !=0){
    this.columnChangedArr = this.rearragneAndRemove(this.columnChangedArr,this.checkColumn)
  }

  if(this.reportName == 'Remote Punch Locations'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appRemote.getData() : ""
  }
  else if(this.reportName == 'Live Location'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appLive.getData() : ""
  }
  else if(this.reportName == 'Absent Details'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appAbsentDetails.getData() : ""
  }
  else if(this.reportName == 'Arrear Days'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appArreardays.getData() : ""
  }
  else if(this.reportName == 'Attendance Regularization Request'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appAttendanceRegularize.getData() : ""
  }
  else if(this.reportName == 'Check In Request'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appCheckin.getData() : ""
  }
  else if(this.reportName == 'Clocking Time Report'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.appPunchtime.getData() : ""
  }
  else if(this.reportName == 'Compensatory Off Request'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appCompoff.getData() : ""
  }
  else if(this.reportName == 'Daily Attendance'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appDailyAttendance.getData() : ""
  }
  else if(this.reportName == 'Floating Holiday Request'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appFloatingHoliday.getData() : ""
  }
  else if(this.reportName == 'Late And Early Go'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appLateEarly.getData() : ""
  }
  else if(this.reportName == 'Monthly Attendance Register'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appAttendanceRegister.getData() : ""
  }
  else if(this.reportName == 'Monthly Overtime Detail'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appMonthlyOvertime.getData() : ""
  }
  else if(this.reportName == 'Monthly Paid Day'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appMonthlyPaidDay.getData() : ""
  }
  else if(this.reportName == 'Monthly Shift Roaster'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appMonthlyShiftroaster.getData() : ""
  }
  else if(this.reportName == 'Monthly Working Hour'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appMonthlyWorkingHour.getData() : ""
  }
  else if(this.reportName == 'Muster Roll Form 25'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appMusterRollForm.getData() : ""
  }
  else if(this.reportName == 'On Duty Request'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appOndutyRequest.getData() : ""
  }
  else if(this.reportName == 'Overtime Request'){
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appOvertimeRequest.getData() : ""
  }
  else{
    // (this.reportName == 'Work From Home Request')
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ?  this.appWorkFromHome.getData() : ""
  }
  if(this.dataFilter == undefined || this.dataFilter == null || this.dataFilter == ""){
    this.dataFilter = JSON.stringify({});
  }
  this.attendanceReportService.filterMemorize({'data_filter' : this.childCall == true ? JSON.stringify({}): this.dataFilter, 'header_filter' :  this.columnChangedArr?.length != 0 ? JSON.stringify(this.columnChangedArr) : ((headerNeeded && !this.is_colfilter) ? JSON.stringify(this.displayedColumns) : JSON.stringify([])), 'is_active' : true, 'report' : Number(this.reportId)}).subscribe((res: any) => {
    if(this.callemp || this.resetFilter){
      this.notify.handleSuccessNotification("Updated Successfully","Success")
    }else{
      this.notify.handleSuccessNotification("Created Successfully","Success")
    }
    this.saveFilterClicked = false
    this.restFilterSave = false
    this.submitted   = false;
    this.filterSubmit = false;
    this.checkFilterSubmit = false
    this.hasChangeCount = 0;
    if(data == 'saveFilter'){
      this.resetFilterControllers('save');
      this.applyFilter = false;
    }
    else if(data == 'resetFilter'){
      this.resetFilter = false;
      this.childCall = false
      if(data1 == 'dataFilter')
        this.resetFilterControllers('reset');
      else if(data1 == 'colFilter')
        this.resetAllUnDefaultColumns();
      this.viewDetail = false;
    }
    if(data1 == 'saveAndSchedule'){
      this.scheduleReport = false;
      setTimeout(() => {
        if(this.reportId != undefined && this.reportId != null)
          this.router.navigate(['/scheduled-reports/reports-setup/'+this.reportId+'/true']);
      }, 3000);
    }
  });
}
fiterResetDisableFunction(){
  if(!!Object.keys(this.resDataFilter)?.length == true)
    this.filterDisable = true;
  else
    this.filterDisable = false;
  }
  resetFilterFunction(data:any){
    this.resetReportName = data;
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter = true
        // !this.arrayAreSame(this.resHeader_filter,this.defaultHeaderList) ? this.resetFilter = true : this.resetFilter = false
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }
  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterSubmit = false;
    if(this.reportName == 'Clocking Time Report'){
      this.appPunchtime.resetFilterControllers('reset')
    }
    if(this.reportName == 'Attendance Regularization Request'){
      this.appAttendanceRegularize.resetFilterControllers('reset')
    }
    if(this.reportName == 'Arrear Days'){
      this.appArreardays.resetFilterControllers('reset')
    }
    if(this.reportName == 'Late And Early Go'){
      this.appLateEarly.resetFilterControllers('reset')
    }
    if(this.reportName == 'Daily Attendance'){
      this.appDailyAttendance.resetFilterControllers('reset')
    }
    if(this.reportName == 'Absent Details'){
      this.appAbsentDetails.resetFilterControllers('reset')
    }
    if(this.reportName == 'Monthly Working Hour'){
      this.appMonthlyWorkingHour.resetFilterControllers('reset')
    }
    if(this.reportName == 'Monthly Attendance Register'){
      this.appAttendanceRegister.resetFilterControllers('reset')
    }
    if(this.reportName == 'Compensatory Off Request'){
      this.appCompoff.resetFilterControllers('reset')
    }
    if(this.reportName == 'Overtime Request'){
      this.appOvertimeRequest.resetFilterControllers('reset')
    }
    if(this.reportName == 'Floating Holiday Request'){
      this.appFloatingHoliday.resetFilterControllers('reset')
    }
    if(this.reportName == 'On Duty Request'){
      this.appOndutyRequest.resetFilterControllers('reset')
    }
    if(this.reportName == 'Monthly Overtime Detail'){
      this.appMonthlyOvertime.resetFilterControllers('reset')
    }
    if(this.reportName == 'Monthly Paid Day'){
      this.appMonthlyPaidDay.resetFilterControllers('reset')
    }
    if(this.reportName == 'Monthly Shift Roaster'){
      this.appMonthlyShiftroaster.resetFilterControllers('reset')
    }
    if(this.reportName == 'Muster Roll Form 25'){
      this.appMusterRollForm.resetFilterControllers('reset')
    }
    if(this.reportName == 'Work From Home Request'){
      this.appWorkFromHome.resetFilterControllers('reset')
    }
    if(this.reportName == 'Check In Request'){
      this.appCheckin.resetFilterControllers('reset')
    }
    if(this.reportName == 'Remote Punch Locations'){
      this.appRemote.resetFilterControllers('reset')
    }
    if(this.reportName == 'Live Location'){
      this.appLive.resetFilterControllers('reset')
    }
  }
  childCall : boolean = false
  resetFilterApply(){
    this.restFilterSave = true
  if(this.is_colfilter == true){
    this.header_filter = [];
    this.childCall = false
    this.filterMemorizeFunction('resetFilter','colFilter');
    this.is_colfilter = false
    this.childFilterSubmit = true;
  }
  else{
    this.childCall =  true
    this.dataFilter = JSON.stringify({});
    this.filterMemorizeFunction('resetFilter','dataFilter');
  }
}
disableDate(){
  return false;
}
yearListFunction(){
  this.selectedYear = new Date().getFullYear();
  this.attendanceReportService.yearListDropdown().subscribe((res: any) => {
   if(res?.created_year == this.selectedYear){
      this.Years.push(res?.created_year)
    }
    else{
      for(let i=this.selectedYear;i>=res?.created_year;i--){
        this.Years.push(i)
      }
    }
  })
}
limit = 20
offset = 0
lastPage : any
initialLoaded : boolean  = false
pageNumber :  any = 1
pageResetIfFlag = true;
pageResetElseFlag = false
pageChanged(val: any) {
  this.pageNumber = val-1
  this.offset = this.attendanceReportService.calculateOffset(val-1)
  this.cd.detectChanges()
  this.pageChangeDetect = true
  this.callAllReportsFunctions()
  if (!this.columnApplied) {
    this.columnApplied = false;
  } else {
    this.columnApplied = true;
  }
}
  callParentFunction(val:any){
    this.initialLoaded = val
  }
  resetOffset : boolean = false
  resetCall(val:any){
    this.page = 1
    this.pageNumber = 0
    this.resetOffset = true
  }

  direction : string = 'asc'
  sortProperty : string = 'Employee Code'
  onSort(val:any) {
    if (this.sortProperty === val) {
      this.direction = this.direction === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortProperty = val;
      this.direction = 'desc';
    }
    if (this.reportName == 'Remote Punch Locations' || this.reportName == 'Live Location') {
      this.ordering = val + '-' + this.direction.toUpperCase()
    } else {
      const active = val.split(' ').join('_').toLowerCase();
      const newOrder = this.direction === 'desc' ? '-' : '';
      this.ordering = newOrder + (val.includes(' ') ? active : val.toLowerCase());
        }
      this.cd.detectChanges()
      this.filterFormData = this.filterFormData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
      this.pageChangeDetect = true
      this.columnApplied == true ? this.columnApplied = true : this.columnApplied = false
      this.callAllReportsFunctions()
      // this.columnApplied = false
  }
  // Convert to HH:mm
  convertToHHMM(min:any){
    const hours = Math.floor(min / 60);
    const remainingMinutes = min % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(remainingMinutes).padStart(2, '0');
    return this.appservice.formatTime(formattedHours + ':' + formattedMinutes)
  }
  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }
  callAllReportsFunctions(){
    if(this.reportName == 'Clocking Time Report'){
      this.punchTimeReport();
    }
    if(this.reportName == 'Attendance Regularization Request'){
      this.attendanceRegularizationReport();
    }
    if(this.reportName == 'Arrear Days'){
      this.arrearDaysReport();
    }
    if(this.reportName == 'Late And Early Go'){
      this.lateEarlyReport();
    }
    if(this.reportName == 'Daily Attendance'){
      this.dailyAttendanceReport();
    }
    if(this.reportName == 'Absent Details'){
      this.absentDetailsReport();
    }
    if(this.reportName == 'Monthly Working Hour'){
      this.monthlyWorkingHourReport();
    }
    if(this.reportName == 'Monthly Attendance Register'){
      this.attendanceRegisterReport();
    }
    if(this.reportName == 'Compensatory Off Request'){
      this.compOffReport();
    }
    if(this.reportName == 'Overtime Request'){
      this.overtimeReport();
    }
    if(this.reportName == 'Floating Holiday Request'){
      this.restrictedHolidayReport();
    }
    if(this.reportName == 'On Duty Request'){
      this.onDutyReport();
    }
    if(this.reportName == 'Monthly Overtime Detail'){
      this.monthlyOvertimeReport();
    }
    if(this.reportName == 'Monthly Paid Day'){
      this.monthlyPaidReport();
    }
    if(this.reportName == 'Monthly Shift Roaster'){
      this.monthlyShiftRoasterReport();
    }
    if(this.reportName == 'Muster Roll Form 25'){
      this.musterRollFormReport();
    }
    if(this.reportName == 'Work From Home Request'){
      this.workFromHomeReport();
    }
    if(this.reportName == 'Check In Request'){
      this.checkInRequestReport();
    }
    if(this.reportName == 'Remote Punch Locations'){
      this.locationRemoteReport();
    }
    if(this.reportName == 'Live Location'){
      this.locationLiveReport();
    }
  }
}
