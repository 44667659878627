import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { SnapshotComponent } from './profile/snapshot/snapshot.component';
import { ProfessionalsComponent } from './profile/professionals/professionals.component';
import { EducationComponent } from './profile/education/education.component';
import { PersonalComponent } from './profile/personal/personal.component';
import { FamilyComponent } from './profile/family/family.component';
import { HealthInsuranceComponent } from './profile/health-insurance/health-insurance.component';
import { PreviousEmployerComponent } from './profile/previous-employer/previous-employer.component';
import { BankDetailsComponent } from './profile/bank-details/bank-details.component';
import { DocumentsComponent } from './profile/documents/documents.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Angular */
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { color } from '@alyle/ui/color';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyDialogModule } from '@alyle/ui/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { MasterSelectModule } from 'src/app/@shared/master-select.module';

import { Ng2SearchPipeModule } from 'ng2-search-filter';

/** Alyle UI */
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LY_THEME_GLOBAL_VARIABLES,
  LyHammerGestureConfig
} from '@alyle/ui';


export class GlobalVariables {
  testVal = color(0x00bcd4);
  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: color(0xffffff),
    shadow: color(0x11998e)
  };
  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: color(0xffffff),
    shadow: color(0xB36FBC)
  };
  Amber = {
    default: color(0xffc107),
    contrast: color(0, 0, 0, 0.87)
  };
}



import { CropperDialog } from './profile/cropper.dialog';
import { ProfileRightLoaderComponent } from './profile/profile-right-loader/profile-right-loader.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { PayslipComponent } from './my-finance/payslip/payslip.component';
import { TaxslipComponent } from './my-finance/taxslip/taxslip.component';
import { Form16Component } from './my-finance/form16/form16.component';
import { HolidayCalenderComponent } from './holiday-calender/holiday-calender.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MyAttendanceComponent } from './my-attendance/my-attendance.component';
import { NguCarouselModule } from '@ngu/carousel';
// import { NgbdProgressbarBasic } from './progressbar-basic';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);
import { IgxTimePickerModule } from 'igniteui-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MyReimbursementComponent } from './my-reimbursement/my-reimbursement.component';
import { MySalaryStructureComponent } from './my-finance/my-salary-structure/my-salary-structure.component';
import { MyItDeclarationsComponent } from './my-finance/my-it-declarations/my-it-declarations.component';
import { MyProposedDeclarationComponent } from './my-finance/my-it-declarations/my-proposed-declaration/my-proposed-declaration.component';
import { MyConfirmDeclarationComponent } from './my-finance/my-it-declarations/my-confirm-declaration/my-confirm-declaration.component';
import { ViewFileComponent } from 'src/app/@shared/components/view-file/view-file.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MyLeaveBalanceComponent } from './my-leave-balance/my-leave-balance.component';
import { RequestModule } from '../request/request.module';
import { ProfileGeoLocationComponent } from './profile/profile-geo-location/profile-geo-location.component';
import { CountUpModule } from 'ngx-countup';
import { ExpenseReceiptsComponent } from './expenses/expense-receipts/expense-receipts.component';
import { BulkExpenseComponent } from './expenses/bulk-expense/bulk-expense.component';
import { TripsComponent } from './expenses/trips/trips/trips.component';
import { TripsSetupComponent } from './expenses/trips/trips-setup/trips-setup.component';
import { TripDetailsComponent } from './expenses/trips/trips-setup/trip-details/trip-details.component';
import { TripItineraryComponent } from './expenses/trips/trips-setup/trip-itinerary/trip-itinerary.component';
import { TripReviewComponent } from './expenses/trips/trips-setup/trip-review/trip-review.component';
import { ExpenseReportsComponent } from './expenses/expense-reports/expense-reports.component';
import { ExpenseReportsSetupComponent } from './expenses/expense-reports-setup/expense-reports-setup.component';
import { ExpenseReportDetailsComponent } from './expenses/expense-reports-setup/expense-report-details/expense-report-details.component';
import { ExpenseReportExpenseReceiptComponent } from './expenses/expense-reports-setup/expense-report-expense-receipt/expense-report-expense-receipt.component';
import { ExpenseReportAdvanceDetailsComponent } from './expenses/expense-reports-setup/expense-report-advance-details/expense-report-advance-details.component';
import { ExpenseReportSummaryComponent } from './expenses/expense-reports-setup/expense-report-summary/expense-report-summary.component';
import { TicketsComponent } from './expenses/tickets/tickets.component';
import { AdvanceComponent } from './expenses/advance/advance.component';
import { DocumentRepositoryComponent } from './document-repository/document-repository.component';
import { AcknowledgeFileComponent } from './acknowledge-file/acknowledge-file.component';
import { MyAssetComponent } from './my-asset/my-asset.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MyLetterComponent } from './my-letter/my-letter.component';
import { MyLetterViewComponent } from './my-letter/my-letter-view/my-letter-view.component';

@NgModule({
  declarations: [
    ProfileComponent,
    SnapshotComponent,
    ProfessionalsComponent,
    EducationComponent,
    PersonalComponent,
    FamilyComponent,
    HealthInsuranceComponent,
    PreviousEmployerComponent,
    BankDetailsComponent,
    DocumentsComponent,
    CropperDialog,
    ProfileRightLoaderComponent,
    ChangePasswordComponent,
    PayslipComponent,
    TaxslipComponent,
    Form16Component,
    HolidayCalenderComponent,
    MyAttendanceComponent,
    MyReimbursementComponent,
    MySalaryStructureComponent,
    MyItDeclarationsComponent,
    MyProposedDeclarationComponent,
    MyConfirmDeclarationComponent,
    MyLeaveBalanceComponent,
    ProfileGeoLocationComponent,
    ExpenseReceiptsComponent,
    BulkExpenseComponent,
    TripsComponent,
    TripsSetupComponent,
    TripDetailsComponent,
    TripItineraryComponent,
    TripReviewComponent,
    ExpenseReportsComponent,
    ExpenseReportsSetupComponent,
    ExpenseReportDetailsComponent,
    ExpenseReportExpenseReceiptComponent,
    ExpenseReportAdvanceDetailsComponent,
    ExpenseReportSummaryComponent,
    TicketsComponent,
    AdvanceComponent,
    DocumentRepositoryComponent,
    AcknowledgeFileComponent,
    MyAssetComponent,
    MyLetterComponent,
    MyLetterViewComponent,
    // NgbdProgressbarBasic,


  ],

  exports: [ProfileComponent,
    // NgbdProgressbarBasic,
    ProfileComponent,
    SnapshotComponent,
    ProfessionalsComponent,
    EducationComponent,
    PersonalComponent,
    FamilyComponent,
    HealthInsuranceComponent,
    PreviousEmployerComponent,
    BankDetailsComponent,
    DocumentsComponent,
    MyAttendanceComponent

  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    FormsModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    NgSelectModule,
    MasterSelectModule,
    NgbModule,
    PdfViewerModule,
    AngularMyDatePickerModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDateModule,
    MatDatepickerModule,
    // MatNativeDateModule,
    MatCardModule,
    NgxChartsModule,
    NguCarouselModule,
    FullCalendarModule,

    IgxTimePickerModule,
    InfiniteScrollModule,
    Ng2SearchPipeModule,
    QRCodeModule,
    RequestModule,
    CountUpModule,
    NgxDocViewerModule,
 	CountUpModule,
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MMM/YYYY',
      clearLabel: 'Cancel'
    }),

  ],
  providers: [
    [LyTheme2],
    [StyleRenderer],
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    {
      provide: LY_THEME,
      useClass: MinimaLight,
      multi: true
    },
    {
      provide: LY_THEME,
      useClass: MinimaDark,
      multi: true
    },
    {
      provide: LY_THEME_GLOBAL_VARIABLES,
      useClass: GlobalVariables
    }, // global variables
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    DatePipe
  ],
})
export class ProfileModule { }


