import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { AppService } from 'src/app/app.global';
@Component({
  selector: 'app-unsupported-resolution',
  templateUrl: './unsupported-resolution.component.html',
  styleUrls: ['./unsupported-resolution.component.scss']
})
export class UnsupportedResolutionComponent implements OnInit {

  constructor(public router: Router, private authService: AuthService, public appservice: AppService) { }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    const lastVisitedUrl: any = localStorage.getItem('last_visited_url' + this.authService.isUserId);
    if (window.innerWidth < 850)
      this.router.navigate(['mobile-view']);
    else
      this.router.navigateByUrl(lastVisitedUrl);
  }
  ngOnInit(): void {
    this.getScreenSize()
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && !event.url.includes('mobile-view') && window.innerWidth < 850) {
        
        this.router.navigate(['mobile-view']);
      }
    })
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {

    this.getScreenSize();
  }
}
