<div class=" full-page text-center">
  <div class="vstack position-relative text-center align-items-center justify-content-center"
    style="max-height: calc(100vh - 13.75rem);">
    <div class="first-overlay">

      <div class="cloud large cloud-1">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud normal cloud-2">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud small cloud-3">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud tiny cloud-4">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud large cloud-5">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud normal cloud-6">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud small cloud-7">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud tiny cloud-8">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud small cloud-9">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud normal cloud-10">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud tiny cloud-11">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="cloud small cloud-12">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <!-- <img class="img-fluid cloud cloud1" src="/assets/images/bg/cloud1.png" alt="">
        <img class="img-fluid cloud cloud2" src="/assets/images/bg/cloud2.png" alt="">
        <img class="img-fluid cloud cloud3" src="/assets/images/bg/cloud3.png" alt=""> -->
    <div class="second-overlay">

      <img class="img-fluid main-bg mt-auto" src="/assets/images/bg/bg-4.png" alt="">
      <h6 class="fw-600 fs-18 text-white px-3">This is an unsupported resolution !</h6>
      <p class="fs-14 mb-0 text-white px-3"> Please download our mobile app</p>
      <img class="ground mt-auto img-fluid" src="/assets/images/bg/shadow.png" alt="">
    </div>
  </div>
  <div class="card card-c2 position-sticky w-100 bottom-0 py-24 px-16 radius-t-24 border-0 mt-auto">
    <img class="img-fluid mb-22" src="/assets/images/SkizzleHR-Logo/SkizzleHR-HorizontalLogo-Color.svg"
      style="height: 2.5rem;" alt="SkizzleHR Logo">
    <p class="text-uppercase text-center fs-12">GET IT ON</p>
    <div class="d-flex justify-content-center gap-16">
      <a href="https://apps.apple.com/us/app/skizzlehr/id6464050073" target="_blank">
        <img class="img-fluid " src="/assets/images/bg/appstore.png" style="height: 2.375rem;" alt="Apple Store">
      </a>
      <div class="vr h-100"></div>
      <a href="https://play.google.com/store/apps/details?id=com.skizzlehr" target="_blank">
        <img class="img-fluid " src="/assets/images/bg/playstore.png" style="height: 2.375rem;" alt="Play Store">
      </a>
    </div>
    <hr class="hr-1 my-16">
    <p class="text-center mb-0 text-light-400 ">
      <small>
        <a href="https://www.skizzlehr.tech/pages/privacy-policy.php" class="link-secondary" target="_blank">Privacy
          policy</a> |
        <a href="https://www.skizzlehr.tech/pages/terms-and-condition.php" class="link-secondary" target="_blank">Terms
          and conditions</a> | {{this.appservice.version}}
      </small>
    </p>
  </div>
</div>