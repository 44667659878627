import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.global';
​
@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {
  @Input() searchlist:any;
  @Input() searchplaceholder:any;
  @Input() isShowList:any;
  @Input() defaultFilterFunction:any = undefined
  @Input() isLoading = false
  @ViewChild('auto') auto:any;
  @Output() searchEmitter:any = new EventEmitter();
  @Output() keywordsearchEmitter:any = new EventEmitter();
  @Output() closesearchEmitter:any = new EventEmitter();
  @Output() enterClicked:any = new EventEmitter();

  keyword = 'name';
  bgcolor   =   '#324552'
  inputFocusClass = false;
  constructor(public appService:AppService ) { }
​
  ngOnInit() {

  }


  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }
  selectEvent(item :any) {
    this.searchEmitter.emit(item.id)
  }

  onChangeSearch(val: string) {
    this.keywordsearchEmitter.emit(val)
  }
  onCleared(e:any) {
    this.searchEmitter.emit('');
    this.closesearchEmitter.emit('');
  }
  clearBar(){
    this.auto.close();
    this.auto.clear();
  }
  addToKeywords(){
    this.enterClicked.emit()
  }

}
