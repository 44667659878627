import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppService } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  currentUser = {};
  NEEDPROFILEPIC:any = false
  MENULIST:any
  SIDEBARCLASS = ''
  MENUCREATED = false
  
  constructor(
    private http: HttpClient,
    public router: Router,
    public appService  : AppService
  ) {
  }
  // Sign-in
  signIn(user: any) {
    return this.http.post(`${this.API_PATH}api/v1/login/`, user, this.httpOption)
  }


  getToken() {
    return localStorage.getItem('access_token');
  }
  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }
  menuList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/user-page-access/`, this.httpOption)
  }

  getUserData() {
    var obj = localStorage.getItem('userData');
    var replacer = function(key:any, value:any) {
      return typeof value === 'undefined' ? null : value;
    }
    var result = JSON.parse(JSON.stringify(obj, replacer));

    return result;
  }


  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
    // return true ;
  }

  doLogout() {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('userData');
    let removeToken = localStorage.removeItem('access_token');
    this.appService.resetPermissions()
    this.SIDEBARCLASS = ''
    if (removeToken == null) {
      this.router.navigate(['login']);
    }
  }
  doSesstionOut() {
    localStorage.removeItem('access_token');
    this.router.navigate(['session-timeout']);
  }

/*Fetch currently logged user id*/
  get isUserId(): any {
    let Data = this.getUserData();
    if(Data!='undefined'){

      return (Data!=undefined && JSON.parse(Data).id != null) ? JSON.parse(Data).id : 0;
    }

  }

  get isEmployeeId(): number {
    let Data = this.getUserData();
    return (Data!=undefined && JSON.parse(Data).employee_id != null) ? JSON.parse(Data).employee_id : 0;
  }

  refresh() {
    let refreshToken = localStorage.getItem('refresh_token');
    return this.http.post<any>(`${this.API_PATH}api/token/refresh/`, {"refresh":refreshToken}, this.httpOption)
      .pipe(tap((res: any) => {
        localStorage.setItem('access_token', res.access);
        localStorage.setItem('refresh_token', res.refresh);
        return res.access;
      }));
  }

  resetPassword(data:any){
    return this.http.post(`${this.API_PATH}reset_password/`,data, this.httpOption)
  }
  changethemeupdate(theme:any){
    return this.http.put(`${this.API_PATH}general_settings/api/v1/theme-detail/`,{"name":theme}, this.httpOption)
  }

  themeget(){
    return this.http.get(`${this.API_PATH}general_settings/api/v1/theme-detail/`, this.httpOption)
  }
  gotoLastVisited(){
    const lastVisitedUrl: any = localStorage.getItem('last_visited_url'+this.isUserId);
    localStorage.removeItem('last_visited_url'+this.isUserId);
    if (lastVisitedUrl && this.appService.getPermissions(lastVisitedUrl)) {
        this.router.navigateByUrl(lastVisitedUrl);
    } else {
      this.router.navigate(['home/']);
    }
  }

  isSuperUser(){
    let Data = this.getUserData();
    return (Data!=undefined && JSON.parse(Data).is_superuser == true) ? true : false;
  }
}
