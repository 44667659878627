<div >
    <div class="container-fluid p-32  form-container">
      <div class="row row-16"  >
        <div class="col-12 mt-16" *ngIf="Isalert && employeeData.message!=''">
          <div role="alert" class="alert alert-warning mb-0 show">
              <p class="mb-0 fs-14">{{employeeData.message}}</p>
              <div class="btn-close pointer" (click)="Isalert=false;"></div>
          </div>
        </div>
          <div class="col-12">
              <div class="card details-card">
                <div class="card-body p-16 d-flex align-items-start">
                    <img *ngIf="employeeData.employee_data?.profile_image !=  null && employeeData.employee_data?.profile_image !=  ''" class="img-fluid avatar-circle sq-42 me-16"  src="{{employeeData.employee_data.profile_image}}" />

                    <div *ngIf="employeeData.employee_data?.profile_image ==  '' || employeeData.employee_data?.profile_image ==  null" class="avatar-circle avatar-border sq-42 me-16 {{employeeData.employee_data?.color_code}} fs-14">{{this.appService.getFirstChar(employeeData.employee_data?.first_name+" "+employeeData.employee_data?.last_name,2)}}</div>
                  <div class="d-flex gap-6 flex-column">
                      <div class="fs-14 fw-600">{{employeeData.employee_data?.first_name+" "+(employeeData.employee_data?.middle_name!=null?employeeData.employee_data?.middle_name:'')+" "+employeeData.employee_data?.last_name}} ({{employeeData.employee_data?.employee_code}})</div>
                      <div class="fs-12 fw-500 text-light-400">{{employeeData.employee_data?.designation_name}} | {{employeeData.employee_data?.company__company_name}}</div>
                  </div>
                  <div class=" ms-auto link-primary1 fs-12 fw-500" (click)="salaryRevisionHistory = true" > <i class="icon-eye me-1"></i> Salary Revision History</div>
              </div>
                  <div class="card-body p-16" *ngIf="employeeData.revision_timeline.length>0">

                    <ul class="timeline timeline-vetical" >
                        <li class="timeline-item" *ngFor="let timeline of employeeData.revision_timeline; let i=index;">
                            <div class="timeline-title bg{{i%5}}">
                              {{timeline?.created_at__year}}

                            </div>
                            <div class="timeline-content ">
                                {{timeline?.designation__name}}
                            </div>
                        </li>
                    </ul>

                </div>
                  <div class="card-body p-16">
                    <div class="row">
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">Current pay group</div>
                            <div class="detail-desc">{{employeeData.pay_group_name}}</div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">Current annual CTC</div>
                            <div class="detail-desc">{{employeeData.ctc| currency:employeeData.currency}}</div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">Current monthly CTC</div>
                            <div class="detail-desc">{{employeeData.monthly_payout| currency:employeeData.currency}}</div>
                        </div>
                    </div>
                  </div>
                  <div class="card-body p-16">
                    <div class="row">
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">New pay group</div>
                            <div class="detail-desc">{{stepperData?.paygrp}}</div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">Revised annual CTC</div>
                            <div class="detail-desc d-flex flex-wrap gap-8">
                                <span class="text-nowrap">{{stepperData.newCTC| currency:employeeData.currency}} </span>
                                <span class="text-nowrap {{stepperData.newCTC - employeeData.ctc > 0?'graph-increase text-success':'graph-decrease text-danger'}} ">{{(stepperData.newCTC - employeeData.ctc > 0?(stepperData.newCTC - employeeData.ctc | currency:employeeData.currency):(employeeData.ctc-stepperData.newCTC | currency:employeeData.currency))}}</span>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">Effective from </div>
                            <div class="detail-desc">{{stepperData.effective_from | date: 'MMMM'}} {{stepperData.effective_from | date: 'YYYY'}}</div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="detail-title">Pay month</div>
                            <div class="detail-desc">{{stepperData.salary_pay_month | date: 'MMMM'}} {{stepperData.salary_pay_month | date: 'YYYY'}}</div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
          <div class="col-12 fw-500 text-uppercase">Pay COMPONENTS </div>
          <div class="col-12">
            <div class="card card-c2 py-24 p-16">
                <div class="row row-24" [formGroup]="formGp">

                    <app-table-loader *ngIf="componentLoader"></app-table-loader>

                    <ng-container *ngIf="!componentLoader">

                        <div class="col-12" *ngIf="gross_benefits?.length>0">
                            <div class="fw-500">Gross benefits</div>
                        </div>
                        <div class="col-12" *ngIf="gross_benefits?.length>0">
                            <div class="card card-c2 ">
                                <div class="table-responsive">
                                    <table class="table table-striped form-table td-10 th-10 table-sm " formArrayName="gross_month">
                                        <thead>
                                            <tr>
                                                <th scope="col">Component name</th>
                                                <th scope="col">Nature</th>
                                                <th scope="col">Formula</th>
                                                <th scope="col">Monthly Pay</th>
                                                <th scope="col">Yearly Pay</th>
                                                <th scope="col">Months</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                          <ng-container *ngFor="let gross_month of month().controls; let i=index;" [formGroupName]="i">
                                            <tr *ngIf="gross_benefits[i]" class="{{gross_benefits[i]?.error==true?'td-alert':''}}">


                                                <input type="hidden" formControlName="comp_id" >
                                                <td>{{gross_benefits[i]?.name}}</td>
                                                <td>{{gross_benefits[i]?.nature==true?'Fixed':'Variable'}}</td>
                                                <td>{{gross_benefits[i]?.formula}}</td>
                                                <td>
                                                  <ng-container *ngIf="(gross_benefits[i]?.component_type_name!='other allowance' && gross_benefits[i]?.error==false && gross_benefits[i]?.is_runtime_tag==false);else otherallowance " >
                                                    <div class="form-icon {{gross_benefits[i].fbp_amount>0?'icon-right':''}}" [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('monthly_renum')?.touched || month().controls[i]?.get('monthly_renum')?.dirty) &&  month().controls[i]?.get('monthly_renum')?.errors}" >
                                                    <input type="text" class="form-control" formControlName="monthly_renum" [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('monthly_renum')?.touched || month().controls[i]?.get('monthly_renum')?.dirty) &&  month().controls[i]?.get('monthly_renum')?.errors}" (keyup)="monthlyValidator(i,'gross_benefits')" />
                                                    <ng-container *ngIf="gross_benefits[i].fbp_amount>0">

                                                        <ng-template #popContent>
                                                            <div class="d-flex flex-column flex-center" style="">
                                                                <span class="fs-14 text-center">The value is kept 0 by default until the employee declare an amount for the component.</span>
                                                                <span class="fs-12">
                                                                    <span class="" style="color:#C3CBD1;"> Max amount based on pay group:</span>
                                                                    <span class="fw-500"> {{gross_benefits[i].fbp_amount | currency: employeeData?.currency}}</span>
                                                                </span>
                                                            </div>
                                                            </ng-template>
                                                      <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                                                    </ng-container>
                                                    </div>
                                                    <div *ngIf="(month().controls[i]?.get('monthly_renum')?.touched || submitted || month().controls[i]?.get('monthly_renum')?.dirty) && month().controls[i]?.get('monthly_renum')?.errors" class="invalid-feedback">

                                                      <div *ngIf="month().controls[i]?.get('monthly_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                      <div *ngIf="month().controls[i]?.get('monthly_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                      <div *ngIf="(!(month().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  month().controls[i]?.get('monthly_renum')?.errors?.maxlength)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                      <div *ngIf="(!(month().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  !(month().controls[i]?.get('monthly_renum')?.errors?.maxlength) && !(month().controls[i]?.get('monthly_renum')?.errors?.required) && month().controls[i]?.get('monthly_renum')?.errors?.max )">{{this.messageService.monthlyAmountValidation()}}</div>
                                                      <div *ngIf="(!(month().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  !(month().controls[i]?.get('monthly_renum')?.errors?.maxlength) && !(month().controls[i]?.get('monthly_renum')?.errors?.required) && !(month().controls[i]?.get('monthly_renum')?.errors?.max) && month().controls[i]?.get('monthly_renum')?.errors?.fbplimit )">{{this.messageService.limitAmountValidation()}}</div>

                                                    </div>
                                                  </ng-container>
                                                  <ng-template #otherallowance>
                                                    <span class=" ms-8" *ngIf="gross_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                    {{gross_benefits[i]?.monthly_renum | currency: employeeData?.currency}}
                                                  </ng-template>
                                                </td>
                                                <!-- <td>
                                                  <span class=" ms-8" *ngIf="gross_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                  {{gross_benefits[i]?.year_renum | currency: employeeData?.currency}}</td> -->

                                                  <td style="max-width: 12.5rem;" >
                                                    <ng-container *ngIf="(gross_benefits[i]?.component_type_name
                                                    !='other allowance' && gross_benefits[i]?.error==false && gross_benefits[i]?.is_runtime_tag==false);else otherallowanceYear " >

                                                  <div class="form-icon" [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('year_renum')?.touched || month().controls[i]?.get('year_renum')?.dirty) &&  month().controls[i]?.get('year_renum')?.errors}">
                                                      <input  type="text" class="form-control" formControlName="year_renum"
                                                      [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('year_renum')?.touched || month().controls[i]?.get('year_renum')?.dirty) &&  month().controls[i]?.get('year_renum')?.errors}" (keyup)="yearlyValidator(i,'gross_benefits')"
                                                      >
                                                      <!-- <ng-container *ngIf="gross_benefits[i].fbp_amount>0">

                                                        <ng-template #popContent>
                                                          <div class="d-flex flex-column flex-center" style="">
                                                              <span class="fs-14 text-center">The value is kept 0 by default until the employee declare an amount for the component.</span>
                                                              <span class="fs-12">
                                                                  <span class="" style="color:#C3CBD1;"> Max amount based on pay group:</span>
                                                                  <span class="fw-500"> {{gross_benefits[i].fbp_amount | currency: employeeData?.currency}}</span>
                                                              </span>
                                                          </div>
                                                         </ng-template>
                                                       <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-info" container="body" triggers="hover"></i>
                                                      </ng-container> -->
                                                  </div>
                                                      <div *ngIf="(month().controls[i]?.get('year_renum')?.touched || submitted || month().controls[i]?.get('year_renum')?.dirty) && month().controls[i]?.get('year_renum')?.errors" class="invalid-feedback">

                                                        <div *ngIf="month().controls[i]?.get('year_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                        <div *ngIf="month().controls[i]?.get('year_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                        <div *ngIf="(!(month().controls[i]?.get('year_renum')?.errors?.pattern) &&  month().controls[i]?.get('year_renum')?.errors?.maxlength)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                        <div *ngIf="(!(month().controls[i]?.get('year_renum')?.errors?.pattern) &&  !(month().controls[i]?.get('year_renum')?.errors?.maxlength) && !(month().controls[i]?.get('year_renum')?.errors?.required) && month().controls[i]?.get('year_renum')?.errors?.max )">{{this.messageService.monthlyAmountValidation()}}</div>
                                                        <div *ngIf="(!(month().controls[i]?.get('year_renum')?.errors?.pattern) &&  !(month().controls[i]?.get('year_renum')?.errors?.maxlength) && !(month().controls[i]?.get('year_renum')?.errors?.required) && !(month().controls[i]?.get('year_renum')?.errors?.max) && month().controls[i]?.get('year_renum')?.errors?.fbplimit )">{{this.messageService.limitAmountValidation()}}</div>

                                                      </div>

                                                    </ng-container>
                                                    <ng-template #otherallowanceYear>

                                                      <span class=" ms-8" *ngIf="gross_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span> {{gross_benefits[i]?.year_renum | currency: employeeData.currency}}
                                                    </ng-template>

                                                  </td>
                                                <td class="form-cell" >


                                                    <ng-select style="width: 8.75rem;" appendTo="body" placeholder="{{this.messageService.selectplaceholddisplay('Month')}}" formControlName="gross_month" [items]="moduleDropdown"[multiple]="true" bindLabel="value" [closeOnSelect]="false" bindValue="id" class="form-ngselect1" *ngIf="gross_benefits[i]?.nature==false" #gross [ngClass]="{ 'is-invalid': (submitted || month().controls[i]?.get('gross_month')?.touched) &&  month().controls[i]?.get('gross_month')?.errors}" (change)="CalculateMonthlyPay(i,'gross_month')">
                                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                            <ul class="selected-values">

                                                                <li class="ng-value" *ngFor="let item of month().controls[i].value.gross_month, let m =index">
                                                                    <ng-container *ngFor="let insideitem of moduleDropdown, let k =index">
                                                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                                        {{ (insideitem['value'].length>15)? (insideitem['value'] | slice:0:15)+'..':(insideitem['value'])  }}</span>
                                                                    </ng-container>
                                                                    <span class="ng-value-icon right" aria-hidden="true" (click)="RemoveData(m,'gross_month',i,gross)"><i class="icon-close fs-16"></i></span>
                                                                </li>
                                                            </ul>
                                                        </ng-template>
                                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                        {{ item.value }}
                                                        </ng-template>
                                                    </ng-select>

                                                    <div *ngIf="(month().controls[i]?.get('gross_month')?.touched || submitted) && month().controls[i]?.get('gross_month')?.errors" class="invalid-feedback">

                                                    <div *ngIf="month().controls[i]?.get('gross_month')?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                                    </div>
                                                    </div>


                                                </td>
                                            </tr>
                                          </ng-container>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="other_benefits.length>0">
                            <div class="fw-500">Other benefits</div>
                        </div>
                        <div class="col-12" *ngIf="other_benefits.length>0">
                            <div class="card card-c2 ">
                                <div class="table-responsive">
                                    <table class="table table-striped form-table td-10 th-10 table-sm " formArrayName="other_month">
                                        <thead>
                                            <tr>
                                                <th scope="col">Component name</th>
                                                <th scope="col">Nature</th>
                                                <th scope="col">Formula</th>
                                                <th scope="col">Monthly Pay</th>
                                                <th scope="col">Yearly Pay</th>
                                                <th scope="col">Months</th>
                                            </tr>
                                        </thead>
                                        <tbody >

                                          <ng-container *ngFor="let other_month of otherMonth().controls; let i=index;" [formGroupName]="i">
                                            <tr class="{{other_benefits[i]?.error==true?'td-alert':''}}">
                                                <input type="hidden" formControlName="comp_id" >

                                                <td>{{other_benefits[i]?.name}}</td>
                                                <td>{{other_benefits[i]?.nature==true?'Fixed':'Variable'}}</td>
                                                <td>{{other_benefits[i]?.formula}}</td>
                                                <td>
                                                  <ng-container *ngIf="other_benefits[i]?.error==false;else invalid_section">

                                                    <div class="form-icon {{other_benefits[i].fbp_amount>0?'icon-right':''}}" [ngClass]="{ 'is-invalid': (submitted || otherMonth().controls[i]?.get('monthly_renum')?.touched || otherMonth().controls[i]?.get('monthly_renum')?.dirty) &&  otherMonth().controls[i]?.get('monthly_renum')?.errors}">
                                                    <input type="text" class="form-control" formControlName="monthly_renum" [ngClass]="{ 'is-invalid': (submitted || otherMonth().controls[i]?.get('monthly_renum')?.touched || otherMonth().controls[i]?.get('monthly_renum')?.dirty) &&  otherMonth().controls[i]?.get('monthly_renum')?.errors}" (keyup)="monthlyValidator(i,'other_benefits')" />
                                                    <ng-container *ngIf="other_benefits[i].fbp_amount>0">
                                                      <ng-template #popContent>
                                                        <div class="d-flex flex-column flex-center" style="">
                                                            <span class="fs-14 text-center">The value is kept 0 by default until the employee declare an amount for the component.</span>
                                                            <span class="fs-12">
                                                                <span class="" style="color:#C3CBD1;"> Max amount based on pay group:</span>
                                                                <span class="fw-500">{{other_benefits[i].fbp_amount | currency: employeeData?.currency}}</span>
                                                            </span>
                                                        </div>
                                                      </ng-template>
                                                      <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                                                    </ng-container>
                                                </div>
                                                    <div *ngIf="(submitted || otherMonth().controls[i]?.get('monthly_renum')?.touched) || otherMonth().controls[i]?.get('monthly_renum')?.dirty || submitted&& otherMonth().controls[i]?.get('monthly_renum')?.errors" class="invalid-feedback" >
                                                    <div *ngIf="otherMonth().controls[i]?.get('monthly_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                    <div *ngIf="otherMonth().controls[i]?.get('monthly_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                    <div *ngIf="(!(otherMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  otherMonth().controls[i]?.get('monthly_renum')?.errors?.maxlength)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                    <div *ngIf="(!(otherMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) && !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.required) && !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.maxlength) && otherMonth().controls[i]?.get('monthly_renum')?.errors?.max)">{{this.messageService.monthlyAmountValidation()}}</div>
                                                    <div *ngIf="(!(otherMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.maxlength) && !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.required) && !(otherMonth().controls[i]?.get('monthly_renum')?.errors?.max) && otherMonth().controls[i]?.get('monthly_renum')?.errors?.fbplimit )">{{this.messageService.limitAmountValidation()}}</div>

                                                  </div>
                                                  </ng-container>

                                                  <ng-template #invalid_section>
                                                    <span class=" ms-8" *ngIf="other_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>

                                                    {{other_benefits[i]?.monthly_renum  | currency: employeeData?.currency}}
                                                </ng-template>
                                                </td>
                                                <!-- <td>
                                                  <span class=" ms-8" *ngIf="other_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>

                                                  {{other_benefits[i]?.year_renum | currency: employeeData?.currency}}</td> -->
                                                  <td  style="max-width: 12.5rem;">
                                                    <ng-container *ngIf="other_benefits[i]?.error==false;else invalid_section_year">
                                                      <div class="form-icon" [ngClass]="{ 'is-invalid': (otherMonth().controls[i]?.get('year_renum')?.touched || otherMonth().controls[i]?.get('year_renum')?.dirty || submitted) &&  otherMonth().controls[i]?.get('year_renum')?.errors}">
                                                      <input type="text"class="form-control" formControlName="year_renum" [ngClass]="{ 'is-invalid': (otherMonth().controls[i]?.get('year_renum')?.touched || otherMonth().controls[i]?.get('year_renum')?.dirty || submitted) &&  otherMonth().controls[i]?.get('year_renum')?.errors}" (keyup)="yearlyValidator(i,'other_benefits')">

                                                      <!-- <ng-container *ngIf="other_benefits[i].fbp_amount>0">
                                                        <ng-template #popContent>
                                                          <div class="d-flex flex-column flex-center" >
                                                              <span class="fs-14 text-center">The value is kept 0 by default until the employee declare an amount for the component.</span>
                                                              <span class="fs-12">
                                                                  <span class="" style="color:#C3CBD1;"> Max amount based on pay group:</span>
                                                                  <span class="fw-500"> {{other_benefits[i].fbp_amount | currency: employeeData?.currency}}</span>
                                                              </span>
                                                          </div>
                                                          </ng-template>
                                                        <i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" [ngbPopover]="popContent" popoverClass="popover-default popover-c2" container="body" triggers="hover"></i>
                                                      </ng-container> -->
                                                  </div>
                                                      <div *ngIf="(submitted || otherMonth().controls[i]?.get('year_renum')?.touched) || otherMonth().controls[i]?.get('year_renum')?.dirty || submitted&& otherMonth().controls[i]?.get('year_renum')?.errors" class="invalid-feedback" >
                                                        <div *ngIf="otherMonth().controls[i]?.get('year_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                        <div *ngIf="otherMonth().controls[i]?.get('year_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                        <div *ngIf="(!(otherMonth().controls[i]?.get('year_renum')?.errors?.pattern) &&  otherMonth().controls[i]?.get('year_renum')?.errors?.maxlength)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                        <div *ngIf="(!(otherMonth().controls[i]?.get('year_renum')?.errors?.pattern) && !(otherMonth().controls[i]?.get('year_renum')?.errors?.required) && !(otherMonth().controls[i]?.get('year_renum')?.errors?.maxlength) && otherMonth().controls[i]?.get('year_renum')?.errors?.max)">{{this.messageService.monthlyAmountValidation()}}</div>
                                                        <div *ngIf="(!(otherMonth().controls[i]?.get('year_renum')?.errors?.pattern) &&  !(otherMonth().controls[i]?.get('year_renum')?.errors?.maxlength) && !(otherMonth().controls[i]?.get('year_renum')?.errors?.required) && !(otherMonth().controls[i]?.get('year_renum')?.errors?.max) && otherMonth().controls[i]?.get('year_renum')?.errors?.fbplimit )">{{this.messageService.limitAmountValidation()}}</div>

                                                      </div>

                                                  </ng-container>
                                                  <ng-template #invalid_section_year>
                                                    <span class=" ms-8" *ngIf="other_benefits[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                      {{other_benefits[i]?.year_renum  | currency: employeeData?.currency}}
                                                  </ng-template>
                                                  </td>
                                                <td class="form-cell">
                                                    <ng-select #ngSelecter style="width: 8.75rem;" appendTo="body"  placeholder="{{this.messageService.selectplaceholddisplay('Month')}}" formControlName="other_month" [items]="moduleDropdown"[multiple]="true" bindLabel="value" [closeOnSelect]="false" bindValue="id" class="form-ngselect1 " *ngIf="other_benefits[i]?.nature==false" #other [ngClass]="{ 'is-invalid': (otherMonth().controls[i]?.get('other_month')?.touched || submitted) &&  otherMonth().controls[i]?.get('other_month')?.errors}" (change)="CalculateMonthlyPay(i,'other_month')">
                                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                            <ul class="selected-values">

                                                                <li class="ng-value" *ngFor="let item of otherMonth().controls[i].value.other_month, let m =index">
                                                                    <ng-container *ngFor="let insideitem of moduleDropdown, let k =index">
                                                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                                        {{ (insideitem['value'].length>15)? (insideitem['value'] | slice:0:15)+'..':(insideitem['value'])  }}</span>
                                                                    </ng-container>
                                                                    <span class="ng-value-icon right" aria-hidden="true" (click)="RemoveData(m,'other_month',i,other)"><i class="icon-close fs-16"></i></span>
                                                                </li>
                                                            </ul>
                                                        </ng-template>
                                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                        {{ item.value }}
                                                        </ng-template>
                                                    </ng-select>
                                                    <div *ngIf="(submitted || otherMonth().controls[i]?.get('other_month')?.touched) && otherMonth().controls[i]?.get('other_month')?.errors" class="invalid-feedback">
                                                    <div *ngIf="otherMonth().controls[i]?.get('other_month')?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                                    </div>
                                                    </div>
                                                </td>
                                            </tr>
                                          </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="contributions.length>0">
                            <div class="fw-500">Contribution/Retirals</div>
                        </div>
                        <div class="col-12" *ngIf="contributions.length>0">
                            <div class="card card-c2 ">
                                <div class="table-responsive">
                                    <table class="table table-striped td-10 th-10  table-sm ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Component name</th>

                                                <th scope="col">Monthly remuneration</th>
                                                <th scope="col">Yearly remuneration</th>

                                            </tr>
                                        </thead>
                                        <tbody >

                                          <ng-container *ngFor="let contribution of contributions">

                                            <tr class="{{contribution?.error==true?'td-alert':''}}">

                                                <td>{{contribution?.name}}</td>

                                                <td>
                                                  <span class=" ms-8" *ngIf="contribution?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                  {{contribution?.monthly_renum | currency: employeeData?.currency}}
                                                </td>
                                                <td>
                                                  <span class=" ms-8" *ngIf="contribution?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                  {{contribution?.year_renum | currency: employeeData?.currency}}
                                                </td>
                                            </tr>
                                          </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="recurring_deductions.length>0">
                            <div class="fw-500">Recurring deductions</div>
                        </div>
                        <div class="col-12" *ngIf="recurring_deductions.length>0">
                            <div class="card card-c2 ">
                                <div class="table-responsive">
                                    <table class="table table-striped form-table td-10 th-10 table-sm " formArrayName="recurring_month">
                                        <thead>
                                            <tr>
                                                <th scope="col">Component name</th>
                                                <th scope="col">Nature</th>
                                                <th scope="col">Formula</th>
                                                <th scope="col">Monthly Pay</th>
                                                <th scope="col">Yearly Pay</th>
                                                <th scope="col">Months</th>
                                            </tr>
                                        </thead>

                                        <tbody >

                                          <ng-container  *ngFor="let recurring_month of recurringMonth().controls; let i=index; " [formGroupName]="i">
                                            <tr class="{{recurring_deductions[i]?.error==true?'td-alert':''}}">

                                                <td>{{recurring_deductions[i]?.name}} </td>
                                                <td>{{recurring_deductions[i]?.nature==true?'Fixed':recurring_deductions[i]?.nature==false?'Variable':''}}</td>
                                                <td>{{recurring_deductions[i]?.formula}}</td>
                                                <!-- <td>
                                                  <span class=" ms-8" *ngIf="recurring_deductions[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                  {{recurring_deductions[i]?.monthly_renum | currency: employeeData?.currency}}
                                                </td>
                                                <td>
                                                  <span class=" ms-8" *ngIf="recurring_deductions[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                  {{recurring_deductions[i]?.year_renum | currency: employeeData?.currency}}
                                                </td> -->
                                                <td>
                                                  <span class=" ms-8" *ngIf="recurring_deductions[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                  <ng-container *ngIf="recurring_deductions[i]?.contribution==null" >
                                                      <div  [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('monthly_renum')?.touched || recurringMonth().controls[i]?.get('monthly_renum')?.dirty) &&  recurringMonth().controls[i]?.get('monthly_renum')?.errors}">
                                                          <input  type="text" class="form-control" formControlName="monthly_renum"
                                                          [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('monthly_renum')?.touched || recurringMonth().controls[i]?.get('monthly_renum')?.dirty) &&  recurringMonth().controls[i]?.get('monthly_renum')?.errors}"  (keyup)="monthlyRDValidator(i)">                                                           
                                                      </div>
                                                      <div *ngIf="(recurringMonth().controls[i]?.get('monthly_renum')?.touched || submitted || recurringMonth().controls[i]?.get('monthly_renum')?.dirty) && recurringMonth().controls[i]?.get('monthly_renum')?.errors" class="invalid-feedback">
                                                            <div *ngIf="recurringMonth().controls[i]?.get('monthly_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                            <div *ngIf="recurringMonth().controls[i]?.get('monthly_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                            <div *ngIf="(!(recurringMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) &&  recurringMonth().controls[i]?.get('monthly_renum')?.errors?.maxlength)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                            <div *ngIf="(!(recurringMonth().controls[i]?.get('monthly_renum')?.errors?.pattern) && !(recurringMonth().controls[i]?.get('monthly_renum')?.errors?.required) && !(recurringMonth().controls[i]?.get('monthly_renum')?.errors?.maxlength) && recurringMonth().controls[i]?.get('monthly_renum')?.errors?.max)">{{this.messageService.deductionAmountValidation()}}</div>
                                                      </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="recurring_deductions[i]?.contribution!=null " >
                                                  {{recurring_deductions[i]?.monthly_renum | currency: employeeData.currency}}
                                              </ng-container></td>
                                              <td>
                                                  <span class=" ms-8" *ngIf="recurring_deductions[i]?.error"><i class="bi bi-info-circle text-danger fs-14 tooltip-icon" ngbPopover="The formula is incorrect, kindly update the formula to save the salary structure." popoverClass="popover-default popover-alert" container="body" triggers="hover"></i></span>
                                                  <ng-container *ngIf="recurring_deductions[i]?.contribution==null " >
                                                      <div  [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('year_renum')?.touched || recurringMonth().controls[i]?.get('year_renum')?.dirty) &&  recurringMonth().controls[i]?.get('year_renum')?.errors}">
                                                          <input  type="text" class="form-control" formControlName="year_renum"
                                                          [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('year_renum')?.touched || recurringMonth().controls[i]?.get('year_renum')?.dirty) &&  recurringMonth().controls[i]?.get('year_renum')?.errors}"  (keyup)="yearlyRDValidator(i)">                                                           
                                                      </div>
                                                      <div *ngIf="(recurringMonth().controls[i]?.get('year_renum')?.touched || submitted || recurringMonth().controls[i]?.get('year_renum')?.dirty) && recurringMonth().controls[i]?.get('year_renum')?.errors" class="invalid-feedback">
                                                            <div *ngIf="recurringMonth().controls[i]?.get('year_renum')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                                                            <div *ngIf="recurringMonth().controls[i]?.get('year_renum')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                                                            <div *ngIf="(!(recurringMonth().controls[i]?.get('year_renum')?.errors?.pattern) &&  recurringMonth().controls[i]?.get('year_renum')?.errors?.maxlength)">{{this.messageService.fieldlengthvalidation('num',10)}}</div>
                                                            <div *ngIf="(!(recurringMonth().controls[i]?.get('year_renum')?.errors?.pattern) && !(recurringMonth().controls[i]?.get('year_renum')?.errors?.required) && !(recurringMonth().controls[i]?.get('year_renum')?.errors?.maxlength) && recurringMonth().controls[i]?.get('year_renum')?.errors?.max)">{{this.messageService.deductionAmountValidation()}}</div>
                                                      </div>
                                                  </ng-container>
                                                  <ng-container *ngIf="recurring_deductions[i]?.contribution!=null " >
                                                  {{recurring_deductions[i]?.year_renum | currency: employeeData.currency}}
                                                  </ng-container>
                                              </td>
                                                <td class="form-cell">

                                                    <ng-select  style="width: 8.75rem;" appendTo="body" placeholder="{{this.messageService.selectplaceholddisplay('Month')}}" formControlName="recurring_month" [items]="moduleDropdown"[multiple]="true" bindLabel="value" [closeOnSelect]="false" bindValue="id" class="form-ngselect1" *ngIf="recurring_deductions[i]?.nature==false" #recurring [ngClass]="{ 'is-invalid': (submitted || recurringMonth().controls[i]?.get('recurring_month')?.touched) &&  recurringMonth().controls[i]?.get('recurring_month')?.errors}" (change)="CalculateMonthlyPay(i,'recurring_month')">
                                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                            <ul class="selected-values">

                                                                <li class="ng-value" *ngFor="let item of recurringMonth().controls[i].value.recurring_month, let m =index">
                                                                    <ng-container *ngFor="let insideitem of moduleDropdown, let k =index">
                                                                    <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                                                        {{ (insideitem['value'].length>15)? (insideitem['value'] | slice:0:15)+'..':(insideitem['value'])  }}</span>
                                                                    </ng-container>
                                                                    <span class="ng-value-icon right" aria-hidden="true" (click)="RemoveData(m,'recurring_month',i,recurring)"><i class="icon-close fs-16"></i></span>
                                                                </li>
                                                            </ul>
                                                        </ng-template>
                                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                        {{ item.value }}
                                                        </ng-template>
                                                    </ng-select>
                                                    <div *ngIf="(recurringMonth().controls[i]?.get('recurring_month')?.touched || submitted) && recurringMonth().controls[i]?.get('recurring_month')?.errors" class="invalid-feedback">
                                                    <div *ngIf="recurringMonth().controls[i]?.get('recurring_month')?.errors?.required">{{this.messageService.validationDisplay('required')}}
                                                    </div>
                                                    </div>
                                                </td>
                                            </tr>
                                          </ng-container>

                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                          <div  role="alert" class="alert alert-info mb-0 show">
                              <p class="mb-0 "><span class="fs-14rem fw-500">There are components that are not part of CTC but added in salary structure which are  </span><span class="fw-600 text-accent2"> Shift Allowance, Bonus, Tips, Incentive</span> </p>
                              <!-- <div class="btn-close" ></div> -->
                            </div>
                        </div>
                        <div class="col-12 d-flex fw-500"> <span class="">Gross pay</span><span class="ms-auto">{{stepperData?.gross_amount | currency: employeeData?.currency}}</span> </div>
                        <div class="col-12 d-flex fw-500"> <span class="">Net take home (excluding TDS)</span><span class="ms-auto">{{stepperData?.net_gross | currency: employeeData?.currency}}</span> </div>
                        <!-- <div class="col-12">
                            <div class="fw-500">Gross</div>
                        </div>
                        <div class="col-12 form-row">
                            <label for="" class="form-label required">Net take home (excluding TDS) </label>
                            <input  type="text" autocomplete="off" formControlName="gross_amount" name="name" id="name" placeholder="{{this.messageService.placeholderdisp('Net take home')}}"
                                class="form-control">
                        </div> -->
                    </ng-container>
                </div>
            </div>
          </div>

      </div>
    </div>
    <footer class="submit-footer">
      <button class="btn btn-primary btn-sm ms-auto" (click)="submit()"  [disabled]="isCompleted || formGp.invalid || isRecalculated">Save</button>
    </footer>
  </div>
<!-- SALARY REVISION HISTORY VIEW START -->
<div class="side-panel" style="--sidepanel-width:59.0625rem;" [class.side-pane-active]='salaryRevisionHistory === true'>
    <div class="side-panel-container">
      <header class="side-panel-head">
          <h5>Salary revision history</h5>
          <a class="toggle-panel" (click)="salaryRevisionHistory = false"><i class=" icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <app-salary-revision-history-view  *ngIf='salaryRevisionHistory === true' [employeeId]="employeeData.employee" [buttonActivate]="false"></app-salary-revision-history-view>
      </div>
    </div>
  </div>
<!-- SALARY REVISION HISTORY VIEW START -->
