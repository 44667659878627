import {  ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InboxService } from 'src/app/@core/services/inbox.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.global';
// import { CityService } from 'src/app/@core/services/city.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { Subject } from 'rxjs';
import { delay,switchMap  } from 'rxjs/operators';
@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit {
  btndisable:any;
  empId: any;
  inboxList: any = [];
  req_type: any = '';
  starred:any = "";
  userArray: any = [];
  userDetails: any = [];
  scroll = false;
  nodata = false;
  inboxData :any = [];
  defaultlimit = 10;
  offset = 0;
  infinityloader  = false;
  listlength = 0;
  direction = '';
  salarystructurecard       = false;
  employeeapprovalcard      = false;
  inboxdata:any       = [];
  inboxDetailsList:any = [];
  selectedmenu:any    = 1;
  selectedtopmenu:any = 1;
  //Get count of items
  alldatalength       = 0
  pdatalength         = 0
  cdatalength         = 0
  sdatalength         = 0
  hdatalength         = 0
  edatalength         = 0
  ddatalength         = 0
  ldatalength         = 0
  rdatalength         = 0
  revisionlength      = 0
  remLen              = 0
  fbpLen              = 0
  ffLen               = 0
  ffALen = 0
  onDutyLen = 0
  attRegLen = 0
  overTimeLen         = 0;
  wrokfrmLen = 0;
  compOffLen          = 0;
  checkInLen          = 0;
  assetLen            = 0;
  manageAssetLen      = 0;
  expAdv = 0
  disAdv = 0
  expRepCnt = 0
  LetterLen          = 0;
  terLetterLen=0;
  probationLetterLen=0;
  relievingLetterLen=0;
  appointmenLetterLen=0;
  revisionLetterLen=0;
  LetterreqLen    =0; 
  SHLCnt = 0

  investmentSingleData:any = [];
  hra80ggSingleData:any = [];
  cahpt80CSingleData:any = [];
  otherSourceSingleData:any = [];
  deductionSingleData:any = [];
  documentSingleData:any
  viewHeader = '';
  cityName:any = '';
  requestitem:any   = [];
  modalToggle                 = false;
  currentDocName              = "";
  currentExtension            = "";
  allowedExtension            = "";
  fileName:any;
  alertToggle              = false;
  investalertMsg:any       = '';
  investmentcard1          = false;
  archeived_status         = false;
  search_value             = '';
  flag = 0;
  action:any=1;
  activeItem = 0;
  inboxLoader= true;
  selectedDate: any;
  selected: any;
  config={
    format: "DD-MM-YYYY",
    cancelLabel: 'Cancel',
  }
  searchData : Subject<void> = new Subject();
  alwaysShowCalendars: any;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  @ViewChild('search') search !: ElementRef;


  isInvalidDate = (m: moment.Moment) =>  {
  }

  constructor(
    // private cityService:CityService,
    private datePipe:DatePipe,
    public appservice:AppService,
    private inboxservice:InboxService,
    public breakpointObserver: BreakpointObserver,
    private authService:AuthService,
  ) {
    this.empId         = this.authService.isEmployeeId;
    this.inboxLiveUpdate();
    this.inboxCancelllationUpdate();
  }

  ngOnInit(): void {
    this.initFunction();
    this.inboxservice.InboxSocket();
    this.inboxservice.InboxCancellationSocket()

     this.searchData.pipe(delay(1000),
        switchMap(() =>   this.inboxservice.getInboxData(this.req_type,this.starred,this.archeived_status,this.search_value,this.selectedDate,this.offset,this.defaultlimit)
      )).subscribe((res: any) => {
        if(res.results){
          let result = res.results;
              for(let i=0;i<result.length;i++){
                // console.log(new Date(result[i]['created_at']).setHours(0,0,0,0))
                // console.log(new Date().setHours(0,0,0,0))
                // if(new Date(result[i]['created_at']).setHours(0,0,0,0) == new Date().setHours(0,0,0,0)) {
                //   result[i]['created_display'] =this.appservice.timeFormatDisplay(result[i]['created_at'])
                //   // result[i]['created_display'] = moment(String(this.datePipe.transform(result[i]['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()
                // } else {
                //   result[i]['created_display'] =this.appservice.dateFormatDisplay(result[i]['created_at'])
                //   // result[i]['created_display'] = moment(String(this.datePipe.transform(result[i]['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()
                // }

                if(new Date(result[i]['created_at']).setHours(0,0,0,0) == new Date().setHours(0,0,0,0)) {
                  result[i]['list_created_display'] = this.datePipe.transform(result[i]['created_at'], 'hh:mm a')?.toString()
                  result[i]['created_display'] = this.datePipe.transform(result[i]['created_at'], 'hh:mm a')?.toString() + " (" +moment(String(this.datePipe.transform(result[i]['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()+ ")"
              }
                else if(new Date(result[i]['created_at']).getFullYear() == new Date().getFullYear()){
                  result[i]['list_created_display'] = this.datePipe.transform(result[i]['created_at'], 'MMM dd')?.toString()
                  result[i]['created_display'] = this.datePipe.transform(result[i]['created_at'], 'E, MMM dd, YYYY, hh:mm a')?.toString() + " (" + moment(String(this.datePipe.transform(result[i]['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()+ ")"
                }
                else {
                  result[i]['list_created_display'] = this.datePipe.transform(result[i]['created_at'], 'dd/MM/yy')?.toString()
                  result[i]['created_display'] = this.datePipe.transform(result[i]['created_at'], 'E, MMM dd, YYYY, hh:mm a')?.toString()
                }

              }
          if (res.results.length == 0) {
            this.listlength  = 0;
            if(this.offset ==0){
              this.inboxdata = [];
            }
          } else {
            this.listlength = res.results.length;
            if(this.offset ==0){
              this.inboxdata = [];
            }
            this.inboxDetailsList = res.results;
            this.addItems(this.listlength);
          }
          this.infinityloader = false;
          if(this.inboxdata.length > 0){
            this.tabCardClick(this.inboxdata[0],0);
            this.nodata=false;
            this.scroll = false;
          }
          else{
            this.nodata=true;
          }
          this.inboxLoader =false;
        }
      });
  }

  initFunction(){
    this.inboxdata = [];
    this.getCount();
    this.offset = 0;
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 10;

      }
    });
    this.getDateFormat().then(() => {this.getInboxList(this.selectedmenu,1);})
  }
  inboxCancelllationUpdate(){
    this.inboxservice.cancellation_item.subscribe(result =>{
      if(this.inboxdata[this.activeItem]?.id == result?.event?.id ){
        this.tabCardClick(this.inboxdata[this.activeItem],this.activeItem);
      }
    })
  }
  updateDate(){
    // Updated the code with time formatting too
   let message = this.inboxdata[0].message
    message = message.replace(/\d{2}([-])\d{2}\1\d{4}/g, (dateString:any) => {
      let ndateString = dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1");
      return this.dateFormatDisplay(ndateString);
    });
    message = message.replace(/\b\d{2}:\d{2}:\d{2}\b/g, (timeString:any) => {
        return this.appservice.timeFormatDisplay(timeString);
    });
    this.inboxdata[0].message = message
  }
  inboxLiveUpdate(){
    this.inboxservice.inbox_item.subscribe(result =>{
      let res:any = [];

      if(this.empId == result.event.to_person.id){
        if((this.req_type == result.event.request_type || this.req_type == '')){
          result.event['list_created_display'] = this.datePipe.transform(result.event['created_at'], 'hh:mm a')?.toString()
          // result.event['created_display'] = moment(String(this.datePipe.transform(result.event['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()
          result.event['created_display'] = this.datePipe.transform( result.event['created_at'], 'hh:mm a')?.toString() + " (" +moment(String(this.datePipe.transform( result.event['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()+ ")"

            res[0] = result.event;
          if(this.archeived_status == false){
            var flag = false;
            for(let i =0; i < this.inboxdata.length;i++){
              if(this.inboxdata[i].id == result.event.id && flag == false){

                this.inboxdata = this.inboxdata.filter((item:any) =>{ return item.id != result.event.id })

                if(this.inboxdata.length>0 && this.activeItem <= i)
                  this.tabCardClick(this.inboxdata[this.activeItem],this.activeItem);
                else if(this.inboxdata.length>0 )
                  this.tabCardClick(this.inboxdata[this.activeItem-1],this.activeItem-1);
                else
                  this.nodata=true;

                this.offset =this.inboxdata.length;


                flag = true;
              }
            }
            if(flag == false){
              result.event.request_type_color_code = result?.event?.request_data?.request_type?.color_code;
              this.inboxdata.unshift(result.event); //Live update triggered when a request is raised .Hence display in pending list
              this.offset+=1
              this.getDateFormat().then(() => {this.updateDate()})
              if(this.inboxdata.length == 1){
                this.tabCardClick(this.inboxdata[0],0);
              }
              else{
                this.activeItem++;
              }
            }
            if(this.inboxdata != 0){
              this.inboxLoader = false;
              this.nodata = false;
            }
          }
          else{
            if(result.event.achieved_status === true){ //to unshift an item in the archived list of the parallel approver when a confirm/ reject is performed.
              this.inboxdata.unshift(result.event);
              this.offset+=1
              this.getDateFormat().then(() => {this.updateDate()})
              if(this.inboxdata.length == 1){
                this.tabCardClick(this.inboxdata[0],0);
              }
              else{
                this.activeItem++;
              }
            }
          }
        }
      }

      this.getCount();
    })
  }

  getCount(){
    if(this.archeived_status == true)
      this.flag = 1;
    else
      this.flag = 0;
    this.inboxservice.getInboxMenuCount(this.flag,this.search_value).subscribe((res: any) =>{
      res.data.forEach((element: any) => {
        if(element.name == 'All'){
          this.alldatalength = element.count;
        }
        else if(element.name == 'Assign Salary Structure')
          this.sdatalength = element.count;
        else if(element.name == 'Confirmed Investment Declaration')
          this.cdatalength = element.count;
        else if(element.name == 'Employee Profile')
          this.edatalength = element.count;
        else if(element.name == 'Holiday')
          this.hdatalength = element.count;
        else if(element.name == 'Leave')
          this.ldatalength = element.count;
        else if(element.name == 'Proposed Investment Declaration')
          this.pdatalength = element.count;
        else if(element.name == 'Salary Revision')
          this.revisionlength = element.count;
        else if(element.name == 'Reimbursement')
          this.remLen = element.count;
        else if(element.name == 'FBP Declaration')
          this.fbpLen = element.count;
        else if(element.name == 'Final settlement Process')
          this.ffLen = element.count;
        else if(element.name == 'Final settlement Approval')
          this.ffALen = element.count;
        else if(element.name == 'On Duty')
          this.onDutyLen = element.count;
        else if(element.name == 'Attendance Regularization')
          this.attRegLen = element.count;
        else if(element.name == 'Overtime')
          this.overTimeLen = element?.count;
        else if(element.name == 'Work From Home')
              this.wrokfrmLen = element.count;
        else if(element.name == 'Compensatory Off')
          this.compOffLen = element?.count;
        else if(element.name == 'Check-in Request')
          this.checkInLen = element?.count;
        else if(element.name == 'Asset')
          this.assetLen = element?.count;
        else if(element.name == 'Manage Asset')
          this.manageAssetLen = element?.count;
        else if(element.name == 'Expense Advance')
          this.expAdv = element?.count;
        else if(element.name == 'Advance Dispense')
          this.disAdv = element?.count;
        else if(element.name == 'Expense Report')
          this.expRepCnt = element?.count;
        else if(element.name == 'Confirmation Letter')
          this.LetterLen = element?.count;
        else if(element.name == 'Termination Letter')
          this.terLetterLen = element?.count;
        else if(element.name == 'Probation Extension Letter')
          this.probationLetterLen = element?.count;
        else if(element.name == 'Relieving Letter')
          this.relievingLetterLen = element?.count;
        else if(element.name == 'Appointment Letter')
          this.appointmenLetterLen = element?.count;
        else if(element.name == 'Salary Revision Letter')
          this.revisionLetterLen = element?.count;
         else if(element.name == 'Confirmation')
          this.LetterreqLen = element?.count;
        else if(element.name == 'Short Leave')
          this.SHLCnt = element?.count;
      });
      })
  }

  menuClick(val:any,action:any=""){
    this.scroll = false
    this.offset = 0;
    this.getInboxList(val,action);
  }

  getInboxList(val:any,action:any=""){
    if(val == ""){
      if(action.target.checked == true){
        this.starred = true;
      }
      else{
        this.starred = '';
      }
    }else{
      this.action=action;
      this.selectedmenu = val;
      if(val == 1){
        this.req_type = ""
      }else if(val == 2){
        this.req_type = "Proposed Investment Declaration";
      }else if(val == 3){
        this.req_type = "Confirmed Investment Declaration";
      }else if(val == 4){
        this.req_type = "Assign Salary Structure";
      }else if(val == 5){
        this.req_type = "Holiday";
      }else if(val == 6){
        this.req_type = "Employee Profile";
      }else if(val == 7){
        this.req_type = "Salary Revision";
      }else if(val == 8){
        this.req_type= "Leave";
      }else if(val == 9){
        this.req_type= "Reimbursement";
      }else if(val == 10){
        this.req_type= "FBP Declaration";
      }else if(val == 13){
        this.req_type= "Final settlement Process";
      }else if(val == 14){
        this.req_type= "Final settlement Approval";
      }
      else if(val == 11){
        this.req_type= "Attendance Regularization";
      }else if(val == 12){
        this.req_type= "On Duty";
      }else if(val == 15){
        this.req_type= "Overtime";
      }else if(val == 17){
        this.req_type= "Work From Home";
      }else if(val == 16){
        this.req_type= "Compensatory Off";
      }else if(val == 18){
        this.req_type= "Check-in Request";
      }else if(val == 19){
        this.req_type= "Asset";
      }else if(val == 20){
        this.req_type= "Manage Asset";
      }else if(val == 21){
        this.req_type= "Expense Advance";
      }else if(val == 22){
        this.req_type= "Advance Dispense";
      }else if(val == 23){
        this.req_type= "Expense Report"
  	  }else if(val == 24){
      	this.req_type= "Confirmation";
		}
      else if(val == 25){
      	this.req_type= "Confirmation Letter";
      }
      else if(val == 26){
      	this.req_type= "Termination Letter";
      }
      else if(val == 27){
      	this.req_type= "Probation Extension Letter";
      }
      else if(val == 28){
      	this.req_type= "Relieving Letter";
      }
      else if(val == 29){
      	this.req_type= "Appointment Letter";
      }
      else if(val == 30){
      	this.req_type= "Salary Revision Letter";
      }
	  else if(val == 31){
        this.req_type= "Short Leave";
      }
    }
    if(!this.scroll)
      this.inboxLoader = true;
    this.getInboxData();
  }

  getInboxData(){
    this.inboxservice.getInboxData(this.req_type,this.starred,this.archeived_status,this.search_value,this.selectedDate,this.offset,this.defaultlimit).subscribe((res: any) => {
      if(res.results){
        let result = res.results;
            for(let i=0;i<result.length;i++){

              if(new Date(result[i]['created_at']).setHours(0,0,0,0) == new Date().setHours(0,0,0,0)) {
                result[i]['list_created_display'] = this.datePipe.transform(result[i]['created_at'], 'hh:mm a')?.toString()
                result[i]['created_display'] = this.datePipe.transform(result[i]['created_at'], 'hh:mm a')?.toString() + " (" +moment(String(this.datePipe.transform(result[i]['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()+ ")"
            }
              else if(new Date(result[i]['created_at']).getFullYear() == new Date().getFullYear()){
                result[i]['list_created_display'] = this.datePipe.transform(result[i]['created_at'], 'MMM dd')?.toString()
                result[i]['created_display'] = this.datePipe.transform(result[i]['created_at'], 'E, MMM dd, YYYY, hh:mm a')?.toString() + " (" + moment(String(this.datePipe.transform(result[i]['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()+ ")"
              }
              else {
                result[i]['list_created_display'] = this.datePipe.transform(result[i]['created_at'], 'dd/MM/yy')?.toString()
                result[i]['created_display'] = this.datePipe.transform(result[i]['created_at'], 'E, MMM dd, YYYY, hh:mm a')?.toString()
              }
              // result[i]['created_display'] = moment(String(this.datePipe.transform(result[i]['created_at'], 'yyyy-MM-dd HH:mm:ss')), "YYYY-MM-DD HH:mm:ss").fromNow()
            }
        if (res.results.length == 0) {
          this.listlength  = 0;
          if(this.offset ==0){
            this.inboxdata = [];
          }
        } else {
          this.listlength = res.results.length;
          if(this.offset ==0){
            this.inboxdata = [];
          }
          this.inboxDetailsList = res.results;
          this.addItems(this.listlength);
        }
        this.infinityloader = false;

        if(this.inboxdata.length > 0){
          if(!this.scroll)
          this.tabCardClick(this.inboxdata[0],0);
          this.nodata=false;
          this.scroll = false;
        }
        else{
          this.nodata=true;
        }
        this.inboxLoader =false;
      }

    });
  }


  showMail(flag:any){

    let   Eventkey = flag; /* key passed from html page*/
    this.inboxData = {
      'salarySturcture':false,
      'InvestmentDeclaration':false,
      'empDataApproval':false,
      'leaveRequest':false,
      'onDuty':false,
      'regularization':false,
      'floatingholiday':false,
      'salaryRevision':false,
      'reimbursement':false,
      'fbpDeclaration':false,
      'fandf':false,
      'fandfApp':false,
      'overtimeRequest':false,
      'workfromhome':false,
      'compOffRequest':false,
      'checkin' :false,
      'asset': false,
      'manageAsset': false,
      'expAdv' : false,
      'dispAdv' : false,
      'expRep' : false,
      'Confirmation' :false,
      'ConfirmationLetter' :false,
      'TerminationLetter' :false,
      'ProbationExtensionLetter' :false,
      'RelievingLetter' :false,
      'AppointmentLetter' :false,
      'SalaryRevisionLetter' :false,
      'shl' : false
    }
    Object.entries(this.inboxData).forEach(([key, value]) => {
        if(key==Eventkey){
          this.inboxData[key] =  true;
        }
        else
        this.inboxData[key] =  false;
    })
  }

  tabCardClick(request_item:any, index: any){
    this.btndisable = false;
    this.activeItem = index;
    this.inboxDataLoader   = true;
    setTimeout(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.inboxDataLoader = false;
  }, 500);



    if(request_item['request_type'] != 'Reimbursement' && request_item['request_type']!='On Duty'  && request_item['request_type']!='Attendance Regularization' && request_item['request_type']!='Check-in Request' && request_item['request_type']!='Asset' && request_item['request_type']!='Manage Asset'){
      this.inboxservice.getRequestDetails(request_item['request_type'],request_item['request_data']['id']).subscribe((res: any) => {
        if(request_item['request_type']=='Holiday'){
          request_item['holiday_name']  = res['data']['holiday_name'];
          request_item['day_of_week']   = res['data']['day_of_week'];
          request_item['holiday_date']  = this.dateFormatDisplay(res['data']['holiday_date']);
          request_item['cancel_remark']  = res['data']['remark'];
        } else if(request_item['request_type']=='Employee Profile'){

          request_item['employee_profile']  = res['data'];
          if(request_item?.request_data?.request_subcategory=='document'){
            this.inboxservice.getSingleInboxData(request_item['id']).subscribe((res:any)=>{
              request_item['employee_profile']['document_edit_data'] =  res?.temp_emp_doc_inbox?.edit_data
              request_item['employee_profile']['document_new_data'] =  res?.temp_emp_doc_inbox?.new_data
            })
          }
        }else if(request_item['request_type']=='Leave'){
          this.requestitem['leave_data']= res['data'];
          this.requestitem['leave_type_name']= res['data']['leave_type_name'];
        } else if(request_item['request_type'] == "FBP Declaration"){
          this.requestitem['fbp_request_data']= res['data'];
        } else if(request_item['request_type'] == "Overtime"){
          this.requestitem['overtime']= res['data'];
        } else if(request_item['request_type'] == "Compensatory Off"){
          this.requestitem['compoff']= res['data'];
        } else if(request_item['request_type']=='Work From Home'){
             this.requestitem['requests']= res['data'];
        } else
        this.requestitem['requests']= res['data'];
      })
    }
    else{

      this.inboxservice.getSingleInboxData(request_item['id']).subscribe((res:any)=>{
        if(res['request_data']?.is_cancel ==true){
          this.requestitem['reimbursement_data'] = {'reimburse_bill':res['cancellation_request_data'], 'remark' : res['request_data']?.['cancel_remark']};
        }
        else
          this.requestitem['reimbursement_data'] = res['reimbursement_bill_inbox'];
        this.requestitem['currency'] = res['currency']

        if(request_item['request_type']=='On Duty'){
          this.requestitem['requests']= res['onduty_request_date_inbox'];
        }
        else if(request_item['request_type']=='Attendance Regularization'){
          this.requestitem['requests']= res['regularization_request_date_inbox'];
        }
        else if(request_item['request_type']=='Check-in Request'){
          this.requestitem['requests']= res['checkin_request_date_inbox'];
        } else if(request_item['request_type']=='Asset'){
          this.requestitem['requests']= res['asset_inbox'][0];
          // this.requestitem['requests']= res['asset_inbox']['new_assets_inbox'];
          // this.requestitem['return_requests']= res['asset_inbox']['return_assets_inbox'];
        }else if(request_item['request_type']=='Manage Asset'){
          this.requestitem['requests']= res['asset_inbox'][0];
        }
      })
    }


    this.requestitem = request_item;
    this.performAction(1,0,request_item.id,'',request_item.read_status,'');

      if(this.requestitem.request_type == 'Confirmed Investment Declaration' || this.requestitem.request_type == 'Proposed Investment Declaration' ){
        this.showMail('InvestmentDeclaration');
      }
      else if(this.requestitem.request_type == 'Assign Salary Structure'){
        this.showMail('salarySturcture');
      }
      else if(this.requestitem.request_type == 'Holiday'){
        this.showMail('floatingholiday');
      }
      else if(this.requestitem.request_type == 'Employee Profile'){
        this.showMail('empDataApproval');
      }
      else if(this.requestitem.request_type == 'Leave'){
        this.showMail('leaveRequest');
      }
      else if(this.requestitem.request_type == 'Salary Revision'){
        this.showMail('salaryRevision');
      }
      else if(this.requestitem.request_type == 'Reimbursement'){
        this.showMail('reimbursement');
      }
      else if(this.requestitem.request_type == 'FBP Declaration'){
        this.showMail('fbpDeclaration');
      }else if(this.requestitem.request_type == 'Final settlement Process'){
        this.showMail('fandf');
      }else if(this.requestitem.request_type == 'Final settlement Approval'){
        this.showMail('fandfApp');
      }
      else if(this.requestitem.request_type == 'On Duty'){
        this.showMail('onDuty');
      }

      else if(this.requestitem.request_type == 'Attendance Regularization'){
        this.showMail('regularization');
      }
      else if(this.requestitem?.request_type == 'Overtime'){
        this.showMail('overtimeRequest');
      } else if(this.requestitem?.request_type == 'Compensatory Off'){
        this.showMail('compOffRequest');
      }
      else if(this.requestitem.request_type == 'Work From Home'){
        this.showMail('workfromhome');
      }
      else if(this.requestitem.request_type == 'Check-in Request'){
        this.showMail('checkin');
      } else if(this.requestitem.request_type == 'Asset'){
        this.showMail('asset');
      }else if(this.requestitem.request_type == 'Manage Asset'){
        this.showMail('manageAsset');
      }
      else if(this.requestitem.request_type == 'Expense Advance'){
        this.showMail('expAdv');
      }
      else if(this.requestitem.request_type == 'Advance Dispense'){
        this.showMail('dispAdv');
      }
      else if(this.requestitem.request_type == 'Expense Report'){
        this.showMail('expRep');
      }
      else if(this.requestitem.request_type == 'Confirmation'){
        this.showMail('Confirmation');
      }
      else if(this.requestitem.request_type == 'Confirmation Letter'){
        this.showMail('ConfirmationLetter');
      }
      else if(this.requestitem.request_type == 'Termination Letter'){
        this.showMail('TerminationLetter');
      }
      else if(this.requestitem.request_type == 'Probation Extension Letter'){
        this.showMail('ProbationExtensionLetter');
      }
      else if(this.requestitem.request_type == 'Relieving Letter'){
        this.showMail('RelievingLetter');
      }
      else if(this.requestitem.request_type == 'Appointment Letter'){
        this.showMail('AppointmentLetter');
      }
      else if(this.requestitem.request_type == 'Salary Revision Letter'){
        this.showMail('SalaryRevisionLetter');
      }
      else if(this.requestitem.request_type == 'Short Leave'){
        this.showMail('shl');
      }
     //this.showMail('salaryRevision');
  }


  topmenuselector(val:any){
    if (this.search && this.search.nativeElement) {
      this.search.nativeElement.value = ''
      this.search_value = ''
    }
    this.offset = 0;
    if(!val){
      this.archeived_status = false;
      this.selectedtopmenu = 1;
    }
    else{
      this.archeived_status = true;
      this.selectedtopmenu = 2;
    }
    this.getCount();
    this.menuClick(this.selectedmenu,1)
  }


  performAction(action:any,type:any=0,id:any,comment_value:any='',read_status:any=true,star:any=true){
    let dataArray:any = [];
    if(action == 1){
      if(read_status == false){
        read_status = true;
        dataArray = {
          "read_status": read_status,
       };
      }
    }else if(action == 2){
      if(star == false){
        star = true;
      }else{
        star = false;
      }
      dataArray = {
        "star": star,
     };
    }
    if(dataArray.length != 0){
      this.inboxservice.updateInboxItem(id,dataArray).subscribe((res: any) =>{
        if(res){
          Object.entries(dataArray).forEach(([key, value]) => {
            for(let i = 0; i < this.inboxdata.length; i++){
              if(this.inboxdata[i]['id'] == id){
                this.inboxdata[i][key] = value;
              }
            }
          })
        }
      })
    }
  }

  searchValue(searchvalue:any){
    this.offset = 0;
    this.inboxdata = [];
    this.search_value = searchvalue;
    this.inboxLoader = true;
    this.getCount();
    // this.getInboxList(this.selectedmenu,'');
    this.searchData.next()
  }

  addItems(itemlist: any) {
    for (let i = 0; i < itemlist; ++i) {
        let message = this.inboxDetailsList[i].message
        message = message.replace(/\d{2}([-])\d{2}\1\d{4}/g, (dateString:any) => {
          let ndateString = dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1");
          return this.dateFormatDisplay(ndateString);
        });
        message = message.replace(/\b\d{2}:\d{2}:\d{2}\b/g, (timeString:any) => {
            return this.appservice.timeFormatDisplay(timeString);
        });
        this.inboxDetailsList[i].message = message
        this.inboxdata.push(this.inboxDetailsList[i]);
    }
  }

  onScrollDown() {
    this.scroll = true;
    this.offset += this.defaultlimit;
    if(this.listlength){
      this.infinityloader = true;
      this.getInboxList(this.selectedmenu,'');
      this.direction    = "down";
    }
  }


  dateFormatDisplay(date:any){
      return this.datePipe.transform(date, this.appservice.getdateformat())?.toString()
  }



  closeSidePanel(val: boolean){
    this.investmentcard1=val;

  }
  inboxDataLoader = false
  refreshInbox(event: boolean){    

    if(event){
      // this.initFunction();
      this.getCount();
      this.inboxdata = this.inboxdata.filter((item:any) =>{ return item.id != this.requestitem.id })
      this.inboxDataLoader   = true;

      setTimeout(() => {
        // set showloader to false to hide loading div from view after 5 seconds
        this.inboxDataLoader = false;
    }, 500);
	    if(this.inboxdata.length>0 && this.inboxdata[this.activeItem])
	      this.tabCardClick(this.inboxdata[this.activeItem],this.activeItem);
	    else if(this.inboxdata.length>0 )
	      this.tabCardClick(this.inboxdata[this.activeItem-1],this.activeItem-1);
	    else
	      this.nodata=true;
    this.offset =this.inboxdata.length;
    if(this.inboxdata.length<10 && this.listlength){
      this.scroll = true;
      this.getInboxList(this.selectedmenu,'');
    }
  }
  }

  dateChanged(eve:any){ //Date filter function
    this.offset = 0;
    this.inboxdata = [];
    if(eve.startDate != null && eve.endDate != null){
      let start = moment(eve.startDate._d).format('YYYY-MM-DD');
      let end = moment(eve.endDate._d).format('YYYY-MM-DD');
      this.selectedDate=[start,end];
      this.getInboxList(this.selectedmenu);
    }
    else if(eve == ''){
      this.selectedDate = '';
      this.selected = '';
      this.starred = '';
      this.getInboxList(this.selectedmenu);
    }
    else{
      this.getInboxList(this.selectedmenu);
    }
  }
  // Date Format
  getDateFormat(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const dateFormat = this.appservice.getdatepickerformat();
        if (dateFormat !== '') {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }

}
