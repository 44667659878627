import { Component, OnInit,Input,Output,ChangeDetectorRef,EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { FormBuilder,Validators ,FormArray,FormGroup} from '@angular/forms';
import { GeolocationService } from 'src/app/@core/services/geo-location.service';
import * as moment from 'moment';
import { AppService } from 'src/app/app.global';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';


@Component({
  selector: 'app-profile-geo-location',
  templateUrl: './profile-geo-location.component.html',
  styleUrls: ['./profile-geo-location.component.scss']
})
export class ProfileGeoLocationComponent implements OnInit {

  @Input() routingUrl:any;
  @Input() id:any;
  @Input() loginAs :any;
  @Input() empLocationData:any
  @Input() from1:any;
  @Input() fieldPermission:any;
  @Input() addEditPermission:any;
  @Output() assignLocation  = new EventEmitter();
  @Output() deleteLocation  = new EventEmitter();


  showSidePanel         = false;
  loader                = false;
  deleteToggle          = false;
  permissions:any       = [];
  deleteClicked         = false;
  from:any
  locationItem:any;
  deletedId:any
  locationIndex:any
  locationForm          = this.formBuilder.group({
                          location:this.formBuilder.array([])
                          });

  constructor(
    public messageService : MessageService,
    private formBuilder:FormBuilder,
    public locationService:GeolocationService,
    public appService:AppService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getPermission();
    if(this.loginAs=='HR')
    this.getLocationDropdown();

  }
  location() {
    return this.locationForm.get('location') as FormArray;
  }
  addlocationControl(){
    this.location().clear();
    this.addlocation()
  }
  addlocation() {
    this.locationForm.markAsUntouched()

    this.location().push(this.createLocation());
  }
  createLocation():FormGroup{
    return this.formBuilder.group({
                            geofencing_location_id      :[null,[Validators.required]],
                            for_specific_days_only      :[false,[Validators.required]],
                            duration_of_days            :[0,Validators.max(365)],

                          })
  }
  addValidator(index:any){
    if(this.location().controls[index]?.get('for_specific_days_only')?.value==true){
      this.location().controls[index]?.get('duration_of_days')?.setValue('');
      this.location().controls[index]?.get('duration_of_days')?.addValidators([Validators.required,NumberpatternValidator()])
    } else{
      this.location().controls[index]?.get('duration_of_days')?.clearValidators();
      this.location().controls[index]?.get('duration_of_days')?.setValue(0);

    }
    this.location().controls[index]?.get('duration_of_days')?.updateValueAndValidity();

  }
  getLocationDropdown(){
    this.locationService.hrLocationDropdown().subscribe((res:any)=>{

      this.locationItem = res.body;
      for(let i=0;i<this.locationItem.length;i++){
        this.locationItem[i]['locationData']  = '';
        this.locationItem[i]['locationData']  = this.locationItem[i]['location_name']+"("+this.locationItem[i]['latitude']+"-"+this.locationItem[i]['longitude']+")"
      }
    })
  }

  submitEmployeeLocation(){
    if(this.locationForm.invalid)
    return
    else{

      let data  = {"employee_id":this.id,"geofencing_locations":this.locationForm.value.location}
      this.assignLocation.emit(data)
      this.clear();

    }
  }
  clear(){
    this.showSidePanel  = false;

    this.location().clear();
  }

  deleteEmpLocation(id:any){
      this.deleteToggle     = true;
      this.deletedId        = id;


  }
  deleteEmpLocationConfirm(){

    this.deleteClicked  = true
    this.deleteToggle   = false;
    this.deleteLocation.emit(this.deletedId);

  }
  deleteLocationControl(index:any){
    this.location().removeAt(index);

  }
  getPermission(){
    this.permissions = this.appService.getPermissions(this.routingUrl);
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  getFieldPermission(fieldName:any){
    if((this.from1=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from1 != 'myteam')){
      return true;
    }else{
      return false;
    }
  }
}
