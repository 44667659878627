import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder,Validators ,FormGroup } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import * as moment from 'moment';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { debounceTime } from 'rxjs/operators'
import { Subject } from 'rxjs';
import { Router,ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-rs-schedule-details',
  templateUrl: './rs-schedule-details.component.html',
  styleUrls: ['./rs-schedule-details.component.scss']
})

export class RsScheduleDetailsComponent implements OnInit {

  submitted                                = false;
  reportScheduleDropdownPlaceHolder        = 'Select schedule';
  scheduleDayPH                            = 'Select Day';
  scheduleMonthPH                          = 'Select Month';
  scheduleDatePH                           = 'Select Date';
  report_schedule:string                   = "report_schedule";
  weekdays:string                          = "weekdays";
  listOfMonth:string                       = "listOfMonth";
  schedule_dates:string                    = "schedule_dates";
  isrequired                               = true;
  fromDateConvert: Date                    = new Date();
  toDateConvert: Date                      = new Date();
  isDateConvert                            = false;
  reportsDD:any                            = [];
  disabled                                 = false;
  fromMinDate                              = new Date();
  toMinDate                                = new Date();
  fromMaxDate                              = new Date(new Date().getFullYear() + 50, 11, 31);
  toMaxDate                                = new Date(new Date().getFullYear() + 50, 11, 31);
  @Output() submitmethod                   = new EventEmitter();

  validated : boolean = false
  reportUnit : any =[]
  id:any

  private subjectKeyUp = new Subject<any>();

  @Input()formGp : FormGroup = this.fb.group({
    scheduler_name          : ['',[Validators.required, UnamepatternValidator(), Validators.maxLength(100)]],
    schedule                : [,[Validators.required]],
    report_scheduler_time   : ["00:00:00",[Validators.required]],
    schedule_start_date     : ['',[Validators.required]],
    schedule_end_date       : ['',[Validators.required]],
    description             : ['',[Validators.required,Validators.maxLength(500)]],
    report                  : ['',[Validators.required]],
    report_scheduler_day    : [],
    report_scheduler_date   : [],
    report_scheduler_month  : []
  });

  constructor(
    public fb:FormBuilder,
    public appservice:AppService,
    public messageService : MessageService,
    private reportService:ReportsService,
    private existDataService : ExistDatasService,
    public router :  ActivatedRoute
    ) { }

  ngOnInit(): void {

    this.router.params.subscribe((params: Params) => {
      this.id = params['id']
    })

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService()
    })

    this.getReports();
    if(this.formGp.controls.schedule_start_date.value != '' && this.formGp.controls.schedule_start_date.value != null){
      this.fromMinDate = new Date(moment(this.formGp.controls.schedule_start_date.value,"YYYY-MM-DD").year(), moment(this.formGp.controls.schedule_start_date.value,"YYYY-MM-DD").month(), moment(this.formGp.controls.schedule_start_date.value,"YYYY-MM-DD").date());
    }
  }

  /************ GET REPORT LIST FUNCTION *****************/
  getReports(){
    this.reportService.payrollReportDropdown().subscribe((res: any) => {
      this.reportsDD = res;
      if (this.reportsDD.length != 0) {
        this.selectAllForDropdownItems(this.reportsDD);
        }
    });
  }

  /************ CLEAR DROP DOWN VALUE FUNCTION *****************/
  clearcommonForm(item:any,index:number){
    if(item == "report"){
      let daata = this.formGp.value.report;
      daata.splice(index, 1);
      this.formGp.patchValue({
        report: daata
      });
    }
  }

  get f() {return  this.formGp.controls; }

  /********* SUBMITT ****************/
  validateSchedule() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit();
  }

  /************ SCHEDULE CHANGE FUNCTION *****************/
  scheduleChangeFunction(scheduleValue: any){

    if(scheduleValue == "Daily"){

      this.formGp.get('report_scheduler_day')?.clearValidators();
      this.formGp.get('report_scheduler_date')?.clearValidators();
      this.formGp.get('report_scheduler_month')?.clearValidators();
      this.formGp.get('report_scheduler_day')?.updateValueAndValidity();
      this.formGp.get('report_scheduler_date')?.updateValueAndValidity();
      this.formGp.get('report_scheduler_month')?.updateValueAndValidity();

      this.formGp.get('report_scheduler_day')?.setValue(null);
      this.formGp.get('report_scheduler_date')?.setValue(null);
      this.formGp.get('report_scheduler_month')?.setValue(null);

    }else if(scheduleValue == "Weekly"){

      this.formGp.get('report_scheduler_day')?.setValidators([Validators.required]);
      this.formGp.get('report_scheduler_date')?.clearValidators();
      this.formGp.get('report_scheduler_month')?.clearValidators();
      this.formGp.get('report_scheduler_day')?.updateValueAndValidity();
      this.formGp.get('report_scheduler_date')?.updateValueAndValidity();
      this.formGp.get('report_scheduler_month')?.updateValueAndValidity();

      this.formGp.get('report_scheduler_date')?.setValue(null);
      this.formGp.get('report_scheduler_month')?.setValue(null);
    }else if(scheduleValue == "Monthly"){

      this.formGp.get('report_scheduler_day')?.clearValidators();
      this.formGp.get('report_scheduler_date')?.setValidators([Validators.required]);
      this.formGp.get('report_scheduler_month')?.clearValidators();
      this.formGp.get('report_scheduler_day')?.updateValueAndValidity();
      this.formGp.get('report_scheduler_date')?.updateValueAndValidity();
      this.formGp.get('report_scheduler_month')?.updateValueAndValidity();

      this.formGp.get('report_scheduler_day')?.setValue(null);
      this.formGp.get('report_scheduler_month')?.setValue(null);

    }else if(scheduleValue == "Yearly"){

      this.formGp.get('report_scheduler_day')?.clearValidators();
      this.formGp.get('report_scheduler_date')?.setValidators([Validators.required]);
      this.formGp.get('report_scheduler_month')?.setValidators([Validators.required]);
      this.formGp.get('report_scheduler_day')?.updateValueAndValidity();
      this.formGp.get('report_scheduler_date')?.updateValueAndValidity();
      this.formGp.get('report_scheduler_month')?.updateValueAndValidity();

      this.formGp.get('report_scheduler_day')?.setValue(null);

    }
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // toDateFilter(d: any){
  //   let dateRange = [new Date(),
  //     new Date(new Date().getFullYear() + 50, 11, 31)]
  //   return (d >= dateRange[0] && d<= dateRange[1])
  // }

 // Simplified function to use for validation
 existService(){

    const value ={
      "id":this.id,
      "page":"ScheduledReport",
      "field":"scheduler_name",
      "value": this.f.scheduler_name.value
    }

  this.existDataService.getSpecificName(value).subscribe((res:any)=>{
    this.reportUnit = res.body

    if(this.reportUnit.data ==='invalid'){
      this.f.scheduler_name.setErrors({
        'exist' : true
      })
    }
  })
}


// For validation of keyup(name)

keyUp(value:any){
  this.subjectKeyUp.next(value);
}
}
