import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MapsAPILoader } from "@agm/core";
import { AppService } from 'src/app/app.global';
import { InfoWindow, MapTypeStyle } from '@agm/core/services/google-maps-types';
@Component({
  selector: 'app-employee-map-view',
  templateUrl: './app-employee-map-view.component.html',
  styleUrls: ['./app-employee-map-view.component.scss']
})
export class EmployeeMapViewComponent implements OnInit {
  activeTab = 1;
  constructor(private mapsAPILoader: MapsAPILoader, public appservice: AppService) {
    this.mapsAPILoader.load().then(() => {
      this.createWayPoints()
    })
  }
  @Input() data: any;
  @Output() closepanel = new EventEmitter();

  lat: number = 8
  lng: number = 76
  zoom: number = 18;

  icon: any
  waypoints: any = []
  @Input() origin: any
  @Input() destination: any
  markerOptions: any = { "shape": "circle" }
  renderOptions = {
    suppressMarkers: true,
    polylineOptions: { strokeColor: '#0078CE', strokeWeight: 5, }
  };
  infoWindow: any;
  label = ""
  styles: any = [{
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#0c0c0c"
      }
    ]
  }]
  style: any = "styled-class avatar-circle "

  ngOnInit(): void {
    if (this.data?.employee[0].profile_image == null || this.data?.employee[0].profile_image == '') {
      this.label = this.appservice.getFirstChar(this.data?.employee[0].user__first_name + " " + this.data?.employee[0].user__last_name, 2)
    }

    this.mapsAPILoader.load().then(() => {

      let shape = {
        coords: [25, 25, 25],
        type: 'circle',
      };
      if (this.data?.employee[0].profile_image != null && this.data?.employee[0].profile_image != '') {
        this.icon = {
          url: this.data?.employee[0].profile_image,
          scaledSize: new google.maps.Size(30, 30), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(0, 0),
          shape: shape, // anchor

        };
      } else {
        this.icon = '/assets/images/helper/map-img/map-' + this.data?.employee[0].color_code + '.png'
        // this.icon = '/assets/images/helper/map-img/map-uniquebg10.png'

      }
    })

  }
  closePanel() {
    this.closepanel.emit();
  }
  createWayPoints() {

    this.data?.real_time_data?.forEach((a: any, k: any) => {
      if (k != 0 && k != this.data?.real_time_data.length - 1) {
        let latlng = new google.maps.LatLng(a.latitude, a.longitude);
        this.waypoints.push({
          location: latlng,
          stopover: false,

        });
      }
    });

  }

  openedWindow: number = -1; // alternative: array of numbers

  openWindow(id: any) {
    if (this.isInfoWindowOpen(id))
      this.openedWindow = -1;
    else
      this.openedWindow = id; // alternative: push to array of numbers
  }

  isInfoWindowOpen(id: any) {
    return this.openedWindow == id; // alternative: check if id is in array
  }

}
