import { Component, Output, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { MiscellaneousService } from 'src/app/@core/services/miscellaneous.service';
import { SalaryDashboardService } from 'src/app/@core/services/salary-dashboard';
import { SalaryProcessService } from 'src/app/@core/services/salary-process.service';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { AppService } from 'src/app/app.global';
import { Item } from './submenu.model';
import { environment } from 'src/environments/environment';
import { AttendanceProcessService } from 'src/app/@core/services/attendance-process.service';
import { EmppShiftMapService } from 'src/app/@core/services/empp-shift-map.service';
import { clarityService } from 'src/app/@shared/clarity/clarity';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Output() sidebarExpanded = new EventEmitter<number>();
  subMenuList: Item[] = [];
  basicEmpData: any = [];
  collapseValue = 0;
  theme: any;
  clicked = false;
  isCompleted = false;
  @Input() changeThemesidePanel :any;
  @Output() changeThemesidePanel1 :any = new EventEmitter();
  env = environment
  profilemenuindex = 0;
  id = 0;

  expandDisp = '';
  homeMenuIndex = 0;

  popupClass ="popup-primary"
  showProgress = false;
  errorLog        = false;
  exportData:any  = [];
  processText = ''
  completeText = ''
  btnClass=""
  saveData:any = {}
  timeLeft:any = "";
  progPercentage:any = 0
  
  
  //process Attendance 
  popupClassA ="popup-primary"
  showProgressA = false;
  errorLogA        = false;
  exportDataA:any  = [];
  processTextA = ''
  completeTextA = ''
  btnClassA=""
  saveDataA:any = {}
  timeLeftA:any = "";
  progPercentageA:any = 0

    //Assign Shift
    popupClassB ="popup-primary"
    showProgressB = false;
    errorLogB       = false;
    exportDataB:any  = [];
    processTextB = ''
    completeTextB = ''
    btnClassB=""
    saveDataB:any = {}
    timeLeftB:any = "";
    progPercentageB:any = 0
    successMsgB = ''
    errorMsgB = ''
    empCntB = 0

  taskText = ""
  constructor(
    public appService: AppService, public authService: AuthService, public snapshotService: SnapshotService, private miscellaneousservice: MiscellaneousService, public router:Router,private SalaryProcessService:SalaryProcessService,public sds:SalaryDashboardService, public aps:AttendanceProcessService,public empShift : EmppShiftMapService,    private clarityService: clarityService
  ) {    
      this.SalaryProcessService.processPercentage.subscribe(result =>{
        this.taskText = result.event.is_process_publish?"Publish":"Process"
        this.showProgress = true
        this.empCnt = result.event.employee_count
        this.progPercentage = result.event.percentage
        if(result.event.percentage == 100){
          this.timeLeft = "0 min 0 sec "
          this.SalaryProcessService.getProcessData().subscribe((res:any) => {
            this.saveData = res.data;
            this.showCompleted(res.data.error_list)
          });
        }
        else{
          this.popupClass ="popup-primary"
          this.timeLeft = this.convertMinSecs(result.event.time_left)
        }
      })

      this.aps.processPercentage.subscribe(result =>{
        this.showProgressA = true
        this.empCntA = result.event.employee_count
        this.progPercentageA = result.event.percentage
        if(result.event.percentage == 100){
          this.timeLeftA = "0 min 0 sec "
          this.aps.getAttendanceProcessData().subscribe((res:any) => {
            this.saveDataA = res.data;
            this.showAttendanceCompleted(res.data.error_list)
          });
        }
        else{
          this.popupClassA ="popup-primary"
          this.timeLeftA = this.convertMinSecs(result.event.time_left)
        }
      })

      this.empShift.processPercentage.subscribe(result =>{
        if(this.router.url != "/employee-shift" && this.empShift.PROCESSTEXT!='Update')
          if(this.empShift.BGPROGRESS)
          this.showProgressB = true
        this.empCntB = result.event.employee_count
        this.progPercentageB = result.event.percentage
        if(result.event.percentage == 100){
          this.timeLeftB = "0 min 0 sec "
          if(this.empShift.BGPROGRESS){
            this.empShift.getShiftProcessData().subscribe((res:any) => {
              this.saveDataB = res.body.data;
              this.showSHiftCompleted(res.body.data?.error_list)
            });
          }
        }
        else{
          this.popupClassB ="popup-primary"
          this.timeLeftB = this.convertMinSecs(result.event.time_left)
        }
      })
  }
  ngOnInit(): void {
    
    this.themegetfn();
    setTimeout(() => {
      this.activateMenuParent()
    }, 2000);


    this.subMenuList = this.authService.MENULIST
    this.checkActiveMenu(this.subMenuList,-1,-1);
    // this.authService.menuList().subscribe((res: any) => {
    //   this.subMenuList = res.page_access;
    //   this.checkActiveMenu(this.subMenuList,-1,-1);
    //   this.appService.setPermissions(res.page_access)
    // });
    this.id = this.authService.isEmployeeId; /*Currently login employe Id*/
    this.getEmpBasicData();

    //Setting date and time format globally
    this.miscellaneousservice.miscellaneousGet().subscribe((res: any) => {
      for(let i = 0; i < res.body.length; i++){
        if (res.body[i].setting=='date') {
          this.appService.setdateformat(res.body[i].value)
        }
        if (res.body[i].setting=='time') {
          this.appService.settimeformat(res.body[i].value)
        }
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkActiveMenu(this.subMenuList,-1,-1);
      }
    })
    this.SalaryProcessService.requestSocket();
    this.aps.requestSocket();
    this.empShift.requestSocket();
  }
  ngOnChanges() {

  }
  changetheme(theme1: any) {
    this.authService.changethemeupdate(theme1).subscribe((res: any) => {
      this.themegetfn();
      this.changeThemesidePanel = false;
      window.location.reload();
    })
  }
  themegetfn() {
    this.authService.themeget().subscribe((res: any) => {
      this.theme = res.name;
    });
  }
  checkActiveMenu(List:any,isRoot:any,pKey:number){

    List.forEach((value:any, key:any) =>  {
      if(value['url']=='/profile')
        this.profilemenuindex = key;
      else if(value['url']=='/home')
        this.homeMenuIndex = key;

      if(this.router.url == value['url'] && isRoot == -1){
        this.parentActive = key;
        this.collapseValue = key;
        if(value['children'].length==0 || (value['url']=='/request'))
          this.expandDisp = 'd-none'
      } else if(this.router.url == value['url']){
        this.parentActive = pKey;
        this.collapseValue = pKey;
      }

      if(isRoot == -1)
        pKey = key;

      if(value['children'].length>0)
        this.checkActiveMenu(value['children'],0,pKey)
    });
    if(this.selectedListItemIndex == -1)
      this.addNewItem(-1);
  }

  getEmpBasicData() {
    if(this.id != 0){
      this.snapshotService.getEmployeeBasicData(this.id).subscribe((res: any) => {
        this.basicEmpData = res;
        this.snapshotService.setCompanyLogo(this.basicEmpData?.company_logo,this.basicEmpData?.company);
        this.snapshotService.setBasicDetails(this.basicEmpData);
        this.snapshotService.setCompanyID(this.basicEmpData?.company_id)
        this.clarityService.createScriptForClarity();
        
      })
    } else if(this.authService.isSuperUser()){
      this.basicEmpData.first_name = "Super"
      this.basicEmpData.last_name = "Admin User"
      this.basicEmpData.designation = "Super Admin"
      this.snapshotService.setCompanyLogo("","");
      this.snapshotService.setBasicDetails(this.basicEmpData);
      this.clarityService.createScriptForClarity();
    } else {
      this.snapshotService.setCompanyLogo("","");
      this.snapshotService.setBasicDetails([]);
    }
    
  }
  addNewItem(value: number) {
    this.sidebarExpanded.emit(value);
  }
  public selectedListItemIndex = -1;
  public parentActive = -1;
  setIndex(i: number) {
      let childlen:any = this.subMenuList[i]?.children?.length ? this.subMenuList[i]?.children?.length :0;
      let url:any = this.subMenuList[i]?.url ? this.subMenuList[i]?.url : "";
      if (i != -1) {
        this.parentActive = i;
        this.collapseValue = i;
        this.appService.toggleSidebarVisibility(true)
      } else
        this.appService.toggleSidebarVisibility(false)
      if((childlen <=0 && i != -1) || (url=='/request')){
        this.expandDisp = 'd-none'
        i=-1;
      } else
      this.expandDisp = ''
      this.selectedListItemIndex = i;
      this.addNewItem(i);
  }
  toggleSubmenu(event: any, className: string | undefined) {
    if (event.target.parentElement.classList.contains(className)) {
      event.target.parentNode.classList.remove(className);
    } else {
      event.target.parentNode.classList.add(className);
    }
  }

  
  sidebarMenuList: any[] = [];


  activateMenuParent(): void {
    if(document.querySelectorAll(".subChild.active")[0]){
      var activeSubChild = document.querySelectorAll(".subChild.active")[0].parentElement?.parentElement;
      activeSubChild?.parentElement?.classList.add('showMenu')
      activeSubChild?.previousElementSibling?.classList.add('active')
    }
  }

  changeParentActiveClass(){
    setTimeout(() => {
      if(document.querySelectorAll(".subParent.active")[0] && document.querySelectorAll(".subChild.active")[0]){
        var prevParent = document.querySelectorAll(".subParent.active")[0];
        var currentParent = document.querySelectorAll(".subChild.active")[0].parentElement?.parentElement?.previousElementSibling;
        if( prevParent != currentParent ){
          prevParent.classList.remove('active');
        }
      }
     }, 100);
    setTimeout(() => { this.activateMenuParent() }, 200);
  }


  removeParentClassActive(){
    if(document.querySelectorAll(".subParent.active")[0])
      document.querySelectorAll(".subParent.active")[0].classList.remove('active');
  }
  countThirdLevel(children:any){
    var count =0 ;
    children.forEach((value:any, key:any) =>{
      if(value.level == 3)
      count++;
    });
    return count
  }





  convertMinSecs(min:any){
    let time = min*60;
    var minutes = Math.floor(time / 60);
    var seconds:any = time - minutes * 60;
    seconds = seconds.toFixed(2)
    return minutes+" min "+seconds+" sec "
  }
  ngAfterContentChecked() {
    this.createDragElement();
  }
  
  private createDragElement() {
    const elmnt = document.getElementById('draggableElement');
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    const dragMouseDown = (e:any) => {
      e = e || window.event;
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    };

    const elementDrag = (e:any) => {
      e = e || window.event;
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      if(elmnt){
        elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
      }
    };

    const closeDragElement = () => {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    };
    if(elmnt){     
        elmnt.onmousedown = dragMouseDown;
  }
  }

  showCompleted(errorList:any){

    if(errorList?.length == 0 || errorList === undefined){
      this.popupClass = 'popup-success'
      this.processText = 'Completed'
      this.btnClass = 'btn-success'
      this.completeText = 'Salary '+this.taskText+'ed for '+this.empCnt+' employees.'
    } else {
      this.popupClass = 'popup-danger'
      this.processText = 'Error'
      this.btnClass = 'btn-danger'
      this.completeText = errorList?.length+' employees salary was not '+this.taskText+'ed.'
    }
  }
  closeprogress(){
    this.errorLog = false;
    this.showProgress = false
    this.sds.setProcessData(0,'',"")
    this.btnClass = ''
    this.sds.setProgress(true);
  }
  viewDetails(){
    this.showProgress = false
    this.errorPopup()
  }
  successMsg = ''
  errorMsg = ''
  empCnt = 0

  errorPopup(){
    this.exportData = []
    if(this.saveData?.error_list.length>0){
      for (let i = 0; i < this.saveData.error_list.length; i++) {
        this.exportData.push({'Employee code':this.saveData.error_list[i]['employee_code'], 'Employee name':this.saveData.error_list[i]['employee_name'], 'Error Description':this.saveData.error_list[i]['error_list'][0]['description']});
      }
      this.errorLog = true;
      this.successMsg = this.empCnt-this.saveData?.error_list.length+" out of "+this.empCnt+" employee(s) salary "+this.taskText+"ed successfully"
      this.errorMsg =this.saveData?.error_list.length+' employee(s) have error data.'
    } else {
      this.errorLog = true;
      this.successMsg = this.empCnt-this.saveData?.error_list.length+" out of "+this.empCnt+" employee(s) salary "+this.taskText+"ed successfully"
      this.errorMsg = ''
    }
  }


  //Atendance process
  showAttendanceCompleted(errorList:any){

    if(errorList?.length == 0 || errorList === undefined){
      this.popupClassA = 'popup-success'
      this.processTextA = 'Completed'
      this.btnClassA = 'btn-success'
      this.completeTextA = 'Attendance processed for '+this.empCntA+' employees.'
    } else {
      this.popupClassA = 'popup-danger'
      this.processTextA = 'Error'
      this.btnClassA = 'btn-danger'
      this.completeTextA = errorList?.length+' employees Attendance was not processed.'
    }
  }

  closeprogressA(){
    this.errorLogA = false;
    this.showProgressA = false
    this.aps.setProcessData(0,'',"")
    this.btnClassA = ''
    this.aps.setProgress(true);
  }
  viewDetailsA(){
    this.showProgressA = false
    this.errorPopupA()
  }
  successMsgA = ''
  errorMsgA = ''
  empCntA = 0
  errorPopupA(){
    this.exportDataA = []
    if(this.saveDataA?.error_list.length>0){
      for (let i = 0; i < this.saveDataA.error_list.length; i++) {
        this.exportDataA.push({'Employee code':this.saveDataA.error_list[i]['employee_code'], 'Employee name':this.saveDataA.error_list[i]['employee_name'], 'Error Description':this.saveDataA.error_list[i]['error_list'][0]['description']});
      }
      this.errorLogA = true;

      this.successMsgA = this.empCntA-this.saveDataA?.error_list.length+" out of "+this.empCntA+" employee(s) attendance processed successfully"
      this.errorMsgA =this.saveDataA?.error_list.length+' employee(s) have error data.'

    } else{
      this.errorLogA = true;

      this.successMsgA = this.empCntA-this.saveDataA?.error_list.length+" out of "+this.empCntA+" employee(s) attendance processed successfully"
      this.errorMsgA =''
    }
  }


  //Assign Shift

  showSHiftCompleted(errorList:any){
    if(this.router.url == "/employee-shift" && this.empShift.PROCESSTEXT=='Update'){
      this.viewDetailsB()
    } else {
      if(this.saveDataB?.error_list[0]?.failed_employee_count == 0 || this.saveDataB?.error_list[0] === undefined){
        this.popupClassB = 'popup-success'
        this.processTextB = 'Completed'
        this.btnClassB = 'btn-success'
        this.completeTextB = 'Shift assigned for '+this.empCntB+' employees.'
      } else {
        this.popupClassB = 'popup-danger'
        this.processTextB = 'Error'
        this.btnClassB = 'btn-danger'
        this.completeTextB = 'Shift not assigned for '+this.saveDataB?.error_list[0]?.failed_employee_count+' employees.'
      }
    }
  }

  closeprogressB(){
    this.errorLogB = false;
    this.showProgressB = false
    this.empShift.setProcessData('',"")
    this.btnClassB = ''
    this.empShift.RUNBG =false
    this.empShift.setProgress(true);
  }
  viewDetailsB(){
    this.showProgressB = false
    this.errorPopupB()
  }

  errorPopupB(){
    this.exportDataB = []
    let sucLen = this.saveDataB?.error_list[0]?.failed_employee_count?this.empCntB-this.saveDataB?.error_list[0]?.failed_employee_count:this.empCntB
    this.successMsgB = "Shift Assigned for "+ sucLen +" out of "+this.empCntB+" employee(s) successfully"
    this.errorLogB = true;
    if(this.saveDataB.error_list[0]?.failed_employee_data.length>0){
      for (let i = 0; i < this.saveDataB.error_list[0].failed_employee_data.length; i++) {
        if(this.saveDataB.error_list[0].failed_employee_data[i]['employee']){
          this.exportDataB.push({
            'Employee code':this.saveDataB.error_list[0].failed_employee_data[i]['employee'], 
            'Error Date':this.saveDataB.error_list[0].failed_employee_data[i]['date'], 
            'Error Description':this.saveDataB.error_list[0].failed_employee_data[i]['error']});
        } else {
          const orderedData : any = {};
          orderedData['Employee Name'] = this.saveDataB.error_list[0]?.failed_employee_data[i].data['employee_name'] 
          orderedData['Employee Code'] = this.saveDataB.error_list[0]?.failed_employee_data[i].data['employee_code']
          for (const key in this.saveDataB.error_list[0]?.failed_employee_data[i]['data']) {
            if (key !== 'employee_name' && key !== 'employee_code' && key !== 'error') {
              orderedData[key] = this.saveDataB.error_list[0]?.failed_employee_data[i]['data'][key];
            }
          }
          orderedData['Error Description'] = this.saveDataB.error_list[0]?.failed_employee_data[i].error
          this.exportDataB.push(orderedData);
        }
      }      
      
      this.errorMsgB = this.saveDataB?.error_list[0]?.failed_employee_count>0 ?this.saveDataB?.error_list[0].failed_employee_count+' employee(s) have error data.':''
    }
  }

}
