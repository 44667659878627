import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-leave-report',
  templateUrl: './leave-report.component.html',
  styleUrls: ['./leave-report.component.scss']
})
export class LeaveReportComponent implements OnInit {

  /**** PERMISSIONS ****/
  permissions           : any

  /**** General Variables ****/
  loader                : boolean = false
  noData                : boolean = false
  searchListData        : any = [];
  placeHolder           : string = this.msgService.searchdisplay('Reports') ;
  leaveReports          : any = []

  /******** INFINITY SCROLL VARIABLES  ****/
  offset                     = 0;
  listlength                 = 0;
  direction                  = '';
  defaultlimit:any           = 20;
  reoprtDetailsList:any      = [];
  infinityloader             = false;
  searchKeyword              = "";
  sLoading = false;

  constructor(
    public appService  : AppService,
    public msgService  : MessageService,
    public leaveServ   : LeaveReportService,
    public router      : Router
  ) { }

  ngOnInit(): void {
    // Permission
    this.getPermission();
    // Landing API
    this.landingAPI();
  }

  // Landing Screen Data
  landingAPI(){
    this.loader = true
    this.leaveServ.reportTypeList('Leave',this.defaultlimit,this.offset,this.searchKeyword).subscribe((res:any)=>{
      this.listlength        = res.results.length;
      if(this.offset ==0){
        this.leaveReports = [];
      }
      if (res.results.length != 0) {
        this.reoprtDetailsList = res.results;
      }
      this.infinityloader    = false;
      this.addItems(this.listlength);
      if (res.results.length == 0) {
        this.noData = this.offset == 0 ? true:false;
      }else{
        this.noData = false;
      }
      this.loader            = false;
      this.sLoading          = false;
    });
    // this.leaveServ.leaveReportDropdown().subscribe((res: any) => {
    //   this.searchListData = res;
    // });
  }

  // Pushing data on scroll
  addItems(itemlist:any){
    for (let i = 0; i < itemlist; i++) {
      this.leaveReports.push(this.reoprtDetailsList[i]);
    }
  }

  // Search Reports
  searchResults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.leaveReports = [];
      this.reoprtDetailsList = [];
      this.landingAPI();
     }
    else{
      this.leaveServ.getSingleLeaveReport(data).subscribe((res: any) => {
        this.leaveReports = res;
        this.loader = false;
      });
    }
  }

  searchLeave(val:any){
    this.loader = true;
    this.sLoading = true;
    this.offset           = 0;
    this.leaveReports= [];
    this.searchKeyword = val;
    if(this.searchKeyword != ""){
      this.landingAPI();
    }
  }

  // Scroll
  onScrollDown() {
    this.infinityloader = true;
    this.offset        += this.defaultlimit;
    if(this.listlength){
      this.landingAPI();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  // Navigate to table data
  reportTableView(reportname:any, reportid:any){
    if(this.permissions.v){
      if(reportid != undefined && reportid != null){
        reportname = reportname.replace(/\s/g, "");
        this.router.navigate(['/leave-report/leave-report-configure/'+reportname+'/'+reportid]);
      }
    }
  }

  // Permission
  getPermission(){
    this.permissions = this.appService.getPermissions('/leave-report');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

}
