<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3 class="mb-0">Reports</h3>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar #search [searchlist]='searchListData' [searchplaceholder]='placeHolder'
            (searchEmitter)="searchResults($event)" (keywordsearchEmitter)="searchLeave($event)" [isLoading]="sLoading"></app-searchbar>
        </div>
      </div>
    </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-md-4 col-sm-6" [cardType]="'card5'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="noData && !loader;else all_data" class="row pt-4" style="height: calc(100vh - 14rem);">
    <app-nodata-view class="vstack" [imgHeight]="'16rem'" [noDataImg]="'bg2.png'" [noDataText]="'No reports added yet'"
      [hasPermission]="false"> </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <div class="row row-16 ">
        <ng-container *ngIf="loader==false">
          <div class="col-12">
            <p class="mb-0 fw-500">Leave ({{leaveReports.length}} Reports)</p>
          </div>
          <div class=" col-md-4 col-sm-6" *ngFor="let report of leaveReports,let i = index">
            <div class="card card-c2 card-hover card-highlight highlight-primary h-100"
              (click)="reportTableView(report.name,report.id)">
              <div class="card-body p-0 d-flex align-items-start">
                <div class="d-flex px-16 pt-16 pb-8 w-100">
                  <div class="overflow-hidden ">
                    <p class="fw-500 text-trim mb-0" ngbPopover="{{report.name}}"
                      popoverClass="popover-input popover-info" container="body" triggers="hover">{{report.name}}</p>
                  </div>
                </div>
              </div>
              <div class="card-body px-16 pb-16 pt-0 border-0">
                <p class="text-light-400 fs-14 mb-0">{{report.description}}</p>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- Loader updations -->
        <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-md-4 col-sm-6" [cardType]="'card5'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
