import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder,Validators ,FormGroup } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';

@Component({
  selector: 'app-report-setup',
  templateUrl: './report-setup.component.html',
  styleUrls: ['./report-setup.component.scss']
})

export class ReportSetupComponent implements OnInit {
  activeTab                   = 1;
  reportId                    = 0;
  loader                      = false;
  id                          = 0;
  fromDateConvert: Date       = new Date();
  toDateConvert: Date         = new Date();
  isDateConvert               = false;
  is_filter                   = false;

  scheduledReportForm: FormGroup = this.formBuilder.group({
    schedule: this.formBuilder.group({
      scheduler_name          : ['',[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
      schedule                : [,[Validators.required]],
      report_scheduler_time   : ["00:00:00",[Validators.required]],
      schedule_start_date     : ['',[Validators.required]],
      schedule_end_date       : ['',[Validators.required]],
      description             : ['',[Validators.required, Validators.maxLength(500)]],
      report                  : ['',[Validators.required]],
      report_scheduler_day    : [],
      report_scheduler_date   : [],
      report_scheduler_month  : []
    },
    { validators: CustomDateValidators.fromToDate('schedule_start_date', 'schedule_end_date') }
    ),

    rights: this.formBuilder.group({
      company                 : [''],
      businessunit            : [''],
      department              : [''],
      designation             : [''],
      grade                   : [''],
      branch                  : [''],
      employee                : ['']
    }),

  });

  constructor(
    private _location: Location,
    private formBuilder:FormBuilder,
    private reportService:ReportsService,
    public router: Router,
    public route:ActivatedRoute,
    private appservice:AppService,
    private previousRouteService: PreviousRouteService


    ) { }

  backClicked() {
    this._location.back();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if((params['edit'])){
        if (!isNaN(params['id'])) {
            this.id = params['id'];
            this.editForm();
          }
      }else{
        if(!isNaN(params['id'])){
            this.reportId = params['id'];
            this.is_filter = params['isfilter']
            this.scheduledReportForm['controls'].schedule.get('report')?.reset([Number(this.reportId)]);
          }
      }
    })
  }

  editForm(){
    this.loader = true;
    if(this.id != undefined && this.id != null){
      this.reportService.scheduleReportGet(this.id).subscribe((res:any)=>{
        this.loader       = false;
        this.setEditForm(res);
        return
      },(error)=>{
        this.router.navigate(['pay-component']);
      });
    }
  }

  setEditForm(res:any){
    this.scheduledReportForm['controls'].rights.reset(res.scheduled_report_right[0]);
    this.scheduledReportForm['controls'].schedule.get('scheduler_name')?.reset(res.scheduler_name);
    this.scheduledReportForm['controls'].schedule.get('schedule')?.reset(res.schedule);


    this.scheduledReportForm['controls'].schedule.get('report_scheduler_time')?.reset(res.report_scheduler_time);

    this.scheduledReportForm['controls'].schedule.get('schedule_end_date')?.reset(res.schedule_end_date);
    this.scheduledReportForm['controls'].schedule.get('schedule_start_date')?.reset(res.schedule_start_date);
    this.scheduledReportForm['controls'].schedule.get('description')?.reset(res.description);

    this.scheduledReportForm['controls'].schedule.get('report')?.reset(res.report);
    this.scheduledReportForm['controls'].schedule.get('report_scheduler_day')?.reset(res.report_scheduler_day);
    this.scheduledReportForm['controls'].schedule.get('report_scheduler_date')?.reset(res.report_scheduler_date);
    this.scheduledReportForm['controls'].schedule.get('report_scheduler_month')?.reset(res.report_scheduler_month);

    this.is_filter    = res.is_filter;

  }

  get scheduleForm() {
    return this.scheduledReportForm.get('schedule') as FormGroup;
  }

  get rightsForm(){
    return this.scheduledReportForm.get('rights') as FormGroup;
  }

  submitOne(){
      this.activeTab = 2;
  }

  currentrightArray:any = [];

  submitForm(){
    let scheduler_name          = this.scheduledReportForm.getRawValue().schedule['scheduler_name'];
    let schedule                = this.scheduledReportForm.getRawValue().schedule['schedule'];
    let report_scheduler_time   = this.scheduledReportForm.getRawValue().schedule['report_scheduler_time'];
    let schedule_start_date     = this.appservice.dateFormatConvert(this.scheduledReportForm.getRawValue().schedule['schedule_start_date']);
    let schedule_end_date       = this.appservice.dateFormatConvert(this.scheduledReportForm.getRawValue().schedule['schedule_end_date']);
    let description             = this.scheduledReportForm.getRawValue().schedule['description'];
    let report                  = this.scheduledReportForm.getRawValue().schedule['report'];
    let report_scheduler_month  = this.scheduledReportForm.getRawValue().schedule['report_scheduler_month'];
    let report_scheduler_day    = this.scheduledReportForm.getRawValue().schedule['report_scheduler_day'];
    let report_scheduler_date   =this.scheduledReportForm.getRawValue().schedule['report_scheduler_date'];

    this.currentrightArray.push({
      "company"       : (this.scheduledReportForm.getRawValue().rights.company!="")?this.scheduledReportForm.getRawValue().rights.company:[],
      "businessunit"  : (this.scheduledReportForm.getRawValue().rights.businessunit!="")?this.scheduledReportForm.getRawValue().rights.businessunit:[],
      "department"    : (this.scheduledReportForm.getRawValue().rights.department!="")?this.scheduledReportForm.getRawValue().rights.department:[],
      "designation"   : (this.scheduledReportForm.getRawValue().rights.designation!="")?this.scheduledReportForm.getRawValue().rights.designation:[],
      "branch"        : (this.scheduledReportForm.getRawValue().rights.branch!="")?this.scheduledReportForm.getRawValue().rights.branch:[],
      "grade"         : (this.scheduledReportForm.getRawValue().rights.grade!="")?this.scheduledReportForm.getRawValue().rights.grade:[],
      "employee"      : (this.scheduledReportForm.getRawValue().rights.employee!="")?this.scheduledReportForm.getRawValue().rights.employee:[],
    });

    if(this.id == 0){
      this.reportService.scheduleReportCreate({'scheduled_report_right': this.currentrightArray, 'scheduler_name':scheduler_name, 'schedule': schedule, 'report_scheduler_time': report_scheduler_time,'schedule_start_date': schedule_start_date,'schedule_end_date': schedule_end_date, 'description':description, 'report': report,'report_scheduler_month': report_scheduler_month,'report_scheduler_day': report_scheduler_day,'report_scheduler_date': report_scheduler_date,'is_filter': this.is_filter}).subscribe((res:any)=>{
        this.router.navigate(['scheduled-reports']);
      });
    }else{
      this.reportService.scheduleReportUpdate(this.id,{'scheduled_report_right': this.currentrightArray, 'scheduler_name':scheduler_name, 'schedule': schedule, 'report_scheduler_time': report_scheduler_time,'schedule_start_date': schedule_start_date,'schedule_end_date': schedule_end_date, 'description':description, 'report': report,'report_scheduler_month': report_scheduler_month,'report_scheduler_day': report_scheduler_day,'report_scheduler_date': report_scheduler_date,'is_filter': this.is_filter}).subscribe((res:any)=>{
        this.router.navigate(['scheduled-reports']);
      });
    }

  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
     if (this.scheduledReportForm['controls'].schedule.invalid) {
       changeEvent.preventDefault();
     }
   }
 }

 checkcommonFormValid(){
  if(this.scheduledReportForm.getRawValue().rights.company?.length != 0 || this.scheduledReportForm.getRawValue().rights.businessunit?.length != 0 || this.scheduledReportForm.getRawValue().rights.department?.length != 0 || this.scheduledReportForm.getRawValue().rights.designation?.length != 0 || this.scheduledReportForm.getRawValue().rights.grade?.length != 0 || this.scheduledReportForm.getRawValue().rights.branch?.length != 0 || this.scheduledReportForm.getRawValue().rights.employee?.length != 0){
    return true;
  } else{
    return false;
  }

}

}
