import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FinalSettlementService } from 'src/app/@core/services/final-settlement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { debounceTime } from 'rxjs/operators'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-final-settlement-recovery-or-pay',
  templateUrl: './final-settlement-recovery-or-pay.component.html',
  styleUrls: ['./final-settlement-recovery-or-pay.component.scss']
})
export class FinalSettlementRecoveryOrPayComponent implements OnInit {

  @Input() empId:any;
  @Input() noticePayAndClearenceData:any;
  @Input() payrollStatus:any;
  @Input() resignationDetails: any;


  @Output() submitmethod          = new EventEmitter();
  @Output() getOtherPaymentData   = new EventEmitter();
  @Output() warningMsg            = new EventEmitter();

  recoveryName:any         = 'notice';
  submitted                = false;
  saveClicked              = false;
  @Input()errnoticeCompmsg : any
  @Input()errclearenceCompmsg : any
  @Input()errBothCompmsg : any

  private ngModelChangeSubject = new Subject<any>();

  constructor(
    public finalSettlementService: FinalSettlementService,
    public router: Router,
    public route:ActivatedRoute,
    public appService: AppService,
    public messageService: MessageService,
    public fb:FormBuilder,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    this.ngModelChangeSubject.pipe(
      debounceTime(300),
    ).subscribe((value: any) => {
      this.payTypeChangeFunction(this.formGp.value.notice_paytype);
    });

  }

  onNgModelChange(status:any){
    this.ngModelChangeSubject.next(status);
  }

  get f() {return  this.formGp.controls; }


  @Input()formGp : FormGroup = this.fb.group({
    notice_paytype     : [''],
    no_of_days         : [''],
    no_of_days_edited  : [''],
    total_amount       : [''],
    clearance_comments : [''],
    clearance_amount   : ['']
  });


  getNoticePayAndClearence(){
    this.finalSettlementService.getNoticePay(this.empId,this.formGp.controls.notice_paytype.value,this.resignationDetails?.value?.pay_month,this.resignationDetails?.value?.pay_year).subscribe((res: any) => {
      this.noticePayAndClearenceData = res?.data;
      this.formGp.get('no_of_days_edited')?.setValue(this.noticePayAndClearenceData?.no_of_days_edited);
      this.formGp.get('no_of_days')?.setValue(this.noticePayAndClearenceData?.no_of_days);
      this.formGp.get('total_amount')?.setValue(this.noticePayAndClearenceData?.payable_amount);
      this.formGp.get('clearance_amount')?.setValue(this.noticePayAndClearenceData?.clearance_payable_amount);
    });
  }

  recoveryChangeFunction(e:any){
    if(e.target?.id == 'btnradio1'){
      this.recoveryName = 'notice';
      this.formGp.get('clearance_amount')?.clearValidators();
    }else{
      this.recoveryName = 'clearence';
      this.formGp.get('clearance_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(6)]);
    }
    this.formGp.get('clearance_amount')?.updateValueAndValidity();
  }

  saveNoticeClearence(){
    if(this.payrollStatus == 'Processed' || this.payrollStatus == 'Publish'){
      this.warningMsg.emit();
    }else{
      this.submitted = true;
      if (this.formGp.invalid) {
        return;
      }

      this.saveClicked = true;

      let type: any       = this.formGp.controls.notice_paytype.value == 'Notice pay' ? 'notice_pay' : 'notice_recovery';

      type = this.errnoticeCompmsg == true ? null : type;

      let no_of_days_edited = this.recoveryName == 'clearence' ? 0 : Number(this.formGp.controls.no_of_days_edited.value);
      let no_of_days = this.recoveryName == 'clearence' ? 0 : Number(this.formGp.controls.no_of_days.value);

      var dataArray;
      if(this.recoveryName == 'notice'){
        dataArray = {"step_name": 'notice_pay_and_clearance',"notice_paytype": type, "employee": this.empId,"no_of_days":no_of_days,"no_of_days_edited": no_of_days_edited,"amount":this.noticePayAndClearenceData?.amount,"payable_amount": Number(this.formGp.controls.total_amount?.value), "clearance_recovery": null}
      }else{
        dataArray = {"step_name": 'notice_pay_and_clearance',"notice_paytype": null, "employee": this.empId,"no_of_days":no_of_days,"no_of_days_edited": no_of_days_edited,"amount":this.noticePayAndClearenceData?.amount,"payable_amount": Number(this.formGp.controls.total_amount?.value), "clearance_recovery": { "clearance_amount": Number(this.noticePayAndClearenceData?.clearance_amount),"clearance_payable_amount": Number(this.formGp.controls.clearance_amount?.value),"clearance_comment": this.formGp.controls.clearance_comments?.value}}
      }

      this.finalSettlementService.finalSettlementSave(dataArray).subscribe((res: any) => {

        this.saveClicked = false;
        // this.getOtherPaymentData.emit();
        if(res?.status == 1)
          this.notificationService.handleSuccessNotification("Created successfully.","Success");
      },(error)=>{
        this.saveClicked = false;
      });
    }
  }

  payTypeChangeFunction(paytype:any){
    this.getNoticePayAndClearence();
  }

  submitRecovery(){
    this.submitmethod.emit();
  }

}
