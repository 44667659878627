import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-leave-balance-import',
  templateUrl: './leave-balance-import.component.html',
  styleUrls: ['./leave-balance-import.component.scss']
})
export class LeaveBalanceImportComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }


}
