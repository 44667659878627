<form [formGroup]="scheduledReportForm">
  <div class=" config-layout">
    <header class=" config-header">
      <h5 class="m-0">Schedule Report</h5>
      <button class="btn-reset text-light-600" (click)="backClicked()"><i class="icon-close-lg fs-16"></i></button>
    </header>
    <app-fw-edit-loader *ngIf="loader"></app-fw-edit-loader>
    <ng-container *ngIf="!loader">
      <aside class=" config-sidebar ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="steps gap-24">
          <li [ngbNavItem]="1">
            <a ngbNavLink class="step" [class]="{'success':scheduledReportForm['controls'].schedule.valid}">
              <div class="count">1</div>
              <div class="content">
                <span>Step 1</span>
                <h6>Scheduler Details</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-rs-schedule-details [formGp]="scheduleForm" (submitmethod)="submitOne()"></app-rs-schedule-details>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="step" [class]="{'success':checkcommonFormValid()}">
              <div class="count">2</div>
              <div class="content">
                <span>Step 2</span>
                <h6>Assign Report</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-rs-assign-report [formGp]="rightsForm" (submitmethod)="submitForm()"></app-rs-assign-report>
            </ng-template>
          </li>
        </ul>
      </aside>
      <main class=" config-container">
        <div [ngbNavOutlet]="nav"></div>
      </main>
    </ng-container>
  </div>
</form>
