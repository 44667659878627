<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3 class="mb-0">Reports</h3>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='tasksearch'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchpayroll($event)" [isLoading]="sLoading"></app-searchbar>
        </div>

      </div>
    </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-md-4 col-sm-6" [cardType]="'card5'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

   <div *ngIf="nodata && !loader;else all_data"  class="row pt-4"  style="height: calc(100vh - 14rem);">
    <app-nodata-view class="vstack" [imgHeight]="'16rem'" [noDataImg]="'bg2.png'"
      [noDataText]="'No reports added yet'"
      [hasPermission]="false"> </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <div class="row row-16 ">

        <ng-container *ngIf="loader==false">
          <div class="col-12">
            <p class="mb-0 fw-500">Payroll ({{reports.length}} Reports)</p>
          </div>
          <div class=" col-md-4 col-sm-6" *ngFor="let report of reports,let i = index">
            <div class="card card-c2 card-hover card-highlight highlight-primary h-100"
              (click)="reportTableView(report.name,report.id)">
              <div class="card-body p-0 d-flex align-items-start">
                <div class="d-flex px-16 pt-16 pb-8 w-100">
                  <div class="overflow-hidden ">
                    <p class="fw-500 text-trim mb-0">{{report.name}}</p>
                  </div>
                </div>
                <!-- <div  ngbDropdown class="pe-16 pt-16 pb-8 dropdown-section d-inline-block "  *ngIf="schedulePermissions1 && schedulePermissions1.a">
                                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                                    ngbDropdownToggle>
                                    <i class="icon-three-dots-vertical fs-16"></i>
                                </button>
                                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                                    <button ngbDropdownItem routerLink="/scheduled-reports/reports-setup/{{report.id}}/false">
                                        Schedule report
                                    </button>

                                </div>
                            </div> -->


              </div>
              <div class="card-body px-16 pb-16 pt-0 border-0">
                <p class="text-light-400 fs-14 mb-0">{{report.description}}</p>
              </div>

            </div>
          </div>
          <!-- <div class=" col-md-4 col-sm-6">
                    <div   class="card card-c2 card-hover card-highlight highlight-primary">
                        <div class="card-body p-0 d-flex align-items-start">
                            <div class="d-flex px-16 pt-16 pb-8 view-section " routerLink="/employee-reports-config" >
                                <div class="overflow-hidden ">
                                    <p class="fw-500 text-trim mb-0">Employee Language</p>
                                </div>
                            </div>
                            <div  ngbDropdown class="pe-16 pt-16 pb-8 dropdown-section d-inline-block " >
                                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                                    ngbDropdownToggle>
                                    <i class="icon-three-dots-vertical fs-16"></i>
                                </button>
                                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                                    <button ngbDropdownItem routerLink="/reports-setup">
                                        Schedule report
                                    </button>

                                </div>
                            </div>


                        </div>
                        <div class="card-body px-16 pb-16 pt-0 border-0" routerLink="/employee-reports-config">
                            <p class="text-light-400 fs-14 mb-0">Lorem ipsum dolor sit amet, sed consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>

                      </div>
                </div> -->
          <!-- <div class=" col-md-4 col-sm-6">
                    <div   class="card card-c2 card-hover card-highlight highlight-primary">
                        <div class="card-body p-0 d-flex align-items-start">
                            <div class="d-flex px-16 pt-16 pb-8 view-section " routerLink="/employee-reports-config" >
                                <div class="overflow-hidden ">
                                    <p class="fw-500 text-trim mb-0">Employee Family Details</p>
                                </div>
                            </div>
                            <div  ngbDropdown class="pe-16 pt-16 pb-8 dropdown-section d-inline-block " >
                                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                                    ngbDropdownToggle>
                                    <i class="icon-three-dots-vertical fs-16"></i>
                                </button>
                                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                                    <button ngbDropdownItem routerLink="/reports-setup">
                                        Schedule report
                                    </button>

                                </div>
                            </div>


                        </div>
                        <div class="card-body px-16 pb-16 pt-0 border-0" routerLink="/employee-reports-config">
                            <p class="text-light-400 fs-14 mb-0">Lorem ipsum dolor sit amet, sed consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>

                      </div>
                </div> -->
          <!-- <div class=" col-md-4 col-sm-6">
                    <div   class="card card-c2 card-hover card-highlight highlight-primary">
                        <div class="card-body p-0 d-flex align-items-start">
                            <div class="d-flex px-16 pt-16 pb-8 view-section " routerLink="/employee-reports-config" >
                                <div class="overflow-hidden ">
                                    <p class="fw-500 text-trim mb-0">Employee Personal Details </p>
                                </div>
                            </div>
                            <div  ngbDropdown class="pe-16 pt-16 pb-8 dropdown-section d-inline-block " >
                                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                                    ngbDropdownToggle>
                                    <i class="icon-three-dots-vertical fs-16"></i>
                                </button>
                                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                                    <button ngbDropdownItem routerLink="/reports-setup">
                                        Schedule report
                                    </button>

                                </div>
                            </div>


                        </div>
                        <div class="card-body px-16 pb-16 pt-0 border-0" routerLink="/employee-reports-config">
                            <p class="text-light-400 fs-14 mb-0">Lorem ipsum dolor sit amet, sed consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>

                      </div>
                </div> -->
          <!-- <div class=" col-md-4 col-sm-6">
                    <div   class="card card-c2 card-hover card-highlight highlight-primary">
                        <div class="card-body p-0 d-flex align-items-start">
                            <div class="d-flex px-16 pt-16 pb-8 view-section " routerLink="/employee-reports-config" >
                                <div class="overflow-hidden ">
                                    <p class="fw-500 text-trim mb-0">Employee Health Insurance</p>
                                </div>
                            </div>
                            <div  ngbDropdown class="pe-16 pt-16 pb-8 dropdown-section d-inline-block " >
                                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                                    ngbDropdownToggle>
                                    <i class="icon-three-dots-vertical fs-16"></i>
                                </button>
                                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                                    <button ngbDropdownItem routerLink="/reports-setup">
                                        Schedule report
                                    </button>

                                </div>
                            </div>


                        </div>
                        <div class="card-body px-16 pb-16 pt-0 border-0" routerLink="/employee-reports-config">
                            <p class="text-light-400 fs-14 mb-0">Lorem ipsum dolor sit amet, sed consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>

                      </div>
                </div> -->
        </ng-container>
        <!-- Loader updations -->
        <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-md-4 col-sm-6" [cardType]="'card5'"
            *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader" >
    <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
  </div> -->
  <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->

</div>
